<template>
  <w-crud-table
    ref="crud"
    title="Lista de materiais"
    crud-endpoint="material-item"
    sort-by="name"
    class="elevation-2"
    :before-send="beforeSend"
    :custom-save="actionSave"
    :custom-update="actionUpdate"
    :fill-update-form="fillForm"
    :model-generator="() => generateModel()"
    :headers="headers"
    :can-create="true"
    :can-edit="canEdit"
    :can-delete="canEdit"
  >
    <template #form="{ item }">
      <v-text-field label="Código" v-model="item.code" class="required" :rules="[$validationRules.required.rule]" />
      <v-autocomplete
        dense
        label="Material"
        :items="materialList || []"
        return-object
        :item-text="(data) => data.name + (data.description === 'Não informado' ? '' : ' - ' + data.description)"
        item-value="id"
        class="required"
        :rules="[$validationRules.required.rule]"
        v-model="item.material"
      />
      <v-autocomplete
        ref="autocomplete"
        dense
        label="Marca"
        :items="materialBrands || []"
        return-object
        :item-text="(data) => data.name"
        item-value="id"
        class="required"
        :rules="[$validationRules.required.rule]"
        v-model="item.brand"
      />

      <v-combobox
        ref="autocomplete"
        dense
        label="Tamanho"
        :items="materialSizes || []"
        class="required"
        :rules="[$validationRules.required.rule]"
        v-model="item.size"
      />

      <v-currency-field
        label="Preço unitário"
        v-model="item.unitaryPrice"
        :value-as-integer="false"
        :auto-decimal-mode="false"
        :decimal-length="5"
        :allow-negative="false"
      />
      <v-autocomplete
        v-if="isAdmin"
        ref="autocomplete"
        dense
        label="Imobiliária"
        :items="realEstateAgencies || []"
        return-object
        item-text="name"
        item-value="id"
        class="required"
        :rules="[$validationRules.required.rule]"
        v-model="item.realEstateAgency"
      />
    </template>
    <template #realEstateAgency="{ item }">
      <v-chip v-if="item.realEstateAgency" color="primary">{{ item.realEstateAgency.name }}</v-chip>
      <span v-else>-</span>
    </template>
  </w-crud-table>
</template>

<script>
import { MATERIAL_CATEGORIES } from '@/config/constants'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import api from '../api/services'
import WCrudTable from '../components/WCrudTable.vue'
createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { WCrudTable },
  data() {
    return {
      headers: [
        { text: 'Código', value: 'code' },
        { text: 'Material', value: 'material.name' },
        { text: 'Descrição do material', value: 'material.description' },
        { text: 'Marca', value: 'brand.name' },
        { text: 'Tamanho', value: 'size' },
        {
          text: 'Preço unitário',
          value: 'unitaryPrice',
          converter: (item) => this.$utils.formatCurrency(item.unitaryPrice, 6),
        },
        { text: 'Nome completo', value: 'fullName' },
        {
          text: 'Imobiliária',
          value: 'realEstateAgency',
        },
      ],

      materialSizes: [],
      materialBrands: [],
      materialList: [],
      realEstateAgencies: [],
      currentMaterial: null,

      actionSave: async (item) => {
        return await api.createMaterialItem(item)
      },

      actionUpdate: async (item) => {
        return await api.updateMaterialItem(item)
      },
    }
  },

  methods: {
    canEdit(item) {
      return this.isAdmin || item.realEstateAgency.id === this.myRealEstateAgency.id
    },
    fillForm(model, item) {
      item.material.categories = item.material.categories?.map((category) => {
        return { key: category.name, value: MATERIAL_CATEGORIES[category.name] }
      })

      this.$set(
        model,
        'material',
        this.materialList.find((m) => m.id === item.material.id),
      )
    },
    generateModel() {
      return {
        code: '',
        fullName: '',
        unitaryPrice: 0,
        brand: {
          name: '',
        },
        size: null,
        material: {
          name: '',
          description: '',
          categories: [],
        },
      }
    },
    beforeSend(item) {
      if (!item.id) return
      item.fullName = `${item.material.name}${
        item.material.description === MATERIAL_CATEGORIES.NAO_INFORMADO ? '' : ' ' + item.material.description
      }${item.brand.name === MATERIAL_CATEGORIES.NAO_INFORMADO ? '' : ' ' + item.brand.name}${
        item.size === MATERIAL_CATEGORIES.NAO_INFORMADO ? '' : ' ' + item.size
      }`
    },
  },

  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      myRealEstateAgency: 'myRealEstateAgency',
    }),
  },

  async created() {
    this.materialSizes = (await api.listMaterialSizes()).data.filter((s) => s !== MATERIAL_CATEGORIES.NAO_INFORMADO)
    this.materialBrands = (await api.listMaterialBrands()).data.filter(
      (b) => b.name !== MATERIAL_CATEGORIES.NAO_INFORMADO,
    )

    this.materialList = (await api.listMaterials()).data
    this.realEstateAgencies = (await api.getRealEstateAgencies()).data
  },
}
</script>

<style></style>
