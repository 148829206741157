<template>
  <v-sheet class="section-parent d-flex flex-column align-center flex-grow-0 fill-height">
    <h4 class="primary--text text-center" style="height: 60px">{{ title }}</h4>
    <v-sheet
      class="mt-4 transparent demand-card-wrapper d-flex flex-column fill-height"
      ref="slidesWrapper"
      :key="'slidesWrapper' + title"
    >
      <v-sheet
        v-for="(demand, index) in demandList"
        :key="'demand-' + title + index"
        @click="openDemand(demand)"
        class="click demand-card"
      >
        <div @click="goToDetails(demand.id)">
          <demand-card :demand="demand" @updateDemands="$emit('updateDemands')" />
        </div>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>
import services from '@/api/services'
import DemandCard from '../components/DemandCard.vue'

export default {
  components: { DemandCard },
  props: {
    title: {
      type: String,
    },
    demandList: {
      type: Array,
    },
  },
  computed: {
    visibleSlides() {
      return Math.min(this.demandList.length, Math.max(1, Math.floor(this.slidesWrapperWidth / 300)))
    },
  },
  data() {
    return {
      slidesWrapperWidth: 0,
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    goToDetails(demandId) {
      this.$router.push(`/demand/details/${demandId}`)
    },
    async openDemand(demand) {
      const fullDemand = (await services.getDemand(demand.id)).data
      this.$emit('click:demand', fullDemand)
    },
    handleResize(event) {
      if (this.$refs.slidesWrapper) {
        this.slidesWrapperWidth = this.$refs.slidesWrapper.$el.clientWidth
      }
    },
  },
}
</script>

<style scoped>
h4 {
  font-family: Raleway;
  font-size: 21px;
}
.click {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 16px;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 150ms;
}
.click:hover {
  border: 1px solid var(--v-primary-base);
}
.section-parent {
  background-color: white !important;
  border-radius: 16px 16px 0 0;
  padding: 20px;
  height: 100%;
  overflow: hidden;
  width: 334.24px;
  padding-bottom: 0;
}
.demand-card-wrapper {
  gap: 20px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.demand-card {
  width: 100%;
}
</style>
