<template>
  <div class="box">
    <div>
      <v-row v-for="(value, key) in data" :key="key">
        <v-col cols="4"
          ><span>{{ getTip(key) }}</span></v-col
        >
        <v-col><input class="input-social-media" v-model="data[key]" /></v-col>
        <v-col cols="1"
          ><v-icon>{{ getIcon(key) }}</v-icon></v-col
        >
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },

  methods: {
    firstLetterUpperCase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getTip(key) {
      return !this.isContact(key)
        ? 'www.' + this.$consts.SOCIAL_MEDIA_LABELS[key.toUpperCase()].toLowerCase() + '.com/'
        : this.$consts.SOCIAL_MEDIA_LABELS[key.toUpperCase()]
    },
    isContact(key) {
      return this.$consts.SOCIAL_MEDIA_LABELS.CONTACT === this.$consts.SOCIAL_MEDIA_LABELS[key.toUpperCase()]
    },
    getIcon(key) {
      return 'mdi-' + this.$consts.SOCIAL_MEDIA_LABELS[key.toUpperCase()].toLowerCase()
    },
  },

  computed: {
    data: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style scoped>
.input-social-media {
  border: 1px solid rgba(109, 110, 113, 1);
  border-radius: 4px;
  padding: 4px;
  width: 100%;
}
</style>
