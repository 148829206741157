<template>
  <div class="loginform d-flex justify-center align-center">
    <v-card class="pb-4 px-8" width="400" :loading="loading" :disabled="loading">
      <div class="d-flex justify-center mt-8" width="100%">
        <Logo />
      </div>
      <v-card-title class="pt-8">Login</v-card-title>
      <v-card-text style="position: relative">
        <v-form ref="formlogin">
          <v-text-field
            class="my-2"
            type="text"
            v-model="email"
            label="Email"
            placeholder="Digite seu email"
            validate-on-blur
            :rules="[$validationRules.required.rule, $validationRules.email.rule]"
            @keyup.enter="login"
          />
          <v-text-field
            class="my-2"
            type="password"
            v-model="password"
            label="Senha"
            placeholder="Digite sua senha"
            :rules="[$validationRules.required.rule]"
            validate-on-blur
            @keyup.enter="login"
          />
          <v-btn
            tabindex="-1"
            style="position: absolute; bottom: 20px; right: 16px"
            dense
            text
            x-small
            color="primary"
            @click="
              () => {
                $refs.formlogin.resetValidation()
                forgotPassword = true
              }
            "
            >Esqueci a senha</v-btn
          >
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn color="primary" @click="login" :loading="loading">entrar</v-btn>
      </v-card-actions>

      <v-expand-transition>
        <v-card v-if="forgotPassword" class="d-flex flex-column transition-fast-in-fast-out v-card--forgot pb-4 px-8">
          <v-btn
            icon
            color="primary"
            @click="
              () => {
                $refs.formforgot.resetValidation()
                forgotPassword = false
              }
            "
            style="position: absolute; left: 8px; top: 8px"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-card-subtitle class="subtitle-2 font-weight-bold mt-4 pt-12 pb-2"
            >Um email de redefinição de senha será enviado para o endereço informado abaixo:</v-card-subtitle
          >
          <v-card-text class="flex-grow-1">
            <v-form ref="formforgot">
              <v-text-field
                class="my-2"
                type="text"
                v-model="email"
                label="Email"
                placeholder="Digite seu email"
                :rules="[$validationRules.mandatory, $validationRules.email]"
                validate-on-blur
                @keyup.enter="sendForgottenPasswordEmail"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="sendForgottenPasswordEmail" :loading="loading">enviar</v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
      <w-alert ref="alert" />
    </v-card>
  </div>
</template>

<script>
import api from '../api/services'
import Logo from '../assets/logo-workay.svg'
import WAlert from '../components/WAlert.vue'
import store from '../store'

export default {
  components: { WAlert, Logo },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      forgotPassword: false,
    }
  },
  methods: {
    getErrorMessage(error) {
      switch (error.code) {
        case 'auth/invalid-email':
          return 'Informe um email válido'
        case 'auth/wrong-password':
          return 'Senha incorreta'
        case 'auth/internal-error':
          return 'Houve um erro ao fazer login'
        case 'auth/user-not-found':
          return 'Usuário não existe ou foi removido'
        case 'auth/too-many-requests':
          return 'Excedido o número máximo de tentativas de login. Aguarde alguns minutos e tente novamente.'
        default:
          return error.message
      }
    },
    sendForgottenPasswordEmail() {
      if (!this.$refs.formforgot.validate()) {
        return
      }
      this.loading = true
      this.$auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.$root.info = 'Email enviado. Verifique sua caixa de mensagens.'
          this.forgotPassword = false
        })
        .catch((error) => {
          console.error(error)
          this.$refs.alert.showError(this.getErrorMessage(error))
        })
        .finally(() => (this.loading = false))
    },
    login() {
      this.$root.error = null

      if (!this.$refs.formlogin.validate()) {
        return
      }

      this.loading = true

      this.$auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async (data) => {
          const user = await api.getUserByEmail(data.user.email)

          store.commit('setAdmin', user.data.admin)

          if (store.state.user.roles.length === 0) {
            const response = await api.getRoles()

            store.commit('setRoles', response.data)
          }

          await api.updateUserFirstAccess(user.data.id)

          const permission = await Notification.requestPermission()
          if (permission == 'granted') {
            const messaging = this.$root.firebaseInstance.messaging()

            const userFirebaseToken = await messaging.getToken({
              vapidKey: process.env.VUE_APP_FIREBASE_MESSAGING_VAPID_KEY,
            })

            store.dispatch('setFirebaseToken', userFirebaseToken)
          }

          this.$router.replace('/summary')
          this.$root.signedIn = true
        })
        .catch((error) => {
          console.log('falha no login')
          console.log(error)
          this.$root.signedIn = false
          console.error(error)
          this.$refs.alert.showError(this.getErrorMessage(error))
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped>
.loginform {
  height: 100%;
  width: 100%;
}
.v-card--forgot {
  bottom: 0;
  left: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
