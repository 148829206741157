export const getTasksPhotos = (services) => {
  return services.reduce((acc, current) => {
    const taskImagesUrl = current.tasks.flatMap((task) => task.images.map((taskImage) => taskImage.url))

    if (taskImagesUrl.length === 0) return acc

    const categoryId = current.serviceCategory.id
    const categoryName = current.serviceCategory.name

    if (!acc[categoryId]) {
      acc[categoryId] = {
        taskImagesUrl,
        categoryName,
      }
    } else {
      acc[categoryId].taskImagesUrl = acc[categoryId].taskImagesUrl.concat(taskImagesUrl)
    }

    return acc
  }, {})
}
