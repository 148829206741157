<template>
  <v-sheet class="parent px-12 d-flex flex-column">
    <div class="d-flex flex-row justify-space-between align-center" style="gap: 100px">
      <input-filter placeholder="Busque um endereço" @search="getFilteredDemands($event)" v-if="showDashboardView" />
      <v-sheet
        id="visualization-mode"
        class="d-flex flex-row align-center justify-end transparent"
        :style="!showDashboardView ? 'margin-left: auto' : ''"
      >
        <span class="font-weight-bold mr-2">Visualizar por:</span>
        <v-btn icon @click="showDashboardView = true" :class="{ 'white primary--text': showDashboardView }"
          ><v-icon>mdi-view-dashboard</v-icon></v-btn
        >
        <v-btn icon @click="showDashboardView = false" :class="{ 'white primary--text': !showDashboardView }"
          ><v-icon>mdi-format-list-bulleted</v-icon></v-btn
        >
      </v-sheet>
    </div>

    <v-sheet
      v-if="!demands?.length"
      class="transparent text-center d-flex flex-column align-center justify-center"
      style="gap: 16px; margin-top: 25vh"
    >
      <v-sheet class="transparent" style="width: 250px">
        <Logo />
      </v-sheet>
      <h1>{{ loading ? 'Carregando demandas...' : 'Não existem solicitações cadastradas' }}</h1>
    </v-sheet>
    <v-slide-x-transition mode="out-in" v-else>
      <div id="content">
        <v-slide-x-transition mode="out-in">
          <dashboard :sections="dashboardSections" v-if="showDashboardView" @updateDemands="$emit('updateDemands')" />
          <demand-list v-else ref="demand-list" :demands="demands" @updateDemands="$emit('updateDemands')" />
        </v-slide-x-transition>
      </div>
    </v-slide-x-transition>
    <v-sheet id="new-demand-wrapper" class="transparent d-flex flex-row align-center justify-end mt-auto">
      <v-btn rounded class="WYellow" @click="openDemandDialog()"
        ><v-icon class="mr-2">mdi-plus</v-icon
        ><span class="text-none black--text font-weight-bold">Criar Novo Serviço</span></v-btn
      >
    </v-sheet>
    <v-dialog v-model="demandDialog" max-width="1200px">
      <v-sheet class="pa-8 py-4" style="position: relative; width: 100%">
        <v-icon id="close-dialog" large color="black" @click="closeDialog">mdi-close</v-icon>
        <create-demand @save="onSaveDemand" @cancel="closeDialog" />
      </v-sheet>
    </v-dialog>
  </v-sheet>
</template>

<script>
import InputFilter from '@/components/InputFilter.vue'
import Logo from '../assets/logo-workay.svg'
import CreateDemand from '../components/CreateDemand.vue'
import Dashboard from '../components/Dashboard.vue'
import DemandList from '../components/DemandList.vue'

export default {
  components: { Logo, Dashboard, DemandList, CreateDemand, InputFilter },
  props: {
    dashboardSections: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    demands: {
      type: Array,
    },
    filteredDemands: {
      type: Array,
    },
  },
  data() {
    return {
      showDashboardView: true,
      demandDialog: false,
    }
  },
  methods: {
    getFilteredDemands(text) {
      const filteredDemands = this.demands.filter((demand) => {
        const { address, addressComplement, addressNumber, district } = demand
        const addressString = `${address} ${addressComplement} ${addressNumber} ${district}`
        return addressString.toLowerCase().includes(text.toLowerCase())
      })

      this.$emit('setDemands', filteredDemands)
    },
    async onSaveDemand() {
      this.closeDialog()
    },
    closeDialog() {
      this.demandDialog = false
    },
    openDemandDialog() {
      this.demandDialog = true
    },
  },
}
</script>
<style scoped>
#new-demand-wrapper {
  height: 60px;
}
.parent {
  gap: 10px;
  height: 100%;
}
#content {
  height: calc(100% - 120px);
  overflow-x: auto;
}
#close-dialog {
  position: absolute;
  right: 32px;
}
</style>
