let mediaRecorder
let chunks = []

export async function startRecordingAudio(callback) {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder = new MediaRecorder(stream)
        const audioContext = new (window.AudioContext || window.webkitAudioContext)()
        const audioChunks = []

        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunks.push(e.data)
          }
        }

        mediaRecorder.onstop = async () => {
          if (audioChunks.length > 0) {
            const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' })

            const audioBuffer = await audioBlob.arrayBuffer()
            audioContext.decodeAudioData(audioBuffer, async (buffer) => {
              await callback(audioBlob)
            })
            audioChunks.length = 0
          } else {
            console.error('Nenhum dado de áudio gravado.')
          }
        }

        mediaRecorder.start()
      })
      .catch((err) => {
        console.error(`The following getUserMedia error occurred: ${err}`)
      })
  } else {
    console.error('getUserMedia not supported on your browser!')
  }
}

export async function stopRecordingAudio() {
  if (mediaRecorder && mediaRecorder.state === 'recording') {
    mediaRecorder.stop()
  } else {
    console.warn('A gravação não está em andamento.')
  }
}
