<template>
  <div id="new-chat-wrapper" class="d-flex flex-column">
    <div id="header">
      <div id="header-box" class="d-flex justify-space-between" style="gap: 16px">
        <div class="d-flex align-center" style="gap: 16px">
          <div id="title" class="d-flex align-center">
            <v-icon
              @click="
                $emit('navigateTo', {
                  tabName: $consts.CHAT_NAVIGATION_TAB.CHAT_LIST,
                })
              "
              color="white"
              >mdi-chevron-left</v-icon
            >
            <p>Selecionar novo chat</p>
          </div>
        </div>
      </div>
    </div>

    <div id="search" class="ma-4 px-4"><v-text-field placeholder="Pesquise um contato" v-model="filterText" /></div>

    <div v-if="!filteredContacts">
      <div v-for="index in 5" :key="index">
        <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
      </div>
    </div>

    <div id="contacts" class="d-flex flex-column">
      <div v-for="(contact, index) in filteredContacts" :key="index">
        <user-card
          type="contact"
          :contact="contact"
          @currentChat="(currentChat) => setCurrentChat(currentChat)"
          :is-selected="contact.id === currentChat"
        />
        <v-divider style="opacity: 50%" />
      </div>
    </div>
  </div>
</template>
<script>
import services from '@/api/services'
import { createNamespacedHelpers } from 'vuex'
import UserCard from './UserCard.vue'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { UserCard },

  data() {
    return {
      contacts: null,
      filterText: '',
      currentChat: null,
    }
  },
  async created() {
    this.contacts = (await services.getChatContacts()).data
  },
  computed: {
    filteredContacts() {
      return this.contacts?.filter(
        (contact) =>
          contact.name.toLowerCase().startsWith(this.filterText.trim().toLowerCase()) ||
          contact.workPartnerDetails.phone.replaceAll(/[()\s-]/g, '').includes(this.filterText.trim()),
      )
    },
  },
  methods: {
    setCurrentChat(chat) {
      this.currentChat = chat
      this.$emit('currentChat', this.currentChat)
    },
  },
}
</script>
<style scoped>
#new-chat-wrapper {
  height: 100%;
}

#search {
  background-color: #f2f4f7;
  border-radius: 32px;
}

#header {
  padding: 24px 16px;
  background-color: var(--v-primary-base);
}

#title p {
  color: white;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

#title {
  gap: 8px;
}

::v-deep .v-input__slot::before {
  display: none;
}

::v-deep .v-text-field__details {
  display: none;
}

:deep(.v-input.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder) {
  margin: 0;
  padding: 0;
}

:deep(.v-input__slot) {
  margin: 0;
}

:deep(.v-input__slot::after) {
  border-style: none !important;
}
</style>
