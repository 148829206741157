<template>
  <v-app class="app">
    <v-app-bar color="primary" app dark v-if="$vuetify.breakpoint.smAndDown">
      <v-app-bar-nav-icon @click.stop="showMenu = !showMenu" v-if="$root.signedIn"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-sheet class="transparent" style="height: 40px; width: 100%" contain
          ><img src="@/assets/logo_dark.png" style="height: 100%" /></v-sheet
      ></v-toolbar-title>
    </v-app-bar>

    <v-main style="height: 100vh; overflow-y: auto">
      <div id="content">
        <v-navigation-drawer
          v-if="$root.signedIn"
          :permanent="$vuetify.breakpoint.mdAndUp"
          :absolute="$vuetify.breakpoint.smAndDown"
          :temporary="$vuetify.breakpoint.smAndDown"
          v-model="showMenu"
          style="min-width: 256px"
        >
          <Menu v-if="$root.signedIn && (showMenu || $vuetify.breakpoint.mdAndUp)" class="flex-shrink-0" />
        </v-navigation-drawer>
        <v-fade-transition mode="out-in">
          <router-view class="bg flex-grow-1 pa-5" style="height: 100vh; overflow-y: auto" />
        </v-fade-transition>
      </div>
    </v-main>
    <w-alert ref="globalAlert" id="globalAlert" />
    <w-dialog v-model="showNotificationDialog" :buttons="notificationModalButtons" :dismissible="false">
      <div>
        <h2 class="mb-2">Você precisa ativar as suas notificações para acessar todos os recursos da Workay.</h2>
        <p>Você pode perder acesso a alguns recursos caso não ative. Qualquer dúvida, entre em contato conosco.</p>
      </div>
    </w-dialog>
    <w-notifications ref="globalNotifications" id="globalNotifications" />
    <v-snackbar :value="systemNeedsUpdate && $root.signedIn" timeout="-1" top>
      Há uma nova versão do sistema: {{ latestVersion }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="forceUpdateSystem"> atualizar </v-btn>
      </template>
    </v-snackbar>
    <w-crm-rating-modal v-model="showCrmRatingModal" :editable="true" />
    <w-partner-rating-modal v-model="showPartnerRatingModal" :partner-rating="pendingWorkPartnerRatings[0] || {}" />
  </v-app>
</template>

<script>
import '@firebase/messaging'
import axios from 'axios'
import firebase from 'firebase'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import api from './api/services'
import Menu from './components/Menu.vue'
import WAlert from './components/WAlert.vue'
import WCrmRatingModal from './components/WCrmRatingModal.vue'
import WDialog from './components/WDialog.vue'
import WNotifications from './components/WNotifications.vue'
import WPartnerRatingModal from './components/WPartnerRatingModal.vue'
import store from './store'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { WDialog, Menu, WAlert, WNotifications, WCrmRatingModal, WPartnerRatingModal },
  computed: {
    notificationModalButtons() {
      return [
        {
          label: 'Ok',
          action: () => {
            this.showNotificationDialog = false
            const permission = Notification.requestPermission()
            this.onAcceptNotification(permission)
          },
        },
      ]
    },
    systemNeedsUpdate() {
      return this.localVersion !== this.latestVersion
    },
    ...mapGetters({
      userFirebaseToken: 'userFirebaseToken',
      pendingWorkPartnerRatings: 'pendingWorkPartnerRatings',
    }),
  },
  data() {
    return {
      showNotificationDialog: false,
      localVersion: '',
      latestVersion: '',
      showCrmRatingModal: false,
      showPartnerRatingModal: false,

      partnerToRating: {},
      showMenu: this.$vuetify.breakpoint.mdAndUp,
    }
  },
  methods: {
    async onAcceptNotification(permission) {
      const currentUser = firebase.auth().currentUser

      if (currentUser && permission === 'granted') {
        const messaging = this.$root.firebaseInstance.messaging()

        const userFirebaseToken = await messaging.getToken({
          vapidKey: process.env.VUE_APP_FIREBASE_MESSAGING_VAPID_KEY,
        })

        store.dispatch('setFirebaseToken', userFirebaseToken)

        messaging.onMessage(async ({ notification }) => {
          const { title, body } = notification

          new Notification(title, {
            body,
            icon: '/favicon.png',
          })

          store.state.globalNotificationsComponent.addNotification(title, body)
        })
      }
    },
    signOut() {
      this.$auth.signOut().then(() => {
        this.$root.signedIn = false
        this.$router.replace('login')
      })
    },
    checkVersion() {
      axios
        .create({
          baseURL: '/',
          headers: {
            Accept: 'text/plain',
            'Content-Type': 'text/plain',
          },
        })
        .get(`version.txt?timestamp=${new Date().getTime()}`)
        .then((r) => {
          this.localVersion = localStorage.getItem('version')
          if (!this.localVersion) {
            this.localVersion = r.data
            localStorage.setItem('version', r.data)
          }
          this.latestVersion = r.data
        })
    },
    forceUpdateSystem() {
      localStorage.setItem('version', this.latestVersion)
      this.localVersion = this.latestVersion
      window.location.reload()
    },
  },
  async mounted() {
    store.dispatch('setGlobalAlertComponent', this.$refs.globalAlert)
    store.dispatch('setGlobalNotificationsComponent', this.$refs.globalNotifications)
    await this.checkVersion()
  },

  async created() {
    if (Notification.permission !== 'granted') {
      this.showNotificationDialog = true
    }

    const currentUser = firebase.auth().currentUser

    if (currentUser) {
      const needsToRateCrm = (await api.verifyNeedsToRateCrm()).data

      if (needsToRateCrm) {
        this.showCrmRatingModal = true
      }

      store.dispatch('verifyNeedsToRateWorkPartner')
    }
  },

  watch: {
    pendingWorkPartnerRatings(val) {
      this.showPartnerRatingModal = !!this.pendingWorkPartnerRatings?.length
    },
  },
}
</script>

<style lang="scss">
html {
  overflow-y: auto;
}

.vueperslides__arrow {
  font-size: 8px !important;
}
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
}
#content {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#globalAlert .v-alert {
  top: 10vh !important;
}

.cls-1 {
  fill: #222;
}
.cls-2 {
  fill: #27ae60;
}
.vueperslides__bullet {
  color: var(--v-primary-base) !important;
}

.required label::after {
  content: ' *';
  color: red;
}
</style>
