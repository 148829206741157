<template>
  <w-crud-table
    ref="crud"
    title="Imobiliárias"
    crud-endpoint="real-estate-agency"
    sort-by="name"
    create-button-text="Nova imobiliária"
    dialog-new-item="Adicionar imobiliária"
    dialog-edit-item="Editar imobiliária"
    :headers="headers"
    :can-create="true"
    :can-edit="true"
    :can-delete="true"
    :customSave="save"
    :customUpdate="update"
    :model-generator="() => generateModel()"
  >
    <template #form="{ item }">
      <h2 class="mb-4">Dados do Usuário</h2>
      <v-text-field label="Nome" v-model="item.name" class="required" :rules="[$validationRules.required.rule]" />
      <v-text-field
        label="Celular"
        v-mask="'(##) #####-####'"
        v-model="item.primaryPhoneNumber"
        class="required"
        :rules="[$validationRules.required.rule]"
      />
      <v-text-field
        label="E-mail"
        v-model="item.user.email"
        class="required"
        :rules="[$validationRules.required.rule, $validationRules.email.rule]"
      />
      <v-text-field
        label="CNPJ"
        v-model="item.cnpj"
        class="required"
        v-mask="['##.###.###/####-##']"
        :rules="[$validationRules.cnpj.rule, $validationRules.required.rule]"
      />
      <v-text-field label="Data de Ativação" class="required" disabled :value="$moment().format('DD/MM/YYYY')" />
      <v-text-field
        label="CEP"
        v-model="item.zipCode"
        class="required"
        v-mask="'#####-###'"
        :rules="[$validationRules.required.rule]"
        @blur="fillAddress(item)"
      />

      <v-text-field label="Endereço" v-model="item.address" class="required" disabled />
      <v-text-field label="Número" v-model="item.addressNumber" class="required" />
      <v-text-field label="Bairro" v-model="item.district" class="required" disabled />

      <v-text-field label="Cidade" :value="item.city?.name" class="required" disabled />
      <v-text-field label="Estado" :value="item.city?.state.name" class="required" disabled />

      <div class="pt-2">
        <span>Observações</span>
        <v-checkbox small v-model="item.publicProfile" color="primary">
          <template #label>
            <div class="d-flex align-center" style="gap: 4px">
              <span>Desejo compartilhar dados com a Workay</span>
              <button type="button" @click.stop="infoDialog = true">
                <v-icon dense color="primary">mdi-help-circle</v-icon>
              </button>
            </div>
          </template>
        </v-checkbox>

        <w-dialog title="Perfil público" v-model="infoDialog">
          <p>
            A Workay tem como valor a colaboração entre indivíduos, por isso encoraja tornar os perfis de profissionais,
            preços de materiais e tarefas personalizadas públicos, para que todos tenham mais chance de fechar reformas
            com mais qualidade e mais rápido.
          </p>
          <p>
            Algumas informações inseridas sobre materiais, serviços, etc. podem ser compartilhadas com outros usuários.
            A Workay nunca compartilha dados pessoais de cadastro com outros usuários.
          </p>
        </w-dialog>
      </div>
    </template>
  </w-crud-table>
</template>

<script>
import api from '@/api/services'
import { mask } from 'vue-the-mask'
import WCrudTable from '../components/WCrudTable.vue'
import WDialog from '../components/WDialog.vue'

export default {
  components: {
    WCrudTable,
    WDialog,
  },
  directives: {
    mask,
  },
  data() {
    return {
      headers: [
        { text: 'Nome da Imobiliária', value: 'name' },
        { text: 'E-mail', value: 'user.email' },
        { text: 'Telefone', value: 'primaryPhoneNumber' },
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'Bairro', value: 'district' },
        { text: 'Cidade', value: 'city.name' },
        { text: 'Estado', value: 'city.state.name' },
      ],
      infoDialog: false,
    }
  },
  methods: {
    async save(item) {
      return await api.addRealEstateAgency({
        name: item.name,
        primaryPhoneNumber: item.primaryPhoneNumber,
        email: item.user.email,
        cnpj: item.cnpj,
        zipCode: item.zipCode,
        address: item.address,
        addressNumber: item.addressNumber,
        district: item.district,
        ibgeCode: item.addressIbge,
        publicProfile: item.publicProfile,
      })
    },
    async update(item) {
      return await api.updateRealEstateAgency({
        id: item.id,
        name: item.name,
        primaryPhoneNumber: item.primaryPhoneNumber,
        email: item.user.email,
        cnpj: item.cnpj,
        zipCode: item.zipCode,
        address: item.address,
        addressNumber: item.addressNumber,
        district: item.district,
        ibgeCode: item.city.ibgeCode || item.addressIbge,
        publicProfile: item.publicProfile,
      })
    },
    generateModel() {
      return {
        user: {
          name: null,
        },
        city: {
          name: null,
          state: {
            name: null,
          },
        },
      }
    },

    async fillAddress(item) {
      const parsedZipCode = item.zipCode?.replace(/\D/g, '') ?? ''
      if (parsedZipCode.length != 8) {
        return
      }
      try {
        const address = await this.$viaCep.buscarCep(parsedZipCode)

        this.$set(item, 'address', address.logradouro)
        this.$set(item, 'district', address.bairro)
        this.$set(item, 'city', { name: address.localidade, state: { name: address.uf } })
        this.$set(item, 'addressIbge', address.ibge)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style>
h2 {
  color: black;
  font-size: 18px;
}
</style>
