<template>
  <div style="height: 100%; width: 100%">
    <LineChartGenerator :options="chartOptions" :data="display" />
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler)

export default {
  components: { LineChartGenerator },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      display: null,
      serviceCategories: [],

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,

        borderWidth: 1,
        barThickness: 30,

        plugins: {
          legend: {
            display: false,
          },
        },

        scales: {
          y: {
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
      },
    }
  },
  async created() {
    this.display = {
      labels: this.chartData.map((item) => {
        return item.label
      }),
      datasets: [
        {
          label: 'Serviços realizados',
          fill: true,
          backgroundColor: 'red',
          tension: 0.1,
          backgroundColor: 'rgba(247, 190, 0, 0.3)',
          borderColor: 'rgb(247, 190, 0)',
          data: this.chartData.map((item) => {
            return item.value
          }),
        },
      ],
    }
  },
}
</script>
