<template>
  <div class="about">
    <h1>OI</h1>
    <v-file-input accept="image/*" label="File input" v-model="file" />
    <img :src="fileUploaded" />
    <v-btn @click="uploadFile()">Enviar</v-btn>
    <!-- <teste v-model="visit" />
    <div>
      <p>Date: {{ visit.date }}</p>
      <p>Time: {{ visit.time }}</p>
      <p>Name: {{ visit.name }}</p>
    </div> -->
  </div>
</template>

<script>
// import Teste from '../components/Teste.vue'
import api from '../api/services'
export default {
  // components: { Teste },"

  data() {
    return {
      visit: {
        date: this.$moment().format('YYYY-MM-DD'),
        time: this.$moment().format('HH:mm'),
        name: 'João',
      },
      file: null,
      fileUploaded: null,
    }
  },

  methods: {
    async uploadFile() {
      this.fileUploaded = (await api.uploadFile(this.file)).data
    },
  },
}
</script>
