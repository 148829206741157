var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-dialog',{attrs:{"title":(_vm.type === 'add' ? 'Adicionar' : 'Editar') + ' Profissional',"maxWidth":600,"buttons":[
    {
      label: 'fechar',
      action: () => {
        this.close()
      },
    },
    {
      label: 'salvar',
      action: async () => {
        await this.save()
      },
      disabled: !_vm.item || _vm.item.length === 0,
    },
  ]},on:{"input":_vm.close},model:{value:(_vm.workPartnerDialog),callback:function ($$v) {_vm.workPartnerDialog=$$v},expression:"workPartnerDialog"}},[(_vm.workPartnerDialog)?_c('div',{staticStyle:{"height":"100%","width":"100%"}},[(_vm.type === 'add')?_c('w-add-associated-work-partner-form',{on:{"input":_vm.updateItem}}):_vm._e(),(_vm.type === 'edit')?_c('w-edit-associated-work-partner-form',{attrs:{"association":_vm.association},on:{"input":_vm.updateItem}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }