<template>
  <demands
    @setDemands="setDemands($event)"
    :dashboard-sections="dashboardSections"
    :loading="loading"
    :demands="allDemands(demands)"
    :filtered-demands="filteredDemands"
    @updateDemands="listDemands()"
  />
</template>

<script>
import api from '../api/services'
import Demands from './Demands.vue'

import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { Demands },
  methods: {
    allDemands(demands) {
      return this.demandsWithoutServices(demands)
        .concat(this.demandsWaitingForPartners(demands))
        .concat(this.demandsReadyForProposal(demands))
    },
    demandsWithoutServices(demands) {
      return demands.filter(
        (demand) => demand.currentStatus.id !== this.$consts.STATUS.CANCELLED && demand.services.length === 0,
      )
    },
    demandsWaitingForPartners(demands) {
      return demands.filter(
        (demand) =>
          demand.currentStatus.id !== this.$consts.STATUS.CANCELLED &&
          demand.services.some(
            (service) =>
              !service.currentEstimate?.workPartner ||
              service.currentEstimate?.currentStatus?.id === this.$consts.STATUS.WAITING_PARTNER,
          ),
      )
    },
    demandsReadyForProposal(demands) {
      return demands.filter(
        (demand) =>
          ![this.$consts.STATUS.CANCELLED, this.$consts.STATUS.IN_PROGRESS].includes(demand.currentStatus.id) &&
          demand.services.length > 0 &&
          demand.services.every(
            (service) => service.currentEstimate?.currentStatus?.id === this.$consts.STATUS.ACCEPTED,
          ),
      )
    },
    setDemands(newDemands) {
      this.filteredDemands = newDemands
    },
    async listDemands() {
      try {
        this.$nextTick(async () => {
          this.demands = (await api.listCompactDemands(this.myRealEstateAgency?.id, this.isAdmin)).data
          this.filteredDemands = this.demands
        })
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),

    dashboardSections() {
      return [
        { list: this.demandsWithoutServices(this.filteredDemands), title: 'Solicitações sem serviços' },
        { list: this.demandsWaitingForPartners(this.filteredDemands), title: 'Solicitações aguardando parceiro' },
        {
          list: this.demandsReadyForProposal(this.filteredDemands),
          title: 'Solicitações prontas para fechar proposta',
        },
      ]
    },
  },
  data() {
    return {
      demands: [],
      filteredDemands: [],
      loading: true,
    }
  },
  async created() {
    await this.listDemands()
  },
}
</script>
<style scoped>
#new-demand-wrapper {
  height: 60px;
}
.parent {
  gap: 10px;
  height: 100%;
}
#content {
  height: calc(100% - 120px);
  overflow-x: auto;
}
#close-dialog {
  position: absolute;
  right: 32px;
}
</style>
