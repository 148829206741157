<template>
  <div class="user-rating d-flex flex-column">
    <input-filter placeholder="Busque um usuário" @search="getFilteredRatings($event)" />

    <div class="table-box">
      <w-table
        ref="table"
        title="Avaliação dos Usuários"
        :headers="headers"
        :load-action="getRatings"
        :custom-actions="customActions"
        :can-filter="false"
      >
        <template #date="{ item }">
          {{ item.date.format('DD/MM/YYYY') }}
        </template>

        <template #hour="{ item }">
          {{ item.date.format('HH:mm') }}
        </template>
      </w-table>
    </div>
    <w-crm-rating-modal v-model="showRatingModal" :editable="false" :item="itemToView" />
  </div>
</template>

<script>
import api from '@/api/services'
import InputFilter from '@/components/InputFilter.vue'
import WCrmRatingModal from '@/components/WCrmRatingModal.vue'
import WTable from '@/components/WTable.vue'

export default {
  components: { WTable, WCrmRatingModal, InputFilter },

  data() {
    return {
      showRatingModal: false,
      itemToView: {},
      ratings: [],
      filteredRatings: [],
    }
  },

  computed: {
    headers() {
      return [
        { text: 'Data', value: 'date' },
        { text: 'Hora', value: 'hour' },
        { text: 'Usuário', value: 'user.name' },
        { text: 'Avaliação', value: 'evaluationScore' },
        { text: 'Comentário', value: 'comment' },
      ]
    },

    customActions() {
      return [
        {
          icon: 'mdi-eye',
          color: 'primary',
          tooltip: 'Visualizar',
          action: (item) => {
            return new Promise((resolve) => {
              this.viewItem(item)
              resolve({})
            })
          },
        },
      ]
    },
  },

  async created() {
    this.ratings = (await api.getRatings()).data.map((rating) => {
      return {
        ...rating,
        date: this.$moment.utc(rating.date).local(),
      }
    })

    this.filteredRatings = this.ratings
  },

  methods: {
    getFilteredRatings(text) {
      if (!text) {
        this.filteredRatings = this.ratings
        this.$refs.table.refreshList()
        return
      }

      const filteredRatings = this.ratings.filter((rating) => {
        const { user } = rating
        const userString = `${user.name} ${user.realEstateAgencyName}`

        return userString.toLowerCase().includes(text.toLowerCase())
      })

      this.filteredRatings = filteredRatings

      this.$refs.table.refreshList()
    },
    viewItem(item) {
      this.itemToView = this.$utils.copy(item)
      this.showRatingModal = !this.showRatingModal
    },
    getRatings() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            data: this.filteredRatings,
          })
        }, 1000)
      })
    },
  },
}
</script>

<style scoped>
.user-rating {
  gap: 12px;
}

.table-box {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>
