<template>
  <w-dialog
    v-model="visitDialog"
    :title="editMode ? 'Editar Visita' : 'Agendar Visita'"
    :maxWidth="600"
    @input="close"
    :buttons="[
      {
        label: 'fechar',
        action: () => {
          close()
        },
      },
      {
        label: 'salvar',
        action: async () => {
          isLoading = true
          if (editMode) {
            await update()
          } else {
            await save()
          }
          isLoading = false
          close()
          $emit('reload')
        },
        loading: isLoading,
        disabled: formDisabled,
      },
    ]"
  >
    <div style="height: 100%; width: 100%">
      <w-visit-form
        @update:form-disabled="formDisabled = $event"
        v-model="visit"
        :availableServices="demand.services.map(({ id, name }) => ({ id, name }))"
        :disabledServices="disabledServices"
      />
    </div>
  </w-dialog>
</template>

<script>
import WVisitForm from '@/components/WVisitForm.vue'
import api from '../api/services'
import WDialog from './WDialog.vue'

export default {
  components: {
    WDialog,
    WVisitForm,
  },
  data() {
    return {
      formDisabled: true,
      isLoading: false,
      visitDialog: false,
      disabledServices: false,
      visit: {
        datetime: null,
        estimatedDuration: null,
        observation: null,
        timeUnit: null,
      },
      editMode: false,
    }
  },
  props: {
    demand: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async save() {
      const { workPartner } = this.demand.estimates
        .filter((estimate) => estimate.currentStatus.id !== this.$consts.STATUS.CANCELLED)
        .find((estimate) => estimate.service.id === this.visit.service.id)

      const fullVisit = {
        ...this.visit,
        workPartner,
      }

      try {
        await api.addVisit(this.demand.id, fullVisit)
        this.$emit('save')
        this.$emit('showSuccess', 'Visita adicionada com sucesso!')
      } catch (error) {
        this.$emit('showError', 'Erro ao agendar uma visita!')
        console.error(error)
      }
    },
    async update() {
      try {
        await api.updateVisit(this.visit)
        this.$emit('save')
        this.$emit('showSuccess', 'Visita editada com sucesso!')
      } catch (error) {
        this.$emit('showError', 'Erro ao editar uma visita!')
        console.error(error)
      }
    },
    close() {
      this.$emit('close')
      this.visitDialog = false
    },
    new(service) {
      if (service) {
        this.disabledServices = true
        this.visit = {
          service: {
            id: service.id,
            name: service.name,
          },
        }
      } else {
        this.disabledServices = false
        this.visit = {}
      }
      this.editMode = false
      this.visitDialog = true
    },
    edit(visit) {
      this.visit = {
        ...this.visit,
        ...visit,
      }
      this.disabledServices = true
      this.editMode = true
      this.visitDialog = true
    },
  },
}
</script>
