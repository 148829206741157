<template>
  <v-sheet
    class="filedrop p-relative"
    :class="{
      'filedrop--over': dragover && (!value || value.length === 0),
      'filedrop--dropped': !!value && (!isMultiple || value.length > 0),
    }"
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @drop.prevent="onDrop"
    @dragover.prevent=""
    @dragend.prevent=""
    @drag.prevent=""
  >
    <div class="d-flex flex-column align-center text-center filedrop__zone overflow-y-auto">
      <v-btn
        v-show="!!value && (!isMultiple || value.length > 0)"
        icon
        class="filedrop__close"
        color="primary"
        @click="removeFile(null)"
        ><v-icon>mdi-close-circle</v-icon></v-btn
      >
      <v-icon size="64" color="primary" class="mb-2">{{
        !value || value.length === 0
          ? 'mdi-file-upload-outline'
          : isMultiple && value.length > 1
          ? 'mdi-file-multiple-outline'
          : 'mdi-file-outline'
      }}</v-icon>
      <div
        class="d-flex flex-column align-center headline font-weight-bold primary--text"
        v-if="!value || value.length === 0"
      >
        <span>Arraste e solte</span>
        <span>ou</span>
        <v-btn outlined class="mt-2" color="primary" :disabled="dragover" @click="openFileSelect">{{
          buttonLabel || defaultLabel
        }}</v-btn>
      </div>
      <div v-else-if="isMultiple">
        <div class="filedrop__file" v-for="(file, index) in value" :key="index">
          <v-btn icon color="primary" @click="removeFile(index)"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
          <div class="flex-grow-1 text-left">
            <span class="font-weight-bold headline primary--text text--darken-1">{{ file.name }}</span>
            <span
              v-if="!maxFileSize || file.size <= maxFileSize"
              class="subtitle-2 primary--text"
              style="white-space: pre"
              >&#32;({{ $formatters.toByteSize(file.size) }})</span
            >
            <template v-else>
              <span class="font-weight-bold subtitle-2 error--text"
                >&#32;({{ $formatters.toByteSize(file.size) }})</span
              >
              <v-icon x-small color="error" class="px-1">mdi-alert</v-icon>
            </template>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex-grow-1 text-left">
          <span class="font-weight-bold headline primary--text text--darken-1 mr-2">{{ value.name }}</span>
          <span v-if="!maxFileSize || value.size <= maxFileSize" class="subtitle-2 primary--text"
            >&#32;({{ $formatters.toByteSize(value.size) }})</span
          >
          <template v-else>
            <span class="font-weight-bold subtitle-2 error--text">&#32;({{ $formatters.toByteSize(value.size) }})</span>
            <v-icon x-small color="error" class="px-1">mdi-alert</v-icon>
          </template>
        </div>
      </div>
    </div>
    <div v-if="!!maxFileSize" class="mt-4 text-center">
      <span v-if="!hasTooBigAFile" class="font-weight-bold caption primary--text"
        >Tamanho máximo de arquivo: {{ $formatters.toByteSize(maxFileSize) }}</span
      >
      <template v-else>
        <v-icon x-small color="error" class="px-1">mdi-alert</v-icon>
        <span class="font-weight-bold caption error--text">{{
          Array.isArray(value)
            ? `Um ou mais arquivos excedem o tamanho máximo de ${$formatters.toByteSize(maxFileSize)}`
            : `O arquivo excede o tamanho máximo de ${$formatters.toByteSize(maxFileSize)}`
        }}</span>
      </template>
    </div>
    <input
      class="filedrop__input"
      type="file"
      ref="fileInput"
      :multiple="isMultiple"
      :accept="!accept ? null : `.${accept.join(',.')}`"
      @change="selectFile($event.target.files)"
    />
  </v-sheet>
</template>

<script>
export default {
  props: {
    accept: Array,
    value: [File, Array],
    buttonLabel: String,
    maxFileSize: Number,
  },
  watch: {
    value(newVal) {
      if (!newVal || newVal.length === 0) {
        this.$refs.fileInput.value = ''
      }
    },
  },
  computed: {
    isMultiple() {
      return Array.isArray(this.value)
    },
    defaultLabel() {
      return this.isMultiple ? 'selecione os arquivos' : 'selecione um arquivo'
    },
    hasTooBigAFile() {
      if (!this.maxFileSize || this.maxFileSize <= 0) {
        return false
      }

      if (Array.isArray(this.value)) {
        return this.value.some((file) => file.size > this.maxFileSize)
      }

      return this.value && this.value.size > this.maxFileSize
    },
  },
  data() {
    return {
      dragover: false,
    }
  },
  methods: {
    onDragEnter(dragevent) {
      if (this.value && (!this.isMultiple || this.value.length > 0)) {
        return
      }

      if (dragevent.dataTransfer && dragevent.dataTransfer.items) {
        for (let item of dragevent.dataTransfer.items) {
          if (item.kind === 'file') {
            this.dragover = true
            break
          }
        }
      }
    },
    onDragLeave(event) {
      const rect = this.$el.getBoundingClientRect()
      this.dragover =
        event.clientX >= rect.left &&
        event.clientX <= rect.right &&
        event.clientY >= rect.top &&
        event.clientY <= rect.bottom
    },
    onDrop(dragevent) {
      if (this.value && (!this.isMultiple || this.value.length > 0)) {
        return
      }

      this.dragover = false
      if (dragevent.dataTransfer && dragevent.dataTransfer.files) {
        this.$refs.fileInput.files = dragevent.dataTransfer.files
        this.selectFile(dragevent.dataTransfer.files)
      }
    },
    openFileSelect() {
      this.$refs.fileInput.click()
    },
    selectFile(fileList) {
      const validExtensions = this.accept ? this.accept.map((extension) => extension.toLowerCase()) : null
      const files = []
      for (let file of fileList) {
        const parts = file.name.split('.')
        const extension = parts[parts.length - 1].trim().toLowerCase()
        if (validExtensions === null || validExtensions.includes(extension)) {
          files.push(file)
        }
      }

      if (files.length === 0) {
        this.$emit('input', this.isMultiple ? [] : null)
      } else {
        this.$emit('input', this.isMultiple ? files : files[0])
      }
    },
    removeFile(index) {
      this.$emit('input', this.isMultiple ? (index !== null ? this.value.filter((_, i) => i !== index) : []) : null)
    },
  },
}
</script>

<style scoped>
.filedrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px dashed var(--v-primary-base);
  background-color: var(--v-bg-lighten1);
  padding: 16px;
  transition: background-color 0.3s;
}

.filedrop--over {
  border: 2px dashed #4d7cfe;
  background-color: #e7edfc;
}
.filedrop--dropped {
  border: 2px solid var(--v-primary-base);
}

.filedrop__file {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.filedrop__close {
  position: absolute;
  right: 4px;
  top: 4px;
}

.filedrop__input {
  display: none;
}
</style>
