<template>
  <div class="box">
    <input :placeholder="placeholder" v-model="text" @input="search($event)" />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Pesquisar',
    },
  },
  data() {
    return {
      text: null,
    }
  },
  methods: {
    search(e) {
      this.$emit('search', this.text)
    },
  },
}
</script>

<style scoped>
.box {
  background-color: white;
  border-radius: 32px;
  width: 50%;
  padding-left: 24px;
  height: 38px;
}

input {
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-size: 14px;
}

input:focus {
  outline: none;
}
</style>
