<template>
  <div id="chat-screen-wrapper">
    <div v-if="chat" class="d-flex flex-column" style="height: 100%">
      <Header :header-data="headerData" @navigateTo="(tab) => $emit('navigateTo', tab)" />
      <div id="scroll-button-container" v-if="showScrollButton" @click="scrollToBottom" class="pa-1">
        <div id="scroll-button">
          <v-icon color="primary">mdi-arrow-down</v-icon>
        </div>
        <span id="new-messages-counter" v-if="newMessagesCounter > 0">{{ newMessagesCounter }}</span>
      </div>
      <div id="chat" v-on:scroll="handleScroll">
        <div style="text-align: center" v-if="paginating">
          <v-progress-circular indeterminate color="primary" :size="24"></v-progress-circular>
        </div>
        <div id="messages" class="d-flex flex-column-reverse">
          <v-progress-circular v-if="!chat" indeterminate color="primary" style="align-self: center" />
          <div v-for="(messages, day) in groupMessages" :key="day">
            <div class="d-flex justify-center" style="position: sticky; top: 0; z-index: 1">
              <div class="message-day-group mt-4">
                <p>{{ messageDayGroup(day) }}</p>
              </div>
            </div>

            <div class="d-flex flex-column-reverse">
              <div
                v-for="message in messages"
                :key="`message-${message.id}`"
                class="d-flex message-box"
                :class="'message-box-' + message.origin.toLowerCase()"
              >
                <message
                  :data="message"
                  :type="getMessageType(message)"
                  @openImage="(image) => openImage(image)"
                  :photos="photos"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="bottom" class="d-flex px-4">
        <label for="fileInput" class="d-flex align-center" id="file-input-label">
          <v-icon color="primary">mdi-plus</v-icon>
        </label>

        <div id="input-message">
          <v-textarea
            id="input-field"
            no-resize
            rows="1"
            auto-grow
            class="px-4"
            v-model="message"
            @keydown.enter.exact.prevent="sendMessage"
          />
        </div>

        <div id="input-icon" class="d-flex align-center">
          <v-progress-circular v-if="sendingMessage" :size="18" indeterminate color="primary"></v-progress-circular>

          <div v-else>
            <v-btn key="send" v-if="canSendMessage" @click="sendMessage">
              <v-icon color="primary">mdi-send</v-icon>
            </v-btn>

            <v-btn v-else-if="!recordingAudio" key="recording" @click="recordAudio">
              <v-icon color="primary">mdi-microphone</v-icon>
            </v-btn>
            <v-btn v-else-if="recordingAudio" key="pause" @click="stopRecording">
              <v-icon color="primary">mdi-stop-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <input type="file" id="fileInput" style="display: none" @change="handleFileChange" />
    </div>

    <NoChat v-else />

    <v-overlay :value="loading" absolute :opacity="0.2">
      <v-progress-circular v-if="loading" indeterminate color="white" style="align-self: center" />
    </v-overlay>

    <v-dialog v-model="imageDialog" :max-width="700">
      <v-card>
        <div class="d-flex justify-end">
          <v-icon class="ma-2" large @click="imageDialog = false">mdi-close</v-icon>
        </div>
        <v-carousel :show-arrows="false">
          <v-carousel-item>
            <v-sheet color="grey lighten-4">
              <v-img :src="imageToView" contain height="500"></v-img>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import services from '@/api/services'
import store from '@/store'
import { createNamespacedHelpers } from 'vuex'
import { startRecordingAudio, stopRecordingAudio } from '../../recordAudio'
import Header from './Header.vue'
import Message from './Message.vue'
import NoChat from './NoChat.vue'
createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { Header, NoChat, Message },

  props: {
    otherId: {
      type: Number,
    },
  },
  methods: {
    pollMessages() {
      this.newMessagesInterval = setInterval(async () => {
        if (this.otherId && !this.sendingMessage) {
          const messagesData = (await services.getNewChatMessages(this.otherId)).data

          if (messagesData.length > 0) {
            this.updateChat(messagesData)

            if (!this.showScrollButton) {
              this.$nextTick(() => {
                this.scrollToBottom()
              })
            } else {
              this.newMessagesCounter += messagesData.length
            }
          }
        }
      }, 1000)
    },
    openImage(image) {
      this.imageToView = image
      this.imageDialog = true
    },
    async handleScroll(el) {
      const chatElement = el.target
      if (chatElement.scrollTop < chatElement.scrollHeight - chatElement.clientHeight) {
        this.showScrollButton = true
      } else {
        this.showScrollButton = false
      }

      if (chatElement.scrollTop === 0) {
        if (this.chat.messages.hasNext) {
          this.messagesPage += 1

          const chatElement = document.getElementById('chat')

          this.paginating = true

          const oldScrollHeight = document.getElementById('messages').scrollHeight

          if (chatElement) await this.getChat()

          const newScrollHeight = document.getElementById('messages').scrollHeight

          this.$nextTick(() => {
            chatElement.scrollTo({
              top: newScrollHeight - oldScrollHeight,
            })
          })

          this.paginating = false
        }
      }
    },
    isImage(mimeType) {
      return mimeType.startsWith('image')
    },
    getMessageType(message) {
      if (message.content.type === this.$consts.CHAT_MESSAGE_TYPE.TEXT) return this.$consts.CHAT_MESSAGE_TYPE.TEXT
      else if (this.isImage(message.content.file.mime)) return this.$consts.CHAT_MESSAGE_TYPE.IMAGE
      else return message.content.type
    },
    messageDayGroup(messageDay) {
      switch (Math.abs(this.$moment(messageDay).diff(this.$moment(), 'days'))) {
        case 0:
          return 'HOJE'
        case 1:
          return 'ONTEM'
        default:
          return this.$moment(messageDay).format('DD [de] MMMM [de] YYYY')
      }
    },
    async getChat() {
      if (this.otherId) {
        if (this.messagesPage.length > 0) this.loading = true

        const data = (await services.getChatByOtherId(this.otherId, this.messagesPage)).data

        if (this.messagesPage > 0) {
          this.chat.messages = {
            ...this.chat.messages,
            hasNext: data.messages.hasNext,
            content: this.chat.messages.content.concat(data.messages.content),
          }
        } else {
          this.chat = data
        }

        if (this.messagesPage.length > 0) this.loading = false
      }
    },
    async clearMessage() {
      this.message = ''
    },
    async sendMessage() {
      if (this.message.trim() === '') return
      this.sendingMessage = true
      const myMessage = (await services.sendChatMessage(this.otherId, this.message.trim())).data

      this.updateChat([myMessage])

      this.sendingMessage = false
      this.clearMessage()

      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },
    async recordAudio() {
      await startRecordingAudio(this.sendAudio)

      this.recordingAudio = true
    },
    async stopRecording() {
      await stopRecordingAudio()
      this.recordingAudio = false
    },
    async sendAudio(blob) {
      this.sendingMessage = true

      const myMessage = (await services.sendChatFile(this.otherId, blob)).data

      this.updateChat([myMessage])

      this.sendingMessage = false

      this.$nextTick(() => {
        this.scrollToBottom()
      })

      this.recorder = null
    },
    async handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        let maxSize = this.$consts.MAX_FILE_SIZE.CHAT
        if (file.size > maxSize) {
          store.state.globalAlertComponent.showError(
            `Você não pode enviar arquivos acima de ${this.$formatters.toByteSize(maxSize)}`,
          )
          return
        }

        this.file = file

        this.sendingMessage = true
        const myMessage = (await services.sendChatFile(this.otherId, file)).data

        this.updateChat([myMessage])

        this.sendingMessage = false

        this.$nextTick(() => {
          this.scrollToBottom()
        })
      } else {
      }
    },

    updateChat(messages) {
      messages.forEach((message) => {
        this.chat.messages.content.unshift(message)
      })
    },
    scrollToBottom() {
      const chatElement = document.getElementById('chat')

      if (chatElement)
        chatElement.scrollTo({
          top: document.getElementById('messages').scrollHeight,
        })
    },
  },
  data() {
    return {
      recorder: null,
      recordingAudio: false,
      paginating: false,
      messagesPage: 0,
      imageToView: null,
      imageDialog: false,
      newMessagesCounter: 0,
      showScrollButton: false,
      chat: null,
      loading: false,
      message: '',
      file: null,
      newMessagesInterval: null,
      sendingMessage: false,
    }
  },
  computed: {
    photos() {
      return {
        me: this.chat.me.photo?.url,
        other: this.chat.other.photo?.url,
      }
    },
    groupMessages() {
      return this.chat.messages.content.reduce((acc, message) => {
        const messageDate = this.$moment(message.sentAt).format('YYYY-MM-DD')

        return {
          ...acc,
          [messageDate]: Array.isArray(acc[messageDate]) ? [...acc[messageDate], message] : [message],
        }
      }, {})
    },
    headerData() {
      return {
        info: this.chat?.other,
        photo: this.chat?.other.photo?.url,
        name: this.chat?.other.name,
      }
    },
    canSendMessage() {
      return this.message.trim() !== ''
    },
  },
  async created() {
    await this.getChat()
    this.pollMessages()
  },

  beforeDestroy() {
    clearInterval(this.newMessagesInterval)
  },

  watch: {
    async otherId() {
      await this.getChat()

      setTimeout(() => this.scrollToBottom(), 1000)
    },
    showScrollButton() {
      this.newMessagesCounter = 0
    },
  },
}
</script>

<style scoped>
#scroll-button-container {
  position: absolute;
  bottom: 90px;
  z-index: 1;
  right: 10px;
  border-radius: 50%;
  background: white;
  border: 1px solid var(--v-primary-base);
}
#scroll-button {
  position: relative;
}

#scroll-button-container:hover {
  cursor: pointer;
}

#new-messages-counter {
  position: absolute;
  right: -5px;
  top: -10px;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
  color: white;
  background: rgb(255, 30, 30);
}

#chat-screen-wrapper {
  width: 100%;
  background-color: #f6fffa;
  height: 100%;
  position: relative;
}

#chat {
  position: relative;
  align-content: center;
}

#header {
  background-color: #f2f4f7;
  padding: 10px;
  gap: 8px;
}

#header:hover {
  cursor: pointer;
}

#user-photo,
#user-photo img {
  border-radius: 50%;
  background-color: gray;
  width: 50px;
  height: 50px;
}

#user-name {
  font-weight: 600;
  font-size: 18;
  color: #6d6e71;
}

#chat {
  background-color: var(--v-bg-base);
  background-image: url('~@/assets/chat-background.png');
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  background-size: 25%;
  padding: 12px 24px;
  overflow: auto;
}

.message-box {
  margin: 20px 0;
}

.message-box-other {
  justify-content: start;
}

.message-box-me {
  justify-content: end;
}

#bottom {
  background-color: #f2f4f7;
  padding: 15px;
  gap: 15px;
}

::v-deep .v-input__slot::before {
  display: none;
}

::v-deep .v-text-field__details {
  display: none;
}

.v-text-field {
  padding: 0;
}

#input-message {
  background-color: white;
  width: 100%;
  max-height: 100px;
  overflow: auto;
}

#file-input-label:hover {
  cursor: pointer;
}

:deep(.v-input__slot::after) {
  border-style: none !important;
}

.message-day-group {
  background-color: #f2f4f7;
  padding: 4px 8px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  z-index: 100;
}

.message-day-group p {
  color: #6d6e71;
  font-size: 14px;
  margin: 0;
  border-radius: 4px;
}
</style>
