import moment from 'moment'

export default {
  copy(obj) {
    if (!obj || typeof obj !== 'object') {
      return obj
    }
    return JSON.parse(JSON.stringify(obj))
  },
  neatString(string, trim = true) {
    return string === null || string === undefined ? '' : trim ? string.toString().trim() : string.toString()
  },
  removeAccents(string) {
    if (!string) {
      return string
    }

    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  },
  toComparableStringLowerCase(string) {
    return this.removeAccents(this.neatString(string)).toLowerCase()
  },
  hasTermInAnyString(term, ...strings) {
    term = this.toComparableStringLowerCase(term)
    return strings
      .map((string) => this.toComparableStringLowerCase(string))
      .filter((string) => !!string)
      .some((string) => string.includes(term))
  },
  datetimeSorter(asc = true, format = 'YYYY-MM-DDTHH:mm:ss') {
    return asc
      ? (dt1, dt2) => moment(dt1, format, true).diff(moment(dt2, format, true))
      : (dt1, dt2) => moment(dt2, format, true).diff(moment(dt1, format, true))
  },
  stringSorter(asc = true) {
    return asc
      ? (s1, s2) => {
          s1 = this.toComparableStringLowerCase(s1)
          s2 = this.toComparableStringLowerCase(s2)
          return s1.localeCompare(s2)
        }
      : (s1, s2) => {
          s1 = this.toComparableStringLowerCase(s1)
          s2 = this.toComparableStringLowerCase(s2)
          return s2.localeCompare(s1)
        }
  },
  objectSorter(mapFunction, asc = true) {
    return (obj1, obj2) => this.stringSorter(asc)(mapFunction(obj1), mapFunction(obj2))
  },
  deepObjectValue(obj, route) {
    if (!obj || !route || typeof route !== 'string') {
      return obj
    }
    return route.split('.').reduce((obj, key) => (obj ? obj[key] : null), obj)
  },
  formatCurrency(number, maximumFractionDigits = 2) {
    if (number == undefined || number == null) {
      number = 0
    }

    if (typeof number !== 'number') {
      return number
    }

    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits,
    }).format(number)
  },
  formatCpf(cpf) {
    if (!cpf || !/[0-9]{11}/.test(cpf)) {
      return cpf
    }

    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9)}`
  },
  formatCnpj(cnpj) {
    if (!cnpj || !/[0-9]{14}/.test(cnpj)) {
      return cnpj
    }

    return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12)}`
  },
  formatCpfCnpj(cpfCnpj) {
    if (/[0-9]{11}/.test(cpfCnpj)) {
      this.formatCpf(cpfCnpj)
    } else if (!cnpj || !/[0-9]{14}/.test(cnpj)) {
      this.formatCnpj(cpfCnpj)
    } else {
      return cpfCnpj
    }
  },
  getTimestamp() {
    return new Date().getTime()
  },
  getDatesInRange({ dateRange, outputFormat = null, inputFormat = 'YYYY-MM-DD' }) {
    let startDateMoment, endDateMoment
    if (
      !Array.isArray(dateRange) ||
      dateRange.length !== 2 ||
      !(startDateMoment = moment(dateRange[0], inputFormat, true)).isValid() ||
      !(endDateMoment = moment(dateRange[1], inputFormat, true)).isValid()
    ) {
      return []
    }
    const dates = []
    for (let date = startDateMoment; date.isSameOrBefore(endDateMoment, 'day'); date.add(1, 'day')) {
      dates.push(!outputFormat ? moment(date) : date.format(outputFormat))
    }
    return dates
  },
  getTimeWithinQuarter(time, start = true) {
    const timeMoment = moment(time, 'HH:mm', true)
    if (!timeMoment.isValid()) {
      return null
    }

    timeMoment.minutes(Math.floor((timeMoment.minutes() / 15) * (start ? 1 : -1)) * 15 * (start ? 1 : -1))
    return timeMoment
  },
  getTimesInRangeGivenIntervalInMinutes({
    startTime,
    endTime,
    intervalMinutes,
    inputFormat = 'HH:mm',
    outputFormat = null,
  }) {
    let startTimeMoment, endTimeMoment
    if (
      !startTime ||
      !endTime ||
      intervalMinutes <= 0 ||
      intervalMinutes > 24 * 60 ||
      !(startTimeMoment = moment(startTime, inputFormat, true)).isValid() ||
      !(endTimeMoment = moment(endTime, inputFormat, true)).isValid()
    ) {
      return []
    }

    const totalMinutes = endTimeMoment.diff(startTimeMoment, 'minute') || 1440
    const times = []
    for (let addedMinutes = 0; addedMinutes <= totalMinutes; addedMinutes += intervalMinutes) {
      const time = moment(startTimeMoment).add(addedMinutes, 'minute')
      times.push(!outputFormat ? time : time.format(outputFormat))
    }
    return times
  },
  periodsOverlap(period1Start, period1End, period2Start, period2End) {
    const period1StartMoment = moment(period1Start, 'YYYY-MM-DD HH:mm')
    const period1EndMoment = moment(period1End, 'YYYY-MM-DD HH:mm')
    const period2StartMoment = moment(period2Start, 'YYYY-MM-DD HH:mm')
    const period2EndMoment = moment(period2End, 'YYYY-MM-DD HH:mm')
    return period1StartMoment.isBefore(period2EndMoment) && period1EndMoment.isAfter(period2StartMoment)
  },
  datetimeToMoment(datetime) {
    return moment(datetime, 'YYYY-MM-DD HH:mm', true)
  },
  convertToHtml(text, defaultText) {
    return !text ? defaultText || '' : text.replace(/\n/g, '<br />')
  },
  dataURItoBlob(dataURI) {
    var byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
    else byteString = unescape(dataURI.split(',')[1])

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    var ia = new Uint8Array(byteString.length)
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], { type: mimeString })
  },
  async imageToBase64(url) {
    const response = await fetch(url)
    const blob = await response.blob()
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        const base64String = reader.result
        resolve(base64String)
      }
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  },
}
function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
