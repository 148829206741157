<template>
  <div class="d-flex flex-column-reverse alert-container" style="gap: 8px">
    <transition-group name="list">
      <v-alert
        v-for="(notification, i) in notifications"
        :key="notification.timeout"
        icon="mdi-bell"
        color="primary"
        colored-border
        border="left"
        dense
        class="alert elevation-2"
        dismissible
        @input="dismiss(i)"
      >
        <div class="d-flex flex-column" style="gap: 8px">
          <span class="custom-title">{{ notification.title }}</span>

          <span class="custom-body">{{ notification.body }}</span>
        </div>
      </v-alert>
    </transition-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notifications: [],
    }
  },
  methods: {
    addNotification(title, body) {
      const notification = {
        title,
        body,
        timeout: setTimeout(() => {
          this.notifications.shift()
        }, 5000),
      }

      this.notifications.push(notification)
    },
    dismiss(index) {
      const [removedNotification] = this.notifications.splice(index, 1)
      clearTimeout(removedNotification.timeout)
    },
  },
}
</script>

<style scoped>
.custom-title {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
}

.custom-body {
  color: gray;
  font-size: 12px;
  word-break: break-word;
}

.alert-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100000;
}

.alert {
  width: 400px;
  transition: all 0.5s ease;
}
</style>
