<template>
  <v-dialog :value="value" persistent scrollable :max-width="maxWidth" class="parent">
    <v-card :loading="loading" :disabled="loading" class="d-flex flex-column py-4 px-6" style="border-radius: 16px">
      <v-card-title
        class="headline justify-center text-center pa-0 d-flex flex-nowrap"
        style="position: relative"
        v-if="!!title || dismissible"
      >
        <span class="px-10 d-flex flex-grow-1 justify-center">{{ title }}</span>
        <v-btn
          @click="$emit('input', false)"
          icon
          v-if="dismissible"
          style="position: absolute; right: 0; top: 5px; color: inherit"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider v-if="!!title" class="divider"></v-divider>
      <v-alert
        class="ma-0 fill-width"
        type="error"
        transition="scale-transition"
        :value="!!error"
        dismissible
        style="word-break: break-word"
      >
        {{ error }}
      </v-alert>
      <v-card-text class="px-4 py-0 my-2">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="justify-end pt-4" v-if="buttons && buttons.length > 0">
        <v-spacer></v-spacer>
        <template v-for="button in buttons">
          <v-btn
            :key="button.label"
            :color="button.color || 'primary'"
            :outlined="button.outlined"
            class="rounded-lg text-capitalize py-2 px-4"
            @click="button.action"
            small
            :disabled="button.disabled || button.loading"
            v-if="!button.hidden"
          >
            {{ button.label }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    loading: Boolean,
    title: String,
    buttons: Array,
    error: String,
    dismissible: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: [Number, String],
      default: 480,
    },
  },
}
</script>

<style scoped>
::v-deep .v-dialog {
  border-radius: 16px;
}

.divider {
  border: 1px dashed var(--v-primary-base);
  margin: 16px 0;
}
</style>
