<template>
  <v-sheet>
    <v-sheet class="d-flex pa-2 align-center" v-for="(section, i) in sections" :key="`section:${i}`">
      <v-sheet class="ma-0 flex-grow" style="width: 100%">
        <v-row class="d-flex align-center">
          <v-col cols="2" dense>
            <span>{{ section.text }}:</span>
          </v-col>
          <v-col dense>
            <div class="d-flex flex-wrap" style="gap: 12px">
              <div v-for="demandServiceTask in section.demandServiceTasks" :key="demandServiceTask.id">
                <demand-service-task-item
                  :checkbox-data="{
                    id: demandServiceTask.id,
                    name: demandServiceTask.task.name,
                    images: demandServiceTask.images,
                    type: section.type,
                  }"
                  @revise="reviseTask"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>
import services from '@/api/services'
import store from '@/store'
import DemandServiceTaskItem from './DemandServiceTaskItem.vue'

export default {
  name: 'DemandServiceTasks',
  components: { DemandServiceTaskItem },

  props: {
    demandServiceTasks: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    tasks() {
      return this.demandServiceTasks.filter((task) => task.included)
    },
    todoTasks() {
      return this.tasks.filter((task) => !task.done)
    },
    revisionTasks() {
      return this.tasks.filter((task) => task.done && !task.revised)
    },
    finishedTasks() {
      return this.tasks.filter((task) => task.done && task.revised)
    },
    sections() {
      return [
        {
          text: 'Tarefas pendentes',
          demandServiceTasks: this.todoTasks,
          type: 'todo',
        },
        {
          text: 'Tarefas em revisão',
          demandServiceTasks: this.revisionTasks,
          type: 'revision',
        },
        {
          text: 'Tarefas finalizadas',
          demandServiceTasks: this.finishedTasks,
          type: 'finished',
        },
      ]
    },
  },

  methods: {
    async reviseTask(demandServiceTaskId) {
      try {
        await services.reviewTask(demandServiceTaskId)
        store.state.globalAlertComponent.showSuccess('Tarefa revisada!')
        this.$emit('reload')
        store.dispatch('verifyNeedsToRateWorkPartner')
      } catch (error) {
        console.error(error)
        store.state.globalAlertComponent.showError(error)
      }
    },
  },
}
</script>

<style scoped></style>
