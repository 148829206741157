<template>
  <div id="profile-wrapper" class="d-flex flex-column">
    <div id="header">
      <div id="header-box" class="d-flex justify-space-between" style="gap: 16px">
        <div class="d-flex align-center" style="gap: 16px">
          <div id="title" class="d-flex align-center">
            <v-icon
              @click="
                $emit('navigateTo', {
                  tabName: $consts.CHAT_NAVIGATION_TAB.CHAT_LIST,
                })
              "
              color="white"
              >mdi-chevron-left</v-icon
            >
            <p>{{ headerTitle }}</p>
          </div>
        </div>
      </div>
    </div>

    <div id="body">
      <v-menu top close-on-click offset-x>
        <template v-slot:activator="{ on }">
          <div
            id="logo"
            class="d-flex align-center justify-center"
            style="position: relative; border-radius: 50%; width: 160px"
            :style="{ backgroundColor: !photoUrl ? 'gray' : 'white' }"
            @mouseover="showEditImage = true"
            @mouseleave="showEditImage = false"
          >
            <v-avatar size="160">
              <img v-if="photoUrl" :src="photoUrl" />
              <v-icon size="120" color="white" v-else>mdi-account</v-icon>
            </v-avatar>

            <v-progress-circular v-if="updatingPhoto" indeterminate color="primary" />

            <v-overlay
              v-if="profileType === $consts.CHAT_PROFILE_TYPE.ME"
              :value="showEditImage"
              absolute
              @click="on.click"
            >
              <div class="d-flex flex-column align-center" id="logo-overlay">
                <v-icon>mdi-camera</v-icon>
                <p>Mudar foto de perfil</p>
              </div>
            </v-overlay>
          </div>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-list-item>
              <v-list-item-content>
                <label for="photoInput" class="d-flex align-center" id="file-input-label">
                  <v-list-item-title>Carregar Foto</v-list-item-title>
                </label>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content @click="removeProfilePhoto()">
                <v-list-item-title>Remover Foto</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <input
        type="file"
        id="photoInput"
        style="display: none"
        @change="handleFileChange"
        accept="image/png, image/jpeg, image/jpg"
      />

      <div v-if="profileType === this.$consts.CHAT_PROFILE_TYPE.ME" id="user-info" class="d-flex flex-column">
        <p id="user-name-label">Seu nome de usuário</p>
        <p id="user-name">{{ myChatUser.name }}</p>
      </div>

      <div
        v-if="profileType === this.$consts.CHAT_PROFILE_TYPE.WORKPARTNER"
        id="work-partner-info"
        class="d-flex flex-column align-center my-8"
      >
        <p id="work-partner-name">{{ this.workPartner.name }}</p>
        <p id="work-partner-phone">{{ this.workPartner.workPartnerDetails.phone }}</p>
      </div>

      <div
        id="work-partner-skills"
        class="d-flex flex-column align-center"
        v-if="profileType === this.$consts.CHAT_PROFILE_TYPE.WORKPARTNER"
      >
        <p>Serviços</p>

        <div class="d-flex flex-column">
          <span v-for="(skill, index) in this.workPartner.workPartnerDetails.skills" :key="index">{{ skill }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import services from '@/api/services'
import { createNamespacedHelpers } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {},

  data() {
    return {
      showEditImage: false,
      updatingPhoto: false,
    }
  },
  props: {
    myChatUser: {
      type: Object,
      required: true,
    },
    profileType: {
      type: String,
      required: true,
    },
    workPartner: {
      type: Object,
    },
  },

  computed: {
    headerTitle() {
      switch (this.profileType) {
        case this.$consts.CHAT_PROFILE_TYPE.ME:
          return 'Perfil'

        case this.$consts.CHAT_PROFILE_TYPE.WORKPARTNER:
          return 'Detalhes do Contato'

        default:
          return 'Detalhes'
      }
    },
    photoUrl() {
      switch (this.profileType) {
        case this.$consts.CHAT_PROFILE_TYPE.ME:
          return this.myChatUser.photo?.url
        case this.$consts.CHAT_PROFILE_TYPE.WORKPARTNER:
          return this.workPartner.photo?.url
        default:
          return null
      }
    },
  },

  methods: {
    async handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        this.updatingPhoto = true
        let maxSize = this.$consts.MAX_FILE_SIZE.PROFILE_PHOTO
        if (file.size > maxSize) {
          store.state.globalAlertComponent.showError(
            `Você não pode enviar fotos acima de ${this.$formatters.toByteSize(maxSize)}`,
          )
          return
        }

        this.file = file

        await services.uploadChatProfilePhoto(file)

        this.$emit('updateChatUser')

        this.updatingPhoto = false
      } else {
      }
    },
    async removeProfilePhoto() {
      this.updatingPhoto = true
      await services.deleteChatProfilePhoto()
      this.$emit('updateChatUser')
      this.updatingPhoto = false
    },
  },
}
</script>
<style scoped>
#profile-wrapper {
  height: 100%;
  background-color: #f2f4f7;
}

#header {
  padding: 24px 16px;
  background-color: var(--v-primary-base);
}

#title p {
  color: white;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

#title {
  gap: 8px;
}

#logo {
  margin: 24px auto;
}

#logo img {
  object-fit: contain;
}

#logo:hover {
  cursor: pointer;
}

#user-info {
  padding: 16px 32px;
  background-color: #fdfdfd;
  gap: 8px;
}

#user-info p {
  color: #6d6e71;
  margin: 0;
}

#user-name-label {
  font-size: 14px;
}

#user-name {
  font-size: 18px;
  font-weight: 700;
}

#logo-overlay {
  gap: 8px;
  padding: 0px 12px;
}

#logo-overlay p {
  color: white;
  text-align: center;
}

#work-partner-name {
  color: #6d6e71;
  font-weight: 700;
}

#work-partner-phone {
  font-size: 14px;
}

#work-partner-skills p {
  color: var(--v-primary-base);
  font-size: 18px;
  font-weight: 700;
}

#work-partner-skills span {
  font-size: 14px;
}
</style>
