<template>
  <w-dialog v-model="show" title="Serviços do pacote" :buttons="buttons" @input="close">
    <h4 class="mb-2">{{ bundleName }}</h4>
    <h5>Selecione os serviços que serão realizados neste pacote:</h5>
    <v-autocomplete
      class="mt-4 required"
      :value="selected[i - 1]"
      v-for="i in max"
      :key="`option-${i}`"
      :items="options"
      item-text="name"
      item-value="id"
      dense
      hide-details
      @input="$set(selected, i - 1, $event)"
      return-object
    />
  </w-dialog>
</template>

<script>
import WDialog from './WDialog.vue'
export default {
  components: {
    WDialog,
  },
  computed: {
    valid() {
      return this.selected.length === this.max
    },
    buttons() {
      return [
        { label: 'fechar', action: this.close },
        { label: 'salvar', action: this.save, disabled: !this.valid },
      ]
    },
  },
  data() {
    return {
      show: false,
      selected: [],
      max: null,
      bundleId: null,
      bundleName: null,
      options: [],
    }
  },
  methods: {
    open(service) {
      this.bundleId = service.id
      this.bundleName = service.name
      this.max = service.bundleSize
      this.options = service.bundleOptions
      this.selected = [...service.bundleItems]
      this.show = true
    },
    close() {
      this.show = false
      this.bundleId = null
      this.bundleName = null
      this.max = null
      this.options = []
      this.selected = []
    },
    save() {
      this.$emit('save', this.bundleId, this.selected)
      this.close()
    },
  },
}
</script>

<style scoped></style>
