import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-br')

const validations = {
  required: {
    test: (v) => !!v && (!Array.isArray(v) || v.length > 0),
    defaultMessage: 'Campo obrigatório',
  },
  email: {
    test: (v) =>
      !v ||
      /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
        v,
      ),
    defaultMessage: 'Email inválido',
  },
  validDate: {
    test: (v) =>
      !v ||
      (v.length === 10 &&
        moment(v, /^[0-9]{4}-$[0-9]{2}-[0-9]{2}$/.test(v) ? 'YYYY-MM-DD' : 'DD/MM/YYYY', true).isValid()),
    defaultMessage: 'Data inválida',
  },
  validMobileNumber: {
    test: (v) => !v || /^\([1-9][0-9]\) [1-9][0-9]{4}-[0-9]{4}$/.test(v),
    defaultMessage: 'Número inválido. Verifique se ele está no formato (XX) XXXXX-XXXX.',
  },
  validCurrency: {
    test: (v) =>
      !v || v === '0' || /^[1-9][0-9]*$/.test(v) || /^0?\.[0-9][0-9]$/.test(v) || /^[1-9][0-9]*\.[0-9][0-9]$/.test(v),
    defaultMessage: 'Valor de moeda inválido',
  },
  todayOrAfter: {
    test: (v) => {
      if (!v || v.length !== 10) {
        return true
      }
      const momentDate = moment(v, /^[0-9]{4}-$[0-9]{2}-[0-9]{2}$/.test(v) ? 'YYYY-MM-DD' : 'DD/MM/YYYY', true)
      return momentDate.isValid() && momentDate.isSameOrAfter(moment(), 'day')
    },
    defaultMessage: 'Data precisa ser igual ou posterior a hoje',
  },
  cnpj: {
    test: function (v) {
      if (!v) {
        return true
      }
      v = v.replace(/[^0-9]/g, '')
      if (v.length !== 14 || new Set(v.split('')).size === 1) {
        return false
      }

      const calc = (x) => {
        const slice = v.slice(0, x)
        let factor = x - 7
        let sum = 0

        for (let i = x; i >= 1; i--) {
          const n = slice[x - i]
          sum += n * factor--
          if (factor < 2) factor = 9
        }

        const result = 11 - (sum % 11)

        return result > 9 ? 0 : result
      }

      const digits = v.slice(12)

      const digit0 = calc(12)
      if (digit0 != digits[0]) return false

      const digit1 = calc(13)

      return digit1 == digits[1]
    },
    defaultMessage: 'CNPJ inválido',
  },
  cpf: {
    test: function (v) {
      if (!v) {
        return true
      }

      v = v.replace(/[^0-9]/g, '')

      if (v.length !== 11 || new Set(v.split('')).size === 1) {
        return false
      }

      let sum = 0
      let remainder = 0
      for (let i = 1; i <= 9; i++) {
        sum += parseInt(v.substring(i - 1, i)) * (11 - i)
      }
      remainder = (sum * 10) % 11

      if (remainder === 10 || remainder === 11) {
        remainder = 0
      }
      if (remainder !== parseInt(v.substring(9, 10))) {
        return false
      }

      sum = 0
      for (let i = 1; i <= 10; i++) {
        sum += parseInt(v.substring(i - 1, i)) * (12 - i)
      }
      remainder = (sum * 10) % 11

      if (remainder === 10 || remainder === 11) {
        remainder = 0
      }

      if (remainder !== parseInt(v.substring(10, 11))) {
        return false
      }

      return true
    },
    defaultMessage: 'CPF inválido',
  },
  cpfCnpj: {
    test: function (v) {
      return validations.cpf.test(v) || validations.cnpj.test(v)
    },
    defaultMessage: 'CPF/CNPJ inválido',
  },
}

for (let validationName in validations) {
  const validation = validations[validationName]
  Object.defineProperty(validation, 'rule', {
    get: () => (v) => validation.test(v) || validation.defaultMessage,
  })
}

export default validations
