<template>
  <div class="d-flex flex-column" style="height: calc(100% - 22px); width: 100%; gap: 10px">
    <v-text-field
      ref="textfield"
      v-model="typedText"
      append-icon="mdi-magnify"
      outlined
      dense
      hide-details
      placeholder="Digite para filtrar os serviços"
      class="flex-grow-0"
    />
    <v-sheet class="pa-4 selection-window">
      <p class="ma-0" v-if="!services || services.length === 0">Não existem serviços disponíveis.</p>
      <template v-else>
        <div v-for="category in filteredCategoryServices" :key="'category' + category.id" class="category pt-2">
          <w-service-icon bold :service="category" width="30px" height="30px" show-text style="width: 120px" />
          <v-row dense v-for="index in getRowsInCategory(category.id)" :key="`row${index}`">
            <v-col v-for="col in 3" :key="`row${index}col${col}`"
              ><v-card
                v-if="!!getNextService(category, index, col)"
                elevation="0"
                class="pa-2"
                :class="{ 'service-card--selected': isSelectedService(getNextService(category, index, col)) }"
                :ripple="!isSelectedService(getNextService(category, index, col))"
                @click="addService(getNextService(category, index, col))"
              >
                <div class="service-card">
                  <v-icon color="primary" size="20">{{
                    isSelectedService(getNextService(category, index, col))
                      ? 'mdi-playlist-check'
                      : 'mdi-plus-circle-outline'
                  }}</v-icon>
                  <v-card-text
                    class="service-name text-body-2"
                    :class="{ 'service-name--selected': isSelectedService(getNextService(category, index, col)) }"
                    >{{ getNextService(category, index, col).name }}</v-card-text
                  >
                </div>
                <v-card-actions
                  class="pa-0"
                  :class="{ 'visibility-hidden': !isSelectedService(getNextService(category, index, col)) }"
                >
                  <span class="caption primary--text pl-7 font-weight-regular"> Selecionado </span>
                  <v-spacer />
                  <v-btn
                    small
                    text
                    plain
                    color="error"
                    class="caption text-none text-decoration-underline font-weight-medium"
                    @click.stop="removeService(getNextService(category, index, col))"
                  >
                    Desfazer
                  </v-btn>
                </v-card-actions>
              </v-card></v-col
            >
          </v-row>
        </div>
      </template>
    </v-sheet>
  </div>
</template>

<script>
import WServiceIcon from './WServiceIcon.vue'
export default {
  components: { WServiceIcon },
  props: {
    services: {
      type: Array,
      default: () => [],
    },
    serviceCategories: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
    },
  },
  computed: {
    filteredServices() {
      if (!this.services || this.services.length === 0) {
        return []
      }
      const services =
        !this.typedText || !this.typedText.trim()
          ? [...this.services]
          : this.services.filter(
              (service) =>
                this.isSelectedService(service) ||
                this.$utils.hasTermInAnyString(this.typedText, service.name) ||
                this.$utils.hasTermInAnyString(this.typedText, service.category.name),
            )
      return services
    },
    filteredCategoryServices() {
      return this.serviceCategories.filter((c) => this.getServicesInCategory(c.id).length > 0)
    },
  },
  data() {
    return {
      typedText: '',
    }
  },
  methods: {
    getRowsInCategory(categoryId) {
      return Math.ceil(this.getServicesInCategory(categoryId).length / 3)
    },
    getServicesInCategory(categoryId) {
      return this.filteredServices.filter((s) => s.category.id === categoryId)
    },
    addService(service) {
      if (this.isSelectedService(service)) {
        return
      }
      this.$emit('input', [...this.value, service])
    },
    removeService(serviceToRemove) {
      this.$emit('input', [...this.value.filter((service) => service.id !== serviceToRemove.id)])
    },
    isSelectedService(service) {
      return this.value.some((s) => s.id === service.id)
    },
    getNextService(category, index, col) {
      return this.getServicesInCategory(category.id)[index * 3 - (4 - col)]
    },
  },
}
</script>

<style scoped>
.selection-window {
  overflow-y: auto;
}

.service-card {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.service-card--selected {
  cursor: default;
}

.service-name {
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.service-name--selected {
  opacity: 0.6;
}

.visibility-hidden {
  visibility: hidden;
}
.category {
  border-bottom: 1px dashed var(--v-primary-lighten2);
}
</style>
