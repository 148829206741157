<template>
  <v-sheet class="transparent d-flex flex-row flex-grow-0" id="dashboard">
    <dashboard-section
      v-for="section in sections"
      class="dashboard-section"
      :demandList="section.list"
      :title="section.title"
      @click:demand="$emit('click:demand', $event)"
      :key="section.title"
      @updateDemands="$emit('updateDemands')"
    />
  </v-sheet>
</template>

<script>
import DashboardSection from './DashboardSection.vue'

export default {
  components: { DashboardSection },
  props: {
    sections: Array,
  },
}
</script>

<style>
#dashboard {
  gap: 20px;
  width: 100%;
}
</style>
