<template>
  <v-sheet class="transparent parent" style="height: 100%">
    <w-alert ref="alert" />
    <v-card class="text-left pb-4 d-flex flex-column fill-height card">
      <v-card-title class="mb-2 pt-3">
        <v-sheet class="justify-left d-flex flex-wrap align-center flex-grow-1 transparent" style="gap: 4px">
          <v-sheet style="order: 0" class="title--small transparent">{{ demand.title }}</v-sheet>

          <v-chip
            :style="$vuetify.breakpoint.smAndDown ? 'order:4' : ''"
            small
            class="light-blue lighten-5"
            v-if="!!demand.realEstateCategory"
            >{{ demand.realEstateCategory.name }}</v-chip
          >

          <v-chip
            :style="$vuetify.breakpoint.smAndDown ? 'order:7' : ''"
            :dark="[$consts.STATUS.DONE, $consts.STATUS.CANCELLED].includes(demand.currentStatus.id)"
            small
            :color="demand.vchip.color"
            >{{ demand.vchip.name }}</v-chip
          >
        </v-sheet>
      </v-card-title>
      <v-card-subtitle>
        <span class="subtitle--small">
          {{ demand.description }}
        </span>
      </v-card-subtitle>

      <v-sheet class="d-flex flex-column text-left px-4 mt-auto transparent">
        <span class="font-weight-light overline address-label--small">ENDEREÇO</span>
        <span class="font-weight-regular body-2 address--small">{{
          `${demand.address}, ${demand.addressNumber}${
            (demand.addressComplement || '').length > 0 ? ', ' + demand.addressComplement : ''
          } - ${demand.district} - ${demand.city.name}, ${demand.city.state.shortName}`
        }}</span>
      </v-sheet>

      <div class="d-flex px-4" style="justify-content: space-between; align-items: center">
        <div class="d-flex my-4" style="gap: 8px; flex-wrap: wrap">
          <div
            class="service-icon"
            v-for="serviceCategory in demand.serviceCategories"
            :key="`service-category-${serviceCategory.id}`"
          >
            <w-service-icon width="24" :service="serviceCategory" />
          </div>
        </div>
        <ArchivedIcon
          class="archive"
          @click="(event) => archiveDemand(event)"
          v-if="!demand.archived && demand.currentStatus.id === this.$consts.STATUS.DONE"
        />
      </div>

      <div>
        <div class="d-flex px-4 justify-end">
          <v-chip
            :style="$vuetify.breakpoint.smAndDown ? 'order:3' : ''"
            small
            color="primary"
            v-if="isAdmin || demand.realEstateAgency?.id !== myRealEstateAgency?.id"
            >{{ demand.realEstateAgency?.name || 'Workay' }}</v-chip
          >
        </div>
      </div>
    </v-card>
  </v-sheet>
</template>

<script>
import WAlert from '@/components/WAlert.vue'
import 'vueperslides/dist/vueperslides.css'
import api from '../api/services'
import ArchivedIcon from '../assets/archived.svg'
import store from '../store'
import WServiceIcon from './WServiceIcon.vue'

import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {
    WServiceIcon,
    WAlert,
    ArchivedIcon,
  },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
    endpoint() {
      return process.env.VUE_APP_API_ENDPOINT + '/'
    },
  },
  props: {
    demand: {
      type: Object,
    },
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    async archiveDemand(event) {
      event.stopPropagation()
      try {
        await api.archiveDemand(this.demand.id)
        store.state.globalAlertComponent.showSuccess('Demanda arquivada com sucesso!')
        this.$emit('updateDemands')
      } catch (error) {
        store.state.globalAlertComponent.showError(error)
      }
    },
    servicesByCategory(serviceCategory) {
      return this.availableServices.filter((service) => service.category.id === serviceCategory.id)
    },
    demandHasServiceInCategory(serviceCategory) {
      return this.demandServicesInCategory(serviceCategory).length > 0
    },
    demandServicesInCategory(serviceCategory) {
      return this.demand.services.filter((demandService) => demandService.serviceCategory.id === serviceCategory.id)
    },
  },
  data() {
    return {
      viewAll: true,
      imageDialog: false,
      imageSrc: '',
      newStatus: null,
      cancelDemandDialog: false,
      cancelReason: null,
      dialog: false,
      imageSlidesWrapperWidth: 0,
      currentDemandService: { observation: '' },
      templateRef: null,
      isPrinting: false,
    }
  },
}
</script>
<style scoped>
.archive:hover {
  cursor: pointer;
  filter: grayscale(100%);
}
.transparent {
  background-color: transparent !important;
}
.service-icon {
  display: flex;
  padding: 4px;
  border: 1px solid var(--v-primary-base);
  border-radius: 50%;
}
.card {
  border-radius: 16px;
  background-color: rgba(248, 248, 248, 1);
}

.parent >>> .vueperslide {
  transform: scale(0.9);
  transition: 0.3s ease-in-out;
  background-size: contain;
  cursor: pointer;
  max-width: 150px;
}
.parent >>> .vueperslide--active {
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.subtitle--small {
  color: black;
  line-clamp: true;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0px !important;
  margin-bottom: 16px !important;
  margin-top: 16px;
  line-height: 18px;
}
.title--small {
  font-size: 12pt;
  font-weight: 700;
  word-break: break-word;
  line-height: 25px;
  font-family: Raleway;
  width: 100%;
  margin-bottom: 8px;
}
.address--small {
  font-family: Roboto;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
}
.address-label--small {
  font-size: 11px !important;
  line-height: 25px !important;
}
.parent >>> .vueperslides--fixed-height.vueperslides--bullets-outside {
  margin-bottom: 0 !important;
}
.parent >>> .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: 0.8;
}
.parent >>> .v-chip__content {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  height: auto;
}

.bundle-group {
  border: 1px dashed var(--v-primary-lighten2);
}
.parent >>> .disabled-row {
  background-color: #eeeeee;
  opacity: 0.35;
}

.parent >>> .vueperslides__bullet {
  margin-top: 0;
  margin-bottom: 0;
}
.parent >>> .vueperslides__bullets {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
