import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api/services'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalAlertComponent: null,
    globalNotificationsComponent: null,
    user: {
      firebaseToken: null,
      admin: false,
      roles: [],
      realEstateAgency: null,
    },
    pendingWorkPartnerRatings: [],
  },
  mutations: {
    setPendingWorkPartnerRatings(state, component) {
      state.pendingWorkPartnerRatings = component
    },

    setGlobalAlertComponent(state, component) {
      state.globalAlertComponent = component
    },

    setGlobalNotificationsComponent(state, component) {
      state.globalNotificationsComponent = component
    },

    setRoles(state, roles) {
      state.user.roles = roles
    },

    setAdmin(state, admin) {
      state.user.admin = admin
    },

    setRealEstateAgency(state, setRealEstateAgency) {
      state.user.realEstateAgency = setRealEstateAgency
    },
  },
  actions: {
    setGlobalAlertComponent({ commit }, component) {
      commit('setGlobalAlertComponent', component)
    },

    setGlobalNotificationsComponent({ commit }, component) {
      commit('setGlobalNotificationsComponent', component)
    },

    async setFirebaseToken(state, token) {
      window.sessionStorage.setItem('userFirebaseToken', token)

      await api.updateUserFirebaseToken(token)
    },

    async verifyNeedsToRateWorkPartner() {
      const result = (await api.verifyNeedsToRateWorkPartner()).data

      this.commit('setPendingWorkPartnerRatings', result)
    },
  },
  getters: {
    user: (state) => state.user,
    myRealEstateAgency: (state) => state.user.realEstateAgency,
    pendingWorkPartnerRatings: (state) => state.pendingWorkPartnerRatings,
    isAdmin: (state) => state.user.admin,
    userFirebaseToken: (state) => window.sessionStorage.getItem('userFirebaseToken'),
  },
  modules: {},
})
