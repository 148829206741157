<template>
  <w-crud-table
    ref="crud"
    title="Categoria de serviços"
    crud-endpoint="service-category"
    sort-by="name"
    :headers="headers"
    :can-create="isAdmin"
    :can-edit="isAdmin"
    :can-delete="isAdmin"
  >
    <template #form="{ item }">
      <v-text-field label="Nome" v-model="item.name" class="required" :rules="[$validationRules.required.rule]" />
    </template>
  </w-crud-table>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import WCrudTable from '../components/WCrudTable.vue'
createNamespacedHelpers('user/realEstateAgency')
export default {
  components: { WCrudTable },
  data() {
    return {
      headers: [{ text: 'Nome', value: 'name' }],
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
    }),
  },
}
</script>

<style></style>
