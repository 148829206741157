<template>
  <div>
    <span v-if="!editMode" :class="displayClass">{{ displayValue || value }}</span>
    <component
      v-else
      :is="component"
      v-model="localValue"
      :auto-decimal-mode="true"
      :decimal-length="currencyOption?.decimalLength || (integerValue ? 0 : 2)"
      :value-as-integer="integerValue || currencyOption?.valueAsInteger"
      :allow-negative="false"
      :hint="hint"
      :persistent-hint="persistentHint"
      :label="label"
      locale="pt-BR"
      :prefix="prefix"
      @input="$emit('input', onInput($event))"
      @blur="$emit('blur', $event)"
      :outlined="outlined"
      :placeholder="placeholder"
      :counter="counter"
      :rows="rows"
      :class="innerClass"
      dense
      :hide-details="!hint"
      :max="max"
    />
  </div>
</template>

<script>
import { VCurrencyField } from 'v-currency-field'
import { VTextarea, VTextField } from 'vuetify/lib'
export default {
  props: {
    integerValue: {
      type: Boolean,
      default: false,
    },
    currencyOption: {
      type: Object,
    },
    type: {
      type: String,
    },
    value: {
      type: [Object, String, Number, Boolean],
      default: '',
    },
    displayValue: {
      type: String,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    prefix: {
      type: String,
    },
    editMode: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    innerClass: {
      type: [String, Object],
    },
    placeholder: {
      type: String,
    },
    counter: {
      type: String,
    },
    rows: {
      type: String,
    },
    displayClass: {
      type: [String, Object],
    },
    max: {
      type: [String, Number],
    },
  },
  computed: {
    component() {
      switch (this.type) {
        case 'textarea':
          return VTextarea
        case 'currency':
          return VCurrencyField
        case 'decimal':
          return VCurrencyField
        case 'integer':
          return VCurrencyField
        default:
          return VTextField
      }
    },
  },
  data() {
    return {
      localValue: null,
    }
  },
  mounted() {},
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.$nextTick(() => (this.localValue = this.value))
      },
    },
    editMode: {
      immediate: true,
      handler: function () {
        if (this.editMode) {
          this.$nextTick(() => (this.localValue = this.value))
        } else {
          this.$nextTick(() => (this.localValue = null))
        }
      },
    },
  },
  methods: {
    onInput(fieldValue) {
      return fieldValue
    },
    onBlur(fieldValue) {},
    onFocus(event) {},
    onMouseup(event) {},
  },
}
</script>

<style></style>
