export default {
  primary: {
    lighten3: '#daf3cb',
    lighten2: '#b5e897',
    lighten1: '#6DD230',
    base: '#27AE60',
    darken1: '#007A04',
  },
  bg: {
    lighten1: '#f7fff9',
    base: '#E2F9E8',
    darken1: '#EEF2F5',
  },
  fg: {
    lighten3: '#E1E5E8',
    lighten2: '#C8D1DB',
    lighten1: '#7D96B2',
    base: '#5B728C',
    darken1: '#2E2F45',
  },
  outline: '#99B1CC',
  error: { base: '#ED7474', lighten1: '#FDEEEE' },
  info: '#778CA2',
  success: '#76964E',
  warning: '#E59D0B',
  WYellow: '#FFEE56',
}
