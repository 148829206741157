<template>
  <div>
    <w-crud-table
      ref="crud"
      title="Serviços"
      crud-endpoint="service"
      sort-by="name"
      :headers="headers"
      :can-create="true"
      :can-edit="(item) => canEditOrDeleteItem(item)"
      :can-delete="(item) => canEditOrDeleteItem(item)"
      :before-send="(item) => beforeSend(item)"
      :model-generator="() => ({ time: null, timeUnit: 'DAY', tasks: [] })"
      @click:edit="(item) => handleEditClick(item)"
      @click:add="() => updateRealStateAgencies()"
    >
      <template #form="{ item, editMode }">
        <v-text-field
          label="Nome"
          v-model="item.name"
          class="required"
          :rules="[$validationRules.required.rule]"
          counter="80"
        />

        <v-select
          label="Categoria de serviço"
          v-model="item.serviceCategory"
          @change="handleEditClick(item)"
          :items="serviceCategories"
          item-text="name"
          item-value="id"
          class="required"
          :rules="[$validationRules.required.rule]"
          return-object
        />

        <v-select
          v-if="isAdmin"
          label="Imobiliária"
          v-model="item.realEstateAgency"
          @change="handleEditClick(item)"
          :items="realEstateAgencies"
          item-text="name"
          item-value="id"
          class="required"
          :rules="[$validationRules.required.rule]"
          return-object
        />

        <v-checkbox
          label="É pacote"
          v-model="item.bundle"
          @change="
            item.bundleOptions = []
            item.bundleSize = null
          "
        />

        <v-currency-field
          v-show="item.bundle"
          label="Número de serviços no pacote"
          v-model="item.bundleSize"
          locale="pt-BR"
          :auto-decimal-mode="true"
          :decimal-length="0"
          :value-as-integer="false"
          :allow-negative="false"
          class="required"
          :rules="
            item.bundle
              ? [$validationRules.required.rule, (v) => v > 0 || 'O número deve ser pelo menos igual a 1']
              : []
          "
        />

        <v-autocomplete
          v-show="item.bundle"
          label="Serviços abrangidos pelo pacote"
          multiple
          v-model="item.bundleOptions"
          :items="item && item.serviceCategory ? availableServiceForBundle(item.id, item.serviceCategory.id) : []"
          item-text="name"
          item-value="id"
          return-object
          class="required"
          :rules="item.bundle ? [$validationRules.required.rule] : []"
        />

        <v-currency-field
          label="Preço"
          v-model="item.price"
          locale="pt-BR"
          prefix="R$"
          :disabled="priceDisabled"
          :value-as-integer="true"
          :auto-decimal-mode="false"
          :decimal-length="0"
          :allow-negative="false"
        />

        <v-select
          label="Unidade de preço"
          v-model="item.priceUnit"
          :items="['m²', 'm linear', 'pacote', 'unidade']"
          class="required"
          :rules="[$validationRules.required.rule]"
        />
        <v-currency-field
          label="Dias de trabalho"
          v-model="item.time"
          locale="pt-BR"
          :auto-decimal-mode="false"
          :decimal-length="0"
          :value-as-integer="true"
          :allow-negative="false"
          :defaultValue="null"
        />
        <v-select
          label="Unidade de tempo"
          v-model="item.timeUnit"
          :items="timeUnits"
          initial-value="DAY"
          item-text="name"
          item-value="id"
          disabled
          v-show="true"
        />

        <v-currency-field
          label="Comissão"
          v-model="item.workayFee"
          :disabled="priceDisabled"
          locale="pt-BR"
          prefix="R$"
          :auto-decimal-mode="true"
          :decimal-length="2"
          :value-as-integer="false"
          :allow-negative="false"
        />

        <v-combobox
          label="Tarefas"
          :items="taskList"
          item-text="name"
          item-value="id"
          v-model="selectedTask"
          counter="50"
          maxlength="50"
        >
          <template v-slot:item="data">
            {{ data.item.name }}
            <v-chip v-if="data.item.custom" class="ma-2" color="var(--v-primary-base)" outlined label>Sugerido</v-chip>
          </template>
        </v-combobox>
        <v-btn color="primary" @click="addTask(item)">Adicionar tarefa</v-btn>

        <draggable
          :list="item.tasks"
          class="mt-4"
          ghost-class="ghost"
          v-if="item.tasks"
          @change="taskMove($event, item.tasks)"
          v-bind="dragOptions"
        >
          <transition-group :name="!drag ? 'flip-list' : null">
            <v-chip
              class="my-1 task"
              style="width: 100%"
              v-for="(task, index) in item.tasks.filter((task) => task.active)"
              :key="`task_${index}`"
              close
              color="var(--v-primary-base)"
              outlined
              label
              close-icon="mdi-delete"
              @click:close="deleteTask(index, item, editMode)"
            >
              <div>
                <v-icon class="mr-2">mdi-drag</v-icon>
                {{ task.task.name }}
              </div></v-chip
            >
          </transition-group>
        </draggable>

        <v-autocomplete
          class="mt-8"
          ref="autocomplete"
          multiple
          dense
          chips
          label="Materiais"
          v-model="item.materials"
          :items="materials"
          :item-text="(item) => `${item.material.name} - ${item.material.description}`"
          :item-value="(item) => item.material.id"
          counter="50"
          maxlength="50"
          return-object
        />

        <v-textarea label="Descrição" v-model="item.description" counter="255" maxlength="255" />
      </template>
    </w-crud-table>
    <w-dialog
      ref="dialog"
      :title="'Materiais'"
      v-model="showMaterialsDialog"
      :loading="dialogLoading"
      :buttons="[
        {
          label: 'fechar',
          action: () => this.toggleMaterialsDialog(),
        },
      ]"
    >
      <w-service-material-form
        ref="serviceMaterialsForm"
        :all-material-list="materialList"
        :service-materials="serviceMaterials"
        :current-service="currentServiceToShowMaterials"
        @update:serviceMaterials="(event) => (this.serviceMaterials = event)"
        @update:allMaterialList="(event) => (this.allMaterialList = event)"
      />
    </w-dialog>
  </div>
</template>

<script>
import WDialog from '@/components/WDialog.vue'

import draggable from 'vuedraggable'
import services from '../api/services'
import WCrudTable from '../components/WCrudTable.vue'

import { createNamespacedHelpers, mapGetters } from 'vuex'
import WServiceMaterialForm from '../components/WServiceMaterialForm'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { WCrudTable, WServiceMaterialForm, WDialog, draggable },

  data() {
    return {
      drag: false,
      serviceMaterials: [],
      selectedTask: null,
      priceDisabled: false,
      showMaterialsDialog: false,
      dialogLoading: false,
      materialToAdd: null,
      currentServiceToShowMaterials: null,
      materialList: [],
      serviceCategories: [],
      realEstateAgencies: [],
      services: [],
      materials: [],
      taskList: [],
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Categoria', value: 'serviceCategory.name' },
        { text: 'Preço', value: 'price', converter: (item) => this.$utils.formatCurrency(item.price, 0) },
        { text: 'Unidade de preço', value: 'priceUnit' },
        { text: 'Dias de execução', value: 'time' },
        { text: 'Pacote', value: 'bundle', converter: (item) => (item.bundle ? '✔️' : '❌'), align: 'center' },
        { text: 'Descrição', value: 'description' },
        {
          text: 'Imobiliária',
          value: 'realEstateAgency.name',
          converter: (item) => item.realEstateAgency?.name || '-',
        },
      ],
      timeUnits: [
        { id: 'MONTH', name: 'Mês' },
        { id: 'WEEK', name: 'Semana' },
        { id: 'DAY', name: 'Dia' },
        { id: 'HOUR', name: 'Hora' },
        { id: 'MINUTE', name: 'Minuto' },
      ],
      customActions: [
        {
          icon: 'mdi-tools',
          tooltip: 'Materiais',

          action: async (item) => {
            this.dialogLoading = true
            this.toggleMaterialsDialog()
            await this.getMaterialsByService(item.id)
            this.currentServiceToShowMaterials = item
            this.dialogLoading = false
          },

          successMessage: false,
        },
      ],
    }
  },
  methods: {
    beforeSend(item) {
      item.materials =
        item.materials?.map((material) => {
          return {
            ...material,
            service: { ...item },
          }
        }) ?? []
    },
    canEditOrDeleteItem(item) {
      return this.isAdmin || item.realEstateAgency?.id == this.myRealEstateAgency?.id
    },
    toggleMaterialsDialog() {
      this.showMaterialsDialog = !this.showMaterialsDialog
    },
    async getMaterials() {
      this.materialList = (await services.listMaterials()).data
    },
    async getMaterialsByService(serviceId) {
      this.serviceMaterials = (await services.listMaterialsByServiceId(serviceId)).data
    },
    async listTasks(categoryId) {
      this.taskList = (await services.listTasks(categoryId.id)).data
    },
    taskMove(event, tasks) {
      tasks.forEach((task, index) => {
        task.executionOrder = index
      })
    },
    async updateRealStateAgencies() {
      this.realEstateAgencies = (await services.getRealEstateAgencies()).data
    },
    async handleEditClick(item) {
      this.updateRealStateAgencies()
      this.taskList = (await services.listTasks(item.serviceCategory.id)).data
      this.$set(
        item,
        'tasks',
        item.tasks.sort((a, b) => a.executionOrder - b.executionOrder),
      )
    },
    deleteTask(index, item, editMode) {
      if (!editMode) {
        item.tasks.splice(index, 1)
      } else {
        item.tasks[index].active = false
      }
    },
    addTask(item) {
      requestAnimationFrame(() => {
        if (!this.selectedTask) return

        const task = {
          active: true,
          executionOrder: item.tasks.length,
          task: {
            id: this.selectedTask?.id,
            name: this.selectedTask.name ?? this.selectedTask,
            custom: false,
          },
        }
        if (item.tasks.some((t) => t.task.name == task.task.name)) return

        item.tasks.push(task)
        this.$set(item, 'tasks', item.tasks)
      })
    },
    availableServiceForBundle(bundleId, categoryId) {
      return this.services.filter((service) => service.serviceCategory.id === categoryId && service.id !== bundleId)
    },
  },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  async created() {
    this.serviceCategories = (await services.getCrud('service-category')).data
    this.materials = (await services.getCrud('material')).data.map((material) => {
      return {
        id: null,
        material: material,
      }
    })

    this.services = (await services.getCrud('service')).data

    this.services.forEach((service) => ({
      ...service,
      tasks: service.tasks.sort((a, b) => a.executionOrder - b.executionOrder),
    }))

    await this.getMaterials()

    if (!this.isAdmin) {
      this.priceDisabled = true
    }
  },
  watch: {
    showMaterialsDialog: {
      handler: async function (newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.serviceMaterialsForm.fillMaterialList()
          })
        }
      },
    },
  },
}
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}

.v-chip__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.task:active {
  cursor: move;
}
</style>
