import WReportPrintDocument from '@/components/ServiceReport/WReportPrintDocument.vue'
import WProposalPrintDocument from '@/components/WProposalPrintDocument.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import api from '../api/services'
import store from '../store'
import Archived from '../views/Archived.vue'
import AssociatedWorkPartner from '../views/AssociatedWorkPartner.vue'
import Chat from '../views/Chat/Chat.vue'
import Client from '../views/Client.vue'
import DemandDetails from '../views/DemandDetails.vue'
import Demands from '../views/Demands.vue'
import InProgress from '../views/InProgress.vue'
import Login from '../views/Login.vue'
import Material from '../views/Material.vue'
import MaterialItem from '../views/MaterialItem.vue'
import Opportunities from '../views/Opportunities.vue'
import RealEstateAgency from '../views/RealEstateAgency.vue'
import RealEstateAgencyDetails from '../views/RealEstateAgencyDetails.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Service from '../views/Service.vue'
import ServiceCategory from '../views/ServiceCategory.vue'
import Summary from '../views/Summary/Summary.vue'
import Test from '../views/Test.vue'
import UserRating from '../views/UserRating.vue'

import WorkPartner from '../views/WorkPartner.vue'

import firebase from 'firebase/app'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/login',
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: {
      hidden: true,
    },
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/summary',
    name: 'Summary',
    component: Summary,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/service-category',
    name: 'ServiceCategory',
    component: ServiceCategory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/material',
    name: 'Material',
    component: Material,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `/demand/details/:demandId`,
    name: 'Details',
    component: DemandDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/item',
    name: 'Item',
    component: MaterialItem,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `/proposal/:demandId`,
    name: 'Proposal',
    component: WProposalPrintDocument,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `/report/:demandId`,
    name: 'Report',
    component: WReportPrintDocument,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    component: Opportunities,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/in-progress',
    name: 'InProgress',
    component: InProgress,

    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Demands,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/archived',
    name: 'Archived',
    component: Archived,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/work-partner',
    name: 'WorkPartner',
    component: WorkPartner,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/associated-work-partner',
    name: 'AssociatedWorkPartner',
    component: AssociatedWorkPartner,
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: '/client',
    name: 'Client',
    component: Client,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/user',
  //   name: 'User',
  //   component: User,
  //   meta: {
  //     requiresAuth: true,
  //     requiresAdmin: true,
  //   },
  // },
  {
    path: '/real-estate-agency',
    name: 'RealEstateAgency',
    component: RealEstateAgency,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/rating',
    name: 'Rating',
    component: UserRating,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/my-real-estate-agency',
    name: 'RealEstateAgencyDetails',
    component: RealEstateAgencyDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reset-password/:code',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    metade: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const currentUser = firebase.auth().currentUser

  if (currentUser) {
    const user = await api.getUserByEmail(currentUser.email)

    store.commit('setAdmin', user.data.admin)
    store.commit('setRealEstateAgency', user.data.realEstateAgency)

    if (store.state.user.roles.length === 0) {
      const response = await api.getRoles()

      store.commit('setRoles', response.data)
    }
  }

  const authRequired = to.matched.some((route) => route.meta.requiresAuth)
  if (authRequired && !currentUser) {
    next('/login')
  } else if (currentUser && to.name === 'Login') {
    next('/opportunities')
  }

  if (to.meta.requiresAdmin && !store.getters.isAdmin) {
    next('/opportunities')
  }

  next()
})

export default router
