<template>
  <div>
    <span style="font-size: 16px; color: black; font-weight: bold">Pesquisar profissional</span>
    <v-text-field label="Pesquisar por nome, categoria de serviço ou região de atendimento" v-model="search" />

    <div class="d-flex flex-column" style="max-height: 300px; overflow-y: auto" v-if="search.length > 0">
      <div
        v-for="workPartner in filteredAllWorkPartners"
        :key="workPartner.id"
        class="d-flex py-2 align-start work-partner"
        @click="toggleWorkpartner(workPartner)"
      >
        <v-checkbox color="primary" disabled ref="checkbox" :hide-details="true" :value="checkboxValue(workPartner)" />

        <div class="d-flex flex-column work-partner-info" style="width: 100%">
          <div class="d-flex justify-space-between">
            <h3>
              {{ workPartner.name }}
            </h3>
            <v-chip v-if="workPartner.indicatedBy" color="#F3EA8D" style="border-radius: 8px">{{
              workPartner.indicatedBy.name
            }}</v-chip>
            <v-chip v-else color="#97C89C" style="border-radius: 8px">Sem indicação</v-chip>
          </div>

          <div class="work-partner-sub-info">
            <span style="color: var(--v-primary-base)">Cidades:</span>
            <span>{{ workPartner.atuationCities.map((city) => city.name).join(', ') }}</span>
          </div>

          <div class="work-partner-sub-info">
            <span style="color: var(--v-primary-base)">Serviços:</span>
            <span>{{ workPartner.serviceCategories.map((serviceCategory) => serviceCategory.name).join(', ') }}</span>
          </div>

          <div class="work-partner-sub-info">
            <span style="color: #6d6e71">Avaliações:</span>
            <span :style="{ color: workPartner.rating ? '#6D6E71' : 'var(--v-primary-base)' }">{{
              workPartner.rating || 'Novo!'
            }}</span>
          </div>
        </div>
      </div>
      <div v-if="filteredAllWorkPartners.length === 0">
        <p style="color: red; font-weight: bold">Não há nenhum profissional cadastrado com essas informações.</p>
        <p>
          Se você quiser cadastrar um novo profissional, peça para que o profissional instale o aplicativo de celular e
          crie uma conta. Depois que as informações forem preenchidas, ele aparecerá na pesquisa.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import api from '../api/services'

import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {},
  directives: {
    mask,
  },

  data() {
    return {
      search: '',
      availableAssociates: [],
      selectedWorkPartners: [],
    }
  },

  methods: {
    orderWorkPartners(workPartners) {
      return workPartners.sort((a, b) => (a.indicatedBy === null ? -1 : b.indicatedBy === null ? 1 : 0))
    },
    checkboxValue(workPartner) {
      return this.selectedWorkPartners.includes(workPartner.id)
    },
    toggleWorkpartner(workPartner) {
      const alreadyIncluded = this.selectedWorkPartners.find((wp) => wp === workPartner.id)

      if (alreadyIncluded) {
        this.selectedWorkPartners = this.selectedWorkPartners.filter((wp) => wp !== workPartner.id)
      } else {
        this.selectedWorkPartners.push(workPartner.id)
      }

      this.$emit('input', this.selectedWorkPartners)
    },
  },

  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),

    filteredAllWorkPartners() {
      return this.orderWorkPartners(
        this.availableAssociates.filter((workPartner) => {
          if (this.search !== '') {
            return (
              workPartner.name.toLowerCase().includes(this.search.toLowerCase()) ||
              workPartner.serviceCategories.some((serviceCategory) =>
                serviceCategory.name.toLowerCase().includes(this.search.toLowerCase()),
              ) ||
              workPartner.atuationCities.some((city) => city.name.toLowerCase().includes(this.search.toLowerCase()))
            )
          }
          return true
        }),
      )
    },
  },

  async created() {
    this.availableAssociates = (await api.getAvailableAssociates(this.myRealEstateAgency.id)).data
  },
}
</script>

<style scoped>
.work-partner {
  border-radius: 12px;
}
.work-partner:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}
.work-partner-info {
  margin-bottom: 36px;
  margin-left: 12px;
  padding-right: 24px;
}
.work-partner-sub-info {
  display: flex;
}

.work-partner-sub-info span:first-child {
  margin-right: 4px;
  font-weight: 500;
}

.v-input--selection-controls {
  padding: 0 !important;
  margin: 0 !important;
}

* >>> .v-icon.notranslate.mdi.mdi-checkbox-marked.theme--light {
  color: var(--v-primary-base);
}
</style>
