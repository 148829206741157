<template>
  <div style="height: 250px" class="mt-8">
    <Pie :options="chartOptions" :data="display" />
  </div>
</template>

<script>
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js'
import { Pie } from 'vue-chartjs'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  components: { Pie },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      display: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            position: 'right',

            labels: {
              usePointStyle: true,
            },
          },
        },
      },
    }
  },
  async created() {
    this.display = {
      labels: this.chartData?.map((item) => item.label),
      datasets: [
        {
          backgroundColor: ['#27AE60', '#FCED52', '#17683A', '#ACB9FF', '#BAE5CC'],
          data: this.chartData.map((item) => {
            return item.value
          }),
        },
      ],
    }
  },
}
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  div {
    flex-direction: column !important;
    max-width: 100%;
  }
  .col,
  .row {
    flex: 1;
    flex-direction: row;
  }
}
</style>
