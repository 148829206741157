export const CRUD_ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  RESTORE: 'RESTORE',
}

export const ROLES = {
  ARCHITECT: 'Arquiteto',
  ESTATE: 'Imobiliário',
  CLIENT: 'Cliente',
}

export const SOCIAL_MEDIA_LABELS = {
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TWITTER: 'Twitter',
  CONTACT: 'Contato',
}

export const MATERIAL_CATEGORIES = {
  NAO_INFORMADO: 'Não informado',
  GERAL: 'Geral',
  ELETRICA: 'Elétrica',
  HIDRAULICA: 'Hidráulica',
  GAS: 'Gás',
  ACABAMENTO: 'Acabamento (revestimentos, louças e metais)',
  IMPERMEABILIZACAO: 'Impermeabilização',
  PINTURA: 'Pintura',
  BRUTO: 'Bruto',
  FRETE: 'Frete',
  ENTREGA: 'Entrega',
  GESSO: 'Gesso',
}

export const SERVICE_CATEGORIES = {
  1: 'Elétrica',
  2: 'Pintura',
  3: 'Hidráulica',
  4: 'Automação',
  5: 'Alvenaria',
  6: 'Marcenaria',
  7: 'Refrigeração',
  8: 'Arquitetura',
  9: 'Serralheria',
  10: 'Reformas',
}

export const STATUS = {
  PENDING: 1,
  WAITING_PARTNER: 2,
  CANCELLED: 3,
  ACCEPTED: 4,
  REFUSED: 5,
  IN_PROGRESS: 6,
  DONE: 7,
  NOT_DONE: 8,
}

export const DOCUMENT_TYPE = {
  IMAGE: 'IMAGE',
  DOCUMENT: 'DOCUMENT',
  PROPOSAL: 'PROPOSAL',
}

export const MAX_FILE_SIZE = {
  DEMAND: 5 * 1024 * 1024,
  CHAT: 50 * 1024 * 1024,
  PROFILE_PHOTO: 5 * 1024 * 1024,
}

export const TIME_UNIT = {
  MONTH: 'Mês',
  WEEK: 'Semana',
  DAY: 'Dia',
  HOUR: 'Hora',
  MINUTE: 'Minuto',
}

export const REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE = {
  HTML_EDITOR: 'HTML_EDITOR',
  SOCIAL_MEDIA: 'SOCIALMEDIA',
  IMAGE: 'IMAGE',
}

export const SHOW_REAL_ESTATE_AGENCY_PROPOSAL_TYPE = {
  IMAGE: 'IMAGE',
  TEXT: 'TEXT',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
}

export const OBSERVATIONS = [
  {
    title: 'Formas de pagamento',
    description:
      'As informações de pagamento aparecem abaixo do resumo do orçamento da proposta, que deve ser enviado para o cliente. Elas indicam ao seu cliente quais são as restrições de pagamento que ele deve seguir. Por padrão, a sua proposta vem com as informações de pagamento que a Workay! sugere. Abaixo, você pode personalizá-las, se achar necessário.',
    property: 'paymentMethod',
    type: REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.HTML_EDITOR,
    showType: SHOW_REAL_ESTATE_AGENCY_PROPOSAL_TYPE.TEXT,
    showOnFor: true,
  },
  {
    title: 'Observações',
    description:
      'As observações aparecem logo após as informações de pagamento na sua proposta. Nas observações, você pode colocar o que você garante ou não garante com a entrega daquele serviço, bem como as multas, obrigações e responsabilidades do seu cliente com você e suas para com o seu cliente.  Por padrão, a sua proposta vem com as observações que a Workay! sugere. Abaixo, você pode personalizá-las, se achar necessário.',
    actionDescription: 'Personalize as observações',
    property: 'observations',
    type: REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.HTML_EDITOR,
    showType: SHOW_REAL_ESTATE_AGENCY_PROPOSAL_TYPE.TEXT,
    showOnFor: true,
  },
  {
    title: 'Compromisso com o cliente',
    description:
      'As informações de compromisso com o cliente aparecem depois das observações na sua proposta. Elas reforçam o compromisso da empresa com a qualidade e o que será feito em caso de insatisfação com os serviços prestados. Por padrão, a sua proposta vem com o compromisso com o cliente que a Workay! sugere. Abaixo, você pode personalizá-las, se achar necessário.',

    actionDescription: 'Personalize o compromisso com o cliente',
    property: 'clientCommitment',
    type: REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.HTML_EDITOR,
    showType: SHOW_REAL_ESTATE_AGENCY_PROPOSAL_TYPE.TEXT,
    showOnFor: true,
  },
  {
    title: 'Redes sociais e contato',
    description:
      'As redes sociais e o contato aparecem no rodapé da sua proposta. Você pode escolher até três redes sociais.',
    actionDescription: 'Redes sociais e contato',
    property: 'socialMedia',
    type: REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.SOCIAL_MEDIA,
    showType: SHOW_REAL_ESTATE_AGENCY_PROPOSAL_TYPE.SOCIAL_MEDIA,
  },
  {
    title: 'Logo',
    description:
      'Você pode personalizar a proposta com o seu logo e ele aparecerá no canto superior direito e no canto inferior esquerdo. Basta fazer o upload do arquivo .png ou .jpeg ou .jpg.',
    actionDescription: 'Insira o seu logo',
    property: 'logo',
    type: REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.IMAGE,
    showType: SHOW_REAL_ESTATE_AGENCY_PROPOSAL_TYPE.IMAGE,
  },
]

export const CHAT_NAVIGATION_TAB = {
  CHAT_LIST: 'chatList',
  CONTACT_LIST: 'contactList',
  PROFILE: `profile`,
}

export const CHAT_PROFILE_TYPE = {
  ME: 'ME',
  WORKPARTNER: 'WORKPARTNER',
}

export const CHAT_MESSAGE_TYPE = {
  IMAGE: 'IMAGE',
  FILE: 'FILE',
  TEXT: 'TEXT',
  AUDIO: 'AUDIO',
}
