<template>
  <div>
    <w-dialog class="px-8" v-model="showDialog" :max-width="600" :buttons="buttons" :dismissible="false">
      <div class="box mt-4 px-10 pb-0 d-flex flex-column align-center">
        <h2 class="mx-8">O que você achou do serviço de {{ partnerRating.workPartnerName }}?</h2>

        <div class="mt-2">
          <span style="color: var(--v-primary-base); font-weight: 500">Você está avaliando os serviços de: </span
          ><span>{{ serviceCategories }}</span>
        </div>

        <v-rating
          class="my-4"
          background-color="primary"
          color="primary"
          v-model="localRating.evaluationScore"
          hover
          length="5"
          size="42"
        ></v-rating>

        <textarea
          v-model="localRating.comment"
          class="text-area"
          placeholder="O que aconteceu neste serviço? Deixe um elogio ou um feedback"
        />
      </div>
    </w-dialog>
  </div>
</template>

<script>
import WDialog from '@/components/WDialog.vue'
import store from '@/store'
import api from '../api/services'

export default {
  components: { WDialog },

  data() {
    return {
      showDialog: false,
      localRating: {},
      saving: false,
    }
  },

  methods: {
    async save() {
      this.saving = true
      await api.ratingWorkPartner({ id: this.partnerRating.id, ...this.localRating })

      this.showDialog = false
      this.saving = false
      store.state.globalAlertComponent.showSuccess('Avaliação enviada com sucesso!')
    },
  },

  props: {
    partnerRating: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    serviceCategories() {
      return this.partnerRating.serviceCategories?.join(', ').replace(/,([^,]*)$/, ' e$1')
    },
    canSave() {
      return this.localRating.evaluationScore > 0 && this.localRating.comment?.trim().length > 0
    },
    buttons() {
      return [
        {
          label: 'salvar',
          action: () => this.save(),
          disabled: !this.canSave,
          loading: this.saving,
        },
      ]
    },
  },

  watch: {
    value: {
      handler(val) {
        this.showDialog = val
      },
    },
    showDialog: {
      handler(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style scoped>
h2 {
  color: black;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
}

.text-area {
  width: 100%;
  min-height: 100px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 8px;
  resize: vertical;
  font-size: 16px;
  color: #4f4f4f;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

.text-area:focus {
  outline: none;
}
</style>
