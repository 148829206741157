export function getDoneOrNotDone(services, logicType, done) {
  const logicMapper = {
    service: (services) =>
      services
        .filter((s) => s.finished === done)
        .map((service) => ({
          name: service.name,
          price: service.currentEstimate.price,
        })),
    material: (services) =>
      services
        .filter((s) => s.finished === done)
        .flatMap((service) =>
          service.items?.map((materialItem) => ({
            name: materialItem.item.fullName,
            price: materialItem.item.unitaryPrice,
            quantity: materialItem.quantity,
          })),
        ),
    task: (services) =>
      services
        .flatMap((service) => service.tasks)
        .filter((serviceTask) => serviceTask.included && serviceTask.done === done),
  }

  const logicTypeMapper = logicMapper[logicType]

  if (!logicTypeMapper) {
    return []
  }

  return logicTypeMapper(services)
}
