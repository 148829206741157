<template>
  <div id="user-screen-wrapper" class="d-flex flex-column">
    <ChatList
      v-if="tabToShow.tabName === $consts.CHAT_NAVIGATION_TAB.CHAT_LIST"
      :available-chats="availableChats"
      @currentChat="(chat) => $emit('currentChat', chat)"
      @navigateTo="(screen) => (tabToShow = screen)"
      :my-chat-user="myChatUser"
    />

    <Contacts
      v-if="tabToShow.tabName === $consts.CHAT_NAVIGATION_TAB.CONTACT_LIST"
      @currentChat="(chat) => $emit('currentChat', chat)"
      @navigateTo="(screen) => (tabToShow = screen)"
    />

    <Profile
      v-if="tabToShow.tabName?.startsWith($consts.CHAT_NAVIGATION_TAB.PROFILE)"
      @navigateTo="(screen) => (tabToShow = screen)"
      :profile-type="tabToShow.tabName.split('-')[1]"
      :my-chat-user="myChatUser"
      :work-partner="workPartnerData"
      @updateChatUser="$emit('updateChatUser')"
    />
  </div>
</template>
<script>
import services from '@/api/services'
import { createNamespacedHelpers } from 'vuex'
import ChatList from './ChatList.vue'

import Contacts from './Contacts.vue'
import Profile from './Profile.vue'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { ChatList, Contacts, Profile },

  props: {
    myChatUser: {
      type: Object,
    },
    navigateToFromOutside: {
      type: Object,
    },
  },

  data() {
    return {
      availableChatsInterval: null,
      availableChats: null,
      showChatList: true,
      tabToShow: { tabName: this.$consts.CHAT_NAVIGATION_TAB.CHAT_LIST },
      workPartnerData: null,
    }
  },

  watch: {
    navigateToFromOutside(val) {
      if (this.navigateToFromOutside) this.tabToShow = this.navigateToFromOutside
      this.workPartnerData = this.navigateToFromOutside?.data.info
    },

    tabToShow() {
      this.$emit('tabToShowChanged', this.sreenToShow)
    },
  },

  async created() {
    this.availableChatsInterval = setInterval(async () => {
      this.availableChats = (await services.getAvailableChats()).data
    }, 1000)
  },

  beforeDestroy() {
    clearInterval(this.availableChatsInterval)
  },
}
</script>
<style scoped>
#user-screen-wrapper {
  background-color: #fdfdfd;
  height: 100%;
}

#title {
  color: white;
  font-weight: 700;
  font-size: 18px;
}

#logo {
  border-radius: 50%;
  background-color: white;
  width: 50px;
  height: 50px;
  padding: 4px;
  box-sizing: border-box;
}

#new-chat-button {
  gap: 8px;
  padding: 16px 24px;
  background-color: #fced52;
  font-weight: 700;
  border-radius: 8px;
}
</style>
