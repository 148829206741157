<template>
  <div class="parent">
    <v-data-table
      class="crud-table elevation-1"
      :headers="computedHeaders"
      :items="filteredItems"
      :items-per-page="itemsPerPage"
      :no-data-text="typedFilter ? 'O filtro digitado não retornou resultados' : noDataText"
      :loading="loadingTable"
      :sort-by="sortBy"
      :item-key="itemIdKey"
      multi-sort
      loading-text="Carregando informações..."
      :footer-props="{
        'page-text': '{0}-{1} de {2}',
        'items-per-page-text': 'Itens por página',
        'items-per-page-all-text': 'Todos',
        'items-per-page-options': [10, 15, 20, 30, 50, 100],
      }"
      :header-props="headerProps"
    >
      <template slot="top">
        <v-card class="d-flex align-center px-4 py-2 flex-wrap" :disabled="loadingTable" elevation="0">
          <h3 class="title font-weight-bold">{{ title }}</h3>

          <v-text-field
            v-if="!isBasic"
            v-model="typedFilter"
            placeholder="Digite para filtrar os resultados"
            class="mx-8 flex-grow-1"
            hide-details
            clearable
            :style="$vuetify.breakpoint.smAndDown ? 'order:3' : ''"
          ></v-text-field>
          <v-switch
            v-if="!isBasic"
            class="mx-2"
            v-model="showInactive"
            :label="`Exibir desativados (${inactiveCount})`"
            :style="$vuetify.breakpoint.smAndDown ? 'order:2' : ''"
          />
          <section class="d-flex align-center" v-if="!isBasic">
            <v-btn icon class="mx-2" color="primary" @click="refreshList" title="Atualizar tabela">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              color="primary"
              tile
              elevation="0"
              title="Criar novo item"
              :disabled="!canCreate"
              @click="onCreateItem"
            >
              {{ createButtonText }}
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </section>

          <w-dialog
            :title="editMode ? dialogEditItem : dialogNewItem"
            v-model="showFormDialog"
            :loading="loadingDialog"
            :max-width="maxDialogWidth"
            :buttons="[
              {
                label: 'fechar',
                action: closeFormDialog,
                outlined: true,
              },
              {
                label: 'salvar',
                action: () => onSaveItem(model),
                disabled: !validForm,
              },
            ]"
            @input="closeFormDialog"
          >
            <v-form v-model="validForm" ref="form" v-if="showFormDialog">
              <slot name="form" v-bind="{ item: model, editMode: editMode }"></slot>
            </v-form>
          </w-dialog>
        </v-card>
      </template>

      <template v-for="header in dataHeaders" #[`item.${header.value}`]="props">
        <slot :name="header.value" v-bind="props">
          {{ itemToValue(props.item, header) }}
        </slot>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-card class="d-flex justify-space-around" elevation="0" :disabled="loadingTable" color="transparent">
          <v-btn
            small
            icon
            @click="onEditItem(item)"
            title="Editar"
            :disabled="typeof canEdit === 'function' ? !canEdit(item) : !canEdit"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="item[itemActiveKey]"
            small
            icon
            @click="onDeactivateItem(item)"
            title="Desativar"
            :disabled="typeof canDelete === 'function' ? !canDelete(item) : !canDelete"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn v-else small icon @click="onRestoreItem(item)" title="Restaurar" :disabled="!canDelete">
            <v-icon>mdi-delete-restore</v-icon>
          </v-btn>
          <v-btn
            small
            icon
            v-for="action in customActions"
            :key="action.key"
            :disabled="typeof action.disabled === 'function' ? action.disabled(item) : !!action.disabled"
            :title="action.text"
            @click="onCustomAction(action.action, item, action.successMessage)"
          >
            <v-icon>{{ action.icon }}</v-icon>
          </v-btn>
        </v-card>
      </template>
    </v-data-table>
    <w-alert ref="alert" />
  </div>
</template>

<script>
import services from '@/api/services'
import util from '@/config/util'
import WAlert from './WAlert.vue'
import WDialog from './WDialog.vue'

const defaultFilterFunction = (value, search) =>
  util.toComparableStringLowerCase(value).includes(util.toComparableStringLowerCase(search))

export default {
  components: { WDialog, WAlert },
  props: {
    isBasic: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    fillUpdateForm: {
      type: Function,
      default: (formModel, selectedItem) => undefined,
    },
    beforeSend: {
      type: Function,
      default: (model, key) => undefined,
    },
    itemIdKey: {
      type: String,
      default: 'id',
    },
    itemActiveKey: {
      type: String,
      default: 'active',
    },
    noDataText: {
      type: String,
      default: 'Não há dados',
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    title: String,
    dialogMaxWidth: [String, Number],
    customActions: {
      type: Array,
      default: () => [],
    },
    customSave: {
      type: Function,
      default: null,
    },
    customUpdate: {
      type: Function,
      default: null,
    },
    canEdit: [Boolean, Function],
    canDelete: [Boolean, Function],
    canCreate: Boolean,
    createButtonText: {
      type: String,
      default: 'novo item',
    },
    dialogNewItem: {
      type: String,
      default: 'Novo item',
    },
    dialogEditItem: {
      type: String,
      default: 'Editar',
    },
    crudEndpoint: {
      type: String,
      required: true,
    },
    sortBy: [String, Array],
    maxDialogWidth: [Number, String],
    modelGenerator: {
      type: Function,
      default: () => ({}),
    },
    filterFunction: {
      type: Function,
      default: defaultFilterFunction,
    },
    headerProps: {
      type: Object,
      default: () => ({
        sortByText: 'Ordenar por',
      }),
    },
  },
  computed: {
    filteredItems() {
      const items = this.showInactive ? this.allItems : this.activeItems
      if (!this.typedFilter || !this.typedFilter.trim()) {
        return items
      }

      const cleanTypedFilter = this.typedFilter.trim().toUpperCase()

      return items.filter((item) =>
        this.filterableHeaders.some((header) => {
          const filter = header.customFilter || this.filterFunction
          const value = this.itemToValue(item, header)
          return filter(value, cleanTypedFilter, item)
        }),
      )
    },
    allItems() {
      return Object.values(this.items)
    },
    activeItems() {
      return this.allItems.filter((item) => item[this.itemActiveKey])
    },
    computedHeaders() {
      return [
        {
          value: 'actions',
          width: 96 + this.customActions.length * 48,
          sortable: false,
        },
        ...this.headers,
      ]
    },
    dataHeaders() {
      return this.headers.filter((header) => !!header.text)
    },
    filterableHeaders() {
      return this.dataHeaders.filter((header) => header.filterable !== false)
    },
    inactiveCount() {
      return Object.values(this.items).filter((item) => !item[this.itemActiveKey]).length
    },
  },
  data() {
    return {
      loadingTable: false,
      loadingDialog: false,
      showFormDialog: false,
      showFileUploadDialog: false,
      validForm: false,
      editMode: false,
      showInactive: false,
      typedFilter: '',
      model: {},
      items: [],
      crudActionMap: {
        [this.$consts.CRUD_ACTION.CREATE]: services.createCrud,
        [this.$consts.CRUD_ACTION.UPDATE]: services.updateCrud,
        [this.$consts.CRUD_ACTION.DELETE]: services.deleteCrud,
        [this.$consts.CRUD_ACTION.RESTORE]: services.restoreCrud,
      },
    }
  },
  methods: {
    openFileUploadDialog() {
      this.showFileUploadDialog = true
    },
    onBulkLoadSuccess() {
      this.refreshList()
      this.$emit('bulkLoad')
    },
    async onDeactivateItem(item) {
      item = this.$utils.copy(item)
      this.beforeSend(this.model, null)
      item[this.itemActiveKey] = false
      this.loadingTable = true
      await this.saveItem(item[this.itemIdKey], this.$consts.CRUD_ACTION.DELETE)
      this.loadingTable = false
    },
    async onRestoreItem(item) {
      item = this.$utils.copy(item)
      this.beforeSend(this.model, null)
      item[this.itemActiveKey] = true
      this.loadingTable = true
      await this.saveItem(item[this.itemIdKey], this.$consts.CRUD_ACTION.RESTORE)
      this.loadingTable = false
    },
    onEditItem(item) {
      this.model = this.modelGenerator()
      this.beforeSend(this.model, null)
      this.openFormDialog(true)
      this.$nextTick(() => {
        this.model = this.$utils.copy(item)
        if (this.fillUpdateForm) {
          this.fillUpdateForm(this.model, this.$utils.copy(item))
        }
        this.$refs.form.validate()
      })

      this.$emit('click:edit', item)
    },
    onCreateItem() {
      this.model = this.modelGenerator()
      this.beforeSend(this.model, null)
      this.openFormDialog(false)

      this.$nextTick(() => {
        this.$refs.form.resetValidation()
      })

      this.$emit('click:add')
    },
    onSaveItem(item) {
      if (!this.$refs.form.validate()) {
        return
      }

      this.beforeSend(this.model, null)

      item = this.$utils.copy(item)
      for (let key in item) {
        const value = item[key]
        if (typeof value === 'string' && value.trim() === '') {
          item[key] = null
        }
      }

      this.loadingDialog = true

      this.saveItem(item, item[this.itemIdKey] ? this.$consts.CRUD_ACTION.UPDATE : this.$consts.CRUD_ACTION.CREATE)
    },
    onCustomAction(action, item, successMessage) {
      this.loadingTable = true
      action(item)
        .then(() => {
          if (successMessage !== false)
            this.showSuccess(typeof successMessage === 'function' ? successMessage(item) : successMessage)
        })
        .catch(this.showError)
        .finally(() => {
          this.loadingTable = false
        })
    },
    showSuccess(message = 'Operação realizada com sucesso!') {
      this.$refs.alert.showSuccess(message)
    },
    showError(error) {
      const message =
        error.response?.data?.message || error.message || (typeof error === 'string' ? error : 'Ocorreu um erro')
      this.$refs.alert.showError(message)
    },
    saveItem(itemOrId, crudAction) {
      const responseFunction = (response) => {
        const returnedItem = response.data
        this.$set(this.items, returnedItem[this.itemIdKey], returnedItem)
        this.closeFormDialog()
        this.$emit('save', returnedItem)
        this.showSuccess()
        this.loadingDialog = false
      }

      if (
        typeof this.customSave === 'function' &&
        ![this.$consts.CRUD_ACTION.DELETE, this.$consts.CRUD_ACTION.RESTORE].includes(crudAction)
      ) {
        if (itemOrId.id) {
          this.customUpdate(itemOrId)
            .then((response) => {
              responseFunction(response)
            })
            .catch((e) => this.stopLoadingDialog())
        } else {
          this.customSave(itemOrId)
            .then((response) => {
              responseFunction(response)
            })
            .catch((e) => this.stopLoadingDialog())
        }
      } else {
        const crudMethod = this.crudActionMap[crudAction]
        return crudMethod(this.crudEndpoint, itemOrId)
          .then((response) => {
            responseFunction(response)
          })
          .catch(this.showError)
      }
    },
    openFormDialog(editMode) {
      this.editMode = editMode
      this.showFormDialog = true
    },
    closeFormDialog() {
      if (!this.showFormDialog) {
        return
      }

      this.showFormDialog = false
      this.$refs.form.reset()
      this.stopLoadingDialog()
    },
    stopLoadingDialog() {
      this.loadingDialog = false
    },
    refreshList() {
      this.items = []
      this.typedFilter = ''
      this.loadingTable = true
      services
        .getCrud(this.crudEndpoint)
        .then((response) => {
          const items = response && Array.isArray(response.data) ? response.data : []
          items.forEach((item) => this.$set(this.items, item.id, item))
        })
        .catch((e) => {
          console.error(e)
          this.showError('Não foi possível carregar os dados. Tente recarregar a tabela.')
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    itemToValue(item, header) {
      return header.converter ? header.converter(item) : this.$utils.deepObjectValue(item, header.value)
    },
  },
  created() {
    this.refreshList()
  },
}
</script>
