<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div class="loading mt-4" v-if="!demand">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div v-if="demand" style="margin: 16px 0; display: flex; justify-content: space-between">
      <v-sheet class="transparent d-flex justify-center flex-column">
        <v-sheet class="transparent text-left">
          <v-btn text @click="back()" class="px-0"><v-icon>mdi-chevron-left</v-icon>Voltar</v-btn>
        </v-sheet>
      </v-sheet>
      <div class="d-flex flex-row">
        <div class="mr-2">
          <v-progress-circular
            v-if="sendingToEmail"
            indeterminate
            color="primary"
            class="mr-4"
            style="width: 20px"
          ></v-progress-circular>
          <v-btn v-else color="#FDFDFD" small style="border: 1px solid #ffee56" @click="sendToEmail()"
            ><strong>Enviar por e-mail</strong></v-btn
          >
        </div>

        <div>
          <v-progress-circular
            v-if="isPrinting"
            indeterminate
            color="primary"
            style="width: 20px"
          ></v-progress-circular>
          <v-btn v-else color="#FDFDFD" small style="border: 1px solid #ffee56" @click="printProposal()"
            ><strong>Imprimir</strong></v-btn
          >
        </div>
      </div>
    </div>

    <div id="printable-proposal" ref="printableProposal">
      <div>
        <table v-if="demand" class="print" ref="document">
          <thead v-if="!sendingToEmail">
            <tr>
              <th class="print-header">
                <h3>Proposta</h3>

                <div style="width: 150px">
                  <div v-if="!demand.realEstateAgency.proposalInfo?.logo">
                    <Logo />
                  </div>
                  <div v-else>
                    <img id="logo" :src="demand.realEstateAgency.proposalInfo?.logo.url" style="width: 150px" />
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <v-sheet class="print-content">
                  <v-sheet class="client-info">
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">Cliente:</span>
                      </v-col>
                      <v-col class="pa-0 justify-start">
                        <span class="client-info-data">{{ demand.client.name }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">Data:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">{{ $moment().format('DD/MM/YYYY') }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">Telefone:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">{{ demand.client.phone }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">E-mail:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">{{ demand.client.email }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">Endereço:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">
                          {{ `${demand.address}, ${demand.addressNumber} ${demand.addressComplement}` }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">Bairro:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">{{ demand.district }}</span>
                      </v-col>
                    </v-row>
                  </v-sheet>
                  <v-sheet class="proposal-wrapper">
                    <v-sheet
                      class="services-wrapper"
                      :class="demand.serviceCategories.length === 0 ? 'align-self-center' : 'align-self-start'"
                    >
                      <div class="section-label"><span>Diagnóstico Profissional - Serviços</span></div>
                      <v-sheet class="d-flex align-center flex-column" v-if="demand.serviceCategories.length === 0">
                        <h1 class="text-center no-services" style="opacity: 0.4; width: 100%">
                          Não foram adicionados serviços à proposta
                        </h1>
                      </v-sheet>
                      <v-sheet v-else>
                        <v-sheet
                          class="d-flex flex-column font-weight-regular body-2 mb-4"
                          v-for="serviceCategory in demand.serviceCategories"
                          :key="`service-category-${serviceCategory.id}`"
                        >
                          <div class="d-flex align-center font-weight-bold" style="gap: 12px">
                            <w-service-icon
                              :service="serviceCategory"
                              width="30px"
                              height="30px"
                              show-text
                              style="width: 120px"
                            />
                          </div>

                          <span
                            class="my-1 service-row"
                            style="margin-left: 38px"
                            v-if="demandServicesInCategory(serviceCategory).length === 0"
                            >Não foram adicionados serviços desta categoria.</span
                          >
                          <div
                            v-for="service in demandServicesInCategory(serviceCategory)"
                            :key="'service-' + service.id"
                            class="my-1"
                            style="margin-left: 30px"
                          >
                            <div class="px-2 service d-flex flex-column">
                              <v-row class="service-row" align="start">
                                <v-col cols="6">
                                  <span>{{ service.name }}</span>
                                  <v-sheet v-if="service.bundle">
                                    <div
                                      v-for="(bundleItem, index) in service.bundleItems"
                                      :key="`bundle-${service.id}-option-${bundleItem}-${index}}`"
                                      class="bundle-item"
                                    >
                                      <span>{{ bundleItem.name }}</span>
                                    </div>
                                  </v-sheet>
                                </v-col>
                                <v-col cols="2" class="d-flex flex-column align-start justify-center service-col">
                                  <w-editable-input
                                    v-model="service.quantity"
                                    :hint="`Quantidade (${service.priceUnit})`"
                                    persistent-hint
                                    type="decimal"
                                    :display-value="`${service.quantity} (${service.priceUnit})`"
                                    :editMode="false"
                                  />
                                </v-col>
                                <v-col class="d-flex align-center service-col" cols="2">
                                  <w-editable-input
                                    v-model="service.currentEstimate.price"
                                    type="currency"
                                    :display-value="$utils.formatCurrency(service.currentEstimate.price)"
                                    :editMode="false"
                                    hint="Preço"
                                    persistentHint
                                    prefix="R$"
                                  />
                                </v-col>
                                <v-col
                                  class="d-flex align-center flex-row service-col"
                                  cols="2"
                                  v-if="service.currentEstimate.workPartner"
                                >
                                  <v-col class="d-flex pa-0 align-center flex-start" cols="auto" style="padding: 0"
                                    ><img src="@/assets/account-hard-hat.png" class="worker-icon"
                                  /></v-col>
                                  <v-col class="ma-0 pa-0" cols="auto" style="padding: 0"
                                    ><span>{{ service.currentEstimate.workPartner.name }}</span></v-col
                                  >
                                </v-col>
                              </v-row>
                              <div>
                                <w-editable-input
                                  v-model="service.observation"
                                  type="textarea"
                                  :editMode="false"
                                  placeholder="Observação"
                                  outlined
                                  counter="2000"
                                  rows="2"
                                  inner-class="mt-2"
                                  class="caption text-justify grey--text text--darken-1"
                                />
                              </div>
                            </div>
                          </div>
                        </v-sheet>
                      </v-sheet>
                    </v-sheet>
                    <v-divider width="100%"></v-divider>

                    <v-sheet class="all-materials-container">
                      <div class="section-label"><span>Lista de Materiais</span></div>

                      <w-table
                        class="material-item-table"
                        v-if="demand"
                        ref="crud"
                        :disable-items-per-page="true"
                        :show-top-bar="false"
                        :show-footer="false"
                        :load-action="
                          async () => ({
                            data: Object.values(allMaterials),
                          })
                        "
                        sort-by="createdAt"
                        :sort-desc="true"
                        :headers="headers"
                      >
                        <template #categories="{ item }">
                          <div class="d-flex align-center" style="gap: 4px">
                            <v-tooltip bottom v-for="category in item.categories" :key="`${item.id}:${category}`">
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  v-bind="attrs"
                                  v-on="on"
                                  @mouseover="setServiceCategoryDescriptionToShow(category)"
                                  @mouseleave="clearServiceCategoryDescriptionToShow"
                                >
                                  <w-service-icon :service="{ id: category }" :show-text="false" :size="24" />
                                </div>
                              </template>
                              <span v-if="serviceCategoryDescriptionToShow" class="caption">{{
                                $consts.SERVICE_CATEGORIES[serviceCategoryDescriptionToShow]
                              }}</span>
                            </v-tooltip>
                          </div>
                        </template>
                      </w-table>
                    </v-sheet>

                    <v-sheet class="summary">
                      <div class="section-label"><span>Resumo</span></div>
                      <div class="summary-item">
                        <span class="summary-item-label">Serviços: </span>
                        <w-editable-input
                          v-model="demand.proposal.serviceCost"
                          type="currency"
                          prefix="R$"
                          :editMode="false"
                          outlined
                          displayClass="subtitle-1 primary--text"
                          :displayValue="$utils.formatCurrency(demand.proposal.serviceCost)"
                        />
                      </div>
                      <div class="summary-item">
                        <span class="summary-item-label">Materiais: </span>
                        <w-editable-input
                          v-model="demand.proposal.materialCost"
                          type="currency"
                          prefix="R$"
                          :editMode="false"
                          outlined
                          displayClass="subtitle-1 primary--text"
                          :displayValue="$utils.formatCurrency(demand.proposal.materialCost)"
                        />
                      </div>
                      <div class="summary-item">
                        <span class="summary-item-label">Comissão: </span>

                        <w-editable-input
                          v-model="demand.proposal.workayFee"
                          type="currency"
                          prefix="R$"
                          :editMode="false"
                          outlined
                          displayClass="subtitle-1 primary--text"
                          :displayValue="$utils.formatCurrency(demand.proposal.workayFee)"
                        />
                      </div>

                      <div class="summary-item">
                        <span class="summary-item-label">Impostos: </span>
                        <w-editable-input
                          v-model="demand.proposal.tax"
                          type="currency"
                          prefix="R$"
                          :editMode="false"
                          outlined
                          displayClass="subtitle-1 primary--text"
                          :displayValue="$utils.formatCurrency(demand.proposal.tax)"
                        />
                      </div>

                      <div class="summary-item">
                        <span class="summary-item-label">Desconto: </span>
                        <w-editable-input
                          v-model="demand.proposal.discount"
                          type="currency"
                          prefix="R$"
                          :editMode="false"
                          outlined
                          displayClass="subtitle-1 error--text"
                          :displayValue="`- ${$utils.formatCurrency(demand.proposal.discount)}`"
                        />
                      </div>
                      <v-divider width="100%"></v-divider>
                      <div class="summary-item">
                        <span class="mr-2 font-weight-bold title proposal-total">Total: </span>

                        <span class="title font-weight-bold proposal-total">{{
                          $utils.formatCurrency(demandTotalPrice)
                        }}</span>
                      </div>
                    </v-sheet>
                  </v-sheet>
                  <v-sheet class="observation-wrapper">
                    <v-sheet
                      v-for="(category, index) in $consts.OBSERVATIONS"
                      :key="`obs${index}`"
                      class="observation-category-wrapper"
                      :class="{ 'last-observation-category-wrapper': index === $consts.OBSERVATIONS.length - 1 }"
                    >
                      <div v-if="category.showOnFor" class="my-4">
                        <div class="observation-category">{{ category.title }}</div>

                        <div>
                          <span v-html="demand.realEstateAgency.proposalInfo[category.property]"></span>
                        </div>
                      </div>
                    </v-sheet>
                  </v-sheet>
                </v-sheet>
              </td>
            </tr>
          </tbody>

          <tfoot v-if="!sendingToEmail" class="footer">
            <tr>
              <td ref="printFooter">
                <v-row class="align-center">
                  <v-col cols="3" ref="logo" class="justify-center align-center" fill-height>
                    <div style="width: 150px">
                      <div v-if="!demand.realEstateAgency.proposalInfo?.logo">
                        <Logo />
                      </div>
                      <div v-else>
                        <img :src="demand.realEstateAgency.proposalInfo?.logo.url" style="width: 150px" />
                      </div>
                    </div>
                  </v-col>
                  <v-col>
                    <v-col v-if="!isPrinting" style="gap: 10px; justify-content: center">
                      <v-row style="gap: 10px" class="my-2 justify-center">
                        <a class="social-media" :href="socialMedias.facebook" target="_blank">
                          <v-icon color="white" x-large> mdi-facebook </v-icon>
                        </a>
                        <a class="social-media" :href="socialMedias.twitter" target="_blank">
                          <v-icon color="white" x-large> mdi-twitter </v-icon>
                        </a>
                        <a class="social-media" :href="socialMedias.instagram" target="_blank">
                          <v-icon color="white" x-large> mdi-instagram </v-icon>
                        </a>
                      </v-row>
                      <v-row style="justify-content: center; margin-top: 20px">
                        <p style="font-weight: bold">
                          {{ socialMedias.contact }}
                        </p></v-row
                      >
                    </v-col>
                  </v-col>

                  <v-col cols="3"> </v-col>
                </v-row>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <w-alert ref="alert" />
  </div>
</template>

<script>
import LogoImg from '@/assets/logo-workay.png'
import Logo from '@/assets/logo-workay.svg'
import WAlert from '@/components/WAlert.vue'
import util from '@/config/util'
import html2pdf from 'html2pdf.js'
import api from '../api/services'
import WEditableInput from './WEditableInput.vue'
import WServiceIcon from './WServiceIcon.vue'
import WTable from './WTable.vue'

import templateFile from '@/assets/template/email/sentProposal.txt'
import { parseTemplatePlaceholders } from '@/utils/template'

export default {
  components: { WServiceIcon, WEditableInput, Logo, WAlert, WTable },

  data() {
    return {
      showCategoryTableColumn: true,
      serviceCategoryDescriptionToShow: null,
      demand: null,
      isPrinting: false,
      sendingToEmail: false,
    }
  },
  computed: {
    headers() {
      return [
        { text: 'Produto', value: 'materialName' },
        { text: 'Descrição', value: 'description' },
        { text: 'Tamanho', value: 'size' },
        { text: 'Marca', value: 'brand' },
        {
          text: 'Preço unitário',
          value: 'price',
          converter: (item) => this.$utils.formatCurrency(item.unitaryPrice),
        },
        { text: 'Quantidade', value: 'quantity' },
        {
          text: 'Preço total',
          value: 'totalPrice',
          converter: (item) => this.$utils.formatCurrency(item.totalPrice),
        },
        {
          text: this.showCategoryTableColumn ? 'Categoria' : ' ',
          value: 'categories',
        },
      ]
    },
    socialMedias() {
      const socialMediaObject = {
        facebook: this.demand.realEstateAgency.proposalInfo?.socialMedia?.facebook,
        instagram: this.demand.realEstateAgency.proposalInfo?.socialMedia?.instagram,
        twitter: this.demand.realEstateAgency.proposalInfo?.socialMedia?.twitter,
        contact: this.demand.realEstateAgency.proposalInfo?.socialMedia?.contact,
      }

      return Object.fromEntries(
        Object.entries(socialMediaObject).map(([key, value]) => [
          key,
          key === 'contact' ? value : `//www.${key}.com/` + value,
        ]),
      )
    },
    allMaterials() {
      return this.demand.services.reduce((accTotal, service) => {
        const items = service.items.reduce((accLocal, { item, quantity }) => {
          quantity += accTotal[item.id]?.quantity ?? 0
          const categories = accTotal[item.id]?.categories ?? new Set()
          return {
            ...accLocal,
            [item.id]: {
              ...item,
              brand: item.brand.name,
              materialName: item.materialName,
              price: item.unitaryPrice,
              totalPrice: quantity * item.unitaryPrice,
              quantity,
              categories: categories.add(service.serviceCategory.id),
            },
          }
        }, {})

        return {
          ...accTotal,
          ...items,
        }
      }, {})
    },
    demandTotalPrice() {
      return (
        this.demand.proposal.serviceCost +
        this.demand.proposal.materialCost +
        this.demand.proposal.workayFee +
        this.demand.proposal.tax -
        this.demand.proposal.discount
      )
    },
  },
  async created() {
    this.demand = (await api.getDemand(parseInt(this.$route.params.demandId))).data
  },
  methods: {
    setServiceCategoryDescriptionToShow(serviceCategory) {
      this.serviceCategoryDescriptionToShow = serviceCategory
    },
    clearServiceCategoryDescriptionToShow() {
      this.serviceCategoryDescriptionToShow = null
    },
    back() {
      const hasHistory = window.history.length > 2
      hasHistory
        ? this.$router.push({
            name: 'Details',
            params: { demandId: parseInt(this.$route.params.demandId) },
          })
        : this.$router.push({ name: 'Summary' })
    },
    async sendToEmail() {
      this.sendingToEmail = true
      this.showCategoryTableColumn = false
      const alert = this.$refs.alert

      const filename = 'Proposta.pdf'

      html2pdf()
        .from(this.$refs.printableProposal)
        .set({
          filename,
          margin: [35, 0, 35, 0],
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { dpi: 192, letterRendering: false, scale: 2, useCORS: true },
          useCORS: true,
          pageBreak: {
            mode: 'css',
          },

          jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
        })
        .toPdf()
        .get('pdf')
        .then(async (pdf) => {
          try {
            const totalPages = pdf.internal.getNumberOfPages()

            let logo = LogoImg
            let imageWidth = 45
            let imageHeight = 15

            if (this.demand.realEstateAgency.proposalInfo?.logo?.url) {
              const base64Data = (await api.getBase64Image(this.demand.realEstateAgency.proposalInfo?.logo.url)).data
              logo = base64Data.base64
              const maxImageWidth = 50
              const maxImageHeight = 20

              const ratio = base64Data.width / base64Data.height
              imageWidth = maxImageWidth
              imageHeight = maxImageWidth / ratio

              if (imageHeight > maxImageHeight) {
                imageHeight = maxImageHeight
                imageWidth = maxImageHeight * ratio
              }
            }

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i)

              pdf.setTextColor('black')
              pdf.setFont(undefined, 'bold')
              pdf.setFontSize(20)

              pdf.text('Proposta', 10, 25)

              pdf.addImage(logo, 'PNG', pdf.internal.pageSize.getWidth() - 10 - imageWidth, 10, imageWidth, imageHeight)

              pdf.addImage(logo, 'PNG', 15, pdf.internal.pageSize.getHeight() - 35, imageWidth, imageHeight)

              pdf.setFontSize(8)
              pdf.setFont(undefined, 'normal')
              pdf.setTextColor('#666666')

              pdf.text(
                `contato@workay.com.br | Página ${i} de ${totalPages}`,
                pdf.internal.pageSize.getWidth() - 65,
                pdf.internal.pageSize.getHeight() - 8,
              )
            }

            const preBlob = util.dataURItoBlob(pdf.output('datauristring'))
            const file = new File([preBlob], filename, { type: 'application/pdf' })

            const url = window.URL.createObjectURL(file)
            const link = document.createElement('a')

            link.href = url
            link.download = filename

            const emailBody = await this.renderEmailBody()
            await api.sendEmail(file, this.demand.client.email, 'Proposta de Serviço de Reforma', emailBody)

            alert.showSuccess('Proposta enviada com sucesso!')

            setTimeout(() => {
              if (confirm('Deseja salvar uma cópia da proposta?', 'Enviar', 'Cancelar')) {
                link.click()
              }
            }, 1500)
          } catch (e) {
            console.error(e)
            alert.showError('Erro ao enviar email')
          } finally {
            this.categoryTableColumnName = true
            this.sendingToEmail = false
          }
        })
    },
    printProposal() {
      this.isPrinting = true
      this.$nextTick(() => {
        this.$htmlToPaper('printable-proposal', {
          styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css',
            '../print.css',
          ],
        })
        this.isPrinting = false
      })
    },
    demandHasServiceInCategory(serviceCategory) {
      return this.demandServicesInCategory(serviceCategory).length > 0
    },
    demandServicesInCategory(serviceCategory) {
      return this.demand.services.filter((demandService) => demandService.serviceCategory.id === serviceCategory.id)
    },
    async renderEmailBody() {
      const resTemplateFile = await fetch(templateFile)
      const template = await resTemplateFile.text()

      const parsedTemplate = parseTemplatePlaceholders(template, {
        'demand-client-name': this.demand.client.name,
      })

      return `<p>${parsedTemplate.trim().replaceAll('\n', '<br>')}</p>`
    },
  },
}
</script>

<style scoped>
.loading {
  text-align: center;
}

.social-media >>> i {
  color: white;
}

#printable-proposal {
  background-color: white;
}

.all-materials-container {
  width: 100%;
  padding: 5mm 0;
}

* {
  letter-spacing: 0.01px;
}
.cls-1 {
  fill: #222;
}
.cls-2 {
  fill: #27ae60;
}
.print {
  padding-top: 10mm;
  padding-left: 10mm;
  padding-right: 10mm;
  width: 100%;
  height: 100%;
}
.print-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5mm;
  font-size: 1.4em;
  font-weight: bold;
}
.services-wrapper {
  width: 100%;
}
.proposal-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}
.service:not(:last-child) {
  border-bottom: 1px dashed var(--v-primary-lighten2);
}

.service {
  padding-bottom: 4px;
}

.service-col {
  height: 100%;
  flex-direction: row !important;
}

span {
  word-break: break-word;
}

div,
ul li,
.observation-category,
.observation-category-wrapper,
.v-sheet {
  page-break-inside: avoid !important;
}

.row {
  margin: 0;
}
.summary-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5mm 10mm;
}
.summary-item-label {
  font-weight: bold;
}
.client-info {
  margin-bottom: 10mm;
}
.client-info-label {
  font-weight: bold;
  margin-right: 8px;
  padding: 0;
}

.client-info-data {
  font-weight: bold;
}
.section-label {
  font-weight: bold;
  font-size: 16pt;
  padding-bottom: 5mm;
}
.service-row {
  font-weight: bold;
  font-size: 0.875rem !important;
}
.bundle-item {
  color: #757575 !important;
  margin-left: 32px;
}
.summary {
  width: 100%;
}
.primary--text {
  color: #27ae60 !important;
}
.error--text {
  color: #ed7474 !important;
}
.observation-wrapper {
  margin-top: 10mm;
  text-align: justify;
}
.observation-category {
  font-weight: bold;
  margin-bottom: 5mm;
}
.final-image {
  width: 100%;
  margin-top: 100mm;
}
.final-image * {
  width: 100%;
}
.last-observation-category-wrapper {
  page-break-inside: avoid !important;
  margin-bottom: 0px !important;
}
.worker-icon {
  height: 24px;
  margin-right: 4px;
}
.icon-container {
  display: inline-flex;
  align-items: center;
  gap: 2px;
}

.social-media {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #60bd6b;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.proposal-total {
  font-size: 18px !important;
}
</style>
