export function parseTemplatePlaceholders(template, pairs) {
  const placeholderRegex = new RegExp(
    Object.keys(pairs)
      .map((placeholder) => `{{${placeholder}}}`)
      .join('|'),
    'g',
  )

  return template.replaceAll(placeholderRegex, (match) => {
    const placeholder = match.slice(2, -2)
    return pairs[placeholder]
  })
}
