<template>
  <w-dialog
    v-model="workPartnerDialog"
    :title="(type === 'add' ? 'Adicionar' : 'Editar') + ' Profissional'"
    :maxWidth="600"
    @input="close"
    :buttons="[
      {
        label: 'fechar',
        action: () => {
          this.close()
        },
      },
      {
        label: 'salvar',
        action: async () => {
          await this.save()
        },
        disabled: !item || item.length === 0,
      },
    ]"
  >
    <div v-if="workPartnerDialog" style="height: 100%; width: 100%">
      <w-add-associated-work-partner-form v-if="type === 'add'" @input="updateItem" />
      <w-edit-associated-work-partner-form v-if="type === 'edit'" :association="association" @input="updateItem" />
    </div>
  </w-dialog>
</template>

<script>
import services from '@/api/services'
import WAddAssociatedWorkPartnerForm from '@/components/WAddAssociatedWorkPartnerForm.vue'
import WEditAssociatedWorkPartnerForm from '@/components/WEditAssociatedWorkPartnerForm.vue'
import { mask } from 'vue-the-mask'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import WDialog from './WDialog.vue'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {
    WDialog,
    WAddAssociatedWorkPartnerForm,
    WEditAssociatedWorkPartnerForm,
  },
  directives: {
    mask,
  },

  data() {
    return {
      workPartnerDialog: false,
      validForm: false,
      item: null,
    }
  },

  props: {
    type: {
      type: String,
      required: true,
    },
    display: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: Object,
      default: () => {},
    },
    association: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    updateItem(itemObj) {
      this.item = itemObj
    },
    async save() {
      try {
        if (this.type === 'add') await services.createAssociate(this.myRealEstateAgency.id, this.item)
        else await services.updateAssociate(this.item)
        this.alert.showSuccess('Prestador de serviço associado com sucesso!')
        this.$emit('update')
        this.close()
      } catch (e) {
        console.error(e)
        this.alert.showError('Erro ao editar ou associar prestador de serviço!')
      }
    },
    close() {
      this.$emit('close', this.type)
      this.workPartnerDialog = false
    },
  },

  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
    isValidForm() {
      return this.validForm
    },
  },

  watch: {
    display() {
      this.workPartnerDialog = this.display
      this.item = null
    },
  },
}
</script>
