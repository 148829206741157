<template>
  <div>
    <w-table
      class="mb-4"
      ref="visit-crud"
      title="Visitas"
      :load-action="loadAction"
      :handle-create-click="handleCreateClick"
      :handle-edit-click="handleEditClick"
      :create-action="createAction"
      :edit-action="editAction"
      sort-by="datetime"
      :headers="headers"
      :can-create="demand.currentStatus.id === $consts.STATUS.IN_PROGRESS"
      :can-edit="
        (item) =>
          item.currentStatus.id === $consts.STATUS.PENDING && demand.currentStatus.id !== $consts.STATUS.CANCELLED
      "
      :modelGenerator="() => ({ ...newVisit })"
      @closeDialog="newVisit = {}"
      disableItemsPerPage
      @save="$emit('update:visit', $event)"
      :customActions="[
        {
          action: openCancelVisitDialog,
          text: 'Cancelar visita',
          icon: 'mdi-cancel',
          iconColor: 'error',
          key: 'cancel',
          disabled: (item) =>
            item.currentStatus.id !== $consts.STATUS.PENDING || demand.currentStatus.id === $consts.STATUS.CANCELLED,
        },
        {
          action: openVisitStatusDialog,
          text: 'Confirmar realização',
          icon: 'mdi-list-status',
          iconColor: 'success',
          key: 'confirm',
          disabled: (item) =>
            item.currentStatus.id !== $consts.STATUS.PENDING || demand.currentStatus.id === $consts.STATUS.CANCELLED,
        },
      ]"
    >
      <template #observation="{ item }">
        <span v-html="$utils.convertToHtml(item.observation, 'Sem observações')"></span>
      </template>
      <template #status="{ item }">
        <v-chip
          small
          :dark="item.currentStatus.id !== $consts.STATUS.PENDING"
          :color="item.currentStatus.hexcolor"
          class="justify-center"
          style="width: 155px"
          >{{ item.currentStatus.name }}</v-chip
        >
      </template>
    </w-table>
    <v-dialog v-model="cancelVisitDialog" max-width="600px">
      <v-card>
        <v-card-title>Motivo do cancelamento</v-card-title>
        <v-card-text>
          <v-textarea outlined v-model="cancelReason" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeCancelVisitDialog()"> Fechar </v-btn>
          <v-btn color="primary" @click="cancelVisit(currentVisit)"> Cancelar visita </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visitStatusDialog" max-width="600px">
      <v-card>
        <v-card-title>Alteração de status</v-card-title>
        <v-card-text>
          <v-select label="Status" :items="statuses" v-model="newStatus" item-text="name" item-value="id" />
          <v-textarea label="Motivo/Observação" outlined v-model="cancelReason" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeVisitStatusDialog()"> Fechar </v-btn>
          <v-btn color="primary" @click="changeVisitDoneStatus()" :disabled="!newStatus"> Alterar status </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import api from '../api/services'
import WTable from './WTable.vue'

export default {
  directives: { mask },
  components: { WTable },
  props: {
    demand: Object,
    services: Array,
    workPartners: Array,
  },
  data() {
    return {
      currentVisit: {},
      cancelReason: null,
      cancelVisitDialog: false,
      newStatus: null,
      visitStatusDialog: false,
      newVisit: {},
      headers: [
        { text: 'Serviço', value: 'service.name' },
        { text: 'Profissional', value: 'workPartner.name' },
        {
          text: 'Data',
          value: 'datetime',
          converter: (item) =>
            this.$moment(item.datetime).format('DD/MM/YYYY HH:mm') +
            (this.$moment(item.datetime).isBefore(this.$moment(), 'day') &&
            item.currentStatus.id === this.$consts.STATUS.PENDING
              ? ' ⚠️'
              : ''),
        },
        {
          text: 'Duração estimada (min)',
          value: 'estimatedDuration',
        },
        {
          text: 'Observação',
          value: 'observation',
        },
        { text: 'Status', value: 'status' },
        {
          text: 'Observação do status',
          value: 'currentStatus.observation',
          converter: (item) => {
            return item.currentStatus.observation || '-'
          },
        },
      ],
      statuses: [
        { id: this.$consts.STATUS.DONE, name: 'Realizada' },
        { id: this.$consts.STATUS.NOT_DONE, name: 'Não realizada' },
      ],
    }
  },
  methods: {
    inputValue(data, item) {
      item[data.key] = data.value
    },
    handleCreateClick() {
      this.$emit('click:newVisit')
    },
    handleEditClick(item) {
      this.$emit('click:editVisit', item)
    },
    onChangeSelectedService(visit) {
      this.$nextTick(async () => this.fillVisitWithServiceDetails(visit))
    },
    fillVisitWithServiceDetails(visit) {
      this.$set(visit, 'workPartner', visit.service.currentEstimate.workPartner)
      return visit
    },
    openCancelVisitDialog(visit) {
      this.currentVisit = visit
      this.cancelReason = null
      this.cancelVisitDialog = true
      return Promise.resolve({})
    },
    async cancelVisit(oldVisit) {
      this.$emit(
        'update:visit',
        (await api.updateVisitStatus(oldVisit.id, this.$consts.STATUS.CANCELLED, this.cancelReason)).data,
      )
      this.closeCancelVisitDialog()
    },
    refreshData() {
      this.$nextTick(() => {
        if (this.$refs['visit-crud']) {
          this.$refs['visit-crud'].refreshList()
        }
      })
    },
    closeCancelVisitDialog() {
      this.currentVisit = {}
      this.cancelReason = null
      this.cancelVisitDialog = false
    },
    async changeVisitDoneStatus() {
      this.$emit(
        'update:visit',
        (await api.updateVisitStatus(this.currentVisit.id, this.newStatus, this.cancelReason)).data,
      )
      this.closeVisitStatusDialog()
      this.$emit('update:demand')
    },
    openVisitStatusDialog(visit) {
      this.currentVisit = visit
      this.cancelReason = null
      this.visitStatusDialog = true
      return Promise.resolve({})
    },
    closeVisitStatusDialog() {
      this.currentVisit = {}
      this.cancelReason = null
      this.visitStatusDialog = false
    },
    loadAction() {
      return Promise.resolve({ data: this.demand?.visits || [] })
    },
    createAction(item) {
      return api.addVisit(this.demand.id, item).then(async (response) => {
        this.$emit('update:visit', response.data)
        return response
      })
    },
    editAction(item) {
      return api.updateVisit(item).then(async (response) => {
        this.$emit('update:visit', response.data)
        return response
      })
    },
  },
}
</script>

<style></style>
