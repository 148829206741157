<template>
  <div class="transparent d-flex flex-column menu" style="height: 100%">
    <v-sheet
      class="py-5 transparent d-flex align-center justify-center flex-row"
      style="width: 100%"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-sheet class="transparent" style="flex-basis: 150px">
        <Logo />
      </v-sheet>
    </v-sheet>

    <v-divider v-if="$vuetify.breakpoint.mdAndUp"></v-divider>

    <v-list nav dense class="d-flex flex-column justify-space-between" style="height: 100%">
      <div>
        <v-list-item active-class="active-item" link to="/summary">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-left">Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-item active-class="active-item" link to="/in-progress">
          <v-list-item-icon>
            <v-icon>mdi-pencil-ruler</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-left">Serviços em andamento</v-list-item-title>
        </v-list-item>

        <v-list-item active-class="active-item" link to="/opportunities">
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-left">Oportunidades</v-list-item-title>
        </v-list-item>

        <v-list-item active-class="active-item" link to="/archived">
          <v-list-item-icon>
            <v-icon>mdi-archive-arrow-down-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-left">Solicitações arquivadas</v-list-item-title>
        </v-list-item>

        <v-list-item active-class="active-item" link to="/chat">
          <v-list-item-icon>
            <v-icon>mdi-message-text</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-left">Chat</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isAdmin" active-class="active-item" link to="/rating">
          <v-list-item-icon>
            <v-icon>mdi-star-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-left">Avaliação dos Usuários</v-list-item-title>
        </v-list-item>

        <v-list-group :value="true" prepend-icon="mdi-format-list-bulleted" class="text-left" no-action id="cadastros">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Cadastros base</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item class="sub-item" active-class="active-item" link to="/service">
            <v-list-item-title class="text-left">Serviços</v-list-item-title>
          </v-list-item>
          <v-list-item class="sub-item" active-class="active-item" link to="/service-category">
            <v-list-item-title class="text-left">Categorias de serviço</v-list-item-title>
          </v-list-item>
          <v-list-item class="sub-item" active-class="active-item" link to="/material">
            <v-list-item-title class="text-left">Tipos de materiais</v-list-item-title>
          </v-list-item>
          <v-list-item class="sub-item" active-class="active-item" link to="/item">
            <v-list-item-title class="text-left">Lista de materiais</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isAdmin" class="sub-item" active-class="active-item" link to="/work-partner">
            <v-list-item-title class="text-left">Profissionais</v-list-item-title>
          </v-list-item>
          <v-list-item class="sub-item" active-class="active-item" link to="/associated-work-partner">
            <v-list-item-title class="text-left">Profissionais Associados</v-list-item-title>
          </v-list-item>
          <v-list-item class="sub-item" active-class="active-item" link to="/client">
            <v-list-item-title class="text-left">Clientes</v-list-item-title>
          </v-list-item>
          <v-list-item class="sub-item" active-class="active-item" link to="/my-real-estate-agency">
            <v-list-item-title class="text-left">Informações da Imobiliária</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- <v-list-item v-if="isAdmin" active-class="active-item" link to="/user">
          <v-list-item-icon>
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-left">Usuários</v-list-item-title>
        </v-list-item> -->

        <v-list-item v-if="isAdmin" active-class="active-item" link to="/real-estate-agency">
          <v-list-item-icon>
            <v-icon>mdi-office-building-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-left">Imobiliárias</v-list-item-title>
        </v-list-item>
      </div>

      <div>
        <v-list-item active-class="active-item" link @click="signOut()">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-left">Sair</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import Logo from '../assets/logo-workay.svg'

createNamespacedHelpers('user/realEstateAgency')

export default {
  name: 'Menu',
  components: { Logo },
  props: {
    msg: String,
  },
  data() {
    return {
      hasDashboardAccess: false,
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      myRealEstateAgency: 'myRealEstateAgency',
    }),
  },
  methods: {
    signOut() {
      this.$auth.signOut().then(() => {
        this.$root.signedIn = false
        this.$router.replace('login')
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sub-item {
  padding-left: 16px !important;
  margin-left: 48px !important;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#cadastros >>> .v-list-group__header.v-list-item--active {
  color: rgba(0, 0, 0, 0.87) !important;
}
.active-item {
  color: #42b983 !important;
  font-weight: bold;
}
</style>
