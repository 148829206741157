<template>
  <w-crud-table
    ref="crud"
    title="Profissionais"
    crud-endpoint="work-partner"
    sort-by="name"
    :headers="headers"
    :can-create="false"
    :can-edit="(item) => canEditItem(item)"
    :can-delete="isAdmin"
    :fill-update-form="onEdit"
    class="overflow-x-auto"
    :model-generator="() => ({ nationality: 'Brasileira' })"
  >
    <template #form="{ item }">
      <w-add-work-partner-form
        :work-partner="item"
        @input="updateItem"
        @update:estimate="() => $refs.crud.refreshList()"
      />
    </template>
    <template #indicatedBy="{ item }">
      <v-chip :color="item.indicatedBy ? 'primary' : '#F6FFFA'">{{
        item.indicatedBy ? item.indicatedBy.name : 'Sem indicação'
      }}</v-chip>
    </template>
  </w-crud-table>
</template>

<script>
import WAddWorkPartnerForm from '@/components/WAddWorkPartnerForm.vue'
import { mask } from 'vue-the-mask'
import api from '../api/services'
import WCrudTable from '../components/WCrudTable.vue'

import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { WCrudTable, WAddWorkPartnerForm },
  directives: { mask },
  methods: {
    updateItem(itemObj) {
      this.$set(itemObj.workPartner, itemObj.key, itemObj.value)
    },
    formatDate: (value) => {
      return !value ? '' : value.split('-').reverse().join('/')
    },
    onEdit(model, selectedItem) {
      const isCpf = !!selectedItem.cpf
      this.$set(model, 'cpfOrCnpj', isCpf ? selectedItem.cpf : selectedItem.cnpj)
    },
    canEditItem(item) {
      return this.isAdmin || item.realEstateAgency?.id == this.myRealEstateAgency?.id
    },
  },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
  },
  data() {
    return {
      realEstateAgencies: [],
      serviceCategories: [],
      materials: [],
      headers: [
        { text: 'Nome', value: 'name' },
        {
          text: 'Indicação',
          value: 'indicatedBy',
        },
        { text: 'CPF', value: 'cpf', converter: (item) => (item.cpf !== '0' ? item.cpf : '-') },
        { text: 'CNPJ', value: 'cnpj', converter: (item) => (item.cnpj !== '0' ? item.cnpj : '-') },
        { text: 'Celular', value: 'mobile' },
        { text: 'Telefone', value: 'phone' },
        {
          text: 'Data de nascimento',
          value: 'birthday',
          converter: (item) => this.formatDate(item.birthday),
        },
        {
          text: 'Estado civil',
          value: 'civilStatus',
          converter: (item) => this.civilStatuses.find((status) => status.value === item.civilStatus)?.text,
        },
        { text: 'Filhos', value: 'children', converter: (item) => (item.children ? '✔️' : '❌'), align: 'center' },
        { text: 'Nacionalidade', value: 'nationality' },
        { text: 'Autônomo', value: 'autonomy', converter: (item) => (item.autonomy ? '✔️' : '❌'), align: 'center' },
        {
          text: 'Plano de saúde',
          value: 'healthInsurance',
          converter: (item) => (item.healthInsurance ? '✔️' : '❌'),
          align: 'center',
        },
        {
          text: 'Escolaridade',
          value: 'educationalLevel',
          converter: (level) => this.educationalLevels.find((item) => item.value === level.educationalLevel)?.text,
        },
        { text: 'Mobilidade', value: 'transportation' },
        { text: 'Cidades', converter: (item) => item.atuationCities.map((city) => city.name).join(', ') },
        {
          text: 'Categorias de serviço',
          value: 'serviceCategories',
          sortable: false,
          converter: (item) => item.serviceCategories.map((serviceCategory) => serviceCategory.name).join(', '),
        },
      ],
      civilStatuses: [
        {
          text: 'Casado/a',
          value: 'MARRIED',
        },
        {
          text: 'Solteiro/a',
          value: 'SINGLE',
        },
        {
          text: 'Divorciado/a',
          value: 'DIVORCED',
        },
        {
          text: 'Viúvo/a',
          value: 'WIDOWED',
        },
        {
          text: 'União Estável',
          value: 'CIVIL_UNION',
        },
      ],
      educationalLevels: [
        {
          text: 'Analfabeto',
          value: 'ILLITERATE',
        },
        {
          text: 'Ensino Fundamental Incompleto',
          value: 'INCOMPLETE_ELEMENTARY_SCHOOL',
        },
        {
          text: 'Ensino Fundamental Completo',
          value: 'COMPLETE_ELEMENTARY_SCHOOL',
        },
        {
          text: 'Ensino Médio Incompleto',
          value: 'INCOMPLETE_HIGH_SCHOOL',
        },
        {
          text: 'Ensino Médio Completo',
          value: 'COMPLETE_HIGH_SCHOOL',
        },
        {
          text: 'Ensino Superior Incompleto',
          value: 'INCOMPLETE_HIGHER_EDUCATION',
        },
        {
          text: 'Ensino Superior Completo',
          value: 'COMPLETE_HIGHER_EDUCATION',
        },
        {
          text: 'Pós-Graduação Incompleto',
          value: 'INCOMPLETE_POSTGRADUATION',
        },
        {
          text: 'Pós-Graduação Completo',
          value: 'COMPLETE_POSTGRADUATION',
        },
      ],
    }
  },
  async created() {
    this.serviceCategories = (await api.getCrud('service-category')).data.filter((c) => c.active)
    this.materials = (await api.getCrud('material')).data.filter((m) => m.active)
  },
}
</script>

<style></style>
