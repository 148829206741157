<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-sheet class="d-flex flex-column wrapper">
    <h2 class="text-center">{{ demand.id ? demand.client.name : 'Nova Solicitação' }}</h2>
    <v-divider class="mt-4" />
    <v-form v-model="validDemandForm">
      <v-sheet class="d-flex flex-row pa-4" style="gap: 100px">
        <v-sheet class="d-flex flex-column flex-grow-1" style="flex-basis: 40%; gap: 20px">
          <v-sheet class="d-flex flex-column">
            <span class="font-weight-bold">Dados do Serviço</span>
            <v-sheet class="ml-2 d-flex flex-column">
              <v-text-field
                dense
                label="Título"
                v-model="demand.title"
                class="required"
                :rules="[$validationRules.required.rule]"
                counter="100"
              />
              <v-textarea
                dense
                outlined
                label="Descrição"
                v-model="demand.description"
                class="required"
                :rules="[$validationRules.required.rule]"
              />

              <v-select
                v-if="isAdmin"
                label="Imobiliária"
                v-model="demand.realEstateAgency"
                :items="realEstateAgencies"
                item-text="name"
                item-value="id"
                class="required"
                :rules="[$validationRules.required.rule]"
                return-object
              />

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="parseDate(demand.estimatedStart)"
                    label="Início do serviço"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    :rules="[$validationRules.required.rule]"
                    class="required"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="demand.estimatedStart"
                  no-title
                  @input="menu = false"
                  locale="pt-br"
                ></v-date-picker>
              </v-menu>
            </v-sheet>
          </v-sheet>
          <v-sheet class="d-flex flex-column">
            <span class="font-weight-bold">Dados do Cliente</span>
            <v-sheet class="ml-2 d-flex flex-column">
              <v-autocomplete
                ref="autocomplete"
                dense
                label="Nome do cliente"
                :items="clients"
                return-object
                :item-text="(item) => `${item.name} (${item.email})`"
                item-value="id"
                placeholder="Digite para buscar o cliente"
                class="required"
                :rules="[$validationRules.required.rule]"
                v-model="demand.client"
                @change="onChangeClient"
              >
                <template #append-item>
                  <v-list-item @click="toggleShowNewClientForm()">
                    <v-sheet class="add-client primary--text align-center pt-2" style="width: 100%">
                      <v-icon color="primary" class="mr-2">mdi-plus</v-icon>
                      <span>Adicionar novo cliente</span>
                    </v-sheet>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <v-text-field
                dense
                label="Email do cliente"
                v-model="demand.client.email"
                class="required"
                :rules="[$validationRules.required.rule, $validationRules.email.rule]"
                counter="80"
                disabled
              />

              <v-text-field
                dense
                label="Telefone do cliente"
                v-model="demand.client.phone"
                class="required"
                :rules="[$validationRules.required.rule]"
                counter="20"
                disabled
              />

              <v-checkbox label="Pessoa Jurídica" v-model="demand.client.juridicalPerson" disabled />

              <v-text-field
                dense
                label="CPF/CNPJ do cliente"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                class="required"
                v-model="demand.client.cpfCnpj"
                :rules="[$validationRules.cpfCnpj.rule, $validationRules.required.rule]"
                disabled
              />
            </v-sheet>
          </v-sheet>
        </v-sheet>
        <v-sheet class="d-flex flex-column flex-grow-1" style="flex-basis: 40%; gap: 20px" v-if="!showNewAddressForm">
          <span class="font-weight-bold">Endereços</span>
          <v-sheet class="d-flex flex-column" style="gap: 10px">
            <v-text-field
              v-model="demand.realEstateId"
              :rules="[$validationRules.required.rule]"
              style="display: none"
            />

            <v-sheet
              class="d-flex flex-column py-2 px-6 address-card"
              :class="{ selected: realEstate.id == demand.realEstateId }"
              @click="selectAddress(realEstate)"
              v-for="realEstate in demand.client.realEstates"
              :key="realEstate.id"
            >
              <v-icon v-if="realEstate.id == demand.realEstateId" class="selected-icon" color="primary" size="20px">
                mdi-check-circle</v-icon
              >
              <span class="realEstate-name">{{ realEstate.name }}</span>
              <span class="realEstate-address font-weight-bold">{{
                `${realEstate.address}, ${realEstate.addressNumber} - ${realEstate.district}`
              }}</span>
              <span class="realEstate-complement">{{ realEstate.addressComplement || '&nbsp;' }}</span>
              <span class="realEstate-zipcode">{{ realEstate.zipCode }}</span>
            </v-sheet>
          </v-sheet>
          <v-btn outlined color="primary" small class="text-none font-weight-bold py-3" width="60%" @click="newAddress"
            ><v-icon primary class="mr-2">mdi-plus</v-icon>Adicionar novo endereço</v-btn
          >
        </v-sheet>
        <v-sheet class="d-flex flex-column flex-grow-1" style="flex-basis: 40%" v-else>
          <span class="font-weight-bold">Novo Endereço</span>
          <v-sheet class="d-flex flex-column mt-1">
            <v-select
              dense
              label="Tipo de propriedade"
              v-model="demand.realEstateType"
              :items="realEstateTypes"
              item-text="name"
              item-value="id"
              return-object
            />
            <v-text-field
              dense
              label="CEP"
              v-model="demand.zipCode"
              v-mask="'#####-###'"
              class="required"
              :rules="[$validationRules.required.rule]"
              @blur="fillAddress(demand)"
            />
            <v-text-field
              dense
              label="Endereço"
              v-model="demand.address"
              counter="255"
              class="required"
              :rules="[$validationRules.required.rule]"
            />
            <v-text-field
              dense
              label="Número"
              v-model="demand.addressNumber"
              counter="20"
              class="required"
              :rules="[$validationRules.required.rule]"
            />
            <v-text-field
              dense
              label="Complemento"
              v-model="demand.addressComplement"
              :class="{ required: isAppartment(demand.realEstateType?.id) }"
              counter="40"
              :rules="[isAppartment(demand.realEstateType?.id) ? $validationRules.required.rule : () => true]"
            />
            <v-text-field
              dense
              label="Bairro"
              v-model="demand.district"
              counter="100"
              class="required"
              :rules="[$validationRules.required.rule]"
            />

            <v-select
              dense
              label="Cidade"
              v-model="demand.city"
              :items="cities"
              item-text="name"
              item-value="id"
              class="required"
              :rules="[$validationRules.required.rule]"
              return-object
              disabled
            />

            <v-select
              dense
              label="Estado"
              :value="!!demand.city ? demand.city.state : null"
              :items="states"
              item-text="name"
              item-value="id"
              class="required"
              :rules="[$validationRules.required.rule]"
              return-object
              disabled
            />

            <v-select
              dense
              label="Categoria de propriedade"
              v-model="demand.realEstateCategory"
              :items="realEstateCategories"
              item-text="name"
              item-value="id"
              return-object
            />

            <v-checkbox label="Cliente é proprietário" v-model="demand.ownership" />
          </v-sheet>
        </v-sheet>
      </v-sheet>
    </v-form>
    <v-sheet class="mt-auto d-flex flex-row" style="gap: 10px">
      <v-btn outlined color="primary" class="ml-auto text-none" @click="$emit('cancel')">Fechar</v-btn>
      <v-btn color="primary" class="text-none" :disabled="!validDemandForm" @click="saveDemand">Salvar</v-btn>
    </v-sheet>
    <w-dialog
      :title="'Novo cliente'"
      v-model="showNewClientForm"
      :buttons="[
        {
          label: 'fechar',
          action: this.toggleShowNewClientForm,
        },
        {
          label: 'salvar',
          action: this.onSaveItem,
          disabled: !validForm,
        },
      ]"
    >
      <v-form ref="form" v-model="validForm">
        <v-text-field
          label="Nome"
          v-model="client.name"
          class="required"
          :rules="[$validationRules.required.rule]"
          counter="80"
        />
        <v-text-field
          label="Telefone"
          v-model="client.phone"
          v-mask="['(##) #####-####']"
          class="required"
          :rules="[$validationRules.required.rule, $validationRules.validMobileNumber.rule]"
        />

        <v-text-field
          label="Email"
          class="required"
          v-model="client.email"
          :rules="[$validationRules.required.rule, $validationRules.email.rule]"
          counter="80"
        />

        <v-checkbox label="Pessoa Jurídica" v-model="client.juridicalPerson" @change="clearCpfOrCnpj()" />

        <v-text-field
          :label="client.juridicalPerson ? 'CNPJ' : 'CPF'"
          v-mask="client.juridicalPerson ? ['##.###.###/####-##'] : ['###.###.###-##']"
          v-model="client.cpfCnpj"
          class="required"
          :rules="[
            client.juridicalPerson ? $validationRules.cnpj.rule : $validationRules.cpf.rule,
            $validationRules.required.rule,
          ]"
        />
      </v-form>
    </w-dialog>
    <w-alert ref="alert" />
  </v-sheet>
</template>

<script>
import services from '@/api/services'
import { clientModel } from '@/models/ClientModel'

import { mask } from 'vue-the-mask'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import WAlert from './WAlert.vue'
import WDialog from './WDialog.vue'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { WAlert, WDialog },
  directives: { mask },
  data() {
    return {
      realEstateAgencies: [],
      validDemandForm: false,
      demand: {
        client: clientModel,
        realEstateId: null,
        estimatedStart: this.$moment().format('YYYY-MM-DD'),
        title: null,
        description: null,
        realEstateAgency: null,
        zipCode: null,
        address: null,
        addressNumber: null,
        addressComplement: null,
        district: null,
        city: null,
        realEstateType: null,
        realEstateCategory: null,
        ownership: null,
      },
      menu: false,
      validForm: false,
      clients: [],
      client: {
        cpfCnpj: null,
      },
      showNewClientForm: false,
      showNewAddressForm: false,
      searchClient: false,
      searchAddress: true,
      realEstateCategories: [{ id: 1, name: 'Residencial' }],
      realEstateTypes: [{ id: 2, name: 'Apartamento' }],
      cities: [],
      states: [],
    }
  },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
  },
  methods: {
    clearCpfOrCnpj() {
      this.$nextTick(() => {
        this.client.cpfCnpj = ''
      })
    },
    parseDate(date) {
      return this.$moment(date).format('DD/MM/YYYY')
    },
    showSuccess(message = 'Operação realizada com sucesso!') {
      this.$refs.alert.showSuccess(message)
    },
    showError(error) {
      const message =
        error.response?.data?.message || error.message || (typeof error === 'string' ? error : 'Ocorreu um erro')
      this.$refs.alert.showError(message)
    },

    saveClient(item) {
      const crudMethod = services.createCrud

      crudMethod('client', item)
        .then(async (response) => {
          const returnedItem = response.data

          this.toggleShowNewClientForm()
          this.$emit('save:client', returnedItem)
          this.clients = (await services.listClients()).data

          this.demand.client = this.clients.find((c) => c.id === returnedItem.id)
          this.showSuccess()
        })
        .catch(this.showError)
    },

    onSaveItem() {
      let item = this.client

      if (!this.$refs.form.validate()) {
        return
      }
      item = this.$utils.copy(item)
      for (let key in item) {
        const value = item[key]
        if (typeof value === 'string' && value.trim() === '') {
          item[key] = null
        }
      }

      this.saveClient(item)
    },

    toggleShowNewClientForm() {
      this.showNewClientForm = !this.showNewClientForm
    },

    newAddress() {
      this.showNewAddressForm = true
      // eslint-disable-next-line vue/no-mutating-props
      this.demand.realEstateId = null
    },

    selectAddress(address) {
      // eslint-disable-next-line vue/no-mutating-props
      this.demand.realEstateId = address.id
    },
    onChangeClient() {
      // eslint-disable-next-line vue/no-mutating-props
      this.demand.realEstateId = null
      this.showNewAddressForm = false
    },
    fillAddress(item) {
      if (!item.zipCode) {
        return
      }
      this.$viaCep.buscarCep(item.zipCode.replace(/[^0-9]/g, '')).then((data) => {
        this.$set(item, 'district', data.bairro)
        this.$set(item, 'address', data.logradouro)
        this.$set(item, 'addressComplement', data.complemento)
        let city = this.cities.find((c) => `${c.ibgeCode}` === data.ibge)
        this.$set(item, 'city', city)
        this.$set(item, 'state', city.state)
      })
    },
    isAppartment(realEstateTypeId) {
      // TODO: Trazer do backend se é um apartamento ou não. Criar uma flag para isso
      const realEstateAppartmentIds = [1, 2, 3, 8, 10, 11, 14, 17, 18]

      return realEstateAppartmentIds.includes(realEstateTypeId)
    },
    async fillDemandAddress(zipCode) {
      if (!zipCode) {
        return
      }

      const addressData = await this.$viaCep.buscarCep(zipCode.replace(/[^0-9]/g, ''))

      this.demand.address = addressData.logradouro
      this.demand.district = addressData.bairro
      this.demand.city = this.cities.find((city) => city.ibgeCode.toString() === addressData.ibge) ?? null
      if (!this.isAppartment(this.demand.realEstateId)) {
        this.demand.addressComplement = addressData.complemento
      }
    },
    async saveDemand() {
      const savedDemand = (await services.createDemand(this.demand)).data

      this.$emit('save')
      this.$router.push({
        name: 'Details',
        params: { demandId: savedDemand.id },
      })
    },
  },
  async created() {
    if (!this.isAdmin) {
      this.demand.realEstateAgency = this.myRealEstateAgency
    }

    const [resRealEstateTypes, resRealEstateCategories, resCities, resStates, resClients] = await Promise.all([
      services.listRealEstateTypes(),
      services.listRealEstateCategories(),
      services.listCities(),
      services.listStates(),
      services.listClients(),
    ])

    this.realEstateTypes = resRealEstateTypes.data
    this.realEstateCategories = resRealEstateCategories.data
    this.cities = resCities.data
    this.states = resStates.data
    this.clients = resClients.data
  },
  async mounted() {
    this.demandCopy = this.demand
    this.realEstateAgencies = (await services.getRealEstateAgencies()).data
  },
}
</script>

<style scoped>
.add-client {
  border-top: 1px dashed var(--v-primary-base);
}
.address-card {
  border: 2px solid #6d6e71;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}
.selected {
  border-color: var(--v-primary-base) !important;
}
.selected-icon {
  position: absolute;
  right: 12px;
}
.wrapper >>> .v-text-field.v-input--dense {
  padding-top: 12px;
}
</style>
