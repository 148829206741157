var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-sheet',{staticClass:"wrapper pa-4"},[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('h3',[_vm._v("Informações da Imobiliária")]),_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.loadRealEstateAgency()}}},[_vm._v("mdi-reload")])],1),_c('div',{staticClass:"d-flex justify-center"},[(_vm.loadingData)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),(!_vm.loadingData)?_vm._l((_vm.proposalInfoItems),function(infoItem){return _c('proposal-info-item',{key:infoItem.title,attrs:{"item":infoItem,"type":infoItem.showType},on:{"editItem":(item) => _vm.editItem(item)}})}):_vm._e()],2),(_vm.itemToEdit)?_c('w-dialog',{attrs:{"title":_vm.itemToEdit.title,"max-width":600,"buttons":[
      {
        label: 'fechar',
        action: _vm.stopEditItem,
      },
      {
        label: 'salvar',
        action: _vm.save,
        disabled: !_vm.itemToEdit.content,
      },
    ]},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('p',{staticClass:"title"},[_vm._v("O que é")]),_c('p',[_vm._v(_vm._s(_vm.itemToEdit.description))]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.itemToEdit.actionDescription))]),(_vm.itemToEdit.type === _vm.$consts.REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.HTML_EDITOR)?_c('w-real-estate-agency-html-editor-modal',{attrs:{"databaseColumn":"proposalInfo"},model:{value:(_vm.itemToEdit),callback:function ($$v) {_vm.itemToEdit=$$v},expression:"itemToEdit"}}):_vm._e(),(_vm.itemToEdit.type === _vm.$consts.REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.IMAGE)?_c('w-real-estate-agency-image-modal',{model:{value:(_vm.itemToEdit.content),callback:function ($$v) {_vm.$set(_vm.itemToEdit, "content", $$v)},expression:"itemToEdit.content"}}):_vm._e(),(_vm.itemToEdit.type === _vm.$consts.REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.SOCIAL_MEDIA)?_c('w-real-estate-agency-social-media-modal',{model:{value:(_vm.itemToEdit.content),callback:function ($$v) {_vm.$set(_vm.itemToEdit, "content", $$v)},expression:"itemToEdit.content"}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }