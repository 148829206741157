<template>
  <div :style="cssVars" class="parent">
    <v-data-table
      class="crud-table"
      :headers="computedHeaders"
      :items="filteredItems"
      :items-per-page="itemsPerPage"
      :no-data-text="typedFilter ? 'O filtro digitado não retornou resultados' : noDataText"
      :loading="loadingTable"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :item-key="itemIdKey"
      :show-expand="showExpand"
      :expanded.sync="expandedItems"
      multi-sort
      loading-text="Carregando informações..."
      :footer-props="showFooter ? footerProps : null"
      :hide-default-footer="!showFooter"
      :dense="dense"
      @click:row="$emit('click:row')"
      :item-class="itemClass"
      :header-props="headerProps"
    >
      <template slot="top" v-if="showTopBar">
        <v-card
          class="d-flex justify-space-between align-center px-4 py-2 flex-wrap"
          :disabled="loadingTable"
          elevation="0"
        >
          <h3 class="title font-weight-bold">{{ title }}</h3>
          <v-text-field
            v-if="canFilter"
            v-model="typedFilter"
            placeholder="Digite para filtrar os resultados"
            class="mx-8 flex-grow-1"
            hide-details
            clearable
            :style="$vuetify.breakpoint.smAndDown ? 'order:3' : ''"
          ></v-text-field>

          <section class="d-flex align-center">
            <v-btn icon class="mx-2" color="primary" @click="refreshList" title="Atualizar tabela">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              color="primary"
              tile
              elevation="0"
              title="Criar novo item"
              :disabled="!canCreate"
              @click="handleCreateClick ? handleCreateClick() : onCreateItem()"
              v-if="!!createAction"
              >novo item<v-icon>mdi-plus</v-icon></v-btn
            >
          </section>

          <w-dialog
            :title="editMode ? 'Editar' : 'Novo item'"
            v-model="showFormDialog"
            :loading="loadingDialog"
            :max-width="maxDialogWidth"
            :buttons="[
              {
                label: 'fechar',
                action: closeFormDialog,
              },
              {
                label: 'salvar',
                action: () => onSaveItem(model),
                disabled: !validForm,
              },
            ]"
            @input="onDialogInput"
          >
            <v-form v-model="validForm" v-if="showFormDialog" ref="form">
              <slot name="form" v-bind="{ item: model, editMode }"></slot>
            </v-form>
          </w-dialog>
        </v-card>
      </template>

      <template v-for="header in dataHeaders" #[`item.${header.value}`]="props">
        <slot :name="header.value" v-bind="props">
          {{ itemToValue(props.item, header) }}
        </slot>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-card class="d-flex justify-space-around" elevation="0" :disabled="loadingTable" color="transparent">
          <v-btn
            small
            icon
            @click="handleEditClick ? handleEditClick(item) : onEditItem(item)"
            title="Editar"
            :disabled="typeof canEdit === 'function' ? !canEdit(item) : !canEdit"
            v-if="!!editAction"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            small
            icon
            v-for="action in customActions"
            :key="action.key"
            :disabled="typeof action.disabled === 'function' ? action.disabled(item) : !!action.disabled"
            :title="action.text"
            @click="onCustomAction(action.action, item, action.successMessage)"
          >
            <v-icon :color="action.iconColor">{{ action.icon }}</v-icon>
          </v-btn>
        </v-card>
      </template>

      <template #expanded-item="props">
        <slot name="expanded-item" v-bind="props"> </slot>
      </template>
    </v-data-table>
    <w-alert ref="alert" />
  </div>
</template>

<script>
import util from '@/config/util'
import WAlert from './WAlert.vue'
import WDialog from './WDialog.vue'

const defaultFilterFunction = (value, search) =>
  util.toComparableStringLowerCase(value).includes(util.toComparableStringLowerCase(search))

export default {
  components: { WDialog, WAlert },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    itemIdKey: {
      type: String,
      default: 'id',
    },
    itemActiveKey: {
      type: String,
      default: 'active',
    },
    fillUpdateForm: {
      type: Function,
      default: (formModel, selectedItem) => undefined,
    },
    beforeSend: {
      type: Function,
      default: (model, key) => undefined,
    },
    noDataText: {
      type: String,
      default: 'Não há dados',
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    title: String,
    dialogMaxWidth: [String, Number],
    handleCreateClick: {
      type: Function,
    },
    handleEditClick: {
      type: Function,
    },
    customActions: {
      type: Array,
      default: () => [],
    },
    customRefresh: {
      type: Function,
    },
    canEdit: [Function, Boolean],
    canCreate: Boolean,
    canFilter: {
      type: Boolean,
      default: true,
    },
    sortBy: [String, Array],
    maxDialogWidth: [Number, String],
    modelGenerator: {
      type: Function,
      default: () => ({}),
    },
    filterFunction: {
      type: Function,
      default: defaultFilterFunction,
    },
    loadAction: {
      type: Function,
      required: true,
    },
    createAction: {
      type: Function,
    },
    editAction: {
      type: Function,
    },
    disableItemsPerPage: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    itemClass: [Function, String],
    headerProps: {
      type: Object,
      default: () => ({
        sortByText: 'Ordenar por',
      }),
    },
    showTopBar: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cssVars() {
      return {
        '--pagination-margin-left': this.disableItemsPerPage ? 'auto' : '24px',
      }
    },
    filteredItems() {
      const items = this.allItems
      if (!this.typedFilter || !this.typedFilter.trim()) {
        return items
      }

      const cleanTypedFilter = this.typedFilter.trim().toUpperCase()

      return items.filter((item) =>
        this.filterableHeaders.some((header) => {
          const filter = header.customFilter || this.filterFunction
          const value = this.itemToValue(item, header)
          return filter(value, cleanTypedFilter, item)
        }),
      )
    },
    allItems() {
      return Object.values(this.items)
    },
    computedHeaders() {
      return [
        ...(this.customActions.length > 0
          ? [
              {
                value: 'actions',
                width: 64 + this.customActions.length * 48,
                sortable: false,
              },
            ]
          : []),
        ...this.headers,
      ]
    },
    dataHeaders() {
      return this.headers.filter((header) => !!header.text)
    },
    filterableHeaders() {
      return this.dataHeaders.filter((header) => header.filterable !== false)
    },
  },
  data() {
    return {
      expandedItems: [],
      loadingTable: false,
      loadingDialog: false,
      showFormDialog: false,
      validForm: false,
      editMode: false,
      typedFilter: '',
      model: this.modelGenerator() || {},
      items: [],
      footerProps: {
        'page-text': '{0}-{1} de {2}',
        'disable-items-per-page': this.disableItemsPerPage,
        'items-per-page-text': this.disableItemsPerPage ? '' : 'Itens por página',
        'items-per-page-all-text': this.disableItemsPerPage ? '' : 'Todos',
        'items-per-page-options': this.disableItemsPerPage ? [] : [10, 15, 20, 30, 50, 100],
      },
    }
  },
  methods: {
    onEditItem(item) {
      this.openFormDialog(true)

      if (this.fillUpdateForm) {
        this.fillUpdateForm(this.model, this.$utils.copy(item))
      }

      this.model = this.$utils.copy(item)
      this.$nextTick(() => {
        this.$refs.form.validate()
      })

      this.$emit('click:edit', item)
    },
    onCreateItem() {
      this.model = this.modelGenerator()
      this.openFormDialog(false)
      this.$nextTick(() => {
        this.$refs.form.resetValidation()
      })
    },
    onSaveItem(item) {
      if (!this.$refs.form.validate()) {
        return
      }
      item = this.$utils.copy(item)
      for (let key in item) {
        const value = item[key]
        if (typeof value === 'string' && value.trim() === '') {
          item[key] = null
        }
      }
      this.loadingDialog = true
      this.beforeSend(item, null)

      this.saveItem(item)
    },
    onCustomAction(action, item, successMessage) {
      this.loadingTable = true
      action(item)
        .then(() => {
          if (!!successMessage)
            this.showSuccess(typeof successMessage === 'function' ? successMessage(item) : successMessage)
        })
        .catch(this.showError)
        .finally(() => {
          this.loadingTable = false
        })
    },
    showSuccess(message = 'Operação realizada com sucesso!') {
      this.$refs.alert.showSuccess(message)
    },
    showError(error) {
      const message =
        error.response?.data?.message || error.message || (typeof error === 'string' ? error : 'Ocorreu um erro')
      this.$refs.alert.showError(message)
    },
    saveItem(item) {
      let action = this.editMode ? this.editAction : this.createAction
      let event = this.editMode ? 'edit' : 'create'
      return action(item)
        .then((response) => {
          const returnedItem = response.data
          this.$set(this.items, returnedItem[this.itemIdKey], returnedItem)
          this.closeFormDialog()
          this.$emit(event, returnedItem)
          this.$emit('save', returnedItem)
          this.showSuccess()
        })
        .catch(this.showError)
        .finally(() => {
          this.loadingDialog = false
        })
    },
    openFormDialog(editMode) {
      this.editMode = editMode
      this.showFormDialog = true
    },
    onDialogInput(open) {
      if (open) {
        return
      }
      this.closeFormDialog()
    },
    closeFormDialog() {
      this.$emit('closeDialog')
      this.$refs.form.reset()
      this.showFormDialog = false
      this.stopLoadingDialog()
    },
    stopLoadingDialog() {
      this.loadingDialog = false
    },
    refreshList() {
      this.items = []
      this.typedFilter = ''
      this.loadingTable = true
      if (this.customRefresh) this.customRefresh()

      this.loadAction()
        .then((response) => {
          const items = response && Array.isArray(response.data) ? response.data : []
          items.forEach((item) => this.$set(this.items, item.id, item))
        })
        .catch((e) => {
          console.error(e)
          this.showError('Não foi possível carregar os dados. Tente recarregar a tabela.')
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    itemToValue(item, header) {
      return header.converter ? header.converter(item) : this.$utils.deepObjectValue(item, header.value)
    },
  },
  created() {
    this.refreshList()
  },
  watch: {
    expanded: {
      handler(newValue, oldValue) {
        this.$nextTick(() => {
          this.expandedItems = this.$utils.copy(newValue)
        })
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style scoped>
:root {
  --pagination-margin-left: 0;
}
.parent >>> .v-data-footer__pagination {
  margin-left: var(--pagination-margin-left);
}
.parent >>> .v-chip__close {
  display: none;
}
</style>
