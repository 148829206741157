<template>
  <div
    id="user-card-wrapper"
    class="py-3 px-4 d-flex"
    :class="isSelected ? 'is-selected' : ''"
    @click="$emit('currentChat', otherId)"
  >
    <div id="photo" class="d-flex" :style="{ backgroundColor: !photo?.url ? 'gray' : 'white' }">
      <img v-if="photo" :src="photo.url" />
      <v-icon v-else :size="36" color="white" class="ma-auto">mdi-account</v-icon>
    </div>

    <div id="body">
      <div id="header" class="d-flex justify-space-between">
        <span id="other-name">{{ name }}</span>
        <span v-if="isChat" id="message-date">
          {{ messageDate }}
        </span>
      </div>

      <template v-if="isChat">
        <span v-if="chat.lastMessage?.type === $consts.CHAT_MESSAGE_TYPE.FILE"></span>
        <span id="last-message">{{ lastMessage }}</span>
      </template>

      <span v-if="!isChat" id="phone">{{ phone }}</span>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {},

  methods: {},
  props: {
    type: {
      type: String,
      required: true,
    },
    chat: {
      type: Object,
    },
    contact: {
      type: Object,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    otherId() {
      return this.type === 'chat' ? this.chat.other.id : this.contact.id
    },
    isChat() {
      return this.type === 'chat'
    },
    photo() {
      return this.type === 'chat' ? this.chat.other.photo : this.contact.photo
    },
    name() {
      return this.type === 'chat' ? this.chat.other.name : this.contact.name
    },
    phone() {
      return this.type === 'contact' ? this.contact.workPartnerDetails.phone : null
    },

    lastMessage() {
      if (!this.chat) return null
      const prefixMap = {
        ME: 'Você',
        OTHER: this.chat.other.name,
      }

      return `${prefixMap[this.chat.lastMessage.origin]}: ${this.chat.lastMessage.text}`
    },

    messageDate() {
      if (!this.chat) return null
      const messageSentAt = this.$moment.utc(this.chat.lastMessage.sentAt).local()
      const now = this.$moment()

      if (messageSentAt.isSame(now, 'day')) {
        return messageSentAt.format('HH:mm')
      }

      if (messageSentAt.isSame(now.subtract(1, 'days'), 'day')) {
        return 'Ontem'
      }

      return messageSentAt.format('DD/MM/YYYY')
    },
  },

  methods: {},
  data() {
    return {}
  },
}
</script>
<style scoped>
#user-card-wrapper {
  gap: 12px;
  container-type: inline-size;
}

@container (max-width: 265px) {
  #last-message {
    display: none;
  }

  #body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

#user-card-wrapper:hover {
  background-color: #f6fffa;
  cursor: pointer;
}

.is-selected {
  background-color: #f6fffa;
}

.is-selected #message-date {
  color: var(--v-primary-base);
}

#body {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

#photo,
img {
  border-radius: 50%;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  object-fit: contain;
}

#other-name {
  font-weight: 700;
  color: #6d6e71;
}

#last-message {
  color: #6d6e71;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

#message-date,
#phone {
  color: #6d6e71;
  font-size: 14px;
}
</style>
