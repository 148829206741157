<template>
  <div style="height: 100%; width: 100%">
    <Bar :options="chartOptions" :data="display" :plugins="[chartDataLabels]" />
  </div>
</template>

<script>
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  components: { Bar },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartDataLabels: ChartDataLabels,
      display: null,
      serviceCategories: [],

      chartOptions: {
        indexAxis: 'y',
        barThickness: 30,
        maintainAspectRatio: false,

        layout: {
          padding: {
            right: 40,
          },
        },

        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'right',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
            },
            backgroundColor: '#e4f9e8',
            padding: 10,
            borderRadius: 5,
            display: function (context) {
              return context.dataset.data[context.dataIndex] > 0
            },
          },
        },

        scales: {
          x: {
            ticks: {
              stepSize: 1,
            },
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: false,
            },
          },
        },
      },
    }
  },
  async created() {
    this.display = {
      labels: this.chartData.map((item) => item.label),
      datasets: [
        {
          backgroundColor: '#27AE60',
          data: this.chartData.map((item) => {
            return item.value
          }),
        },
      ],
    }
  },
}
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  div {
    width: 100% !important;
    height: 500px !important;
  }
}
</style>
