<template>
  <v-card class="mt-4 pb-4 px-6">
    <v-card-title class="pb-1 px-0 align-center">
      <v-sheet class="d-flex flex-row mb-2 flex-grow-1">
        <v-sheet class="d-flex align-center" style="gap: 16px">
          <h4 class="font-weight-normal title">Proposta</h4>

          <v-btn
            v-if="demand.currentStatus.id === $consts.STATUS.PENDING && !editMode"
            @click="toggleEditMode()"
            color="primary"
            small
            dark
            depressed
            class="text-capitalize"
            >Editar Proposta</v-btn
          >

          <v-btn
            small
            @click="closeDeal"
            class="primary"
            v-if="demand.readyForStart && demand.currentStatus.id === $consts.STATUS.PENDING"
            >Fechar Proposta</v-btn
          >

          <div
            v-if="editMode && demand.services.length === 0"
            class="d-flex justify-space-between align-center"
            style="gap: 16px"
          >
            <v-btn @click="openAddServicesDialog()" color="primary" small v-if="editMode"
              >Adicionar novo serviço<v-icon class="ml-2" small>mdi-plus</v-icon></v-btn
            >
            <v-btn
              v-if="demand.currentStatus.id === $consts.STATUS.PENDING && editMode"
              @click="toggleEditMode()"
              color="primary"
              small
              dark
              >Finalizar edição</v-btn
            >
          </div>
        </v-sheet>

        <v-btn
          v-if="!editMode"
          @click="openProposalOrReport()"
          outlined
          color="primary"
          class="ml-auto text-capitalize"
          small
          >{{
            [this.$consts.STATUS.IN_PROGRESS, this.$consts.STATUS.DONE].includes(this.demand.currentStatus.id)
              ? 'Visualizar Relatório'
              : 'Visualizar Proposta'
          }}</v-btn
        >
      </v-sheet>
    </v-card-title>

    <v-sheet class="d-flex flex-row align-start flex-wrap flex-end">
      <v-sheet
        class="flex-grow-1"
        :class="demandCopy.serviceCategories.length === 0 ? 'align-self-center' : 'align-self-start'"
      >
        <v-sheet class="fill-height d-flex align-center flex-column" v-if="demandCopy.serviceCategories.length === 0">
          <h1 class="text-center" style="opacity: 0.4; width: 100%">Não foram adicionados serviços à proposta</h1>
        </v-sheet>

        <v-sheet v-else>
          <v-sheet
            class="d-flex flex-column font-weight-regular body-2 mb-4"
            v-for="serviceCategory in demandCopy.serviceCategories"
            :key="`service-category-${serviceCategory.id}`"
            style="gap: 16px"
          >
            <div class="d-flex align-center" style="gap: 12px">
              <w-service-icon :service="serviceCategory" width="30px" height="30px" show-text style="width: 120px" />
              <v-btn
                v-if="editMode && !demandHasServiceInCategory(serviceCategory)"
                small
                text
                plain
                color="error"
                class="caption text-none text-decoration-underline font-weight-medium"
                @click="removeServiceCategory(serviceCategory)"
              >
                Remover
              </v-btn>
            </div>

            <span
              class="my-1 font-weight-medium body-2"
              style="margin-left: 38px"
              v-if="demandServicesInCategory(serviceCategory).length === 0"
              >Não foram adicionados serviços desta categoria.</span
            >
            <div
              v-for="service in demandServicesInCategory(serviceCategory)"
              :key="'service-' + service.id"
              class="my-1"
              style="margin-left: 30px"
            >
              <div
                class="pa-4 service d-flex flex-column"
                :class="{ 'edited-service': editMode }"
                :style="!editMode ? 'padding: 0px !important' : ''"
              >
                <div class="d-flex" style="width: 100%">
                  <div class="d-flex justify-space-between align-center" style="width: 100%">
                    <div class="d-flex flex-column justify-center">
                      <div class="d-flex mb-2 align-center">
                        <span class="mr-4 service-name">{{ service.name }}</span>
                        <div class="d-flex" style="gap: 8px">
                          <div class="d-flex flex-column justify-end">
                            <w-editable-input
                              :value="service.quantity"
                              @blur="service.quantity = $event.target.value.replaceAll('.', '')"
                              :hint="`Quantidade ${service.priceUnit ? `(${service.priceUnit})` : ''}`"
                              persistent-hint
                              integer-value
                              type="decimal"
                              :display-value="`${service.quantity} ${
                                service.priceUnit ? '(' + service.priceUnit + ')' : ''
                              }`"
                              :editMode="canEditService(service)"
                            />
                          </div>
                          <div class="d-flex flex-column align-start">
                            <v-btn
                              v-if="editMode"
                              text
                              plain
                              x-small
                              color="secondary"
                              class="caption text-none text-decoration-underline font-weight-medium pa-0"
                              @click="resetServiceValue(service)"
                              :disabled="savingDemand"
                              >Usar preço tabelado</v-btn
                            >
                            <w-editable-input
                              :value="service.currentEstimate.price"
                              type="integer"
                              integer-value
                              @blur="service.currentEstimate.price = $event.target.value.replaceAll('.', '')"
                              :display-value="$utils.formatCurrency(service.currentEstimate.price)"
                              :editMode="canEditService(service)"
                              hint="Preço Total"
                              persistentHint
                              prefix="R$"
                            />
                          </div>
                        </div>
                      </div>
                      <w-editable-input
                        v-if="service.observation || editMode"
                        :value="service.observation"
                        type="textarea"
                        :editMode="canEditService(service)"
                        @blur="service.observation = $event.target.value"
                        placeholder="Observação"
                        outlined
                        counter="2000"
                        rows="2"
                        inner-class="mt-2"
                        class="mt-2 caption text-justify grey--text text--darken-1"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <div v-if="!editMode || !canEditService(service)" class="font-weight-medium body-2" align="start">
                        <div
                          class="d-flex"
                          :class="{ 'justify-end': $vuetify.breakpoint.mdAndUp }"
                          style="gap: 10px"
                          :cols="$vuetify.breakpoint.smAndDown ? 12 : 3"
                        >
                          <div
                            :class="[
                              'd-flex flex-column service-status-icon',
                              { canClick: isWorkPartnerUnassigned(getWorkPartnerText(service, true)) },
                            ]"
                            :title="getWorkPartnerText(service, true)"
                            @click.prevent="showWorkPartnerDialog(service)"
                          >
                            <v-dialog :retain-focus="false" v-model="workPartnerDialog" max-width="600px">
                              <v-card>
                                <v-card-title>Designar profissional</v-card-title>
                                <v-card-text>
                                  <v-autocomplete
                                    label="Profissional"
                                    v-model="localWorkPartner"
                                    :items="availableWorkPartners"
                                    item-text="name"
                                    item-value="id"
                                    class="required"
                                    return-object
                                    :rules="[$validationRules.required.rule]"
                                  />
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer />
                                  <v-btn color="primary" text @click="closeDesignateWorkPartnerDialog()">
                                    Fechar
                                  </v-btn>
                                  <v-btn color="primary" @click="openAddWorkPartnerDialog()">Adicionar novo </v-btn>
                                  <v-btn
                                    color="primary"
                                    :disabled="!localWorkPartner"
                                    @click="designateWorkPartner(currentEstimate, localWorkPartner)"
                                  >
                                    Salvar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>

                            <v-icon :color="getWorkPartnerColor(service)">mdi-account-hard-hat</v-icon>
                            <span
                              :class="`caption font-weight-bold ${getWorkPartnerColor(service)}--text text-center`"
                              :style="{ color: `${getWorkPartnerColor(service)}` }"
                              style="line-height: 16px"
                              >{{ getWorkPartnerText(service) }}</span
                            >
                          </div>

                          <div v-if="service.finished" class="d-flex flex-column service-status-icon" title="Concluído">
                            <v-icon color="primary">mdi-calendar-check</v-icon>
                            <span class="caption font-weight-bold primary--text text-center" style="line-height: 16px">
                              Concluído
                            </span>
                          </div>
                          <div
                            v-else-if="getNextServiceVisit(service.id)"
                            class="d-flex flex-column service-status-icon"
                            :title="
                              'Próxima visita: ' +
                              $moment(getNextServiceVisit(service.id).datetime).format('DD/MM/YYYY HH:mm')
                            "
                          >
                            <v-icon color="#FCBF13">mdi-calendar-clock</v-icon>
                            <span
                              class="caption font-weight-bold text-center"
                              style="line-height: 16px; color: #fcbf13"
                            >
                              {{ $moment(getNextServiceVisit(service.id).datetime).format('DD/MM/YYYY HH:mm') }}
                            </span>
                          </div>
                          <div
                            v-else-if="hasPreviousServiceVisit(service.id)"
                            :class="[
                              'd-flex flex-column service-status-icon',
                              demand.currentStatus.id === $consts.STATUS.IN_PROGRESS ? 'canClick' : 'cannotClick',
                            ]"
                            @click="openVisitDialog(service)"
                            title="Agendar próxima visita"
                          >
                            <v-icon color="#FCBF13">mdi-calendar-clock</v-icon>
                            <span class="caption font-weight-bold text-center" style="line-height: 16px; color: #fcbf13"
                              >Agendar próxima visita</span
                            >
                          </div>
                          <div
                            v-else
                            :class="[
                              'd-flex flex-column service-status-icon',
                              demand.currentStatus.id === $consts.STATUS.IN_PROGRESS ? 'canClick' : 'cannotClick',
                            ]"
                            @click="openVisitDialog(service)"
                            title="Sem visita agendada"
                          >
                            <v-icon color="error">mdi-calendar-remove</v-icon>
                            <span class="caption font-weight-bold error--text text-center" style="line-height: 16px"
                              >Sem visita agendada</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-end pr-4" style="gap: 12px" v-if="canEditService(service)">
                    <v-btn v-if="service.bundle" outlined small color="primary" @click="openBundleDialog(service)"
                      >Editar itens do pacote</v-btn
                    >

                    <v-icon style="align-self: flex-start" @click="removeService(service)" color="error" size="28px">
                      mdi-delete</v-icon
                    >
                  </div>

                  <div v-if="service.bundle" class="px-8">
                    <div
                      v-for="(bundleItem, index) in service.bundleItems"
                      :key="`bundle-${service.id}-option-${bundleItem}-${index}}`"
                    >
                      <span class="grey--text text--darken-1">{{ bundleItem.name }}</span>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap my-6 align-center" style="max-width: 90%; gap: 16px">
                  <v-chip
                    v-for="(task, i) in service.tasks.filter((task) => task.active)"
                    :class="[
                      'task-item',
                      task.task.custom ? 'custom' : ' default',
                      task.included ? 'included' : 'not-included',
                      editMode && !task.task.custom ? 'clickable' : 'not-clickable',
                    ]"
                    :key="`task_${i}`"
                    :close="task.task.custom && editMode"
                    outlined
                    label
                    close-icon="mdi-delete"
                    @click="toggleTaskIncluded(editMode && !task.task.custom, service, task)"
                    @click:close="onDeleteTask(service, task)"
                    >{{ task.task.name }}</v-chip
                  >
                  <v-btn v-if="editMode" color="primary" small class="text-h6" @click="toggleShow(service.id)">
                    +
                  </v-btn>
                </div>

                <v-divider v-if="editMode" />

                <w-suggested-demand-materials
                  v-if="editMode && service"
                  :service-id="service.id"
                  :demand-service-id="service.demandServiceId"
                  :demand="demandCopy"
                  :materials="materials"
                  @add:item="(item) => addItemToDemandService(service.id, item)"
                  @remove:item="(itemId, remove = false) => removeItemToDemandService(service.id, itemId, remove)"
                  class="mt-2"
                />
              </div>
              <v-divider
                v-if="!editMode"
                class="my-2 mt-8"
                width="100%"
                style="border-top: 1px dashed var(--v-primary-base)"
              />
            </div>
          </v-sheet>

          <v-sheet v-if="!editMode" class="ml-8">
            <p class="mt-4" style="font-weight: bold">Materiais adicionados:</p>

            <v-data-table
              :items="Object.values(tableDemandServiceItems)"
              :headers="bdItemsHeaders"
              :hide-default-footer="true"
            >
              <template #[`item.price`]="{ item }">
                <span>
                  {{ $utils.formatCurrency(parseFloat(item.price)) }}
                </span>
              </template>
              <template #[`item.totalPrice`]="{ item }">
                <span>
                  {{
                    $utils.formatCurrency(parseFloat(item.price) * parseInt(item.quantity)),
                  }}
                </span>
              </template>

              <template #[`item.categories`]="{ item }">
                <div class="d-flex align-center" style="gap: 4px">
                  <v-tooltip bottom v-for="category in item.categories" :key="`${item.id}:${category}`">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        @mouseover="setServiceCategoryDescriptionToShow(category)"
                        @mouseleave="clearServiceCategoryDescriptionToShow"
                      >
                        <w-service-icon :service="{ id: category }" :show-text="false" :size="24" />
                      </div>
                    </template>
                    <span v-if="serviceCategoryDescriptionToShow" class="caption">{{
                      $consts.SERVICE_CATEGORIES[serviceCategoryDescriptionToShow]
                    }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-sheet>
        </v-sheet>

        <div class="d-flex justify-space-between mb-4 pb-4 pl-8" v-if="editMode && demand.services.length > 0">
          <v-btn @click="openAddServicesDialog()" color="primary" small
            >Adicionar novo serviço<v-icon class="ml-2" small>mdi-plus</v-icon></v-btn
          >
          <v-btn
            v-if="demand.currentStatus.id === $consts.STATUS.PENDING"
            @click="toggleEditMode()"
            color="primary"
            small
            dark
            >Finalizar edição</v-btn
          >
        </div>
      </v-sheet>

      <v-sheet
        class="d-flex flex-column ml-4"
        style="gap: 12px"
        :style="editMode ? 'min-width: 350px;' : 'min-width: 250px;'"
      >
        <span class="subtitle mr-2 overline">Resumo</span>
        <div class="d-flex align-center justify-space-between">
          <span class="mr-2 font-weight-bold subtitle-2">Serviços: </span>
          <w-editable-input
            v-model="demandCopy.proposal.serviceCost"
            type="currency"
            prefix="R$"
            :editMode="false"
            outlined
            displayClass="subtitle-1 primary--text"
            :displayValue="$utils.formatCurrency(demandCopy.proposal.serviceCost)"
          />
        </div>
        <div class="d-flex align-center justify-space-between">
          <span class="mr-2 font-weight-bold subtitle-2">Materiais: </span>
          <w-editable-input
            v-model="demandCopy.proposal.materialCost"
            type="currency"
            prefix="R$"
            :editMode="false"
            outlined
            displayClass="subtitle-1 primary--text"
            :displayValue="$utils.formatCurrency(demandCopy.proposal.materialCost)"
          />
        </div>
        <div class="d-flex align-center justify-space-between">
          <span class="mr-2 font-weight-bold subtitle-2">Comissão: </span>
          <div class="d-flex flex-column align-end">
            <v-btn
              v-if="editMode"
              text
              plain
              x-small
              color="secondary"
              class="caption text-none text-decoration-underline font-weight-medium pa-0"
              @click="applyComission"
            >
              Aplicar comissão automática
            </v-btn>
            <w-editable-input
              :value="demandCopy.proposal.workayFee"
              @blur="
                demandCopy.proposal.workayFee =
                  parseFloat($event.target.value.replaceAll('.', '').replace(',', '.')) ?? 0
              "
              type="currency"
              prefix="R$"
              :editMode="editMode"
              outlined
              displayClass="subtitle-1 primary--text"
              :displayValue="$utils.formatCurrency(demandCopy.proposal.workayFee)"
            />
          </div>
        </div>

        <div class="d-flex align-center justify-space-between">
          <span class="mr-2 font-weight-bold subtitle-2">Impostos: </span>
          <div class="d-flex flex-column align-end">
            <v-btn
              v-if="editMode"
              text
              plain
              x-small
              color="secondary"
              class="caption text-none text-decoration-underline font-weight-medium pa-0"
              @click="applyTax"
            >
              Aplicar impostos automáticos
            </v-btn>
            <w-editable-input
              :value="demandCopy.proposal.tax"
              @blur="demandCopy.proposal.tax = parseFloat($event.target.value.replaceAll('.', '').replace(',', '.'))"
              type="currency"
              prefix="R$"
              :editMode="editMode"
              outlined
              displayClass="subtitle-1 primary--text"
              :displayValue="$utils.formatCurrency(demandCopy.proposal.tax)"
            />
          </div>
        </div>

        <div class="d-flex align-center justify-space-between">
          <span class="mr-2 font-weight-bold subtitle-2">Desconto: </span>
          <div class="d-flex flex-column align-start">
            <div class="d-flex flex-row justify-space-between" style="width: 100%" v-if="editMode">
              <v-btn
                v-for="discount in discounts"
                :key="'disc' + discount"
                text
                plain
                x-small
                color="secondary"
                class="caption text-none text-decoration-underline font-weight-medium pa-0"
                @click="applyDiscount(discount)"
                >{{ `(${discount}%)` }}</v-btn
              >
            </div>
            <w-editable-input
              :value="demandCopy.proposal.discount"
              @blur="demandCopy.proposal.discount = parseFloat($event.target.value)"
              type="currency"
              prefix="R$"
              :editMode="editMode"
              outlined
              displayClass="subtitle-1 error--text"
              :displayValue="`- ${$utils.formatCurrency(demandCopy.proposal.discount)}`"
            />
          </div>
        </div>
        <v-divider width="100%"></v-divider>
        <div class="d-flex align-center justify-space-between">
          <span class="mr-2 font-weight-bold title proposal-total">Total: </span>

          <span class="title font-weight-bold proposal-total">{{ $utils.formatCurrency(demandTotalPrice) }}</span>
        </div>
      </v-sheet>
    </v-sheet>

    <w-service-progress-bar :steps="this.progress" />
    <w-bundle-dialog ref="bundleDialog" @save="saveBundleSelection" />
    <w-add-services-dialog ref="addServicesDialog" @save="saveNewServices" />

    <w-add-work-partner-dialog
      ref="work-partner-dialog"
      :openDialog="addWorkPartnerDialog"
      @close="addWorkPartnerDialog = false"
      @update:estimate="updateEstimate"
      :alert="this.$refs.alert"
      :service-categories="serviceCategories"
    />

    <w-alert ref="alert" />

    <v-snackbar v-model="savingDemand" color="primary">
      <div class="d-flex justify-space-between align-center">
        <span style="font-size: 14px">Salvamento automático...</span>
        <v-progress-circular indeterminate color="white" :size="14" />
      </div>
    </v-snackbar>

    <w-dialog
      title="Nova tarefa"
      v-model="show"
      :max-width="400"
      :buttons="[
        {
          label: 'fechar',
          action: toggleShow,
        },
        {
          label: 'salvar',
          action: onSaveTask,
          disabled: !newTaskName,
        },
      ]"
    >
      <v-form ref="taskForm">
        <v-text-field
          v-model="newTaskName"
          class="required"
          label="Tarefa"
          @keydown.enter="
            (e) => {
              e.preventDefault()
              onSaveTask()
            }
          "
        ></v-text-field>
      </v-form>
    </w-dialog>
  </v-card>
</template>

<script>
import WAlert from '@/components/WAlert.vue'
import { STATUS } from '@/config/constants'
import _ from 'lodash'
import api from '../api/services'
import WAddServicesDialog from './WAddServicesDialog.vue'
import WAddWorkPartnerDialog from './WAddWorkPartnerDialog.vue'
import WBundleDialog from './WBundleDialog.vue'
import WDialog from './WDialog.vue'
import WEditableInput from './WEditableInput.vue'
import WServiceIcon from './WServiceIcon.vue'
import WServiceProgressBar from './WServiceProgressBar.vue'
import WSuggestedDemandMaterials from './WSuggestedDemandMaterials.vue'

import { createNamespacedHelpers, mapGetters } from 'vuex'
createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {
    WDialog,
    WServiceIcon,
    WEditableInput,
    WBundleDialog,
    WAddServicesDialog,
    WServiceProgressBar,
    WAddWorkPartnerDialog,
    WSuggestedDemandMaterials,
    WAlert,
  },
  props: {
    services: {
      type: Array,
      required: true,
    },
    serviceCategories: {
      type: Array,
      required: true,
    },
    demand: {
      type: Object,
    },
    materials: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      savingDemand: false,
      serviceCategoryDescriptionToShow: null,
      progress: [],
      availableWorkPartners: [],
      currentEstimate: null,
      localWorkPartner: null,
      addWorkPartnerDialog: false,
      workPartnerDialog: false,
      toggleWorkPartnerDialog: false,
      currentServiceId: null,
      show: false,
      newTaskName: '',
      editMode: false,
      viewPropostal: true,
      demandCopy: {},
      workPartners: [],
      discounts: [0, 5, 8, 10, 15],
      loading: false,
      bdItemsHeaders: [
        { text: 'Produto', value: 'name' },
        { text: 'Descrição', value: 'description' },
        { text: 'Tamanho', value: 'size' },
        { text: 'Marca', value: 'brand' },
        { text: 'Preço unitário', value: 'price' },
        { text: 'Quantidade', value: 'quantity' },
        { text: 'Preço total', value: 'totalPrice' },
        { text: 'Categorias', value: 'categories' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
    maxServicePrice() {
      return 1000000
    },
    materialItemsSum() {
      const allItems = this.demandCopy.services?.flatMap((service) => service.items?.map((i) => i)) ?? []

      return allItems.reduce((acc, item) => acc + item?.item.unitaryPrice * item?.quantity, 0)
    },
    tableDemandServiceItems() {
      return this.demand.services.reduce((accTotal, service) => {
        const items = service.items.reduce((accLocal, { item, quantity }) => {
          quantity += accTotal[item.id]?.quantity ?? 0
          const categories = accTotal[item.id]?.categories ?? new Set()
          return {
            ...accLocal,
            [item.id]: {
              ...item,
              brand: item.brand.name,
              name: item.materialName,
              price: item.unitaryPrice,
              totalPrice: quantity * item.unitaryPrice,
              quantity,
              categories: categories.add(service.serviceCategory.id),
            },
          }
        }, {})

        return {
          ...accTotal,
          ...items,
        }
      }, {})
    },
    canEditServices() {
      return this.demandCopy.currentStatus.id === STATUS.IN_PROGRESS
    },
    availableServices() {
      return this.services.filter((service) => !this.demandCopy.services.some((s) => s.id === service.id))
    },
    demandTotalPrice() {
      return [
        this.demandCopy.proposal.serviceCost,
        this.demandCopy.proposal.materialCost,
        this.demandCopy.proposal.workayFee,
        this.demandCopy.proposal.tax,
        -this.demandCopy.proposal.discount,
      ].reduce((acc, curr) => acc + curr, 0)
    },
    totalServicePrice() {
      return this.demandCopy.services.reduce((a, b) => a + Number(b.currentEstimate.price), 0)
    },
  },
  methods: {
    resetServiceValue(service) {
      const originalService = this.demandCopy.services.find((s) => s.id === service.id)
      service.currentEstimate.price = originalService.price * service.quantity
    },
    setServiceCategoryDescriptionToShow(serviceCategory) {
      this.serviceCategoryDescriptionToShow = serviceCategory
    },
    clearServiceCategoryDescriptionToShow() {
      this.serviceCategoryDescriptionToShow = null
    },
    applyComission() {
      const getComission = (cost) => {
        if (!cost) {
          return 0
        }
        if (cost <= 150) {
          return 50
        }
        if (cost <= 250) {
          return 70
        }
        if (cost <= 400) {
          return 95
        }
        if (cost <= 550) {
          return 115
        }
        if (cost <= 700) {
          return 145
        }
        if (cost <= 900) {
          return 170
        }
        if (cost <= 1100) {
          return 195
        }
        if (cost <= 1300) {
          return 220
        }
        if (cost <= 1500) {
          return 245
        }
        if (cost <= 1700) {
          return 270
        }
        if (cost <= 1900) {
          return 295
        }
        if (cost <= 2000) {
          return 330
        }
        if (cost <= 10000) {
          return 0.15 * cost
        }
        if (cost <= 20000) {
          return 0.12 * cost
        }
        if (cost <= 30000) {
          return 0.1 * cost
        }

        return 0.08 * cost
      }

      const cost = (this.demandCopy.proposal.serviceCost ?? 0) + (this.demandCopy.proposal.materialCost ?? 0)

      this.demandCopy.proposal.workayFee = getComission(cost)
    },
    applyTax() {
      this.demandCopy.proposal.tax = (this.demandCopy.proposal.workayFee ?? 0) * 0.18
    },
    cancelEditMode() {
      this.editMode = !this.editMode
    },
    openProposalOrReport() {
      if ([this.$consts.STATUS.IN_PROGRESS, this.$consts.STATUS.DONE].includes(this.demand.currentStatus.id)) {
        this.$router.push({
          name: 'Report',
          params: { id: this.demand.id },
        })
      } else {
        this.$router.push({
          name: 'Proposal',
          params: { id: this.demand.id },
        })
      }
    },
    refreshMaterialCost() {
      this.demandCopy.proposal.materialCost = this.demandCopy.services
        .flatMap((service) => service.items ?? [])
        .reduce((acc, serviceItem) => acc + serviceItem.item.unitaryPrice * serviceItem.quantity, 0)
    },
    addItemToDemandService(serviceId, item) {
      const serviceIndex = this.demandCopy.services.findIndex((service) => service.id === serviceId)

      if (this.demandCopy.services[serviceIndex].items === undefined) {
        this.demandCopy.services[serviceIndex].items = []
      }

      const foundItem = this.demandCopy.services[serviceIndex].items.find((i) => i.item.id === item.item.id)

      if (foundItem) {
        foundItem.quantity += item.quantity
      } else {
        this.demandCopy.services[serviceIndex].items.push(item)
      }

      this.refreshMaterialCost()
    },
    removeItemToDemandService(serviceId, itemId, remove) {
      const serviceIndex = this.demandCopy.services.findIndex((service) => service.id === serviceId)

      const foundItem = this.demandCopy.services[serviceIndex].items.find((i) => i.item.id === itemId)

      foundItem.quantity = remove ? 0 : Math.max(0, foundItem.quantity - 1)

      this.refreshMaterialCost()
    },
    openVisitDialog(service) {
      if (this.demand.currentStatus.id !== this.$consts.STATUS.IN_PROGRESS) {
        return
      }

      this.$emit('click:newVisit', service)
    },
    defineProgress() {
      const services = this.demandCopy.services
      const existsMoreThanOneService = services.length > 0
      const hasPartnerConditions = [
        this.$consts.STATUS.ACCEPTED,
        this.$consts.STATUS.IN_PROGRESS,
        this.$consts.STATUS.DONE,
      ]

      const finishedWorkCondition =
        services.every((s) => s.currentEstimate?.currentStatus?.id === this.$consts.STATUS.DONE) &&
        existsMoreThanOneService

      const isInProgressCondition =
        (services.every((s) => s.currentEstimate?.currentStatus?.id === this.$consts.STATUS.IN_PROGRESS) &&
          existsMoreThanOneService) ||
        finishedWorkCondition

      const hasDesignedWorkpartnerCondition =
        (services.every((s) => hasPartnerConditions.includes(s.currentEstimate?.currentStatus?.id)) &&
          existsMoreThanOneService) ||
        isInProgressCondition ||
        finishedWorkCondition

      const hasContractCondition =
        (this.demandCopy.currentStatus.id === this.$consts.STATUS.IN_PROGRESS && existsMoreThanOneService) ||
        isInProgressCondition ||
        finishedWorkCondition

      const step1 = existsMoreThanOneService
      const step2 = hasDesignedWorkpartnerCondition
      const step3 = hasContractCondition
      const step4 = isInProgressCondition
      const step5 = finishedWorkCondition

      this.progress = [
        { label: 'Designar serviços', completed: step1 },
        { label: 'Designar parceiros', completed: step2 },
        { label: 'Contrato', completed: step3 },
        { label: 'Obra em andamento', completed: step4 },
        { label: 'Obra finalizada', completed: step5 },
      ]
    },
    async showWorkPartnerDialog(service) {
      if (this.isWorkPartnerUnassigned(this.getWorkPartnerText(service, true))) {
        this.currentEstimate = service.currentEstimate
        this.availableWorkPartners = await this.listAvailableWorkPartners(this.currentEstimate?.service)
        this.workPartnerDialog = !this.workPartnerDialog
      }
    },

    closeDesignateWorkPartnerDialog() {
      this.localWorkPartner = null
      this.workPartnerDialog = false
    },
    async updateEstimate() {
      this.$emit('update:estimate', (await api.updateEstimate(this.currentEstimate)).data)
    },
    async designateWorkPartner(estimate, workPartner) {
      estimate.workPartner = workPartner
      this.$emit('update:estimate', (await api.updateEstimate(estimate)).data)
      this.closeDesignateWorkPartnerDialog()

      this.$emit('update:demand', (await api.getDemand(this.demand.id)).data)

      this.$nextTick(() => {
        if (this.$refs['estimate-table']) {
          this.$refs['estimate-table'].refreshData()
        }
        if (this.$refs['visit-table']) {
          this.$refs['visit-table'].refreshData()
        }
      })
    },
    async listAvailableWorkPartners(service) {
      if (!service) {
        return []
      }
      let serviceCategory = this.demand.services.find((s) => s.id === service.id).serviceCategory
      const workPartners = (await api.listWorkPartners()).data

      const filteredsByServiceCategory = workPartners.filter((partner) =>
        partner.serviceCategories.some((s) => s.id === serviceCategory.id),
      )
      const filteredByRealEstateAgency = filteredsByServiceCategory.filter((partner) =>
        partner.associations.some((association) => association.realEstateAgency.id === this.myRealEstateAgency.id),
      )

      return filteredByRealEstateAgency
    },

    isWorkPartnerUnassigned(text) {
      return text === 'Não designado'
    },
    openAddWorkPartnerDialog() {
      this.closeDesignateWorkPartnerDialog()
      this.addWorkPartnerDialog = !this.addWorkPartnerDialog
    },
    closeDialog() {
      this.$emit('close')
    },
    onDeleteTask(service, task) {
      if (!confirm('Tem certeza que deseja excluir esta tarefa?')) {
        return
      }
      const foundTask = service.tasks.find(
        (serviceTask) => serviceTask.task.custom && serviceTask.task.name === task.task.name,
      )
      foundTask.active = false
    },
    toggleTaskIncluded(canClick, service, demandServiceTask) {
      if (!canClick) return

      const serviceIndex = this.demandCopy.services.findIndex((s) => s.id === service.id)

      let taskIndex
      const demandServiceTaskAlreadyExists = !!demandServiceTask.id

      // caso o demandServiceTask não existir, é uma task nova, portanto filtra pela task
      taskIndex = this.demandCopy.services[serviceIndex].tasks.findIndex((t) =>
        demandServiceTaskAlreadyExists ? t.id === demandServiceTask.id : t.task.id === demandServiceTask.task.id,
      )

      this.demandCopy.services[serviceIndex].tasks[taskIndex].included =
        !this.demandCopy.services[serviceIndex].tasks[taskIndex].included
    },
    async onSaveTask() {
      if (this.$refs.taskForm.validate()) {
        const newTask = {
          active: true,
          done: false,
          task: {
            custom: true,
            name: this.newTaskName,
          },
        }

        const serviceIndex = this.demandCopy.services.findIndex((service) => service.id === this.currentServiceId)

        this.demandCopy.services[serviceIndex].tasks.push(newTask)

        this.toggleShow()

        this.$refs.taskForm.resetValidation()

        this.newTaskName = ''
      }
    },
    toggleShow(serviceId) {
      this.show = !this.show
      if (serviceId) this.currentServiceId = serviceId
    },
    getWorkPartnerColor(service) {
      switch (service.currentEstimate.currentStatus.id) {
        case this.$consts.STATUS.PENDING:
          return 'error'
        case this.$consts.STATUS.WAITING_PARTNER:
          return '#FCBF13'
        case this.$consts.STATUS.ACCEPTED:
          return 'primary'
        default:
          return service.currentEstimate.currentStatus.hexcolor
      }
    },
    getWorkPartnerText(service, showName) {
      switch (service.currentEstimate.currentStatus.id) {
        case this.$consts.STATUS.PENDING:
          return 'Não designado'
        case this.$consts.STATUS.WAITING_PARTNER:
          return 'Aguardando parceiro'
        default:
          return service.currentEstimate.workPartner.name
      }
    },
    canEditService(service) {
      return (
        this.editMode &&
        (!service.currentEstimate.id ||
          [this.$consts.STATUS.PENDING, this.$consts.STATUS.WAITING_PARTNER].includes(
            service.currentEstimate.currentStatus.id,
          ))
      )
    },
    applyDiscount(value) {
      this.demandCopy.proposal.discount = (value / 100) * this.demandCopy.proposal.workayFee
    },
    toggleEditMode() {
      this.editMode = !this.editMode
      if (this.editMode && this.demandCopy.services.length === 0) this.openAddServicesDialog()
    },
    demandHasServiceInCategory(serviceCategory) {
      return this.demandServicesInCategory(serviceCategory).length > 0
    },
    demandServicesInCategory(serviceCategory) {
      return this.demandCopy.services.filter((demandService) => demandService.serviceCategory.id === serviceCategory.id)
    },
    removeServiceCategory(serviceCategoryToRemove) {
      if (this.demandHasServiceInCategory(serviceCategoryToRemove)) {
        return
      }

      if (!confirm(`Tem certeza que deseja remover a categoria '${serviceCategoryToRemove.name}'?`)) {
        return
      }

      let indexToRemove = this.demandCopy.serviceCategories.findIndex(
        (serviceCategory) => serviceCategory.id === serviceCategoryToRemove.id,
      )
      this.demandCopy.serviceCategories.splice(indexToRemove, 1)
    },
    setPriceUsingWorkayTable(service) {
      service.currentEstimate.price = (service.quantity || 1) * service.price
    },
    removeService(serviceToRemove) {
      if (!confirm('Deseja remover esse serviço? Os orçamentos associados serão cancelados.')) {
        return
      }
      let indexToRemove = this.demandCopy.services.findIndex((service) => service.id === serviceToRemove.id)
      this.demandCopy.services.splice(indexToRemove, 1)
    },
    openBundleDialog(service) {
      this.$refs.bundleDialog.open(service)
    },
    saveBundleSelection(bundleId, selectedServices) {
      let bundle = this.demandCopy.services.find((service) => service.id === bundleId)
      bundle.bundleItems = selectedServices
    },
    openAddServicesDialog() {
      this.$refs.addServicesDialog.open(this.serviceCategories, this.availableServices)
    },
    saveNewServices(selectedServices) {
      this.demandCopy.services = [...this.demandCopy.services, ...selectedServices]
      selectedServices.forEach((s) => {
        if (!this.demandCopy.serviceCategories.some((c) => c.id === s.serviceCategory.id)) {
          this.demandCopy.serviceCategories.push(s.serviceCategory)
        }
      })
    },
    async closeDeal() {
      if (!confirm('Deseja fechar a proposta?')) {
        return
      }
      this.$emit('closeDeal')
    },
    hasPreviousServiceVisit(serviceId) {
      let visits = this.demand.visits.filter((v) => v.service.id === serviceId)

      return visits.length > 0
    },
    getNextServiceVisit(serviceId) {
      let svc = this.demand.services.find((s) => s.id === serviceId)
      if (svc?.finished) {
        return null
      }

      let visits = this.demand.visits.filter(
        (v) =>
          v.service.id === serviceId &&
          v.currentStatus.id === this.$consts.STATUS.PENDING &&
          this.$moment(v.datetime).isSameOrAfter(this.$moment(), 'day'),
      )

      if (!visits || visits.length === 0) {
        return null
      }
      return visits.reduce(
        (currentMin, current) =>
          this.$moment(currentMin.datetime).isSameOrBefore(current.datetime, 'minute') ? currentMin : current,
        visits[0],
      )
    },
    updateProposal() {
      return {
        ...this.demandCopy.proposal,
        materialCost: this.materialItemsSum,
        serviceCost: this.totalServicePrice,
        workayFee: this.demandCopy.proposal.workayFee,
        tax: this.demandCopy.proposal.tax,
        discount: this.demandCopy.proposal.discount,
      }
    },
  },
  mounted() {
    this.defineProgress()

    setInterval(() => {
      if (!this.editMode) {
        this.$emit('reload:demand')
      }
    }, 1800000)
  },
  watch: {
    demand: {
      immediate: true,
      deep: true,
      handler: function () {
        this.demandCopy = this.$utils.copy(this.demand)
        this.defineProgress()
      },
    },
    demandCopy: {
      deep: true,
      handler: function (newValue, old) {
        if (!_.isEqual(this.demandCopy, this.demand)) {
          this.savingDemand = true
          this.$emit('update:demand', { ...this.demandCopy, proposal: this.updateProposal() })

          setTimeout(() => {
            this.savingDemand = false
          }, 1000)
        }
      },
    },
  },
}
</script>

<style scoped>
.service {
  border-radius: 8px;
  padding: 24px 32px;
}

.edited-service {
  border: 1px dashed var(--v-primary-base) !important;
}
.service-name {
  font-size: 16px;
  color: var(--v-primary-base);
}

span {
  word-break: break-word;
}

.task-item {
  padding: 16px;
  border-radius: 4px;
}

.included {
  border-color: var(--v-primary-base);
}

.custom {
  border-color: var(--v-primary-base);
  color: gray;
}

.default {
  color: gray;
}
.clickable:hover {
  cursor: pointer;
}

.not-clickable:hover {
  cursor: default;
}

.task-item /deep/ label {
  color: black;
}

.service-status-icon {
  max-width: 140px;
  min-width: 90px;
  min-height: 80px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  cursor: default;

  border-radius: 8px;
  border: 1px solid rgba(128, 128, 128, 0.4);
  padding: 4px;
}

.cannotClick:hover {
  cursor: not-allowed;
}

.canClick {
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 150ms;
}

.canClick:hover {
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.1);
}

.canClick:active {
  background-color: rgba(128, 128, 128, 0);
}

.proposal-total {
  font-size: 18px !important;
}
</style>
