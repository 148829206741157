<template>
  <div>
    <w-dialog class="px-8" v-model="showDialog" :max-width="600" :buttons="buttons" :dismissible="!editable">
      <div class="box mt-4 px-10 d-flex flex-column align-center" :class="editable ? 'pb-0' : ''">
        <h2 class="mx-8">O que você está achando de testar a Workay! até agora?</h2>

        <v-rating
          class="my-4"
          background-color="primary"
          color="primary"
          v-model="userRating.evaluationScore"
          hover
          length="5"
          size="42"
          :readonly="!editable"
        ></v-rating>

        <p v-if="!editable" class="mb-8">{{ userRating.comment }}</p>
        <textarea
          v-else
          v-model="userRating.comment"
          class="text-area"
          placeholder="Diga-nos como podemos melhorar o sistema para você..."
        />

        <div v-if="!editable" class="d-flex justify-space-between" style="width: 100%">
          <span>{{ author }}</span>
          <span>{{ date }}</span>
        </div>
      </div>
    </w-dialog>
  </div>
</template>

<script>
import WDialog from '@/components/WDialog.vue'
import api from '@/api/services'
import WAlert from './WAlert.vue'
import store from '@/store'

export default {
  components: { WDialog },

  data() {
    return {
      showDialog: false,
      defaultUserRating: {
        evaluationScore: 0,
        user: {
          name: '',
          realEstateAgency: {
            name: '',
          },
        },
        date: this.$moment(),
      },
      userRating: {},
      saving: false,
    }
  },

  methods: {
    async save() {
      this.saving = true
      await api.createRating(this.userRating)
      this.showDialog = false
      this.saving = false
      store.state.globalAlertComponent.showSuccess(
        'Avaliação enviada com sucesso! Obrigado por nos ajudar a melhorar o sistema.',
      )
    },
  },

  created() {
    this.userRating = this.defaultUserRating
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      required: false,
    },
  },

  computed: {
    canSave() {
      return this.userRating.evaluationScore > 0 && this.userRating.comment?.trim().length > 0
    },
    date() {
      const date = this.$moment(this.userRating.date)
      return date.format('DD/MM/YYYY') + ' às ' + date.format('HH:mm')
    },

    author() {
      if (this.userRating.user.name === this.userRating.user.realEstateAgencyName) {
        return this.userRating.user.name
      } else {
        return this.userRating.user.name + ' (' + this.userRating.user.realEstateAgencyName + ')'
      }
    },
    buttons() {
      return this.editable
        ? [
            {
              label: 'salvar',
              action: () => this.save(),
              disabled: !this.canSave,
              loading: this.saving,
            },
          ]
        : []
    },
  },

  watch: {
    value: {
      handler(val) {
        if (!val) this.userRating = this.defaultUserRating
        this.showDialog = val
      },
    },
    item: {
      handler(val) {
        this.userRating = this.$utils.copy(val)
      },
    },
    showDialog: {
      handler(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style scoped>
h2 {
  color: black;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
}

.text-area {
  width: 100%;
  min-height: 100px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 8px;
  resize: vertical;
  font-size: 16px;
  color: #4f4f4f;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

.text-area:focus {
  outline: none;
}
</style>
