const kilobyte = 1024
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

const formatter = (value, { style, minimumFractionDigits, maximumFractionDigits }) => {
  return new Intl.NumberFormat('pt-BR', {
    style,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value)
}

const formatters = {
  toPercent(value, decimal = 2) {
    return formatter(value, { style: 'percent', minimumFractionDigits: decimal, maximumFractionDigits: decimal })
  },
  toDecimal(value, decimal = 2) {
    return formatter(value, { minimumFractionDigits: decimal, maximumFractionDigits: decimal })
  },
  toHoursDuration(valueInMinutes) {
    return `${formatter(valueInMinutes / 60, { maximumFractionDigits: 1 })}h`
  },
  toInteger(value) {
    return formatter(value, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  },
  toByteSize(bytes) {
    if (bytes === 0) return '0 Bytes'
    const sizeIndex = Math.floor(Math.log(bytes) / Math.log(kilobyte))
    return (
      formatter(bytes / Math.pow(kilobyte, sizeIndex), { minimumFractionDigits: 0, maximumFractionDigits: 1 }) +
      ' ' +
      sizes[sizeIndex]
    )
  },
}

export default formatters
