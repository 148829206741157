<template>
  <w-crud-table
    ref="crud"
    title="Tipos de materiais"
    crud-endpoint="material"
    sort-by="name"
    :before-send="beforeSend"
    :custom-save="actionSave"
    :custom-update="actionUpdate"
    :headers="headers"
    :can-create="isAdmin"
    :can-edit="isAdmin"
    :can-delete="isAdmin"
    @click:edit="(item) => onEdit(item)"
  >
    <template #form="{ item }">
      <v-text-field label="Nome" v-model="item.name" class="required" :rules="[$validationRules.required.rule]" />
      <v-text-field
        label="Descrição"
        v-model="item.description"
        class="required"
        :rules="[$validationRules.required.rule]"
      />
      <v-autocomplete
        ref="autocomplete"
        dense
        multiple
        label="Categorias"
        :items="materialCategories || []"
        return-object
        :item-text="(data) => data.value"
        item-value="id"
        class="required"
        :rules="[$validationRules.required.rule]"
        v-model="item.categories"
      />
      <v-autocomplete
        v-if="isAdmin"
        ref="autocomplete"
        dense
        label="Imobiliária"
        :items="realEstateAgencies || []"
        return-object
        item-text="name"
        item-value="id"
        class="required"
        :rules="[$validationRules.required.rule]"
        v-model="item.realEstateAgency"
      />
    </template>
    <template #realEstateAgency="{ item }">
      <v-chip v-if="item.realEstateAgency" color="primary">{{ item.realEstateAgency.name }}</v-chip>
      <span v-else>-</span>
    </template>
  </w-crud-table>
</template>

<script>
import { MATERIAL_CATEGORIES } from '@/config/constants'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import api from '../api/services'
import WCrudTable from '../components/WCrudTable.vue'

createNamespacedHelpers('user/realEstateAgency')
export default {
  components: { WCrudTable },
  data() {
    return {
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Descrição', value: 'description' },
        {
          text: 'Categorias',
          value: 'categories',
          sortable: false,
          converter: (item) => item.categories.map((category) => MATERIAL_CATEGORIES[category]).join(', '),
        },
        {
          text: 'Imobiliária',
          value: 'realEstateAgency',
        },
      ],

      materialBrands: [],
      materialCategories: [],
      realEstateAgencies: [],
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      myRealEstateAgency: 'myRealEstateAgency',
    }),
  },

  methods: {
    actionSave: async (item) => {
      return await api.createMaterial(item)
    },

    actionUpdate: async (item) => {
      return await api.updateMaterial(item)
    },

    onEdit(item) {
      item.categories = item.categories.map((category) => ({
        key: category,
        value: MATERIAL_CATEGORIES[category],
      }))
    },
    beforeSend(item) {
      if (item.categories?.some((category) => category.key)) {
        item.categories = item.categories.map((category) => category.key)
      }
    },
  },

  async created() {
    this.materialCategories = (await api.listMaterialCategories()).data
      .filter((category) => category !== MATERIAL_CATEGORIES.NAO_INFORMADO)
      .map((category) => ({
        key: category,
        value: MATERIAL_CATEGORIES[category],
      }))

    this.materialSizes = (await api.listMaterialSizes()).data
    this.materialBrands = (await api.listMaterialBrands()).data
    this.realEstateAgencies = (await api.getRealEstateAgencies()).data
  },
}
</script>

<style></style>
