<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div class="d-flex justify-center loading mt-4" v-if="!demand">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div v-if="demand" style="margin: 16px 0; display: flex; justify-content: space-between">
      <v-sheet class="transparent d-flex justify-center flex-column">
        <v-sheet class="transparent text-left">
          <v-btn text @click="back()" class="px-0"><v-icon>mdi-chevron-left</v-icon>Voltar</v-btn>
        </v-sheet>
      </v-sheet>
      <div class="d-flex flex-row">
        <div class="mr-2">
          <v-progress-circular
            v-if="sendingToEmail"
            indeterminate
            color="primary"
            class="mr-4"
            style="width: 20px"
          ></v-progress-circular>

          <v-btn
            v-else
            color="#FDFDFD"
            small
            style="border: 1px solid #ffee56"
            @click="sendToEmail()"
            :disabled="isLoadingImages"
            ><strong>Enviar por e-mail</strong></v-btn
          >
        </div>

        <div>
          <v-progress-circular
            v-if="isPrinting"
            indeterminate
            color="primary"
            style="width: 20px"
          ></v-progress-circular>
          <v-btn
            v-else
            color="#FDFDFD"
            small
            style="border: 1px solid #ffee56"
            @click="printReport()"
            :disabled="isLoadingImages"
            ><strong>Imprimir</strong></v-btn
          >
        </div>
      </div>
    </div>

    <div v-if="demand" id="printable-report" ref="printableReport">
      <div>
        <table class="print report-print" ref="document">
          <thead v-if="!sendingToEmail">
            <tr>
              <th class="print-header">
                <h3>
                  Relatório
                  {{ demand.currentStatus.id === $consts.STATUS.DONE ? 'final de obra' : 'de obra em andamento' }}
                </h3>

                <div style="width: 150px">
                  <div v-if="!demand.realEstateAgency.proposalInfo?.logo">
                    <Logo />
                  </div>
                  <div v-else>
                    <img id="logo" :src="demand.realEstateAgency.proposalInfo?.logo.url" style="width: 150px" />
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td id="content">
                <v-sheet class="print-content">
                  <v-sheet class="client-info">
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">Cliente:</span>
                      </v-col>
                      <v-col class="pa-0 justify-start">
                        <span class="client-info-data">{{ demand.client.name }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">Data:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">{{ $moment().format('DD/MM/YYYY') }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">E-mail:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">{{ demand.client.email }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">Telefone:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">{{ demand.client.phone }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">Endereço:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">
                          {{ `${demand.address}, ${demand.addressNumber} ${demand.addressComplement || ''}` }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">Bairro:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">{{ demand.district }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" class="pa-0">
                        <span class="client-info-label">CEP:</span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="client-info-data">{{ demand.zipCode }}</span>
                      </v-col>
                    </v-row>
                  </v-sheet>
                  <v-sheet class="proposal-wrapper d-flex" style="gap: 20px">
                    <v-sheet class="services-wrapper" v-if="reportData.done.tasks.length > 0">
                      <div class="section-label"><span>O que foi feito</span></div>

                      <ul style="padding-left: 32px">
                        <li
                          v-for="serviceTask in reportData.done.tasks"
                          :key="serviceTask.id"
                          style="margin-bottom: 8px"
                        >
                          <span>{{ serviceTask.task?.name }}</span>
                        </li>
                      </ul>
                    </v-sheet>

                    <v-sheet class="services-wrapper" v-if="reportData.notDone.tasks.length > 0">
                      <div class="section-label"><span>O que falta ser feito</span></div>

                      <ul style="padding-left: 32px">
                        <li
                          v-for="serviceTask in reportData.notDone.tasks"
                          :key="serviceTask.id"
                          style="margin-bottom: 8px"
                        >
                          <span>{{ serviceTask.task?.name }}</span>
                        </li>
                      </ul>
                    </v-sheet>

                    <v-sheet class="services-wrapper" v-if="reportData.observations.length > 0">
                      <div class="section-label"><span>Observações</span></div>

                      <ul style="padding-left: 32px">
                        <li v-for="(observation, index) in reportData.observations" :key="observation + '-' + index">
                          <span>{{ observation }}</span>
                        </li>
                      </ul>
                    </v-sheet>

                    <v-sheet class="services-wrapper">
                      <w-report-document-spent-list
                        style="margin-bottom: 24px"
                        title="Gastos Realizados"
                        :material-items-price="reportData.done.materials"
                        :services-price="reportData.done.services"
                        :done="true"
                      />

                      <w-report-document-spent-list
                        title="Gastos Previstos"
                        style="padding-bottom: 24px"
                        :material-items-price="reportData.notDone.materials"
                        :services-price="reportData.notDone.services"
                      />
                    </v-sheet>

                    <v-sheet class="photos-wrapper" v-if="Object.keys(reportData.photos).length > 0">
                      <div class="section-label" id="service-photos"><span>Fotos do serviço</span></div>

                      <v-sheet class="task-photos">
                        <v-sheet
                          v-for="({ taskImagesUrl, categoryName }, categoryId) in reportData.photos"
                          :key="categoryId"
                          class="task-container"
                          style="width: 100%"
                        >
                          <w-service-icon
                            :service="{ id: parseInt(categoryId), name: categoryName }"
                            :show-text="true"
                            :bold="true"
                            :size="40"
                          />

                          <v-sheet class="task-photos-wrapper" style="width: 100%">
                            <div v-if="isLoadingImages" class="d-flex align-center justify-center fill-height py-2">
                              <v-progress-circular color="var(--v-primary-base)" indeterminate></v-progress-circular>
                            </div>

                            <template v-else>
                              <template v-for="(taskImageUrl, index) in taskImagesUrl">
                                <template v-if="index % 2 !== 0"></template>
                                <v-row v-else :key="`${categoryId}:${index}`">
                                  <v-col
                                    cols="6"
                                    v-if="imagesBase64[taskImagesUrl[index]]"
                                    style="height: 250px; margin-bottom: 8px; padding-left: 0"
                                  >
                                    <div class="task-photo-background">
                                      <img class="task-photo" :src="imagesBase64[taskImagesUrl[index]]" />
                                    </div>
                                  </v-col>
                                  <v-col
                                    cols="6"
                                    v-if="imagesBase64[taskImagesUrl[index + 1]]"
                                    style="height: 250px; margin-bottom: 8px; padding-left: 0"
                                  >
                                    <div class="task-photo-background">
                                      <img class="task-photo" :src="imagesBase64[taskImagesUrl[index + 1]]" />
                                    </div>
                                  </v-col>
                                </v-row>
                              </template>
                            </template>
                          </v-sheet>
                        </v-sheet>
                      </v-sheet>
                    </v-sheet>
                  </v-sheet>
                </v-sheet>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td id="spacer"></td>
            </tr>
          </tfoot>
        </table>
        <div class="footer" v-if="!sendingToEmail && demand">
          <tr>
            <td ref="printFooter">
              <v-row class="align-center">
                <v-col cols="3" ref="logo" class="justify-center align-center" fill-height>
                  <div style="width: 150px">
                    <div v-if="!demand.realEstateAgency.proposalInfo?.logo">
                      <Logo />
                    </div>
                    <div v-else>
                      <img :src="demand.realEstateAgency.proposalInfo?.logo.url" style="width: 150px" />
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <v-col v-if="!isPrinting" style="gap: 10px; justify-content: center">
                    <v-row style="gap: 10px" class="my-2 justify-center">
                      <a class="social-media" :href="socialMedias.facebook" target="_blank">
                        <v-icon color="white" x-large> mdi-facebook </v-icon>
                      </a>
                      <a class="social-media" :href="socialMedias.twitter" target="_blank">
                        <v-icon color="white" x-large> mdi-twitter </v-icon>
                      </a>
                      <a class="social-media" :href="socialMedias.instagram" target="_blank">
                        <v-icon color="white" x-large> mdi-instagram </v-icon>
                      </a>
                    </v-row>
                    <v-row style="justify-content: center; margin-top: 20px">
                      <p style="font-weight: bold">
                        {{ socialMedias.contact }}
                      </p></v-row
                    >
                  </v-col>
                </v-col>

                <v-col cols="3"> </v-col>
              </v-row>
            </td>
          </tr>
        </div>
      </div>
    </div>
    <w-alert ref="alert" />
  </div>
</template>

<script>
import LogoImg from '@/assets/logo-workay.png'
import Logo from '@/assets/logo-workay.svg'
import templateFile from '@/assets/template/email/sentReport.txt'
import WAlert from '@/components/WAlert.vue'
import { parseTemplatePlaceholders } from '@/utils/template'
import html2pdf from 'html2pdf.js'
import api from '../../api/services'
import util from '../../config/util'
import WReportDocumentSpentList from '../WReportDocumentSpentList.vue'
import WServiceIcon from '../WServiceIcon.vue'
import { getDoneOrNotDone } from './data/doneOrNotDone'
import { getObservations } from './data/observations'
import { getTasksPhotos } from './data/tasksPhotos'

export default {
  components: { Logo, WServiceIcon, WReportDocumentSpentList, WAlert },

  data() {
    return {
      imagesBase64: [],
      isLoadingImages: false,
      demand: null,
      isPrinting: false,
      sendingToEmail: false,
      reportData: {
        done: {
          tasks: [],
          services: [],
          materials: [],
        },
        notDone: {
          tasks: [],
          services: [],
          materials: [],
        },
        observations: [],
        photos: {},
      },
    }
  },

  computed: {
    socialMedias() {
      const socialMediaObject = {
        facebook: this.demand.realEstateAgency.proposalInfo?.socialMedia?.facebook,
        instagram: this.demand.realEstateAgency.proposalInfo?.socialMedia?.instagram,
        twitter: this.demand.realEstateAgency.proposalInfo?.socialMedia?.twitter,
        contact: this.demand.realEstateAgency.proposalInfo?.socialMedia?.contact,
      }

      return Object.fromEntries(
        Object.entries(socialMediaObject).map(([key, value]) => [
          key,
          key === 'contact' ? value : `//www.${key}.com/` + value,
        ]),
      )
    },
  },

  methods: {
    async renderEmailBody() {
      const resTemplateFile = await fetch(templateFile)
      const template = await resTemplateFile.text()

      const parsedTemplate = parseTemplatePlaceholders(template, {
        'demand-client-name': this.demand.client.name,
      })

      return `<p>${parsedTemplate.trim().replaceAll('\n', '<br>')}</p>`
    },
    async parseImagesToBase64() {
      this.isLoadingImages = true
      const allImages = Object.values(this.reportData.photos)
        .map((reportData) => reportData.taskImagesUrl)
        .flat()

      const imagesBase64 = await Promise.all(
        allImages.map(async (imageUrl) => {
          const base64 = await util.imageToBase64(imageUrl)

          return {
            [imageUrl]: base64,
          }
        }),
      )

      const imagesBase64Dict = imagesBase64.reduce((acc, curr) => ({ ...acc, ...curr }), {})

      this.imagesBase64 = imagesBase64Dict

      this.isLoadingImages = false
      return imagesBase64Dict
    },
    async sendToEmail() {
      this.sendingToEmail = true

      const alert = this.$refs.alert

      const filename =
        `Relatorio ${
          this.demand.currentStatus.id === this.$consts.STATUS.DONE ? 'final de obra' : 'de obra em andamento'
        }` + '.pdf'

      html2pdf()
        .from(this.$refs.printableReport)
        .set({
          filename,
          margin: [35, 0, 35, 0],
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
            letterRendering: false,
            scale: 2,
            useCORS: true,
            unit: 'mm',
            format: 'A4',
          },
          pagebreak: { mode: ['avoid-all'] },
          useCORS: true,

          jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
        })
        .toPdf()
        .get('pdf')
        .then(async (pdf) => {
          try {
            const totalPages = pdf.internal.getNumberOfPages()

            let logo = LogoImg
            let imageWidth = 45
            let imageHeight = 15

            if (this.demand.realEstateAgency.proposalInfo?.logo?.url) {
              const base64Data = (await api.getBase64Image(this.demand.realEstateAgency.proposalInfo?.logo.url)).data
              logo = base64Data.base64
              const maxImageWidth = 50
              const ratio = base64Data.width / base64Data.height
              imageWidth = maxImageWidth
              imageHeight = maxImageWidth / ratio

              if (imageHeight > maxImageWidth) {
                imageHeight = maxImageWidth
                imageWidth = maxImageWidth * ratio
              }
            }

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i)

              pdf.setTextColor('black')
              pdf.setFont(undefined, 'bold')
              pdf.setFontSize(20)

              pdf.text(
                `Relatório ${
                  this.demand.currentStatus.id === this.$consts.STATUS.DONE ? 'final de obra' : 'de obra em andamento'
                }`,
                10,
                25,
              )

              pdf.addImage(logo, 'PNG', pdf.internal.pageSize.getWidth() - 55, 8, imageWidth, imageHeight)

              pdf.addImage(logo, 'PNG', 15, pdf.internal.pageSize.getHeight() - 25, imageWidth, imageHeight)

              pdf.setFontSize(8)
              pdf.setFont(undefined, 'normal')
              pdf.setTextColor('#666666')

              pdf.text(
                `${this.socialMedias.contact} | Página ${i} de ${totalPages}`,
                pdf.internal.pageSize.getWidth() - 65,
                pdf.internal.pageSize.getHeight() - 8,
              )
            }

            const preBlob = util.dataURItoBlob(pdf.output('datauristring'))
            const file = new File([preBlob], filename, { type: 'application/pdf' })

            const url = window.URL.createObjectURL(file)
            const link = document.createElement('a')

            link.href = url
            link.download = filename

            const emailBody = await this.renderEmailBody()

            await api.sendEmail(file, this.demand.client.email, 'Relatório Status de Reforma', emailBody)

            alert.showSuccess('Relatório enviado com sucesso!')

            setTimeout(() => {
              if (confirm('Deseja salvar uma cópia do relatório?', 'Enviar', 'Cancelar')) {
                link.click()
              }
            }, 1500)
          } catch (e) {
            console.error(e)
            alert.showError('Erro ao enviar email')
          } finally {
            this.sendingToEmail = false
          }
        })
        .catch((e) => {
          console.error(e)
        })
    },
    back() {
      const hasHistory = window.history.length > 2
      hasHistory
        ? this.$router.push({
            name: 'Details',
            params: { demandId: parseInt(this.$route.params.demandId) },
          })
        : this.$router.push({ name: 'Summary' })
    },
    printReport() {
      this.isPrinting = true
      this.$nextTick(() => {
        this.$htmlToPaper('printable-report', {
          styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css',
            '../report-print.css',
          ],
        })
        this.isPrinting = false
      })
    },
  },
  async created() {
    this.demand = (await api.getDemand(parseInt(this.$route.params.demandId))).data

    this.reportData.observations = getObservations(this.demand)
    this.reportData.photos = getTasksPhotos(this.demand.services)

    this.reportData.done.tasks = getDoneOrNotDone(this.demand.services, 'task', true)
    this.reportData.notDone.tasks = getDoneOrNotDone(this.demand.services, 'task', false)

    this.reportData.done.services = getDoneOrNotDone(this.demand.services, 'service', true)
    this.reportData.notDone.services = getDoneOrNotDone(this.demand.services, 'service', false)

    this.reportData.done.materials = getDoneOrNotDone(this.demand.services, 'material', true)
    this.reportData.notDone.materials = getDoneOrNotDone(this.demand.services, 'material', false)

    this.parseImagesToBase64()
  },
}
</script>

<style scoped>
* {
  letter-spacing: 0.01px;
}

ul li span {
  margin-left: 8px !important;
}
.social-media {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #60bd6b;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

#printable-report {
  background-color: white;
}

.photos-wrapper {
  page-break-inside: avoid !important;
}

.bold {
  font-weight: bold;
}

.task-photos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.task-container {
  width: calc(33.33% - 24px);
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.task-photo-background {
  background-color: #c4c4c4;
  border-radius: 16px;
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.task-photo {
  max-width: 100%;
  height: 100%;
}
.cls-1 {
  fill: #222;
}
.cls-2 {
  fill: #27ae60;
}
.print {
  padding-top: 10mm;
  padding-left: 10mm;
  padding-right: 10mm;
  width: 100%;
  height: 100%;
}
.print-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5mm;
  font-size: 1.4em;
  font-weight: bold;
}
.services-wrapper {
  width: 100%;
}
.proposal-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}
.service {
  border-bottom: 1px dashed var(--v-primary-lighten2);
  padding-bottom: 4px;
}

.service-col {
  height: 100%;
  flex-direction: row !important;
}

span {
  word-break: break-word;
}

ul li {
  page-break-inside: avoid !important;
}
.row {
  margin: 0;
}
.summary-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5mm 10mm;
}
.summary-item-label {
  font-weight: bold;
}
.client-info {
  margin-bottom: 10mm;
}
.client-info-label {
  font-weight: bold;
  margin-right: 8px;
  padding: 0;
}

.client-info-data {
  font-weight: bold;
}
.section-label {
  font-weight: bold;
  font-size: 16pt;
  padding-bottom: 5mm;
}
.service-row {
  font-weight: bold;
  font-size: 0.875rem !important;
}
.bundle-item {
  color: #757575 !important;
  margin-left: 32px;
}
.summary {
  width: 100%;
}
.primary--text {
  color: #27ae60 !important;
}
.error--text {
  color: #ed7474 !important;
}
.observation-wrapper {
  margin-top: 10mm;
  text-align: justify;
}
.observation-category {
  font-weight: bold;
  margin-bottom: 5mm;
}
.final-image {
  width: 100%;
  margin-top: 100mm;
}
.final-image * {
  width: 100%;
}
.worker-icon {
  height: 24px;
  margin-right: 4px;
}
.icon-container {
  display: inline-flex;
  align-items: center;
  gap: 2px;
}

.social-media {
  width: 50px;
  height: 50px;
  object-fit: fill;
}

.a-img {
  align-items: center;
  display: flex;
}
</style>
