<template>
  <div>
    <p style="font-size: 18px; font-weight: bold; color: black">Dados do Profissional</p>

    <v-text-field label="Nome" disabled :value="association.workPartner.name" />
    <v-text-field label="Categorias de Serviço" disabled :value="serviceCategories" />
    <v-text-field label="Mobilidade" disabled :value="mobility" />
    <v-text-field label="Dados de Pagamento" v-model="form.paymentData" />
    <v-text-field label="Observações" v-model="form.observations" />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {},
  directives: {
    mask,
  },

  data() {
    return {
      form: {
        paymentData: '',
        observations: '',
      },
    }
  },

  props: {
    association: {
      type: Object,
    },
  },

  methods: {
    inputChange(value) {
      this.$emit('input', value)
    },
  },

  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
    serviceCategories() {
      return this.association.workPartner.serviceCategories.map((sc) => sc.name).join(', ')
    },
    mobility() {
      return this.association.workPartner.atuationCities.map((city) => city.name).join(', ')
    },
    associationInfo() {
      return this.association.workPartner.associations.find(
        (association) => association.realEstateAgency.id === this.myRealEstateAgency.id,
      )
    },
  },

  watch: {
    form: {
      handler(val) {
        this.$emit('input', {
          ...this.association,
          paymentData: val.paymentData,
          observations: val.observations,
        })
      },
      deep: true,
    },
  },

  async created() {
    this.form = {
      paymentData: this.associationInfo.paymentData,
      observations: this.associationInfo.observations,
    }
  },
}
</script>

<style scoped>
.darker-label .v-label {
}
.work-partner-info {
  margin-bottom: 36px;
  margin-left: 12px;
  padding-right: 24px;
}
.work-partner-sub-info {
  display: flex;
}

.work-partner-sub-info span:first-child {
  margin-right: 4px;
}

.v-input--selection-controls {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
