<template>
  <w-dialog
    v-model="workPartnerDialog"
    :title="'Novo Prestador de Serviços'"
    :maxWidth="600"
    @input="close"
    :buttons="[
      {
        label: 'fechar',
        action: () => {
          this.close()
        },
      },
      {
        label: 'salvar',
        action: async () => {
          await this.save()
        },
        disabled: !item,
      },
    ]"
  >
    <div style="height: 100%; width: 100%">
      <w-add-work-partner-form :work-partner="item" @input="updateItem" />
    </div>
  </w-dialog>
</template>

<script>
import WAddWorkPartnerForm from '@/components/WAddWorkPartnerForm.vue'
import { mask } from 'vue-the-mask'
import api from '../api/services'
import WDialog from './WDialog.vue'

export default {
  components: {
    WDialog,
    WAddWorkPartnerForm,
  },
  directives: {
    mask,
  },

  data() {
    return {
      workPartnerDialog: false,
      validForm: false,
      item: {
        nationality: 'Brasileira',
      },
      civilStatuses: [
        {
          text: 'Casado/a',
          value: 'MARRIED',
        },
        {
          text: 'Solteiro/a',
          value: 'SINGLE',
        },
        {
          text: 'Divorciado/a',
          value: 'DIVORCED',
        },
        {
          text: 'Viúvo/a',
          value: 'WIDOWED',
        },
        {
          text: 'União Estável',
          value: 'CIVIL_UNION',
        },
      ],
    }
  },

  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: Object,
      default: () => {},
    },
    serviceCategories: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    updateItem(itemObj) {
      this.$set(itemObj.workPartner, itemObj.key, itemObj.value)
    },
    onEdit(model, selectedItem) {
      const isCpf = !!selectedItem.cpf
      this.$set(model, 'cpfOrCnpj', isCpf ? selectedItem.cpf : selectedItem.cnpj)
    },
    async save() {
      try {
        await api.createCrud('work-partner', this.item)
        this.alert.showSuccess('Prestador de serviço cadastrado com sucesso!')
        this.$emit('update:estimate')
        this.close()
      } catch (e) {
        this.alert.showError('Erro ao cadastrar prestador de serviço!')
      }
    },
    close() {
      this.$emit('close')
      this.workPartnerDialog = false
    },
  },

  computed: {
    isValidForm() {
      return this.validForm
    },
  },

  watch: {
    openDialog() {
      this.workPartnerDialog = this.openDialog
    },
  },
}
</script>
