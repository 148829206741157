import { render, staticRenderFns } from "./ChatList.vue?vue&type=template&id=296e8416&scoped=true&"
import script from "./ChatList.vue?vue&type=script&lang=js&"
export * from "./ChatList.vue?vue&type=script&lang=js&"
import style0 from "./ChatList.vue?vue&type=style&index=0&id=296e8416&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296e8416",
  null
  
)

export default component.exports