<template>
  <v-sheet class="transparent parent" style="height: 100%">
    <w-alert ref="alert" />
    <v-sheet class="transparent d-flex justify-center flex-column">
      <v-sheet class="transparent text-left">
        <v-btn text @click="backToDasboard()" class="px-0"><v-icon>mdi-chevron-left</v-icon>Voltar</v-btn>
      </v-sheet>
    </v-sheet>
    <div class="loading mt-4" v-if="!demand">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <v-card v-if="viewAll" class="text-left pb-4 d-flex flex-column" :class="{ 'fill-height': small }">
        <v-card-title class="mb-2" :class="{ 'pt-3': small }">
          <v-sheet class="justify-left d-flex flex-wrap align-center flex-grow-1" style="gap: 4px">
            <p class="demand-title mb-0 mr-4">{{ demand.title }}</p>
            <v-chip
              :style="$vuetify.breakpoint.smAndDown ? 'order:3' : ''"
              small
              color="primary"
              v-if="isAdmin || demand.realEstateAgency?.id !== myRealEstateAgency?.id"
              >{{ demand.realEstateAgency?.name || 'Workay' }}</v-chip
            >
            <v-chip
              :style="$vuetify.breakpoint.smAndDown ? 'order:3' : ''"
              small
              class="bg"
              v-if="!!demand.realEstateType"
              >{{ demand.realEstateType.name }}</v-chip
            >
            <v-chip
              :style="$vuetify.breakpoint.smAndDown ? 'order:4' : ''"
              small
              class="light-blue lighten-5"
              v-if="!!demand.realEstateCategory"
              >{{ demand.realEstateCategory.name }}</v-chip
            >
            <v-btn
              v-if="!small"
              small
              icon
              plain
              @click="updateDemand()"
              :style="$vuetify.breakpoint.smAndDown ? 'order:1' : ''"
              ><v-icon color="primary">mdi-refresh</v-icon></v-btn
            >
            <v-btn
              :style="$vuetify.breakpoint.smAndDown ? 'order:2' : ''"
              text
              plain
              small
              color="error"
              class="caption text-none text-decoration-underline font-weight-medium"
              @click="openCancelDemandDialog()"
              v-if="demand.currentStatus.id === $consts.STATUS.PENDING && !small"
              >Cancelar solicitação</v-btn
            >

            <v-chip
              v-if="small && demand.landingPage"
              small
              class="yellow lighten-1 flex-grow-0"
              style="max-width: 150px"
              :style="$vuetify.breakpoint.smAndDown ? 'order:5' : ''"
              >Landing Page</v-chip
            >
            <v-chip
              v-if="demand.client"
              small
              class="primary lighten-3 fg--text flex-grow-0"
              style="max-width: 150px"
              :style="$vuetify.breakpoint.smAndDown ? 'order:6' : ''"
              >{{ demand.client.juridicalPerson ? 'PJ' : 'PF' }}</v-chip
            >

            <v-chip
              :style="$vuetify.breakpoint.smAndDown ? 'order:7' : ''"
              :dark="[$consts.STATUS.DONE, $consts.STATUS.CANCELLED].includes(demand.currentStatus.id)"
              :class="{ 'ml-auto': !small && $vuetify.breakpoint.mdAndUp }"
              small
              :color="demand.vchip.color"
              >{{ demand.vchip.name }}</v-chip
            >
          </v-sheet>
        </v-card-title>
        <v-card-subtitle>
          <div v-if="small">
            <w-service-icon
              width="24"
              class="mr-4"
              v-for="serviceCategory in demand.serviceCategories"
              :service="serviceCategory"
              :key="`service-category-${serviceCategory.id}`"
            />
          </div>
          <span :class="{ 'subtitle--small': small }">
            {{ demand.description }}
          </span>
        </v-card-subtitle>

        <v-sheet class="d-flex flex-column text-left px-4" :class="{ 'mt-auto': small }">
          <span class="font-weight-light overline" :class="{ 'address-label--small': small }">ENDEREÇO</span>
          <span class="font-weight-regular body-2" :class="{ 'address--small': small }">{{
            `${demand.address}, ${demand.addressNumber}${
              (demand.addressComplement || '').length > 0 ? ', ' + demand.addressComplement : ''
            } - ${demand.district} - ${demand.city.name}, ${demand.city.state.shortName}`
          }}</span>
        </v-sheet>
        <template v-if="!small">
          <v-sheet class="d-flex mt-4 px-4 flex-wrap demand-info transparent">
            <v-sheet class="d-flex flex-column text-left">
              <span class="font-weight-light overline">SOLICITADO EM</span>
              <span class="font-weight-regular body-2">{{
                `${$moment(demand.createdAt).format('DD/MM/YYYY HH:mm')}`
              }}</span>
            </v-sheet>
            <v-sheet class="d-flex flex-column text-left">
              <span class="font-weight-light overline">Previsão de início</span>
              <span class="font-weight-regular body-2">{{
                $moment.utc(demand.estimatedStart).format('DD/MM/YYYY') || '-'
              }}</span>
            </v-sheet>
          </v-sheet>
          <v-sheet class="d-flex my-4 px-4 flex-wrap demand-info transparent">
            <v-sheet class="d-flex flex-column text-left">
              <span class="font-weight-light overline">NOME DO CLIENTE</span>
              <span class="font-weight-regular body-2">{{ demand.client.name }}</span>
            </v-sheet>
            <v-sheet class="d-flex flex-column text-left">
              <span class="font-weight-light overline">EMAIL</span>
              <span class="font-weight-regular body-2">{{ demand.client.email }}</span>
            </v-sheet>
            <v-sheet class="d-flex flex-column text-left">
              <span class="font-weight-light overline">TELEFONE</span>
              <span class="font-weight-regular body-2">{{ demand.client.phone || '-' }}</span>
            </v-sheet>
            <v-sheet class="d-flex flex-column text-left">
              <span class="font-weight-light overline">CPF/CNPJ</span>
              <span class="font-weight-regular body-2">{{ demand.client.cpfCnpj || '-' }}</span>
            </v-sheet>
            <v-sheet class="d-flex flex-column text-left">
              <span class="font-weight-light overline">É PROPRIETÁRIO</span>
              <span class="font-weight-regular body-2 text-center">{{ demand.ownership ? '✔️' : '❌' }}</span>
            </v-sheet>
          </v-sheet>
          <v-sheet class="my-4 px-4" ref="imageSlidesWrapper">
            <vueper-slides
              class="no-shadow mx-10"
              :visible-slides="visibleSlides"
              :slide-ratio="1 / 4"
              :dragging-distance="70"
              :bullets="$vuetify.breakpoint.smAndDown"
              :bullets-outside="$vuetify.breakpoint.smAndDown"
              :touchable="$vuetify.breakpoint.smAndDown"
              fixed-height="150px"
              v-if="demand.documents.length > 0 && imageSlidesWrapperWidth > 0"
              :arrows="$vuetify.breakpoint.mdAndUp"
            >
              <vueper-slide
                v-for="(document, index) in demandImages"
                :key="'image' + index"
                :image="document.url"
                @click.native="openImageDialog(document.url)"
              >
              </vueper-slide>
            </vueper-slides>
            <div class="d-flex mt-8" style="gap: 8px; flex-wrap: wrap">
              <div v-for="(document, index) in demandDocuments" :key="'file' + index">
                <v-chip @click="downloadFile(document)" outlined color="primary" class="demand-document">
                  {{ document.name }}
                  <v-icon size="18">mdi-download</v-icon>
                </v-chip>
              </div>
            </div>
            <v-sheet
              class="d-flex align-left flex-wrap"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-10' : 'mt-8'"
              style="gap: 16px"
            >
              <v-btn @click="showDemandImages()" outlined color="primary" small>
                Adicionar imagens
                <v-icon small class="ml-1">mdi-plus</v-icon>
              </v-btn>
              <v-btn @click="showDemandDocuments()" outlined color="primary" small>
                Adicionar arquivos
                <v-icon small class="ml-1">mdi-plus</v-icon>
              </v-btn>
            </v-sheet>
          </v-sheet>

          <v-sheet class="px-4 d-flex flex-column">
            <v-btn v-if="demand.readyForConclusion" small @click="finishDemand" class="mt-2 align-self-start primary"
              >Finalizar solicitação</v-btn
            >
            <v-sheet class="text-left px-4 mt-4" v-if="demand.currentStatus.observation">
              <span class="overline">{{ demand.currentStatus.observation }}</span></v-sheet
            >
          </v-sheet>
        </template>
      </v-card>
      <w-proposal-card
        v-if="viewAll"
        ref="proposalCard"
        :demand="demand"
        :services="services"
        :materials="materials"
        :serviceCategories="serviceCategories"
        @toggleViewAll="toggleViewAll"
        @update:demand="onProposalCardUpdateDemand"
        @reload:demand="updateDemand"
        @click:newVisit="(service) => $refs.visitDialog.new(service)"
        @closeDeal="startDemand"
      />

      <v-sheet class="mt-4 transparent" v-if="!small && viewAll">
        <w-visit-table
          ref="visit-table"
          :demand="demand"
          :services="services"
          :workPartners="workPartners"
          @update:demand="onProposalCardUpdateDemand"
          @click:newVisit="(service) => $refs.visitDialog.new(service)"
          @click:editVisit="$refs.visitDialog.edit($event)"
          v-if="demand.currentStatus.id !== $consts.STATUS.PENDING"
        />
      </v-sheet>
      <v-sheet class="mt-4 transparent" v-if="!small && viewAll">
        <w-estimate-table
          v-if="demand.services.length > 0 || demand.estimates.length > 0"
          :demand="demand"
          :services="services"
          @update:estimate="updateDemand()"
          ref="estimate-table"
        />
      </v-sheet>
      <v-dialog v-model="cancelDemandDialog" max-width="600px">
        <v-card>
          <v-card-title>Motivo do cancelamento</v-card-title>
          <v-card-text>
            <v-textarea outlined v-model="cancelReason" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="closeCancelDemandDialog()"> Fechar </v-btn>
            <v-btn color="primary" @click="cancelDemand()"> Cancelar solicitação </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="imageDialog" max-width="600px">
        <v-img :src="imageSrc" max-height="100%" contain />
      </v-dialog>
      <w-demand-files
        ref="imagesDialog"
        title="Imagens da solicitação"
        :max-file-size="$consts.MAX_FILE_SIZE.DEMAND"
        :document-type="$consts.DOCUMENT_TYPE.IMAGE"
        @files-count-changed="updateDemand()"
      />
      <w-demand-files
        ref="documentsDialog"
        title="Documentos da solicitação"
        :max-file-size="$consts.MAX_FILE_SIZE.DEMAND"
        :document-type="$consts.DOCUMENT_TYPE.DOCUMENT"
        @files-count-changed="updateDemand()"
      />
      <w-visit-dialog
        :demand="demand"
        ref="visitDialog"
        @showSuccess="$refs.alert.showSuccess($event)"
        @showError="$refs.alert.showError($event)"
        @save="saveVisit"
        @reload="updateDemand()"
      />
      <w-alert ref="alert" />
    </div>
  </v-sheet>
</template>

<script>
import WAlert from '@/components/WAlert.vue'
import WDemandFiles from '@/components/WDemandFiles.vue'
import WEstimateTable from '@/components/WEstimateTable.vue'
import WProposalCard from '@/components/WProposalCard.vue'
import WServiceIcon from '@/components/WServiceIcon.vue'
import WVisitDialog from '@/components/WVisitDialog.vue'
import WVisitTable from '@/components/WVisitTable.vue'
import { VueperSlide, VueperSlides } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import api from '../api/services'

import axios from 'axios'
import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {
    VueperSlides,
    VueperSlide,
    WServiceIcon,
    WDemandFiles,
    WProposalCard,
    WEstimateTable,
    WVisitTable,
    WAlert,
    WVisitDialog,
  },
  props: {
    demandId: {
      type: Number,
    },
    small: {
      type: Boolean,
    },
  },
  data() {
    return {
      demand: null,
      viewAll: true,
      imageDialog: false,
      imageSrc: '',
      newStatus: null,
      cancelDemandDialog: false,
      cancelReason: null,
      dialog: false,
      newServiceCategoriesIdList: [],
      services: [],
      serviceCategories: [],
      imageSlidesWrapperWidth: 0,
      currentDemandService: { observation: '' },
      workPartners: [],
      templateRef: null,
      isPrinting: false,
      materials: [],
    }
  },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
    demandImages() {
      return this.demand.documents?.filter((d) => d.type === this.$consts.DOCUMENT_TYPE.IMAGE) || []
    },
    demandDocuments() {
      return this.demand.documents?.filter((d) => d.type === this.$consts.DOCUMENT_TYPE.DOCUMENT) || []
    },
    endpoint() {
      return process.env.VUE_APP_API_ENDPOINT + '/'
    },
    visibleSlides() {
      let images = this.demand.documents?.filter((d) => d.type === this.$consts.DOCUMENT_TYPE.IMAGE) || []
      return Math.min(images.length, Math.floor(this.imageSlidesWrapperWidth / 200))
    },
    availableServices() {
      return this.services.filter((service) => !this.demand.services.some((s) => s.id === service.id))
    },
    availableServiceCategories() {
      return this.serviceCategories.filter((service) => !this.demand.serviceCategories.some((s) => s.id === service.id))
    },
    demandTotalPrice() {
      return this.demand.services.reduce((a, b) => a + this.getServicePrice(b), 0)
    },
    demandAttachmentCount() {
      return this.demand.documents?.filter((d) => d.type !== this.$consts.DOCUMENT_TYPE.IMAGE).length
    },
  },
  methods: {
    backToDasboard() {
      this.$router.push({ name: 'Summary' })
    },
    async saveVisit() {
      await this.updateDemand()
    },
    async onProposalCardUpdateDemand(demand) {
      const updatedDemand = (await api.updateDemand(demand)).data
      await this.updateDemand(updatedDemand)
      this.$refs.proposalCard.defineProgress()
    },

    toggleViewAll() {
      this.viewAll = !this.viewAll
      this.$emit('togglePrintOption')
    },
    openImageDialog(src) {
      this.imageSrc = src
      this.imageDialog = true
    },
    showDemandDocuments() {
      this.$refs.documentsDialog.open(
        this.demand.id,
        this.demand.documents,
        true,
        this.demand.currentStatus.id !== this.$consts.STATUS.DONE,
      )
      return Promise.resolve()
    },
    showDemandImages() {
      this.$refs.imagesDialog.open(
        this.demand.id,
        this.demand.documents?.filter((d) => d.type === this.$consts.DOCUMENT_TYPE.IMAGE),
        true,
        this.demand.currentStatus.id !== this.$consts.STATUS.DONE,
      )
      return Promise.resolve()
    },
    getNextServiceVisit(serviceId) {
      let svc = this.demand.services.find((s) => s.id === serviceId)
      if (svc?.finished) {
        return null
      }

      let visits = this.demand.visits.filter(
        (v) =>
          v.service.id === serviceId &&
          v.currentStatus.id === this.$consts.STATUS.PENDING &&
          this.$moment(v.datetime).isSameOrAfter(this.$moment(), 'day'),
      )

      if (!visits || visits.length === 0) {
        return null
      }
      return visits.reduce(
        (currentMin, current) =>
          this.$moment(currentMin.datetime).isSameOrBefore(current.datetime, 'minute') ? currentMin : current,
        visits[0],
      )
    },
    async startDemand() {
      let demand = (await api.startDemand(this.demand.id)).data
      this.updateDemand(demand)
    },
    async finishDemand() {
      if (!confirm('Deseja concluir essa solicitação?')) {
        return
      }
      let demand = (await api.finishDemand(this.demand.id)).data
      this.updateDemand(demand)
    },
    handleResize() {
      if (this.$refs.imageSlidesWrapper) {
        this.imageSlidesWrapperWidth = this.$refs.imageSlidesWrapper.$el.clientWidth
      }
    },

    openCancelDemandDialog() {
      this.cancelReason = null
      this.cancelDemandDialog = true
    },
    async cancelDemand() {
      this.demand = (await api.cancelDemand(this.demand.id, this.cancelReason)).data
      this.closeCancelDemandDialog()
    },
    closeCancelDemandDialog() {
      this.cancelReason = null
      this.cancelDemandDialog = false
    },
    async updateDemand(demand) {
      this.demand = demand ?? (await api.getDemand(this.demand.id)).data
      this.$nextTick(() => {
        if (this.$refs['estimate-table']) {
          this.$refs['estimate-table'].refreshData()
        }
        if (this.$refs['visit-table']) {
          this.$refs['visit-table'].refreshData()
        }
      })
    },
    servicesByCategory(serviceCategory) {
      return this.availableServices.filter((service) => service.category.id === serviceCategory.id)
    },
    demandHasServiceInCategory(serviceCategory) {
      return this.demandServicesInCategory(serviceCategory).length > 0
    },
    demandServicesInCategory(serviceCategory) {
      return this.demand.services.filter((demandService) => demandService.serviceCategory.id === serviceCategory.id)
    },
    async downloadFile(document_) {
      const response = await axios.get(document_.url, { responseType: 'arraybuffer' })

      const blob = new Blob([response.data], { type: response.headers['content-type'] })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)

      link.download = document_.name

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
    },
  },
  async created() {
    const [resDemand, resServices, resServiceCategories, resWorkPartners, resMaterials] = await Promise.all([
      api.getDemand(parseInt(this.$route.params.demandId)),
      api.listServices(),
      api.listServiceCategories(),
      api.listWorkPartners(),
      api.listMaterials(),
    ])

    this.demand = resDemand.data
    this.services = resServices.data
    this.serviceCategories = resServiceCategories.data
    this.workPartners = resWorkPartners.data
    this.materials = resMaterials.data
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    const interval = setInterval(() => {
      if (this.$refs.imageSlidesWrapper) {
        this.handleResize()
        clearInterval(interval)
      }
    }, 100)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>
<style scoped>
.demand-title {
  order: 0;
  font-family: Raleway !important;
}
.loading {
  text-align: center;
}

.parent >>> .vueperslides__track-inner {
  gap: 10px;
}

.parent >>> .vueperslide {
  transition: 0.3s ease-in-out;
  background-size: cover;
  cursor: pointer;
  max-width: 150px;
  border-radius: 4px;
}
.subtitle--small {
  line-clamp: true;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0px !important;
  margin-bottom: 16px !important;
  line-height: 18px;
}
.title--small {
  font-size: 12pt;
  font-weight: bold;
  word-break: break-word;
  line-height: 25px;
}
.address--small {
  font-size: 12px !important;
  line-height: 18px !important;
}
.address-label--small {
  font-size: 11px !important;
  line-height: 25px !important;
}
.parent >>> .vueperslides--fixed-height.vueperslides--bullets-outside {
  margin-bottom: 0 !important;
}
.parent >>> .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: 0.8;
}
.parent >>> .v-chip__content {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  height: auto;
}
.demand-info * {
  flex-shrink: 0;
}
.demand-info {
  gap: 32px;
}

.demand-document:hover {
  cursor: pointer;
}

.bundle-group {
  border: 1px dashed var(--v-primary-lighten2);
}
.parent >>> .disabled-row {
  background-color: #eeeeee;
  opacity: 0.35;
}

.parent >>> .vueperslides__bullet {
  margin-top: 0;
  margin-bottom: 0;
}
.parent >>> .vueperslides__bullets {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
