<template>
  <v-row id="box" no-gutters>
    <v-col cols="4" style="height: 100%">
      <user-screen
        :my-chat-user="myChatUser"
        v-model="otherIdToShowChat"
        @currentChat="(chat) => (currentChat = chat)"
        @tabToShowChanged="(newTabToShow) => updateCurrentTab(newTabToShow)"
        :navigate-to-from-outside="currentTab"
        @updateChatUser="getMyChatUser"
      />
    </v-col>
    <v-col cols="8" style="height: 100%">
      <chat-screen
        :otherId="currentChat"
        @navigateTo="
          (tab) => ({
            tabName: updateCurrentTab(tab),
          })
        "
      />
    </v-col>
  </v-row>
</template>

<script>
import services from '@/api/services'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import ChatScreen from './partials/ChatScreen/ChatScreen.vue'
import UserScreen from './partials/UserScreen/UserScreen.vue'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { UserScreen, ChatScreen },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
  },
  methods: {
    updateCurrentTab(navigation) {
      if (navigation) this.currentTab = navigation
    },
    async getMyChatUser() {
      this.myChatUser = (await services.getMyChatUser()).data
    },
  },
  data() {
    return {
      otherIdToShowChat: null,
      currentChat: null,
      myChatUser: null,
      currentTab: null,
    }
  },
  async mounted() {
    await this.getMyChatUser()
  },
}
</script>

<style scoped>
#box {
  height: 100%;
}
</style>
