<template>
  <div>
    <w-crud-table
      ref="crud"
      title="Clientes"
      crud-endpoint="client"
      sort-by="name"
      :headers="headers"
      :can-create="true"
      :can-edit="true"
      :can-delete="true"
      :customActions="[
        {
          action: openNewDemandDialog,
          successMessage: false,
          text: 'Nova solicitação',
          icon: 'mdi-plus',
          key: 'demand',
        },
        {
          action: openEditRealEstateDialog,
          successMessage: false,
          text: 'Endereços',
          icon: 'mdi-map-marker',
          key: 'address',
        },
      ]"
    >
      <template #form="{ item }">
        <v-text-field
          label="Nome"
          v-model="item.name"
          class="required"
          :rules="[$validationRules.required.rule]"
          counter="80"
        />

        <v-text-field
          label="Telefone"
          v-model="item.phone"
          v-mask="['(##) #####-####']"
          class="required"
          :rules="[$validationRules.required.rule, $validationRules.validMobileNumber.rule]"
        />

        <v-text-field label="Email" v-model="item.email" :rules="[$validationRules.email.rule]" counter="80" />

        <v-checkbox label="Pessoa Jurídica" v-model="item.juridicalPerson" @change="clearCpfOrCnpj(item)" />
        <v-text-field
          :label="item.juridicalPerson ? 'CNPJ' : 'CPF'"
          class="required"
          v-mask="item.juridicalPerson ? ['##.###.###/####-##'] : ['###.###.###-##']"
          v-model="item.cpfCnpj"
          :rules="[
            $validationRules.required.rule,
            item.juridicalPerson ? $validationRules.cnpj.rule : $validationRules.cpf.rule,
          ]"
        />
      </template>
    </w-crud-table>

    <w-dialog
      :loading="loadingDialog"
      :title="`Nova solicitaçao de ${currentClient.name}`"
      v-model="showFormDialog"
      :buttons="[
        {
          label: 'fechar',
          action: closeFormDialog,
        },
        {
          label: 'salvar',
          action: addNewDemand,
          disabled: !validForm,
        },
      ]"
      @input="onDialogInput"
    >
      <v-form v-model="validForm" ref="form">
        <v-text-field
          label="Título"
          v-model="newDemand.title"
          class="required"
          :rules="[$validationRules.required.rule]"
          counter="100"
        />
        <v-textarea
          outlined
          label="Descrição"
          v-model="newDemand.description"
          class="required"
          :rules="[$validationRules.required.rule]"
        />

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="parseDate(newDemand.estimatedStart)"
              label="Início do serviço"
              persistent-hint
              prepend-icon="mdi-calendar"
              readonly
              :rules="[$validationRules.required.rule]"
              class="required"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newDemand.estimatedStart"
            locale="pt-br"
            no-title
            @input="menu = false"
          ></v-date-picker>
        </v-menu>

        <v-btn x-small color="primary" @click="toggleSearchRealEstate()">
          <v-icon class="mr-1" small>{{ searchRealEstate ? 'mdi-plus' : 'mdi-magnify' }}</v-icon
          >{{ searchRealEstate ? 'Criar novo endereço' : 'Buscar' }}
        </v-btn>
        <template v-if="searchRealEstate">
          <v-select
            v-model="newDemand.realEstateId"
            label="Imóvel"
            class="required"
            :rules="[$validationRules.required.rule]"
            :items="currentClient.realEstates || []"
            :item-text="
              (realEstate) =>
                `${realEstate.address}, ${realEstate.addressNumber}${
                  realEstate.addressComplement ? ' - ' + realEstate.addressComplement : ''
                } - ${realEstate.district}`
            "
            item-value="id"
          />
        </template>
        <template v-else>
          <v-text-field
            label="CEP"
            v-model="newDemand.zipCode"
            v-mask="'#####-###'"
            class="required"
            :rules="[$validationRules.required.rule]"
            @blur="fillRealEstate(newDemand)"
          />
          <v-text-field
            label="Endereço"
            v-model="newDemand.address"
            counter="255"
            class="required"
            :rules="[$validationRules.required.rule]"
          />
          <v-text-field
            label="Número"
            v-model="newDemand.addressNumber"
            counter="20"
            class="required"
            :rules="[$validationRules.required.rule]"
          />
          <v-text-field label="Complemento" v-model="newDemand.addressComplement" counter="40" />
          <v-text-field
            label="Bairro"
            v-model="newDemand.district"
            counter="100"
            class="required"
            :rules="[$validationRules.required.rule]"
          />

          <v-select
            label="Cidade"
            v-model="newDemand.city"
            :items="cities"
            item-text="name"
            item-value="id"
            class="required"
            :rules="[$validationRules.required.rule]"
            return-object
            disabled
          />

          <v-select
            label="Estado"
            :value="!!newDemand.city ? newDemand.city.state : null"
            :items="states"
            item-text="name"
            item-value="id"
            class="required"
            :rules="[$validationRules.required.rule]"
            return-object
            disabled
          />

          <v-select
            label="Tipo de propriedade"
            v-model="newDemand.realEstateType"
            :items="realEstateTypes"
            item-text="name"
            item-value="id"
            return-object
          />

          <v-select
            label="Categoria de propriedade"
            v-model="newDemand.realEstateCategory"
            :items="realEstateCategories"
            item-text="name"
            item-value="id"
            return-object
          />

          <v-checkbox label="Cliente é proprietário" v-model="newDemand.ownership" />
        </template>
      </v-form>
    </w-dialog>
    <w-dialog
      :loading="loadingDialog"
      :title="`Endereços de ${currentClient.name}`"
      v-model="showRealEstateDialog"
      :buttons="[
        {
          label: 'fechar',
          action: closeFormDialog,
        },
        {
          label: 'excluir',
          action: deleteRealEstate,
          hidden: !currentRealEstate.id,
          color: 'error',
        },
        {
          label: 'salvar',
          action: updateRealEstate,
          disabled: !validForm,
          hidden: !currentRealEstate.id,
        },
      ]"
      @input="onDialogInput"
    >
      <v-form v-model="validForm" ref="addressForm">
        <v-select
          v-model="currentRealEstate"
          label="Imóvel"
          class="required"
          :rules="[$validationRules.required.rule]"
          :items="currentClient.realEstates || []"
          :item-text="
            (realEstate) =>
              `${realEstate.address}, ${realEstate.addressNumber}${
                realEstate.addressComplement ? ' - ' + realEstate.addressComplement : ''
              } - ${realEstate.district}`
          "
          item-value="id"
          return-object
        />
        <template v-if="!!currentRealEstate.id">
          <v-text-field
            label="CEP"
            v-model="currentRealEstate.zipCode"
            v-mask="'#####-###'"
            class="required"
            :rules="[$validationRules.required.rule]"
            @blur="fillRealEstate(currentRealEstate)"
          />
          <v-text-field
            label="Endereço"
            v-model="currentRealEstate.address"
            counter="255"
            class="required"
            :rules="[$validationRules.required.rule]"
          />
          <v-text-field
            label="Número"
            v-model="currentRealEstate.addressNumber"
            counter="20"
            class="required"
            :rules="[$validationRules.required.rule]"
          />
          <v-text-field label="Complemento" v-model="currentRealEstate.addressComplement" counter="40" />
          <v-text-field
            label="Bairro"
            v-model="currentRealEstate.district"
            counter="100"
            class="required"
            :rules="[$validationRules.required.rule]"
          />

          <v-select
            label="Cidade"
            v-model="currentRealEstate.city"
            :items="cities"
            item-text="name"
            item-value="id"
            class="required"
            :rules="[$validationRules.required.rule]"
            return-object
            disabled
          />

          <v-select
            label="Estado"
            :value="!!currentRealEstate.city ? currentRealEstate.city.state : null"
            :items="states"
            item-text="name"
            item-value="id"
            class="required"
            :rules="[$validationRules.required.rule]"
            return-object
            disabled
          />

          <v-select
            label="Tipo de propriedade"
            v-model="currentRealEstate.realEstateType"
            :items="realEstateTypes"
            item-text="name"
            item-value="id"
            return-object
          />

          <v-select
            label="Categoria de propriedade"
            v-model="currentRealEstate.realEstateCategory"
            :items="realEstateCategories"
            item-text="name"
            item-value="id"
            return-object
          />

          <v-checkbox label="Cliente é proprietário" v-model="currentRealEstate.ownership" />
        </template>
      </v-form>
    </w-dialog>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import WCrudTable from '../components/WCrudTable.vue'
import WDialog from '../components/WDialog.vue'

import services from '../api/services'
import store from '../store'

export default {
  directives: { mask },

  components: { WCrudTable, WDialog },
  data() {
    return {
      menu: false,
      searchRealEstate: true,
      realEstateCategories: [{ id: 1, name: 'Residencial' }],
      realEstateTypes: [{ id: 2, name: 'Apartamento' }],
      cities: [],
      states: [],
      currentRealEstate: {},
      loadingDialog: false,
      showFormDialog: false,
      showRealEstateDialog: false,
      validForm: false,
      currentClient: {},
      newDemand: {},
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'CPF/CNPJ', value: 'cpfCnpj' },
        {
          text: 'Pessoa Jurídica',
          value: 'juridicalPerson',
          converter: (item) => (item.juridicalPerson ? '✔️' : '❌'),
        },
      ],
    }
  },
  methods: {
    clearCpfOrCnpj(item) {
      this.$nextTick(() => {
        item.cpfCnpj = ''
      })
    },
    parseDate(date) {
      return this.$moment(date).format('DD/MM/YYYY')
    },
    toggleSearchRealEstate() {
      this.searchRealEstate = !this.searchRealEstate
      this.newDemand.realEstateId = null
    },
    fillRealEstate(item) {
      if (!item.zipCode) {
        return
      }
      this.$viaCep.buscarCep(item.zipCode.replace(/[^0-9]/g, '')).then((data) => {
        this.$set(item, 'district', data.bairro)
        this.$set(item, 'address', data.logradouro)
        this.$set(item, 'addressComplement', data.complemento)
        let city = this.cities.find((c) => `${c.ibgeCode}` === data.ibge)
        this.$set(item, 'city', city)
        this.$set(item, 'state', city.state)
      })
    },
    addNewDemand() {
      this.loadingDialog = true

      const demand = {
        ...this.newDemand,
        client: {
          id: this.currentClient.id,
        },
      }

      services
        .createDemand(demand)
        .then((response) => {
          store.state.globalAlertComponent.showSuccess('Demanda cadastrada com sucesso!')
          this.showFormDialog = false

          this.$router.push({
            name: 'Details',
            params: { demandId: response.data.id },
          })
        })
        .catch((e) => {
          console.error(e)
          store.state.globalAlertComponent.showError('Não foi possível criar a demanda!')
        })
        .finally(() => {
          this.loadingDialog = false
        })
    },
    updateRealEstate() {
      this.loadingDialog = true

      services
        .updateRealEstate(this.currentClient.id, { ...this.currentRealEstate, clientId: this.currentClient.id })
        .then((response) => {
          store.state.globalAlertComponent.showSuccess('Endereço atualizado com sucesso!')
          this.closeFormDialog()
          this.$refs.crud.refreshList()
        })
        .catch((e) => {
          console.error(e)
          store.state.globalAlertComponent.showError('Não foi possível atualizar o endereço!')
        })
        .finally(() => {
          this.loadingDialog = false
        })
    },
    openNewDemandDialog(client) {
      this.showFormDialog = true
      this.currentClient = client
      return Promise.resolve({})
    },
    openEditRealEstateDialog(client) {
      this.currentClient = client
      this.showRealEstateDialog = true
      return Promise.resolve({})
    },
    onDialogInput(open) {
      if (open) {
        return
      }
      this.closeFormDialog()
    },
    closeFormDialog() {
      this.currentClient = {}
      this.currentRealEstate = {}
      this.newDemand = {}
      this.showFormDialog = false
      this.showRealEstateDialog = false
    },
    deleteRealEstate() {
      if (!confirm('Deseja remover o endereço?')) {
        return
      }
      this.loadingDialog = true

      services
        .deleteRealEstate(this.currentClient.id, this.currentRealEstate.id)
        .then((response) => {
          store.state.globalAlertComponent.showSuccess('Endereço removido com sucesso!')
          this.closeFormDialog()
          this.$refs.crud.refreshList()
        })
        .catch((e) => {
          console.error(e)
          store.state.globalAlertComponent.showError('Não foi possível remover o endereço!')
        })
        .finally(() => {
          this.loadingDialog = false
        })
    },
  },
  async created() {
    this.realEstateTypes = (await services.listRealEstateTypes()).data
    this.realEstateCategories = (await services.listRealEstateCategories()).data
    this.cities = (await services.listCities()).data
    this.states = (await services.listStates()).data
  },
}
</script>

<style></style>
