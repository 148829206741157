var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-dialog',{attrs:{"title":'Novo Prestador de Serviços',"maxWidth":600,"buttons":[
    {
      label: 'fechar',
      action: () => {
        this.close()
      },
    },
    {
      label: 'salvar',
      action: async () => {
        await this.save()
      },
      disabled: !_vm.item,
    },
  ]},on:{"input":_vm.close},model:{value:(_vm.workPartnerDialog),callback:function ($$v) {_vm.workPartnerDialog=$$v},expression:"workPartnerDialog"}},[_c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('w-add-work-partner-form',{attrs:{"work-partner":_vm.item},on:{"input":_vm.updateItem}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }