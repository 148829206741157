<template>
  <demands
    @setDemands="setDemands($event)"
    :dashboard-sections="dashboardSections"
    :loading="loading"
    :demands="allDemands(demands)"
    :filtered-demands="filteredDemands"
    @updateDemands="listDemands()"
  />
</template>
<script>
import api from '../api/services'
import Demands from './Demands.vue'

import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { Demands },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),

    dashboardSections() {
      return [
        { list: this.demandsInProgress(this.filteredDemands), title: 'Solicitações em andamento' },
        { list: this.finishedDemands(this.filteredDemands), title: 'Solicitações concluídas' },
      ]
    },
  },
  methods: {
    allDemands(demands) {
      return this.demandsInProgress(demands).concat(this.finishedDemands(demands))
    },
    setDemands(newDemands) {
      this.filteredDemands = newDemands
    },
    demandsInProgress(demands) {
      return demands.filter((demand) => demand.currentStatus.id === this.$consts.STATUS.IN_PROGRESS)
    },
    finishedDemands(demands) {
      return demands.filter((demand) => demand.currentStatus.id === this.$consts.STATUS.DONE && !demand.archived)
    },
    async listDemands() {
      try {
        this.demands = (await api.listCompactDemands(this.myRealEstateAgency?.id, this.isAdmin)).data
        this.filteredDemands = this.demands
      } finally {
        this.loading = false
      }
    },
  },
  data() {
    return {
      demands: [],
      filteredDemands: [],
      loading: true,
    }
  },
  async created() {
    await this.listDemands()
  },
}
</script>
<style scoped>
#new-demand-wrapper {
  height: 60px;
}
.parent {
  gap: 10px;
  height: 100%;
}
#content {
  height: calc(100% - 120px);
  overflow-x: auto;
}
#close-dialog {
  position: absolute;
  right: 32px;
}
</style>
