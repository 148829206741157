<template>
  <v-sheet>
    <div class="section-label">
      <span>{{ title }}</span>
    </div>

    <v-sheet style="padding-left: 16px">
      <v-sheet class="prices-wrapper" v-if="servicesPrice.length">
        <span class="bold" style="font-size: 1.1rem">Serviços</span>
        <ul class="price-container" style="padding-left: 32px">
          <li v-for="(service, index) in servicesPrice" :key="service.price + '-' + index" style="margin-top: 8px">
            {{ `${service.name}` }} -
            <span class="bold price-text">{{ $utils.formatCurrency(service.price) }}</span>
          </li>
        </ul>
      </v-sheet>

      <v-sheet class="prices-wrapper" v-if="servicesPrice.length">
        <span class="bold" style="font-size: 1.1rem">Materiais</span>
        <ul class="materialItem-container" style="padding-left: 32px">
          <li
            v-for="(materialItem, index) in materialItemsPrice"
            :key="materialItem + '-' + index"
            style="margin-top: 8px"
          >
            <span class="bold">({{ materialItem?.quantity }}x)</span> {{ materialItem?.name }} -
            <span class="bold price-text">{{
              $utils.formatCurrency(materialItem?.price * materialItem?.quantity)
            }}</span>
          </li>
        </ul>
      </v-sheet>

      <v-sheet class="report-total-spent" style="margin-top: 16px">
        <span class="bold" style="font-size: 1.05rem"
          >Total de gastos {{ done ? 'realizados' : 'previstos' }}:
          <span class="price-text">{{ $utils.formatCurrency(reportTotalSpent) }}</span>
        </span>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    servicesPrice: {
      type: Array,
      required: true,
    },
    materialItemsPrice: {
      type: Array,
      required: true,
    },
    done: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    reportTotalSpent() {
      const totalServicesPrice = this.servicesPrice.reduce(this.sumReportItemPrice, 0)
      const totalMaterialItemsPrice = this.materialItemsPrice.reduce(this.sumReportItemPrice, 0)

      return totalServicesPrice + totalMaterialItemsPrice
    },
  },

  methods: {
    sumReportItemPrice(acc, curr) {
      if (curr?.quantity) {
        return acc + curr?.price * curr?.quantity
      }
      return acc + curr?.price
    },
  },
}
</script>

<style scoped>
ul li span:first-child {
  margin-left: 8px !important;
}

.section-label {
  font-weight: bold;
  font-size: 16pt;
}

.prices-wrapper {
  margin-top: 5mm;
}

.price-text {
  color: var(--v-primary-base);
}

ul li {
  page-break-inside: avoid !important;
}

.bold {
  font-weight: bold;
}
</style>
