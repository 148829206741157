<template>
  <w-dialog v-model="show" title="Serviços" :buttons="buttons" @input="close" :maxWidth="1200">
    <div class="flex-column fill-height d-flex" style="height: 100%; width: 100%">
      <h5>Selecione os serviços que serão incluídos na proposta:</h5>
      <w-service-picker :services="availableServices" :service-categories="serviceCategories" v-model="selected" />
    </div>
  </w-dialog>
</template>

<script>
import WDialog from './WDialog.vue'
import WServicePicker from './WServicePicker.vue'
export default {
  components: {
    WDialog,
    WServicePicker,
  },
  computed: {
    buttons() {
      return [
        { label: 'fechar', action: this.close },
        { label: 'salvar', action: this.save, disabled: this.selected.length === 0 },
      ]
    },
  },
  data() {
    return {
      show: false,
      selected: [],
      serviceCategories: [],
      availableServices: [],
    }
  },
  methods: {
    open(serviceCategories, availableServices) {
      this.serviceCategories = serviceCategories
      this.availableServices = availableServices
      this.show = true
    },
    close() {
      this.show = false
      this.serviceCategories = []
      this.availableServices = []
      this.selected = []
    },
    save() {
      this.$emit(
        'save',
        this.selected.map((s) => this.newDemandService(s)),
      )
      this.close()
    },
    addNewServices(selectedServices) {
      this.selected = this.availableServices.filter((services) => selectedServices.includes(services.id))
    },
    newDemandService(service) {
      return {
        ...service,
        currentEstimate: { price: service.price, quantity: 1 },
        serviceCategory: service.category,
        id: service.id,
        quantity: 1,
        bundleItems: [],
        tasks: service.tasks.map((task) => ({
          active: true,
          done: false,
          included: false,
          task: {
            id: task.id,
            name: task.name,
            custom: task.custom,
          },
        })),
      }
    },
  },
}
</script>

<style scoped></style>
