<template>
  <div>
    <w-table
      v-if="demand.services.length > 0 || demand.estimates.length > 0"
      class="services-table"
      ref="estimate-crud"
      title="Serviços"
      :load-action="loadAction"
      :create-action="createAction"
      :edit-action="editAction"
      sort-by="lastUpdated"
      :expanded.sync="expanded"
      show-expand
      sort-desc
      :headers="headers"
      :can-create="
        /*TODO ver se poderá ser cirado em algum momento*/ false && demand.currentStatus.id === $consts.STATUS.PENDING
      "
      :can-edit="
        (item) =>
          (item.currentStatus.id === $consts.STATUS.PENDING ||
            item.currentStatus.id === $consts.STATUS.WAITING_PARTNER) &&
          demand.currentStatus.id !== $consts.STATUS.CANCELLED
      "
      :modelGenerator="() => ({ ...newEstimate, timeUnit: 'DAY' })"
      @closeDialog="newEstimate = {}"
      disableItemsPerPage
      @save="$emit('update:estimate', $event)"
      :customActions="[
        {
          action: openCancelEstimateDialog,
          text: 'Cancelar orçamento',
          icon: 'mdi-cancel',
          iconColor: 'error',
          key: 'cancel',
          disabled: (item) =>
            (item.currentStatus.id !== $consts.STATUS.PENDING &&
              item.currentStatus.id !== $consts.STATUS.WAITING_PARTNER) ||
            demand.currentStatus.id === $consts.STATUS.CANCELLED,
        },
        {
          action: openDesignateWorkPartnerDialog,
          text: 'Designar profissional',
          icon: 'mdi-account-hard-hat',
          key: 'worker',
          disabled: (item) => canDesignateWorkPartner(item),
        },
      ]"
      :item-class="(item) => (item.currentStatus.id === $consts.STATUS.CANCELLED ? 'disabled-row' : '')"
    >
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" v-if="includedTasksSize > 0">
          <demand-service-tasks @reload="$emit('update:estimate')" :demandServiceTasks="item.tasks" />
        </td>
      </template>
      <template #form="{ item, editMode }">
        <v-autocomplete
          label="Serviço"
          v-model="item.service"
          :items="editMode ? services : availableServicesForNewEstimate"
          item-text="name"
          item-value="id"
          class="required"
          return-object
          :rules="[$validationRules.required.rule]"
          @change="onChangeSelectedService(item)"
          :disabled="editMode"
        />
        <v-textarea outlined v-model="item.observation" label="Observação" />
        <v-currency-field
          label="Preço"
          v-model="item.price"
          locale="pt-BR"
          prefix="R$"
          :auto-decimal-mode="true"
          :decimal-length="2"
          :value-as-integer="false"
          :allow-negative="false"
          :hint="getServiceHint(item.service)"
          persistent-hint
        />
        <v-currency-field
          label="Dias de trabalho"
          v-model="item.time"
          locale="pt-BR"
          :auto-decimal-mode="true"
          :decimal-length="0"
          :value-as-integer="false"
          :allow-negative="false"
        />
        <v-text-field label="Prazo para orçamento" type="date" v-model="item.dueDate" />
      </template>
      <template #observation="{ item }">
        <span v-html="$utils.convertToHtml(item.observation, 'Sem observações')"></span>
      </template>
      <template #workPartner="{ item }">
        <v-chip v-if="!item.workPartner" small primary class="error justify-center" style="width: 155px"
          >Sem designação</v-chip
        >
        <span v-else>{{ item.workPartner.name }}</span>
      </template>
      <template #status="{ item }">
        <v-chip
          v-if="
            ![$consts.STATUS.PENDING, $consts.STATUS.CANCELLED, $consts.STATUS.REFUSED].includes(item.currentStatus.id)
          "
          small
          :dark="item.currentStatus.id !== $consts.STATUS.PENDING"
          :color="item.currentStatus.hexcolor"
          class="justify-center"
          style="width: 155px"
          >{{ item.currentStatus.name }}</v-chip
        >
        <span v-else>{{ item.currentStatus.name }}</span>
      </template>
    </w-table>
    <v-dialog v-model="cancelEstimateDialog" max-width="600px">
      <v-card>
        <v-card-title>Motivo do cancelamento</v-card-title>
        <v-card-text>
          <v-textarea outlined v-model="cancelReason" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeCancelEstimateDialog()"> Fechar </v-btn>
          <v-btn color="primary" @click="cancelEstimate(currentEstimate)"> Cancelar orçamento </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="designateWorkPartnerDialog" max-width="600px">
      <v-card>
        <v-card-title>Designar profissional</v-card-title>
        <v-card-text>
          <v-autocomplete
            label="Profissional"
            v-model="localWorkPartner"
            :items="availableWorkPartners"
            item-text="name"
            item-value="id"
            class="required"
            return-object
            :rules="[$validationRules.required.rule]"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeDesignateWorkPartnerDialog()"> Fechar </v-btn>
          <v-btn color="primary" @click="openAddWorkPartnerDialog()">Adicionar novo </v-btn>
          <v-btn
            color="primary"
            :disabled="!localWorkPartner"
            @click="designateWorkPartner(currentEstimate, localWorkPartner)"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <w-add-work-partner-dialog
      ref="work-partner-dialog"
      :openDialog="addWorkPartnerDialog"
      @close="addWorkPartnerDialog = false"
      @update:estimate="updateEstimate"
      :alert="this.$refs.alert"
    />
    <w-alert ref="alert" />
  </div>
</template>

<script>
import WAlert from '@/components/WAlert.vue'
import { STATUS } from '@/config/constants'
import api from '../api/services'
import DemandServiceTasks from './DemandServiceTasks.vue'
import WAddWorkPartnerDialog from './WAddWorkPartnerDialog.vue'
import WTable from './WTable.vue'

import { createNamespacedHelpers, mapGetters } from 'vuex'
createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { WTable, DemandServiceTasks, WAddWorkPartnerDialog, WAlert },
  props: {
    demand: Object,
    services: Array,
  },
  data() {
    return {
      availableWorkPartners: [],
      addWorkPartnerDialog: false,
      expanded: [],
      localWorkPartner: null,
      currentEstimate: {},
      cancelReason: null,
      cancelEstimateDialog: false,
      designateWorkPartnerDialog: false,
      newEstimate: {},
      headers: [
        { text: 'Serviço', value: 'service.name' },
        {
          text: 'Observação',
          value: 'observation',
        },
        {
          text: 'Preço',
          value: 'price',
          converter: (item) => this.$utils.formatCurrency(item.price),
        },
        {
          text: 'Tempo de execução',
          value: 'time',
          converter: (item) =>
            item.time
              ? `${item.time} (${this.$consts.TIME_UNIT[item.timeUnit]})`.toLowerCase()
              : 'Duração não cadastrada',
        },
        {
          text: 'Prazo para orçamento',
          value: 'dueDate',
          converter: (item) =>
            item.dueDate ? this.$moment(item.dueDate).format('DD/MM/YYYY') : 'Prazo não cadastrado',
        },
        { text: 'Profissional', value: 'workPartner' },
        { text: 'Status', value: 'status' },
        { text: 'Observação do status', value: 'currentStatus.observation' },
        {
          text: 'Última atualização',
          value: 'lastUpdated',
          converter: (item) => this.$moment(item.lastUpdated).format('DD/MM/YYYY HH:mm'),
        },
        { text: '', value: 'data-table-expand' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
    }),
    includedTasksSize() {
      return this.demand.services.reduce((acc, service) => {
        return acc + service.tasks.filter((task) => task.included).length
      }, 0)
    },
    availableServicesForNewEstimate() {
      return this.services.filter((service) =>
        this.demand.services.some(
          (s) =>
            s.id === service.id &&
            (!s.currentEstimate || s.currentEstimate.currentStatus.id === this.$consts.STATUS.CANCELLED),
        ),
      )
    },
  },
  methods: {
    async updateEstimate() {
      this.$emit('update:estimate', (await api.updateEstimate(this.currentEstimate)).data)
    },
    openAddWorkPartnerDialog() {
      this.closeDesignateWorkPartnerDialog()
      this.addWorkPartnerDialog = !this.addWorkPartnerDialog
    },
    loadAction() {
      let demandEstimates = { data: this.demand?.estimates || [] }

      this.expanded = demandEstimates.data.filter((data) => data.currentStatus.id === STATUS.IN_PROGRESS)

      return Promise.resolve(demandEstimates)
    },
    createAction(item) {
      return api.addEstimate(this.demand.id, item).then(async (response) => {
        this.$emit('update:estimate', response.data)
        return response
      })
    },
    editAction(item) {
      return api.updateEstimate(item).then(async (response) => {
        this.$emit('update:estimate', response.data)
        return response
      })
    },

    onChangeSelectedService(estimate) {
      this.$nextTick(async () => this.fillEstimateWithServiceDetails(estimate))
    },

    fillEstimateWithServiceDetails(estimate) {
      let s = this.demand.services.find((s) => s.id === estimate.service.id)
      this.$set(estimate, 'price', s.price)
      this.$set(estimate, 'observation', this.demand.description)
      this.$set(estimate, 'time', s.time)
      this.$set(estimate, 'timeUnit', s.timeUnit)
      return estimate
    },
    async listAvailableWorkPartners(service) {
      if (!service) {
        return []
      }
      let serviceCategory = this.demand.services.find((s) => s.id === service.id).serviceCategory
      const workPartners = (await api.listWorkPartners()).data

      const filteredsByServiceCategory = workPartners.filter((partner) =>
        partner.serviceCategories.some((s) => s.id === serviceCategory.id),
      )
      const filteredByRealEstateAgency = filteredsByServiceCategory.filter((partner) =>
        partner.associations.some((association) => association.realEstateAgency.id === this.myRealEstateAgency.id),
      )

      return filteredByRealEstateAgency
    },
    getServiceHint(itemService) {
      if (!itemService) {
        return ''
      }
      let service = this.demand.services.find((s) => s.id === itemService.id)
      return `${this.$utils.formatCurrency(service.price || '')} (${service.priceUnit})`
    },
    openCancelEstimateDialog(estimate) {
      this.currentEstimate = estimate
      this.cancelReason = null
      this.cancelEstimateDialog = true
      return Promise.resolve({})
    },
    async cancelEstimate(oldEstimate) {
      this.$emit('update:estimate', (await api.cancelEstimate(oldEstimate.id, this.cancelReason)).data)
      this.closeCancelEstimateDialog()
      this.$nextTick(() => {
        if (this.$refs['estimate-crud']) {
          this.$refs['estimate-crud'].refreshList()
        }
      })
    },
    closeCancelEstimateDialog() {
      this.currentEstimate = {}
      this.cancelReason = null
      this.cancelEstimateDialog = false
    },
    async openDesignateWorkPartnerDialog(estimate) {
      this.currentEstimate = estimate
      this.availableWorkPartners = await this.listAvailableWorkPartners(this.currentEstimate?.service)
      this.designateWorkPartnerDialog = true
      return Promise.resolve({})
    },
    async designateWorkPartner(estimate, workPartner) {
      estimate.workPartner = workPartner
      this.$emit('update:estimate', (await api.updateEstimate(estimate)).data)
      this.closeDesignateWorkPartnerDialog()
      this.$nextTick(() => {
        if (this.$refs['estimate-crud']) {
          this.$refs['estimate-crud'].refreshList()
        }
      })
    },
    closeDesignateWorkPartnerDialog() {
      this.localWorkPartner = {}
      this.designateWorkPartnerDialog = false
    },
    refreshData() {
      this.$nextTick(() => {
        if (this.$refs['estimate-crud']) {
          this.$refs['estimate-crud'].refreshList()
        }
      })
    },
    canDesignateWorkPartner(estimate) {
      let service = this.demand.services.find((s) => s.currentEstimate.id === estimate.id)

      return (
        estimate.currentStatus.id !== this.$consts.STATUS.PENDING ||
        (service?.bundle && service?.bundleItems.length !== service?.bundleSize)
      )
    },
  },
}
</script>

<style scoped>
.services-table >>> tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
