<template>
  <div id="no-chat-wrapper" class="d-flex flex-column">
    <v-icon color="primary" :size="56">mdi-message-text</v-icon>
    <p id="text-1">Você ainda não tem nenhum chat com Parceiro Prestador de Serviços</p>
    <p id="text-2">
      Clique em novo chat, ao lado do nome da imobiliária, selecione o profissional e inicie uma conversa
    </p>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {},

  data() {
    return {}
  },
  async created() {},
}
</script>
<style scoped>
#no-chat-wrapper {
  height: 100%;
  justify-content: center;
  position: relative;
  padding: 25%;
}

p {
  text-align: center;
}

#text-1 {
  font-size: 26px;
  font-weight: 700;
}

#text-2 {
  font-style: italic;
}
</style>
