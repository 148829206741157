<template>
  <div :title="!showText && service.name" class="icon-container">
    <component :is="icon" v-bind="$attrs" :style="{ height: size + 'px' }" />
    <span v-if="showText" class="primary--text ml-1" :class="bold ? 'font-weight-bold' : 'font-weight-medium'">{{
      service.name
    }}</span>
  </div>
</template>

<script>
import Alvenaria from '../assets/serviceCategoryIcons/alvenaria-hc.svg'
import Arquitetura from '../assets/serviceCategoryIcons/arquitetura-hc.svg'
import Automacao from '../assets/serviceCategoryIcons/automacao-hc.svg'
import Eletrica from '../assets/serviceCategoryIcons/eletrica-hc.svg'
import Gasista from '../assets/serviceCategoryIcons/gasista-hc.svg'
import Hidraulica from '../assets/serviceCategoryIcons/hidraulica-hc.svg'
import Marcenaria from '../assets/serviceCategoryIcons/marcenaria-hc.svg'
import Pintura from '../assets/serviceCategoryIcons/pintura-hc.svg'
import Reformas from '../assets/serviceCategoryIcons/reformas-hc.svg'
import Refrigeracao from '../assets/serviceCategoryIcons/refrigeracao-hc.svg'
import Serralheria from '../assets/serviceCategoryIcons/serralheria-hc.svg'
export default {
  components: {
    Alvenaria,
    Arquitetura,
    Automacao,
    Eletrica,
    Hidraulica,
    Marcenaria,
    Pintura,
    Reformas,
    Refrigeracao,
    Serralheria,
    Gasista,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    showText: Boolean,
    bold: Boolean,
    size: Number,
  },
  computed: {
    icon() {
      const id = this.service.id

      switch (id) {
        case 1:
          return Eletrica
        case 2:
          return Pintura
        case 3:
          return Hidraulica
        case 4:
          return Automacao
        case 5:
          return Alvenaria
        case 6:
          return Marcenaria
        case 7:
          return Refrigeracao
        case 8:
          return Arquitetura
        case 9:
          return Serralheria
        case 10:
          return Reformas
        case 11:
          return Gasista
      }
      return Reformas // TODO substituir pelo ícone genérico quando este for criado
    },
  },
}
</script>

<style scoped>
.icon-container {
  display: inline-flex;
  align-items: center;
  gap: 2px;
}
</style>
