<template>
  <div class="box">
    <w-file-drop v-model="data" :max-file-size="$consts.MAX_FILE_SIZE.DEMAND" :accept="accept" />
  </div>
</template>

<script>
import WFileDrop from '@/components/WFileDrop.vue'
export default {
  components: { WFileDrop },
  props: {
    value: {
      type: Object,
    },
  },

  computed: {
    accept() {
      return ['jpg', 'png', 'jpeg']
    },
    data: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {},
}
</script>

<style scoped>
.box {
  position: relative;
}
</style>
