<template>
  <div
    id="header-wrapper"
    class="d-flex align-center"
    @click="
      $emit('navigateTo', {
        tabName: $consts.CHAT_NAVIGATION_TAB.PROFILE + '-' + $consts.CHAT_PROFILE_TYPE.WORKPARTNER,
        data: headerData,
      })
    "
  >
    <div v-if="headerData" class="d-flex align-center" style="gap: 12px">
      <div id="user-photo" :style="{ backgroundColor: !headerData.photo ? 'gray' : 'white' }" class="d-flex">
        <v-avatar>
          <img v-if="headerData.photo" :src="headerData.photo" />
          <v-icon size="40" color="white" v-else>mdi-account</v-icon>
        </v-avatar>
      </div>
      <p id="user-name" class="mb-0">{{ headerData?.name }}</p>
    </div>

    <v-skeleton-loader v-else type="list-item-avatar" style="width: 50%"></v-skeleton-loader>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {},

  props: {
    headerData: {
      typeo: Object,
    },
  },
}
</script>

<style scoped>
#header-wrapper {
  min-height: 80px !important;
  background-color: #f2f4f7;
  padding: 10px 16px;
}

#header-wrapper:hover {
  cursor: pointer;
}

#user-photo,
img {
  object-fit: contain;
  border-radius: 50%;
}

#user-name {
  font-weight: 600;
  font-size: 18;
  color: #6d6e71;
}

:deep(.v-skeleton-loader__list-item-avatar.v-skeleton-loader__bone) {
  background-color: transparent;
}
</style>
