<template>
  <div>
    <w-table
      :is-basic="true"
      ref="table"
      title="Profissionais Associados"
      :load-action="loadAction"
      :custom-actions="customActions"
      dialogNewItem="Adicionar profissional"
      dialogEditItem="Editar profissional"
      :maxDialogWidth="640"
      sort-by="name"
      :headers="headers"
      :can-create="false"
      :can-edit="true"
      :can-delete="isAdmin"
      class="overflow-x-auto"
      :model-generator="() => ({ nationality: 'Brasileira' })"
    >
      <template #indicatedBy="{ item }">
        <v-chip v-if="item.workPartner.indicatedBy" color="#F3EA8D">{{ item.workPartner.indicatedBy.name }}</v-chip>
        <v-chip v-else color="#97C89C">Sem indicação</v-chip>
      </template>
      <template #rating="{ item }">
        <span v-if="item.workPartner.rating > 0">{{ item.workPartner.rating }} de 5.0</span>
        <span v-else style="color: var(--v-primary-base)">Novo!</span>
      </template>
    </w-table>

    <v-btn class="WYellow" @click="toggleDialog('add')" style="position: absolute; bottom: 20px; right: 20px"
      ><v-icon class="mr-2">mdi-plus</v-icon
      ><span class="text-none black--text font-weight-bold">Adicionar novo profissional</span></v-btn
    >
    <w-alert ref="alert" />
    <w-associated-work-partner-dialog
      :display="displayDialog"
      @close="(type) => toggleDialog(type)"
      :alert="this.$refs.alert"
      @update="() => this.$refs.table.refreshList()"
      :association="itemToEdit"
      :type="formType"
    />
  </div>
</template>

<script>
import WAlert from '@/components/WAlert.vue'
import WAssociatedWorkPartnerDialog from '@/components/WAssociatedWorkPartnerDialog.vue'
import { mask } from 'vue-the-mask'
import WTable from '../components/WTable.vue'

import { createNamespacedHelpers, mapGetters } from 'vuex'
import services from '../api/services'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { WAlert, WTable, WAssociatedWorkPartnerDialog },
  directives: { mask },

  methods: {
    async loadAction() {
      return Promise.resolve(await services.getAssociates(this.myRealEstateAgency.id))
    },
    async deleteAction(associate) {
      return Promise.resolve(await services.deleteAssociate(associate.id))
    },
    toggleDialog(type, itemToEdit) {
      this.itemToEdit = itemToEdit

      this.displayDialog = !this.displayDialog
      this.formType = type
    },
    updateItem(item) {
      this.itemToEdit = item
    },
  },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
  },
  data() {
    return {
      formType: 'add',
      displayDialog: false,
      itemToEdit: {},
      headers: [
        { text: 'Profissional', value: 'workPartner.name' },
        {
          text: 'Indicação',
          value: 'indicatedBy',
        },
        {
          text: 'Cidades',
          value: 'atuationCities',
          sortable: false,
          converter: (item) => item.workPartner.atuationCities.map((atuationCity) => atuationCity.name).join(', '),
        },
        {
          text: 'Serviços',
          value: 'serviceCategories',
          sortable: false,
          converter: (item) =>
            item.workPartner.serviceCategories.map((serviceCategory) => serviceCategory.name).join(', '),
        },
        {
          text: 'Avaliação',
          value: 'rating',
        },
        {
          text: 'Dados de Pagamento',
          value: 'paymentData',
        },
        {
          text: 'Observações',
          value: 'observations',
        },
      ],
      customActions: [
        {
          icon: 'mdi-pencil',
          color: 'primary',
          tooltip: 'Editar',
          action: (item) => {
            return new Promise((resolve) => {
              this.toggleDialog('edit', item)
              resolve({})
            })
          },
        },
        {
          icon: 'mdi-delete',
          color: 'primary',
          tooltip: 'Excluir',
          action: (item) => {
            return new Promise(async (resolve) => {
              await this.deleteAction(item)
              await this.$refs.table.refreshList()
              resolve({})
            })
          },
        },
      ],
    }
  },
  async created() {},
}
</script>

<style></style>
