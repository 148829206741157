const config = {
  development: {
    apiKey: 'AIzaSyBwiGxw_kjCFkqyoVhSO7PMI2wEnDlxfvo',
    authDomain: 'workay-qa.firebaseapp.com',
    projectId: 'workay-qa',
    storageBucket: 'workay-qa.appspot.com',
    messagingSenderId: '66365747688',
    appId: '1:66365747688:web:44f414f47f78565867345d',
  },
  production: {
    apiKey: 'AIzaSyDFpswaFGhUusWuKTuJjDF2SoP9uOZYpYs',
    authDomain: 'auth.workay.com.br',
    projectId: 'workay-9c608',
    storageBucket: 'workay-9c608.appspot.com',
    messagingSenderId: '583184429578',
    appId: '1:583184429578:ios:4b1d9f7b723f22b3e525f3',
    measurementId: 'G-1FPE8HL5J7',
    databaseURL: 'https://workay-9c608-default-rtdb.firebaseio.com',
  },

}
const firebaseConfig = config[process.env.VUE_APP_ENVIRONMENT]

export { firebaseConfig }
