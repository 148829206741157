<template>
  <v-sheet v-if="dashboardData" class="d-flex flex-column wrapper px-12 py-8" id="box">
    <h2 class="text-left">Boas vindas, {{ myRealEstateAgency?.name || user.name }}</h2>

    <v-row style="gap: 40px" class="d-flex mt-12">
      <v-col class="d-flex flex-column pa-0" cols="3" style="gap: 16px">
        <v-row class="ma-0" style="flex-grow: 0">
          <title-data-display
            tip="Quantidade de serviços que a sua imobiliária realizou e que foram finalizados no CRM"
            title="Total de serviços realizados"
            column
            >{{ dashboardData.totalFinishServices }}</title-data-display
          >
        </v-row>

        <v-row class="ma-0">
          <title-data-display title="Serviços realizados por categoria" column>
            <services-by-category :chart-data="dashboardData.servicesByCategory" />
          </title-data-display>
        </v-row>
      </v-col>

      <v-col class="d-flex flex-column pa-0" cols="8" style="gap: 16px">
        <div class="d-flex" style="gap: 40px; justify-content: flex-start; flex-direction: row">
          <div class="ma-0" style="width: calc(50% - 8px)">
            <title-data-display title="Serviços por bairros" column>
              <services-by-district :chart-data="dashboardData.servicesByDistrict" />
            </title-data-display>
          </div>

          <div class="d-flex flex-column" style="gap: 16px; width: calc(50% - 8px)">
            <title-data-display tip="São todas as ordens de serviço em aberto na plataforma" title="Serviços ativos">{{
              dashboardData.totalActiveServices
            }}</title-data-display>
            <title-data-display
              tip="São contratos fechados que já tem parceiros designados e estão esperando o agendamento da visita ou a conclusão da prestação de serviços"
              title="Serviços em andamento"
              >{{ dashboardData.totalServicesInProgress }}</title-data-display
            >
            <title-data-display
              tip="São serviços que foram aceitos pelo prestador de serviços e pelo cliente e estão esperando agendamento de visita"
              title="Contratos fechados"
              >{{ dashboardData.totalClosedContracts }}</title-data-display
            >
            <title-data-display
              tip="São ordens de serviço que estão esperando o aceite do prestador de serviços para prosseguir"
              title="Serviços aguardando parceiro"
              >{{ dashboardData.totalServicesWaitingPartner }}</title-data-display
            >
          </div>
        </div>

        <div class="d-flex flex-column" style="gap: 16px; height: calc(100vh / 2 - 48px - 36px - 10px)">
          <title-data-display title="Evolução dos serviços realizados por mês" column>
            <services-by-month :chart-data="dashboardData.servicesByMonth" />
          </title-data-display>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import TitleDataDisplay from '@/components/TitleDataDisplay.vue'
import firebase from 'firebase/app'
import { mask } from 'vue-the-mask'
import api from '../../api/services'
import ServicesByCategory from './partials/ServicesByCategory.vue'
import ServicesByDistrict from './partials/ServicesByDistrict.vue'
import ServicesByMonth from './partials/ServicesByMonth.vue'

import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {
    TitleDataDisplay,
    ServicesByDistrict,
    ServicesByMonth,
    ServicesByCategory,
  },

  directives: { mask },
  data() {
    return {
      user: null,
      dashboardData: null,
      demandServices: [],
      demands: [],
      filters: [],
    }
  },
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
    }),
  },
  async created() {
    const currentUser = firebase.auth().currentUser
    const user = (await api.getUserByEmail(currentUser.email)).data
    this.user = user

    this.dashboardData = (await api.getMyDashboard()).data
  },
}
</script>

<style scoped>
div {
  margin: 0 !important;
}
@media only screen and (max-width: 768px) {
  div {
    width: 100% !important;
    flex-direction: column !important;
    max-width: 100%;
  }

  #box {
    padding: 16px !important;
  }

  .col,
  .row {
    flex: 1;
    flex-direction: row;
  }
}
</style>
