<template>
  <div
    class="box pa-6 d-flex"
    :class="column ? 'flex-column flex-start' : 'flex-row space-between'"
    style="position: relative"
  >
    <v-tooltip bottom color="white" class="tooltip" v-if="tip">
      <template v-slot:activator="{ on, attrs }">
        <v-icon dense class="tip" color="primary" v-bind="attrs" v-on="on">mdi-information</v-icon>
      </template>
      <p class="tooltip-text-content">{{ tip }}</p>
    </v-tooltip>

    <div class="normal-text" :class="{ 'text-center': column }">
      <span style="font-weight: bold">{{ title }}</span>
    </div>

    <div
      class="text"
      :style="column ? 'width: 100%' : ''"
      :class="column ? 'mt-4 big-text text-center' : 'd-flex medium-text align-center justify-end'"
    >
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    column: {
      type: Boolean,
      default: false,
    },
    tip: {
      type: String,
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
<style scoped>
.box {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  justify-content: flex-start;
  align-items: center;
}

.v-tooltip__content {
  border: 1px solid var(--v-primary-base) !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  max-width: 300px;
}

.tooltip-text-content {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  text-align: center !important;
}

.tip {
  position: absolute;
  margin: 8px;
  top: 0;
  right: 0;
}

.flex-start {
  justify-content: flex-start;
}

.space-between {
  justify-content: space-between;
}

.margin {
  margin-top: 16px;
}

.text {
  font-weight: bold;
  height: 100%;
}

.big-text {
  font-size: 32px;
}

.align-center {
  align-items: center;
}
.normal-text {
  font-size: 16px;
}

.medium-text {
  font-size: 32px;
  margin-left: 12px;
}
</style>
