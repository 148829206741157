import * as constants from '@/config/constants'
import formatters from '@/config/formatters'
import util from '@/config/util'
import validation from '@/config/validation-rules'
import firebase from 'firebase/app'
import 'firebase/auth'
import VCurrencyField from 'v-currency-field'
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
import ViaCep from 'vue-viacep'
import { VTextField } from 'vuetify/lib' //Globally import VTextField
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import moment from 'moment'
import './assets/css/main.css'
import './assets/css/scrollbar.css'
import { firebaseConfig } from './config/FirebaseConfig'
moment.locale('pt-br')

const htmlToPaperOptions = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    'print.css',
    'report-print.css',
  ],
}

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField)
Vue.use(ViaCep)
Vue.use(VueHtmlToPaper, htmlToPaperOptions)

Vue.config.productionTip = false
Vue.prototype.$consts = constants
Vue.prototype.$utils = util
Vue.prototype.$validationRules = validation
Vue.prototype.$moment = moment
Vue.prototype.$formatters = formatters

let app = null
const firebaseApp = firebase.initializeApp(firebaseConfig)

firebaseApp.auth().onAuthStateChanged(async () => {
  if (!app) {
    Vue.prototype.$auth = firebase.auth()
    app = new Vue({
      router,
      store,
      vuetify,
      data: { signedIn: !!firebase.auth().currentUser, firebaseInstance: firebaseApp },
      render: (h) => h(App),
    }).$mount('#app')
  }
  app.$root.signedIn = !!firebase.auth().currentUser
  app.$root.firebaseInstance = firebaseApp
})
