<template>
  <div>
    <v-text-field
      label="Nome"
      :value="workPartner.name"
      @input="updateValue('name', $event)"
      class="required"
      :rules="[$validationRules.required.rule]"
      counter="120"
    />

    <v-select
      label="Categoria de serviço"
      multiple
      :value="workPartner.serviceCategories"
      @change="updateValue('serviceCategories', $event)"
      :items="serviceCategories"
      item-text="name"
      item-value="id"
      class="required"
      :rules="[$validationRules.required.rule]"
      return-object
    />

    <v-text-field
      label="Celular"
      :value="workPartner.mobile"
      @input="updateValue('mobile', $event)"
      v-mask="['(##) #####-####']"
      class="required"
      :rules="[$validationRules.required.rule, $validationRules.validMobileNumber.rule]"
    />

    <v-text-field
      label="Email"
      :value="workPartner.email"
      @input="updateValue('email', $event)"
      :rules="[$validationRules.email.rule]"
      counter="80"
    />
    <v-text-field
      label="CPF ou CNPJ"
      v-mask="['###.###.###-##', '##.###.###/####-##']"
      @input="
        {
          checkCpforCnpj($event), updateValue('cpfOrCnpj', $event)
        }
      "
      :value="workPartner.cpfOrCnpj"
      :rules="[$validationRules.cpfCnpj.rule]"
    />

    <v-text-field label="Telefone" :value="workPartner.phone" @input="updateValue('phone', $event)" counter="30" />
    <v-text-field
      label="Data de nascimento"
      type="date"
      :value="workPartner.birthday"
      @input="updateValue('birthday', $event)"
    />
    <v-select
      label="Estado civil"
      :value="workPartner.civilStatus"
      @input="updateValue('civilStatus', $event)"
      :items="civilStatuses"
      class="required"
      :rules="[$validationRules.required.rule]"
      item-text="text"
      item-value="value"
    />
    <v-checkbox label="Possui filhos" :value="workPartner.children" @change="updateValue('children', $event)" />
    <v-text-field
      label="Nacionalidade"
      :value="workPartner.nationality"
      @input="updateValue('nationality', $event)"
      counter="100"
      class="required"
      :rules="[$validationRules.required.rule]"
    />
    <v-checkbox label="É autônomo" :value="workPartner.autonomy" @change="updateValue('autonomy', $event)" />
    <v-checkbox
      label="Possui plano de saúde"
      :value="workPartner.healthInsurance"
      @change="updateValue('healthInsurance', $event)"
    />
    <v-select
      label="Escolaridade"
      class="required"
      :items="educationalLevels"
      :value="workPartner.educationalLevel"
      @change="updateValue('educationalLevel', $event)"
      item-text="text"
      item-value="value"
      :rules="[$validationRules.required.rule]"
    />

    <v-autocomplete
      label="Estado"
      :items="states"
      v-model="selectedStateId"
      @change="getCities()"
      item-text="name"
      item-value="id"
    />
    <v-autocomplete
      label="Cidades"
      class="required"
      :items="cities.length > 0 ? cities : workPartner.atuationCities"
      :value="workPartner.atuationCities"
      multiple
      @change="updateValue('atuationCities', $event)"
      item-text="name"
      :item-value="(item) => item"
      :rules="[$validationRules.required.rule]"
    />
    <div class="d-flex my-2" style="gap: 16px">
      <v-chip @click:close="removeCity(city)" close v-for="city in workPartner.atuationCities" :key="city.id">{{
        city.name
      }}</v-chip>
    </div>
    <v-textarea
      class="mt-8"
      outlined
      label="Mobilidade"
      :value="workPartner.transportation"
      @change="updateValue('transportation', $event)"
      counter="255"
    />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import api from '../api/services'

import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {},
  directives: {
    mask,
  },

  data() {
    return {
      workPartnerDialog: false,
      selectedStateId: null,
      states: [],
      cities: [],

      civilStatuses: [
        {
          text: 'Casado/a',
          value: 'MARRIED',
        },
        {
          text: 'Solteiro/a',
          value: 'SINGLE',
        },
        {
          text: 'Divorciado/a',
          value: 'DIVORCED',
        },
        {
          text: 'Viúvo/a',
          value: 'WIDOWED',
        },
        {
          text: 'União Estável',
          value: 'CIVIL_UNION',
        },
      ],
      educationalLevels: [
        {
          text: 'Analfabeto',
          value: 'ILLITERATE',
        },
        {
          text: 'Ensino Fundamental Incompleto',
          value: 'INCOMPLETE_ELEMENTARY_SCHOOL',
        },
        {
          text: 'Ensino Fundamental Completo',
          value: 'COMPLETE_ELEMENTARY_SCHOOL',
        },
        {
          text: 'Ensino Médio Incompleto',
          value: 'INCOMPLETE_HIGH_SCHOOL',
        },
        {
          text: 'Ensino Médio Completo',
          value: 'COMPLETE_HIGH_SCHOOL',
        },
        {
          text: 'Ensino Superior Incompleto',
          value: 'INCOMPLETE_HIGHER_EDUCATION',
        },
        {
          text: 'Ensino Superior Completo',
          value: 'COMPLETE_HIGHER_EDUCATION',
        },
        {
          text: 'Pós-Graduação Incompleto',
          value: 'INCOMPLETE_POSTGRADUATION',
        },
        {
          text: 'Pós-Graduação Completo',
          value: 'COMPLETE_POSTGRADUATION',
        },
      ],
      serviceCategories: [],
    }
  },

  props: {
    workPartner: {
      type: Object,
      default: () => ({
        nationality: 'Brasileira',
      }),
    },
  },

  methods: {
    removeCity(city) {
      this.updateValue(
        'atuationCities',
        this.workPartner.atuationCities.filter((item) => item !== city),
      )
    },
    async getCities() {
      this.cities = (await api.getCities(this.selectedStateId)).data
    },
    checkCpforCnpj(cpfOrCnpj) {
      const isCpf = cpfOrCnpj?.length <= 14

      if (isCpf) {
        this.updateValue('cpf', cpfOrCnpj)
        this.updateValue('cnpj', null)
      } else {
        this.updateValue('cnpj', cpfOrCnpj)
        this.updateValue('cpf', null)
      }
    },
    updateValue(key, value) {
      this.$emit('input', {
        key: key,
        value: value,
        workPartner: this.workPartner,
      })
    },
  },

  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
  },

  async created() {
    this.serviceCategories = (await api.getCrud('service-category')).data.filter((c) => c.active)
    this.states = (await api.getStates()).data.sort((a, b) => a.name.localeCompare(b.name))
  },
}
</script>
