<template>
  <div>
    <v-divider />
    <v-row class="py-4">
      <v-col class="d-flex flex-align-center" style="gap: 24px; align-items: center"
        ><v-icon @click="$emit('editItem', item)">mdi-pencil</v-icon> <span class="text">{{ item.title }}</span></v-col
      >

      <v-col
        ><span class="text justify">{{ item.description }}</span></v-col
      >

      <v-col v-if="type === $consts.SHOW_REAL_ESTATE_AGENCY_PROPOSAL_TYPE.IMAGE">
        <img v-if="item.content" class="logo" :src="item.content.url" />
        <Logo v-else class="logo" />
      </v-col>

      <v-col v-else-if="type === $consts.SHOW_REAL_ESTATE_AGENCY_PROPOSAL_TYPE.SOCIAL_MEDIA">
        <ul>
          <li v-for="(socialMedia, key) in item.content" :key="socialMedia.title">
            <span class="text"
              >{{ getSocialMediaLabel(key.toUpperCase()) }} -
              <a :href="getHref(key, socialMedia)" target="_blank">{{ socialMedia }}</a>
            </span>
          </li>
        </ul>
      </v-col>

      <v-col v-else><div class="text limit-lines" v-html="secureContent"></div></v-col>
    </v-row>
  </div>
</template>

<script>
import DOMPurify from 'dompurify'
import Logo from '../assets/logo-workay.svg'
export default {
  components: {
    Logo,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
    },
  },

  data() {
    return {
      secureContent: '',
    }
  },

  methods: {
    getHref(key, socialMedia) {
      return this.getSocialMediaLabel(key.toUpperCase()) === this.$consts.SOCIAL_MEDIA_LABELS.CONTACT
        ? 'mailTo:'
        : '//www.' + this.$consts.SOCIAL_MEDIA_LABELS[key.toUpperCase()].toLowerCase() + '.com/' + socialMedia
    },
    firstLetterUpperCase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getSocialMediaLabel(key) {
      return this.$consts.SOCIAL_MEDIA_LABELS[key]
    },
  },

  created() {
    this.secureContent = DOMPurify.sanitize(this.item.content)
  },
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
}

.text {
  font-size: 14px;
  color: rgba(109, 110, 113, 1);
}

.limit-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.justify {
  text-align: justify;
}

.logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
</style>
