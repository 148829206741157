<template>
  <div style="width: 100%">
    <v-sheet class="wrapper pa-4">
      <div class="d-flex justify-space-between" style="width: 100%">
        <h3>Informações da Imobiliária</h3>

        <v-icon color="primary" @click="loadRealEstateAgency()">mdi-reload</v-icon>
      </div>

      <div class="d-flex justify-center">
        <v-progress-circular indeterminate color="primary" v-if="loadingData" />
      </div>

      <template v-if="!loadingData">
        <proposal-info-item
          @editItem="(item) => editItem(item)"
          v-for="infoItem in proposalInfoItems"
          :key="infoItem.title"
          :item="infoItem"
          :type="infoItem.showType"
        />
      </template>
    </v-sheet>

    <w-dialog
      v-if="itemToEdit"
      v-model="editDialog"
      :title="itemToEdit.title"
      :max-width="600"
      :buttons="[
        {
          label: 'fechar',
          action: stopEditItem,
        },
        {
          label: 'salvar',
          action: save,
          disabled: !itemToEdit.content,
        },
      ]"
    >
      <p class="title">O que é</p>

      <p>{{ itemToEdit.description }}</p>

      <p class="title">{{ itemToEdit.actionDescription }}</p>

      <w-real-estate-agency-html-editor-modal
        v-if="itemToEdit.type === $consts.REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.HTML_EDITOR"
        v-model="itemToEdit"
        databaseColumn="proposalInfo"
      />

      <w-real-estate-agency-image-modal
        v-if="itemToEdit.type === $consts.REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.IMAGE"
        v-model="itemToEdit.content"
      />

      <w-real-estate-agency-social-media-modal
        v-if="itemToEdit.type === $consts.REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.SOCIAL_MEDIA"
        v-model="itemToEdit.content"
      />
    </w-dialog>
  </div>
</template>

<script>
import services from '@/api/services'
import WDialog from '@/components/WDialog.vue'
import WRealEstateAgencyHtmlEditorModal from '@/components/WRealEstateAgencyHtmlEditorModal.vue'
import WRealEstateAgencyImageModal from '@/components/WRealEstateAgencyImageModal.vue'
import WRealEstateAgencySocialMediaModal from '@/components/WRealEstateAgencySocialMediaModal.vue'
import { OBSERVATIONS } from '@/config/constants'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import ProposalInfoItem from '../components/ProposalInfoItem.vue'

createNamespacedHelpers('user/realEstateAgency')
export default {
  components: {
    ProposalInfoItem,
    WDialog,
    WRealEstateAgencyImageModal,
    WRealEstateAgencyHtmlEditorModal,
    WRealEstateAgencySocialMediaModal,
  },

  data() {
    return {
      oldRealEstateAgency: {},
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
    }),
    isWorkay() {
      return this.myRealEstateAgency.name === 'Workay!'
    },
    socialMedias() {
      const socialMedias = {
        facebook: this.myRealEstateAgency.proposalInfo?.socialMedia?.facebook || '',
        instagram: this.myRealEstateAgency.proposalInfo?.socialMedia?.instagram || '',
        twitter: this.myRealEstateAgency.proposalInfo?.socialMedia?.twitter || '',
        contact: this.myRealEstateAgency.proposalInfo?.socialMedia?.contact || '',
      }

      return socialMedias
    },
    proposalInfoItems: {
      get() {
        return OBSERVATIONS.map((observation) => ({
          ...observation,
          content: this.renderContent(observation),
          actionDescription: observation.actionDescription,
          property: observation.property,
        }))
      },
    },
  },

  methods: {
    async save() {
      switch (this.itemToEdit.type) {
        case this.$consts.REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.SOCIAL_MEDIA:
          await services.updateRealEstateAgencySocialMedias(this.myRealEstateAgency)
          break
        case this.$consts.REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.IMAGE:
          await services.addRealEstateAgencyProposalInfoLogo(this.myRealEstateAgency.id, this.itemToEdit.content)
          break
        case this.$consts.REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.HTML_EDITOR:
          await services.updateProposalInfoField(
            this.myRealEstateAgency.id,
            this.itemToEdit.property,
            this.itemToEdit.content,
          )
          break
      }

      this.stopEditItem()
      await this.loadRealEstateAgency()
    },
    firstLetterUpperCase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    renderContent(observation) {
      switch (observation.type) {
        case this.$consts.REAL_ESTATE_AGENCY_PROPOSAL_FORM_TYPE.SOCIAL_MEDIA:
          return this.socialMedias
        default:
          return this.myRealEstateAgency.proposalInfo[observation.property]
      }
    },

    editItem(item) {
      this.oldRealEstateAgency = this.myRealEstateAgency
      this.itemToEdit = item
      this.editDialog = true
    },
    stopEditItem() {
      this.editDialog = false
      this.itemToEdit = {}
      this.myRealEstateAgency = this.oldRealEstateAgency
    },
    async loadRealEstateAgency() {
      this.loadingData = true
      this.myRealEstateAgency = (await services.getRealEstateAgency(this.user.realEstateAgency.id)).data
      this.loadingData = false
    },
  },

  async created() {
    await this.loadRealEstateAgency()
  },

  watch: {
    itemToEdit: {
      handler: function (newValue) {
        const newMyRealRealEstateAgency = {
          ...this.myRealEstateAgency,
          proposalInfo: {
            ...this.myRealEstateAgency.proposalInfo,
            [newValue.property]: newValue.content,
          },
        }

        this.myRealEstateAgency = newMyRealRealEstateAgency
      },
      deep: true,
    },
  },

  data() {
    return {
      editDialog: false,
      itemToEdit: {},
      myRealEstateAgency: {},
      loadingData: false,
    }
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  width: 100%;
}

.text {
  font-size: 14px;
  color: rgba(109, 110, 113, 1);
}

.title {
  color: black;
  font-size: 14px;
  font-weight: bold;
}
</style>
