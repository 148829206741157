<template>
  <div style="width: 100%">
    <jodit-editor class="editor" v-model="contentFromJodit" :buttons="buttons" />
  </div>
</template>

<script>
import 'jodit/build/jodit.min.css'
import { JoditEditor } from 'jodit-vue'
import DOMPurify from 'dompurify'

export default {
  components: { JoditEditor },
  props: {},

  data() {
    return {
      contentFromJodit: '',
      sanitizedContent: '',
    }
  },

  computed: {
    buttons() {
      return ['bold', 'italic', 'underline', 'strikethrough', 'ul', 'ol']
    },
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  created() {
    this.contentFromJodit = this.value
  },

  watch: {
    contentFromJodit: function (val) {
      this.sanitizedContent = DOMPurify.sanitize(val)
      this.$emit('input', this.sanitizedContent)
    },
  },
}
</script>

<style scoped>
.jodit_theme_summer {
  --jd-color-background-default: red;
  --jd-color-border: #474025;
  --jd-color-panel: #5fd3a2;
  --jd-color-icon: #8b572a;
}
</style>
