<template>
  <v-form ref="form" v-model="validForm">
    <v-autocomplete
      label="Serviço"
      v-model="visit.service"
      :items="availableServices"
      item-text="name"
      item-value="id"
      class="required"
      return-object
      :rules="[$validationRules.required.rule]"
      :disabled="disabledServices"
    />

    <v-text-field
      label="Data"
      type="datetime-local"
      v-model="visit.datetime"
      class="required"
      :rules="[$validationRules.required.rule]"
    />

    <v-text-field v-model="visit.estimatedDuration" :label="`Duração estimada (dias)`" type="number" />

    <v-textarea outlined v-model="visit.observation" label="Observação" />
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      validForm: false,
    }
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabledServices: {
      type: Boolean,
      default: false,
    },
    availableServices: {
      type: Array,
      required: true,
    },
  },
  computed: {
    visit: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    validForm: {
      handler: function (newValue) {
        this.$emit('update:form-disabled', !newValue)
      },
    },
  },
}
</script>
