<template>
  <div id="chat-list-wrapper" class="d-flex flex-column">
    <Header @navigateTo="(screen) => $emit('navigateTo', screen)" :my-chat-user="myChatUser" />

    <div id="chat-list" class="d-flex flex-column">
      <div id="search" class="ma-4 px-4">
        <v-text-field v-model="filterText" placeholder="Pesquise uma conversa" />
      </div>

      <div v-if="!filteredChats">
        <div v-for="index in 5" :key="index">
          <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
        </div>
      </div>

      <div id="chats" class="d-flex flex-column">
        <div v-for="(availableChat, index) in filteredChats" :key="index">
          <user-card
            type="chat"
            :chat="availableChat"
            :is-selected="availableChat.other.id === currentChat"
            @currentChat="(currentChat) => setCurrentChat(currentChat)"
          />
          <v-divider style="opacity: 50%" />
        </div>

        <div id="empty-list" class="d-flex flex-column mt-4 mx-2" v-if="filteredChats">
          <p><v-icon :size="14">mdi-lock-outline</v-icon> Suas mensagens estão protegidas</p>
          <p>
            Confira nossos <span class="underline">Termos de Uso</span> e
            <span class="underline">Política de Privacidade</span>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import Header from './Header.vue'
import UserCard from './UserCard.vue'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { UserCard, Header },

  props: {
    availableChats: {
      type: Array,
    },
    myChatUser: {
      type: Object,
    },
  },
  data() {
    return {
      filterText: '',
      currentChat: null,
    }
  },

  computed: {
    filteredChats() {
      return this.availableChats?.filter((chat) =>
        chat.other.name.toLowerCase().startsWith(this.filterText.trim().toLowerCase()),
      )
    },
  },

  methods: {
    setCurrentChat(chat) {
      this.currentChat = chat
      this.$emit('currentChat', this.currentChat)
    },
  },
  async created() {},
}
</script>
<style scoped>
#chat-list-wrapper {
  height: 100%;
}

#chat-list {
  height: 100%;
  overflow: auto;
}

#search {
  background-color: #f2f4f7;
  border-radius: 32px;
}

#chats {
  height: 100%;
}

::v-deep .v-input__slot::before {
  display: none;
}

::v-deep .v-text-field__details {
  display: none;
}

:deep(.v-input.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder) {
  margin: 0;
  padding: 0;
}

:deep(.v-input__slot) {
  margin: 0;
}

#empty-list p,
#empty-list span {
  color: #6d6e71;
  font-size: 12px;
  text-align: center;
  margin: 0px;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  cursor: pointer;
}

:deep(.v-input__slot::after) {
  border-style: none !important;
}
</style>
