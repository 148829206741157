<template>
  <v-layout justify-center align-center>
    <v-alert v-model="visible" class="alert" :type="type" transition="scale-transition" dismissible @input="dismiss">
      <span>{{ message }}</span>
    </v-alert>
  </v-layout>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      type: null,
      message: '',
      lastTimeout: null,
    }
  },
  methods: {
    showError(message, timeout = 10000) {
      this.show(message, 'error', timeout)
    },
    showWarning(message, timeout = 5000) {
      this.show(message, 'warning', timeout)
    },
    showSuccess(message, timeout = 3000) {
      this.show(message, 'success', timeout)
    },
    showInfo(message, timeout = 2000) {
      this.show(message, 'info', timeout)
    },
    show(message, type, timeout = 3000) {
      clearTimeout(this.lastTimeout)
      this.message = message
      this.type = type
      this.visible = true
      this.lastTimeout = setTimeout(() => {
        this.dismiss()
      }, timeout)
    },
    dismiss() {
      this.visible = false
    },
  },
}
</script>
<style scoped>
.alert {
  position: fixed;
  top: 40vh;
  margin: auto;
  z-index: 100000;
}
</style>
