<template>
  <div class="d-flex flex-sm-row flex-column pa-4 rounded-lg steps-container mt-8 align-center">
    <span class="step-title">Progresso</span>
    <div
      class="steps-wrapper"
      :style="`width: 100%; display: grid; grid-template-columns: repeat(${this.steps.length}, minmax(50px, 1fr))`"
    >
      <div
        v-for="(step, i) in this.steps"
        :key="i"
        :class="step.completed ? 'active' : ''"
        class="step d-flex flex-column align-center"
      >
        <div class="step-indicator rounded-circle d-flex align-center justify-center">
          <span>
            {{ step.completed ? '✓' : '' }}
          </span>
        </div>
        <span class="text-center pa-1">{{ step.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.steps-container {
  border: 1px solid var(--v-primary-base);
  gap: 32px;
}

.step-title {
  font-size: 18px;
  font-weight: bold;
  color: var(--v-primary-base);
}

.step {
  position: relative;
  color: #9e9e9e;
  font-size: 12px;
  gap: 4px;
}

.step.active {
  color: #2e2e2e;
  font-weight: 600;
}

.step-indicator {
  height: 24px;
  width: 24px;
  background-color: #9e9e9e;
  color: #fff;
}

.step.active .step-indicator {
  background-color: var(--v-primary-base);
  transform: scale(1.25);
}

.step:not(:last-child).active::after {
  background-color: var(--v-primary-base);
}

.step:not(:last-child)::after {
  content: '';
  position: absolute;
  height: 4px;
  width: calc(100% - 24px);
  background-color: #9e9e9e;
  top: calc((24px / 2) - (4px / 2));
  left: calc(50% + (24px / 2));
}
</style>
