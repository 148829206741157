<template>
  <v-sheet
    :class="[
      'd-flex align-center justify-center py-2 px-3 rounded-pill',
      checkboxData.type === 'finished' ? 'done' : 'not-done',
    ]"
    style="gap: 8px"
  >
    <span>
      {{ checkboxData.name }}
    </span>
    <v-btn v-if="hasImages" icon x-small @click="dialog = true">
      <v-icon> mdi-image-multiple </v-icon>
    </v-btn>
    <div v-if="checkboxData.type === 'todo'" class="task-icon">
      <v-icon dense>mdi-account-hard-hat</v-icon>
    </div>
    <div v-else-if="checkboxData.type === 'revision'" class="task-icon">
      <v-simple-checkbox
        hide-details
        :class="['ma-0 pa-0 slim-checkbox']"
        v-model="checkboxValue"
        x-small
        :disabled="checkboxValue"
      />
    </div>
    <div v-else-if="checkboxData.type === 'finished'" class="task-icon">
      <v-icon dense>mdi-check-bold</v-icon>
    </div>
    <v-dialog v-model="dialog" :max-width="700">
      <v-card>
        <div class="d-flex justify-end">
          <v-icon class="ma-2" large @click="dialog = false">mdi-close</v-icon>
        </div>
        <v-carousel cycle>
          <v-carousel-item v-for="(image, i) in checkboxData.images" :key="i">
            <v-sheet color="grey lighten-4">
              <v-img :src="image.url" contain height="500"></v-img>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
export default {
  name: 'DemandServiceTaskItem',

  data() {
    return {
      checked: false,
      dialog: false,
    }
  },

  props: {
    checkboxData: {
      type: Object,
    },
  },

  methods: {
    showImage() {
      if (this.hasImages) {
        this.dialog = true
      }
    },
  },

  computed: {
    checkboxValue: {
      get() {
        return this.checked
      },
      async set(value) {
        this.checked = value
        this.$emit('revise', this.checkboxData.id)
      },
    },
    hasImages() {
      return this.checkboxData.images?.length > 0
    },
  },
}
</script>

<style scoped>
.task-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.slim-checkbox >>> .v-icon {
  font-size: 20px !important;
}
.slim-checkbox >>> .v-input--selection-controls__ripple {
  left: 0 !important;
  top: 0 !important;
  margin: 0 !important;
  height: 24px !important;
  width: 24px !important;
}
.slim-checkbox >>> .v-input--selection-controls__input {
  margin: 0 !important;
}

.cannot-click:hover {
  cursor: not-allowed;
}

.can-click {
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 150ms;
}

.can-click:hover {
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.1);
}

.can-click:active {
  background-color: rgba(128, 128, 128, 0);
}

.done {
  border: 1px solid var(--v-primary-base);
}

.not-done {
  border: 1px solid rgba(128, 128, 128, 0.3);
}
</style>
