<template>
  <div class="box">
    <w-html-editor v-model="data.content" />
  </div>
</template>

<script>
import WHtmlEditor from '@/components/WHtmlEditor.vue'
export default {
  components: { WHtmlEditor },
  props: {
    value: {
      type: Object,
    },
  },

  computed: {
    data: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style scoped></style>
