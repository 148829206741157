<template>
  <w-dialog v-model="openDialog" :title="title" :loading="loading" :buttons="buttons" maxWidth="840">
    <v-sheet class="d-flex justify-center" v-if="!!demandId" style="max-height: 100%">
      <div v-if="filterFiles.length === 0" class="d-flex flex-column justify-center body-1 pa-4" style="flex: 1">
        <p>Esta solicitação não possui arquivos associados.</p>
        <p v-if="allowAdd">
          Utilize o espaço ao lado para anexar novos arquivos, respeitando o tamanho máximo para cada arquivo.
        </p>
      </div>
      <v-list subheader two-line class="overflow-y-auto mr-2" style="flex: 1" v-else>
        <v-list-item v-for="file in filterFiles" :key="`file_${file.id}`">
          <v-list-item-action class="mr-1">
            <v-btn icon color="primary" :loading="downloadLinks[file.id] === null" @click="download(file)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-content :title="file.name">
            <v-list-item-title>{{ file.name }}</v-list-item-title>
            <v-list-item-subtitle>
              <span>{{ $moment.utc(file.createdTimestamp).local().format('DD/MM/YYYY HH:mm') }}</span>
              <span class="ml-4 caption">({{ $formatters.toByteSize(file.size) }})</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="ml-1" v-if="allowDelete">
            <v-btn icon color="primary" @click="remove(file.id, file.name)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <w-file-drop
        v-if="allowAdd"
        class="ml-1"
        style="flex: 1"
        v-model="filesToUpload"
        :max-file-size="maxFileSize"
        :accept="accept"
      />
    </v-sheet>
    <a v-show="false" :href="currentDownloadLink" ref="downloadLink" :download="currentFileName" />
  </w-dialog>
</template>

<script>
import api from '@/api/services'
import WDialog from '@/components/WDialog.vue'
import WFileDrop from '@/components/WFileDrop.vue'

export default {
  components: { WFileDrop, WDialog },
  props: {
    maxFileSize: Number,
    title: {
      type: String,
      default: 'Anexos da solicitação',
    },
    documentType: {
      type: String,
      default: 'DOCUMENT',
    },
    onlyImages: {
      type: Boolean,
    },
  },
  watch: {
    demandId() {
      this.downloadLinks = {}
      this.currentDownloadLink = null
      this.filesToUpload = []
    },
  },
  computed: {
    accept() {
      if (this.documentType === this.$consts.DOCUMENT_TYPE.IMAGE) {
        return ['jpg', 'png', 'jpeg']
      }

      return null
    },
    buttons() {
      const actionButtons = [
        {
          label: 'finalizar',
          action: async () => {
            if (this.filesToUpload.length > 0 && this.filesToUpload.every((file) => file.size < this.maxFileSize)) {
              await this.upload()
            }
            this.close()
          },
        },
      ]

      if (this.allowAdd) {
        actionButtons.unshift({
          label: 'anexar novos arquivos' + (this.filesToUpload.length === 0 ? '' : ` (${this.filesToUpload.length})`),
          action: this.upload,
          disabled: this.filesToUpload.length === 0 || this.filesToUpload.some((file) => file.size > this.maxFileSize),
        })
      }
      return actionButtons
    },
    filterFiles() {
      return this.files.filter((file) => file.type === this.documentType)
    },
  },
  data() {
    return {
      currentFileName: null,
      currentDownloadLink: null,
      openDialog: false,
      downloadLinks: {},
      loading: false,
      filesToUpload: [],
      files: [],
      demandId: null,
      allowAdd: false,
      allowDelete: false,
    }
  },
  methods: {
    open(demandId, files, allowAdd, allowDelete) {
      this.demandId = demandId
      this.files = files || []
      this.allowAdd = allowAdd
      this.allowDelete = allowDelete
      this.openDialog = true
    },
    close() {
      this.openDialog = false
    },
    async upload() {
      this.loading = true

      const promises = await Promise.allSettled(
        this.filesToUpload.map((file) => api.uploadDemandFile(this.demandId, file, this.documentType)),
      )

      const succesfullyUploadedFiles = promises
        .filter((promise) => promise.status === 'fulfilled')
        .map((promise) => promise.value.data)

      if (succesfullyUploadedFiles.length) {
        this.files = succesfullyUploadedFiles.concat(this.files)
        this.emitFileCountChangeEvent(this.demandId, this.files.length + succesfullyUploadedFiles.length)
      }

      const errors = promises
        .filter((promise) => promise.status === 'rejected')
        .map((promise) =>
          promise.reason.reponse && promise.reason.response.data
            ? promise.reason.response.data.message
            : promise.reason.message,
        )

      if (errors.length) {
        alert('Um ou mais arquivos não puderam ser enviados. Motivos: ' + errors.join('; '))
      }

      this.loading = false
      this.filesToUpload = []
    },
    async download(file) {
      const response = await api.getDocument(file.code)

      this.currentFileName = file.name
      this.currentDownloadLink = window.URL.createObjectURL(new Blob([response.data]))
      this.$nextTick(() => {
        this.$refs.downloadLink.click()
      })
    },
    async remove(fileId, fileName) {
      if (!confirm(`Deseja realmente remover o arquivo '${fileName}'?`)) {
        return
      }

      this.loading = true

      try {
        await api.removeDemandFile(this.demandId, fileId)
        const removedFileIndex = this.files.findIndex((file) => file.id === fileId)
        if (removedFileIndex !== -1) {
          this.files.splice(removedFileIndex, 1)
        }
        this.emitFileCountChangeEvent(this.demandId, this.files.length - 1)
      } catch (error) {
        console.error(error)
        alert('Não foi possível remover o arquivo')
      } finally {
        this.loading = false
      }
    },
    emitFileCountChangeEvent(id, count) {
      this.$emit('files-count-changed', { id, count })
    },
  },
}
</script>

<style scoped></style>
