<template>
  <div class="message d-flex flex-column" :class="[data.origin.toLowerCase(), type.toLowerCase()]">
    <span v-if="type === $consts.CHAT_MESSAGE_TYPE.TEXT" style="word-break: break-word">{{ data.content.text }}</span>
    <img
      v-if="type === $consts.CHAT_MESSAGE_TYPE.IMAGE"
      class="message-image"
      @click="$emit('openImage', data.content.file.url)"
      :src="data.content.file.url"
    />
    <div class="file-message" v-if="type === $consts.CHAT_MESSAGE_TYPE.FILE">
      <div class="d-flex" style="gap: 8px">
        <v-icon :style="{ color: fileIconColor }" :size="48">mdi-file-document</v-icon>
        <div class="d-flex flex-column">
          <p :style="{ color: fileIconColor }">{{ data.content.file.name }}</p>

          <p class="file-size" :style="{ color: fileIconColor }">
            {{ $formatters.toByteSize(data.content.file.size) }}
          </p>
        </div>
      </div>
      <v-icon class="download-icon" @click="downloadFile()" :style="{ color: fileIconColor }" :size="32"
        >mdi-download-circle-outline</v-icon
      >
    </div>
    <div class="audio-message d-flex align-center" v-if="type === $consts.CHAT_MESSAGE_TYPE.AUDIO" style="gap: 8px">
      <audio-player
        :ref="`myAudio-${data.id}`"
        :audio-list="[data.content.file.url]"
        :theme-color="data.origin.toLowerCase() === 'me' ? 'white' : 'var(--v-primary-base)'"
        :show-volume-button="false"
        :show-prev-button="false"
        :show-next-button="false"
        :show-playback-rate="false"
        :isLoop="false"
      />

      <div class="d-flex user-photo">
        <v-icon
          class="small-mic"
          :color="this.data.origin.toLowerCase() === 'me' ? 'white' : 'var(--v-primary-base)'"
          :size="18"
          >mdi-microphone</v-icon
        >
        <img
          v-if="senderPhoto"
          :src="senderPhoto"
          style="object-fit: contain"
          :style="{ backgroundColor: !senderPhoto ? 'gray' : 'white' }"
        />

        <v-icon v-else :size="36" :color="fileIconColor" class="ma-auto ml-4">mdi-account</v-icon>
      </div>
    </div>

    <span class="message-date">{{ $moment.utc(data.sentAt).local().format('HH:mm') }}</span>
  </div>
</template>

<script>
import AudioPlayer from '@liripeng/vue-audio-player'
import axios from 'axios'

export default {
  components: { AudioPlayer },

  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    photos: {
      type: Object,
      required: true,
    },
  },

  mounted() {},

  methods: {
    async downloadFile() {
      const document_ = this.data.content.file

      const response = await axios.get(document_.url, { responseType: 'arraybuffer' })

      const blob = new Blob([response.data], { type: response.headers['content-type'] })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)

      link.download = document_.name

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
    },
  },

  computed: {
    senderPhoto() {
      switch (this.data.origin) {
        case 'ME':
          return this.photos.me
        case 'OTHER':
          return this.photos.other
        default:
          return ''
      }
    },
    fileIconColor() {
      return this.data.origin.toLowerCase() === 'me' ? 'white' : 'gray'
    },
  },
}
</script>

<style scoped>
.user-photo {
  position: relative;
}

.small-mic {
  position: absolute;
  left: -7px;
  top: 30%;
}

.user-photo {
  width: 50px;
  height: 50px;
}

.user-photo > img {
  border-radius: 50%;
  background-color: gray;
  width: 50px;
  height: 50px;
}
.audio-player {
  display: flex;
  width: 100%;
}

:deep(.audio-player .audio__play-start),
:deep(.audio-player .audio__play-pause) {
  margin-left: 0;
}
:deep(.audio-player .audio__current-time) {
  display: none;
}

:deep(.audio-player .audio__duration) {
  display: none;
}

:deep(.audio-player .audio__time-wrap) {
  position: absolute;

  left: 70px;
  bottom: 10px;
}

:deep(.audio-player .audio__progress-wrap) {
  width: 100% !important;
}

:deep(.audio-player .audio__time-wrap) {
  display: flex;
  gap: 8px;
}

:deep(.audio-player .audio__time-wrap div) {
  font-size: 14px;
}

.me :deep(.audio-player .audio__time-wrap div) {
  color: white;
}
.message {
  padding: 10px 14px;
  align-content: flex-end;
  gap: 4px;
  position: relative;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.audio-message {
  width: 300px;
}
.image {
  max-width: min(90%, 300px);
}

.text {
  max-width: 90%;
}

.message-date {
  font-size: 12px;
  text-align: end;
}
.message-image {
  object-fit: contain;
  border-radius: 4px;
}

.message-image:hover {
  cursor: pointer;
}

.other {
  background-color: white;
  border-radius: 8px;
  border-top-left-radius: 0px;
}

.me {
  background-color: var(--v-primary-base);
  border-radius: 8px;
  border-top-right-radius: 0px;
}

.me span {
  color: white;
}

.other span {
  color: black;
}

.file-message {
  display: flex;
  align-items: center;
  gap: 24px;
}

.file p {
  margin: 0;
  color: white;
}

.file-size {
  font-size: 12px;
}

.download-icon:hover {
  cursor: pointer;
}
</style>
