var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-dialog',{attrs:{"title":_vm.editMode ? 'Editar Visita' : 'Agendar Visita',"maxWidth":600,"buttons":[
    {
      label: 'fechar',
      action: () => {
        _vm.close()
      },
    },
    {
      label: 'salvar',
      action: async () => {
        _vm.isLoading = true
        if (_vm.editMode) {
          await _vm.update()
        } else {
          await _vm.save()
        }
        _vm.isLoading = false
        _vm.close()
        _vm.$emit('reload')
      },
      loading: _vm.isLoading,
      disabled: _vm.formDisabled,
    },
  ]},on:{"input":_vm.close},model:{value:(_vm.visitDialog),callback:function ($$v) {_vm.visitDialog=$$v},expression:"visitDialog"}},[_c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('w-visit-form',{attrs:{"availableServices":_vm.demand.services.map(({ id, name }) => ({ id, name })),"disabledServices":_vm.disabledServices},on:{"update:form-disabled":function($event){_vm.formDisabled = $event}},model:{value:(_vm.visit),callback:function ($$v) {_vm.visit=$$v},expression:"visit"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }