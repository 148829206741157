<template>
  <div class="loginform d-flex justify-center align-center">
    <v-card class="pb-4 px-8" width="400" :loading="loading" :disabled="loading">
      <div class="d-flex justify-center mt-8" width="100%">
        <Logo />
      </div>
      <v-card-title class="pt-8">Definição de senha</v-card-title>
      <v-card-text style="position: relative">
        <v-form ref="formlogin">
          <v-text-field class="my-2" :value="$route.query.email" label="E-mail" disabled />
          <v-text-field
            class="my-2"
            type="password"
            v-model="password"
            label="Senha"
            placeholder="Digite sua senha"
            :rules="[$validationRules.required.rule]"
            validate-on-blur
          />
          <v-text-field
            class="my-2"
            type="password"
            v-model="password2"
            label="Repita a senha"
            placeholder="Repita sua senha"
            :rules="[$validationRules.required.rule]"
            validate-on-blur
            @keyup.enter="resetPassword"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn color="primary" @click="resetPassword" :disabled="!isValidForm" :loading="loading">Definir senha</v-btn>
      </v-card-actions>

      <w-alert ref="alert" />
    </v-card>
  </div>
</template>

<script>
import router from '@/router'
import Logo from '../assets/logo-workay.svg'
import WAlert from '../components/WAlert.vue'

export default {
  components: { WAlert, Logo },
  data() {
    return {
      password: '',
      password2: '',
      loading: false,
      forgotPassword: false,
    }
  },
  computed: {
    isValidForm() {
      return this.password === this.password2
    },
  },
  methods: {
    resetPassword() {
      if (!this.isValidForm) {
        this.$refs.alert.showError('As senhas não conferem!')
        return
      }
      const code = this.$route.params.code
      this.$auth
        .confirmPasswordReset(code, this.password)
        .then(() => {
          this.$refs.alert.showSuccess('Sua senha foi alterada com sucesso!')
          setTimeout(() => {
            router.replace('/login')
          }, 1500)
        })
        .catch((error) => {
          let errorMessage
          switch (error.code) {
            case 'auth/weak-password':
              errorMessage = 'A sua senha deve ter pelo menos 6 caracteres!'
              break
            case 'auth/invalid-action-code':
              errorMessage = 'O link pode estar inválido, expirado ou já foi utilizado.'
              break
            default:
              errorMessage = 'Erro interno do servidor, tente novamente mais tarde.'
          }
          console.error(error)
          this.$refs.alert.showError(errorMessage)
        })
    },
  },
}
</script>

<style scoped>
.loginform {
  height: 100%;
  width: 100%;
}
.v-card--forgot {
  bottom: 0;
  left: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
