<template>
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p style="font-weight: bold">
            Sugerimos alguns materiais comuns para esse serviço, personalize como achar necessário:
          </p>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <w-table
            v-if="getMaterials"
            ref="materialsTable"
            disableItemsPerPage
            :headers="headers"
            sort-by="name"
            :show-top-bar="false"
            :load-action="getMaterials"
            class="mb-8 materialsTable"
          >
            <template #sizes="{ item }">
              <div class="d-flex">
                <v-select
                  ref="sizeSelect"
                  flat
                  class="select select-material-size"
                  :items="getSizesByMaterial(item.id, item.selectedBrand)"
                  v-model="item.selectedSize"
                  append-icon="mdi-arrow-down-drop-circle-outline"
                  color="#27ae60"
                  item-color="#27ae60"
                  height="5px"
                />
              </div>
            </template>
            <template #brands="{ item }">
              <v-select
                flat
                class="select select-material-brand"
                :items="getBrandsByMaterial(item.id, item.selectedSize)"
                v-model="item.selectedBrand"
                append-icon="mdi-arrow-down-drop-circle-outline"
                color="#27ae60"
                item-color="#27ae60"
                height="5px"
              />
            </template>
            <template #price="{ item }">
              <span>
                {{ $utils.formatCurrency(findItem(item)?.unitaryPrice || 0) }}
              </span>
            </template>
            <template #totalPrice="{ item }">
              <span>
                {{
            $utils.formatCurrency(
              (findItem(item)
                ?.unitaryPrice || 0) * item.quantity),
                }}
              </span>
            </template>
            <template #quantity="{ item }">
              <v-text-field v-model="item.quantity" hide-details single-line type="number" :min="1" />
            </template>
            <template #addToDemandService="{ item }">
              <div class="d-flex flex-row" style="gap: 4px">
                <div class="circle background-green" :class="{ canSelect: !findItem(item) }">
                  <v-icon @click="() => addMaterialToDemandService(item)" color="white" size="18px"> mdi-plus</v-icon>
                </div>
              </div>
            </template>
          </w-table>

          <v-btn
            color="primary"
            class="my-4"
            small
            text
            outlined
            style="border: 1px solid #27ae60; border-radius: 8px"
            @click="toggleModal"
          >
            <v-icon class="ml-2" small>mdi-plus</v-icon>
            Adicionar Material
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <p style="font-weight: bold">Materiais adicionados:</p>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-data-table :items="materialItemsToAdd" :headers="bdItemsHeaders" :loading="!this.recommendedMaterials">
            <template #[`item.removeToDemandService`]="{ item }">
              <div class="d-flex flex-row" style="gap: 4px">
                <div class="circle background-red">
                  <v-icon @click="() => decreaseMaterialToDemandService(item)" color="white" size="18px">
                    mdi-minus</v-icon
                  >
                </div>

                <div class="circle background-red trash">
                  <v-icon @click="() => removeMaterialToDemandService(item)" color="white" size="18px">
                    mdi-delete</v-icon
                  >
                </div>
              </div>
            </template>
            <template #[`item.price`]="{ item }">
              <span>
                {{ $utils.formatCurrency(parseFloat(item.price)) }}
              </span>
            </template>
            <template #[`item.totalPrice`]="{ item }">
              <span>
                {{
            $utils.formatCurrency(parseFloat(item.price) * parseInt(item.quantity)),
                }}
              </span>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="showAddMaterialDialog" max-width="600px">
      <v-card>
        <v-card-title class="d-flex" style="justify-content: center">Adicionar material</v-card-title>
        <v-sheet class="pa-4">
          <v-autocomplete
            dense
            placeholder="Digite o nome do material"
            v-model="dialogMaterialToAdd"
            :items="allMaterialsFilteredAdded"
            :item-text="(item) => `${item.name} - ${item.description}`"
            item-value="id"
            return-object
          />

          <div class="d-flex mt-8" style="gap: 10px; justify-content: end">
            <v-btn color="primary" small outlined @click="toggleModal()"> Fechar </v-btn>
            <v-btn color="primary" @click="addMaterial" small :disabled="!dialogMaterialToAdd"> Adicionar </v-btn>
          </div>
        </v-sheet>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../api/services'
import WTable from './WTable.vue'
export default {
  components: { WTable },
  data() {
    return {
      panel: [0, 1],
      selectedQuantity: null,
      selectedSize: null,
      selectedBrand: null,
      recommendedMaterials: null,
      addedMaterials: [],
      dialogMaterialToAdd: null,
      showAddMaterialDialog: false,
      serviceMaterials: [],
      getMaterials: null,
      headers: [
        { text: 'Produto', value: 'name' },
        { text: 'Descrição', value: 'description' },
        { text: 'Tamanho', value: 'sizes' },
        { text: 'Marca', value: 'brands' },
        { text: 'Preço unitário', value: 'price' },
        { text: 'Quantidade', value: 'quantity' },
        { text: 'Preço total', value: 'totalPrice' },
        { text: 'Adicionar', value: 'addToDemandService' },
      ],
      bdItemsHeaders: [
        { text: 'Produto', value: 'name' },
        { text: 'Descrição', value: 'description' },
        { text: 'Tamanho', value: 'size' },
        { text: 'Marca', value: 'brand' },
        { text: 'Preço unitário', value: 'price' },
        { text: 'Quantidade', value: 'quantity' },
        { text: 'Preço total', value: 'totalPrice' },
        { text: 'Remover', value: 'removeToDemandService' },
      ],

      materialItemsToAdd: [],
    }
  },

  computed: {
    allMaterialsFilteredAdded() {
      return (this.materials ?? []).filter((material) =>
        (this.recommendedMaterials ?? [])
          .concat(this.addedMaterials)
          .every((addedMaterial) => addedMaterial.id != material.id),
      )
    },
    organizedMaterialItems() {
      return (this.recommendedMaterials ?? []).concat(this.addedMaterials).reduce(
        (acc, material) => ({
          ...acc,
          [material.id]: {
            brands: material.items.reduce((acc, item) => {
              const brandName = item.brand.name

              return {
                ...acc,
                [brandName]: [...(acc[brandName] ?? []), item.size],
              }
            }, {}),
            sizes: material.items.reduce((acc, item) => {
              const size = item.size

              return {
                ...acc,
                [size]: [...(acc[size] ?? []), item.brand.name],
              }
            }, {}),
          },
        }),
        {},
      )
    },
  },

  props: {
    serviceId: null,
    demandServiceId: null,
    demand: {
      type: Object,
      required: true,
    },
    materials: {
      type: Array,
      required: true,
    },
  },

  methods: {
    toggleModal() {
      this.showAddMaterialDialog = !this.showAddMaterialDialog
    },
    findItem(material) {
      return material.items.find(
        (materialItem) =>
          materialItem.size === material.selectedSize && materialItem.brand.name === material.selectedBrand,
      )
    },
    getBrandsByMaterial(materialId, size) {
      if (!materialId) {
        return []
      }

      if (!size) {
        return Object.keys(this.organizedMaterialItems[materialId]?.brands ?? {})
      }

      return this.organizedMaterialItems[materialId]?.sizes[size] ?? []
    },
    getSizesByMaterial(materialId, brand) {
      if (!materialId) {
        return []
      }

      if (!brand) {
        return Object.keys(this.organizedMaterialItems[materialId]?.sizes ?? {})
      }

      return this.organizedMaterialItems[materialId]?.brands[brand] ?? []
    },
    addMaterialToDemandService(materialItem) {
      const materialItemToAdd = this.findItem(materialItem)

      if (!materialItemToAdd || materialItem.quantity < 1) {
        return
      }

      this.$emit('add:item', {
        item: materialItemToAdd,
        quantity: parseInt(materialItem.quantity),
      })

      const existingMaterialItem = this.materialItemsToAdd.find(
        (materialItem) => materialItem.id === materialItemToAdd.id,
      )
      if (existingMaterialItem) {
        existingMaterialItem.quantity += parseInt(materialItem.quantity)
        return
      }

      this.materialItemsToAdd.push({
        ...materialItemToAdd,
        quantity: parseInt(materialItem.quantity),
        name: materialItem.name,
        brand: materialItem.selectedBrand,
        size: materialItem.selectedSize,
        price: materialItemToAdd.unitaryPrice,
        totalPrice: parseInt(materialItem.quantity) * materialItemToAdd.unitaryPrice,
        description: materialItem.description,
      })
    },
    removeMaterialToDemandService(item) {
      if (item.quantity <= 0) return

      this.$emit('remove:item', item.id, true)
      item.quantity = 0
      this.materialItemsToAdd = this.materialItemsToAdd.filter((i) => i.id !== item.id)
    },
    decreaseMaterialToDemandService(item) {
      if (item.quantity <= 0) return

      this.$emit('remove:item', item.id)

      if (item.quantity > 1) {
        item.quantity -= 1
        return
      }

      this.materialItemsToAdd = this.materialItemsToAdd.filter((i) => i.id !== item.id)
    },
    async addMaterial() {
      const { data: material } = await api.getMaterial(this.dialogMaterialToAdd.id)
      this.addedMaterials.push({ ...material, quantity: 1 })
      this.getMaterials = async () => {
        const materials = (this.recommendedMaterials ?? []).concat(this.addedMaterials)
        materials.forEach(this.checkMaterialItemOnlyOption)
        return { data: materials }
      }
      this.$nextTick(() => {
        this.$refs.materialsTable.refreshList()
        this.dialogMaterialToAdd = null
      })
    },
    waitForRecommendedMaterials() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.recommendedMaterials !== null) {
            clearInterval(interval)
            this.recommendedMaterials.forEach(this.checkMaterialItemOnlyOption)
            resolve({ data: this.recommendedMaterials })
          }
        }, 100)
      })
    },
    checkMaterialItemOnlyOption(materialItem) {
      const sizes = this.getSizesByMaterial(materialItem.id, null)
      const brands = this.getBrandsByMaterial(materialItem.id, null)

      if (sizes.length === 1) {
        materialItem.selectedSize = sizes[0]
      }

      if (brands.length === 1) {
        materialItem.selectedBrand = brands[0]
      }
    },
  },
  async created() {
    this.getMaterials = this.waitForRecommendedMaterials

    const { data: materialsByService } = await api.listMaterialsByServiceId(this.serviceId)

    this.recommendedMaterials = materialsByService.map((item) => ({
      ...item,
      quantity: 1,
    }))

    this.materialItemsToAdd =
      this.demand.services
        .find((service) => service.id === this.serviceId)
        .items?.map((serviceItem) => ({
          ...serviceItem.materialItem,
          id: serviceItem.item.id,
          name: serviceItem.item.fullName,
          quantity: serviceItem.quantity,
          description: serviceItem.item.description,
          brand: serviceItem.item.brand.name,
          size: serviceItem.item.size,
          price: serviceItem.item.unitaryPrice.toFixed(2),
        })) ?? []
  },
}
</script>

<style scoped>
.select {
  border: 2px solid var(--v-primary-base);
  border-radius: 8px;
  padding: 12px;
}

.select-material-size {
  width: 130px;
}

.select-material-brand {
  width: 180px;
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-green {
  background-color: var(--v-primary-base);
}

.background-green:hover {
  cursor: default;
}

.background-red {
  background-color: var(--v-error-base);
}

.canSelect {
  background-color: rgba(197, 197, 197, 1) !important;
}

.canSelect:hover {
  cursor: default;
}

.trash {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  padding: 20px;
  padding-top: 0;
}

:deep(.v-text-field__details) {
  display: none !important;
}

:deep(.v-input__slot) {
  margin-bottom: 0;
}

:deep(.v-data-footer) {
  display: none;
}

:deep(.select .v-input__slot::before),
:deep(.select .v-input__slot::after) {
  display: none !important;
}

:deep(.v-expansion-panel::before) {
  box-shadow: none;
}
</style>
