<template>
  <div id="header-wrapper">
    <div id="header" class="d-flex justify-space-between" style="gap: 16px">
      <div class="d-flex align-center" style="gap: 16px" v-if="myChatUser">
        <div
          id="logo"
          class="d-flex align-center justify-center"
          @click="
            $emit('navigateTo', {
              tabName: $consts.CHAT_NAVIGATION_TAB.PROFILE + '-' + $consts.CHAT_PROFILE_TYPE.ME,
            })
          "
        >
          <v-icon v-if="!myChatUser.photo?.url" :size="36" color="white" class="ma-auto">mdi-account</v-icon>
          <img v-else :src="myChatUser.photo?.url" />
        </div>
        <span id="title">{{ myChatUser.name }}</span>
      </div>
      <v-skeleton-loader v-else type="list-item-avatar"></v-skeleton-loader>
      <div
        id="new-chat-button"
        class="d-flex align-center"
        @click="
          $emit('navigateTo', {
            tabName: $consts.CHAT_NAVIGATION_TAB.CONTACT_LIST,
          })
        "
      >
        <v-icon color="black">mdi-plus</v-icon> Novo Chat
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: {},
  computed: {
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
    }),
  },
  props: {
    myChatUser: {
      type: Object,
    },
  },
}
</script>
<style scoped>
@container (max-width: 345px) {
  #header {
    flex-direction: column;
  }
}

#header-wrapper {
  background-color: var(--v-primary-base);
  padding: 10px 16px;

  container-type: inline-size;
}

#title {
  color: white;
  font-weight: 700;
  font-size: 18px;
}

#logo,
#logo img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: contain;
}

#logo {
  background-color: gray;
}

#logo img {
  background-color: white;
}

#logo:hover {
  cursor: pointer;
}

#new-chat-button {
  gap: 8px;
  padding: 16px 24px;
  background-color: #fced52;
  font-weight: 700;
  border-radius: 8px;
}

#new-chat-button:hover {
  cursor: pointer;
  background-color: #e4d74a;
}

:deep(.v-skeleton-loader__list-item-avatar.v-skeleton-loader__bone) {
  background-color: transparent;
  padding: 0;
}
</style>
