import router from '@/router'
import axios from 'axios'
import firebase from 'firebase/app'

import store from '../store'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response?.status === 401) {
      router.push({ path: '/' })
    } else {
      let message = error?.response?.data?.message
      if (!!message && message.length > 0) {
        store.state.globalAlertComponent.showError(message)
      }
      return Promise.reject(error)
    }
  },
)

apiClient.interceptors.request.use(
  async function (config) {
    let token
    try {
      token = await firebase.auth().currentUser?.getIdToken()
    } catch (e) {
      await firebase.auth().signOut()
      router.push({ path: '/login' })
      throw e
    }
    const headers = { Authorization: `Bearer ${token}` }
    if (config.headers) {
      config.headers = { ...config.headers, ...headers }
    } else {
      config.headers = headers
    }

    if (config.params) {
      const params = new URLSearchParams()
      for (const key in config.params) {
        if (config.params[key] !== null && config.params[key] !== undefined) {
          params.append(key, config.params[key])
        }
      }
      config.params = params
    }
    return config
  },
  async function (error) {
    return Promise.reject(error)
  },
)

export default {
  ping() {
    return apiClient.get('public/ping')
  },
  getRoles() {
    return apiClient.get('user/roles/me')
  },

  getUserByEmail(email) {
    return apiClient.get(`user/email/${email}`)
  },

  getCrud(endpoint) {
    return apiClient.get(`crud/${endpoint}`)
  },

  createCrud(endpoint, entity) {
    return apiClient.post(`crud/${endpoint}`, entity)
  },
  updateCrud(endpoint, entity) {
    return apiClient.put(`crud/${endpoint}`, entity)
  },
  deleteCrud(endpoint, entityId) {
    return apiClient.delete(`crud/${endpoint}/${entityId}`)
  },
  restoreCrud(endpoint, entityId) {
    return apiClient.patch(`crud/${endpoint}/${entityId}`)
  },
  getDemand(demandId) {
    return apiClient.get(`demand/id/${demandId}`)
  },
  getMyDashboard() {
    return apiClient.get(`user/dashboard/me`)
  },
  listDemands() {
    return apiClient.get(`demand`)
  },
  listCompactDemands() {
    return apiClient.get(`demand/compact`)
  },
  createDemand(demand) {
    return apiClient.post('demand', demand)
  },
  updateDemand(demand) {
    return apiClient.put('demand', demand)
  },
  updateCompactDemand(demand) {
    return apiClient.put('demand/compact', demand)
  },
  listRealEstateTypes() {
    return apiClient.get('list/real-estate/type')
  },
  listRealEstateCategories() {
    return apiClient.get('list/real-estate/category')
  },
  listCities() {
    return apiClient.get('list/city')
  },
  listStates() {
    return apiClient.get('list/state')
  },
  listServices() {
    return apiClient.get('list/service')
  },
  listMaterials() {
    return apiClient.get('material/dto')
  },
  getMaterial(id) {
    return apiClient.get(`material/${id}`)
  },
  listTasks(category) {
    return apiClient.get(`list/task/${category}`)
  },
  reviewTask(demandServiceTaskId) {
    return apiClient.patch(`demand/demandServiceTask/${demandServiceTaskId}/review`)
  },
  listServiceCategories() {
    return apiClient.get('list/service-category')
  },
  listMaterialCategories() {
    return apiClient.get('material/category')
  },
  listMaterialsByServiceId(serviceId) {
    return apiClient.get(`material/service/${serviceId}`)
  },
  addServiceMaterialsByServiceId(materialId, serviceId) {
    return apiClient.post(`service-material/service/${serviceId}/material/${materialId}`, materialId)
  },
  deleteServiceMaterialsByServiceId(materialId, serviceId) {
    return apiClient.delete(`service-material/service/${serviceId}/material/${materialId}`, materialId)
  },
  listMaterialItemsByDemandServiceItems(demandServiceItems) {
    return apiClient.get('material-item/demandServiceItems', demandServiceItems)
  },
  listMaterialSizes() {
    return apiClient.get('material-item/sizes')
  },
  listMaterialBrands() {
    return apiClient.get('material/brands')
  },
  createMaterialItem(item) {
    return apiClient.post('material-item', item)
  },
  updateMaterialItem(item) {
    return apiClient.put('material-item', item)
  },
  createMaterial(item) {
    return apiClient.post('material', item)
  },
  updateMaterial(item) {
    return apiClient.put('material', item)
  },
  addNewDemandServices(demandId, serviceIdList) {
    return apiClient.put(`demand/${demandId}/service/${serviceIdList}`)
  },
  addNewDemandServiceCategories(demandId, serviceCategoryIdList) {
    return apiClient.put(`demand/${demandId}/service-category/${serviceCategoryIdList}`)
  },
  removeDemandServiceCategory(demandId, serviceCategoryId) {
    return apiClient.delete(`demand/${demandId}/service-category/${serviceCategoryId}`)
  },
  listWorkPartners() {
    return apiClient.get(`list/work-partner`)
  },
  addRealEstateAgency(realEstateAgency) {
    return apiClient.post('real-estate-agency', realEstateAgency)
  },
  updateRealEstateAgency(realEstateAgency) {
    return apiClient.put(`real-estate-agency`, realEstateAgency)
  },
  addEstimate(demandId, estimate) {
    return apiClient.post(`demand/${demandId}/add-estimate`, estimate)
  },
  updateEstimate(estimate) {
    return apiClient.put('demand/update-estimate', estimate)
  },
  cancelEstimate(estimateId, observation) {
    return apiClient.patch(`demand/cancel-estimate/${estimateId}`, observation)
  },
  removeDemandService(demandId, serviceId) {
    return apiClient.delete(`demand/${demandId}/remove-service/${serviceId}`)
  },
  startDemand(demandId) {
    return apiClient.patch(`demand/${demandId}/start`)
  },
  cancelDemand(demandId, observation) {
    return apiClient.patch(`demand/${demandId}`, observation)
  },
  addVisit(demandId, visit) {
    return apiClient.post(`demand/${demandId}/add-visit`, visit)
  },
  updateVisit(visit) {
    return apiClient.put('demand/update-visit', visit)
  },
  updateVisitStatus(visitId, newStatusId, reason) {
    if (reason) {
      return apiClient.patch(`demand/update-visit-status/${visitId}/${newStatusId}`, reason)
    }
    return apiClient.patch(`demand/update-visit-status/${visitId}/${newStatusId}`)
  },
  uploadDemandFile(demandId, file, documentType) {
    const formData = new FormData()
    formData.append('file', file)
    return apiClient.post(`demand/${demandId}/add-document/${documentType}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  removeDemandFile(demandId, fileId) {
    return apiClient.delete(`demand/${demandId}/remove-document/${fileId}`)
  },
  getDocument(code) {
    return apiClient.get(`storage/file/${code}`)
  },
  finishDemand(demandId) {
    return apiClient.patch(`demand/${demandId}/finish`)
  },
  updateDemandBundle(demandId, bundleId, selectedServices) {
    return apiClient.put(`demand/${demandId}/bundle/${bundleId}`, selectedServices)
  },
  updateDemandServiceQuantity(demandId, serviceId, quantity) {
    return apiClient.patch(`demand/${demandId}/update-service-quantity/${serviceId}/${quantity}`)
  },
  listClients() {
    return apiClient.get('list/client')
  },
  updateRealEstate(clientId, realEstate) {
    return apiClient.put(`client/${clientId}/real-estate`, realEstate)
  },
  deleteRealEstate(clientId, realEstateId) {
    return apiClient.delete(`client/${clientId}/real-estate/${realEstateId}`)
  },
  sendEmail(file, email, subject, body) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('subject', subject)

    if (body) formData.append('body', body)

    return apiClient.post(`demand/sendProposalToMail/${email}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  uploadFile(file) {
    const formData = new FormData()
    formData.append('file', file)

    return apiClient.post(`storage/file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  archiveDemand(demandId) {
    return apiClient.put(`demand/${demandId}/archive`)
  },
  updateUserFirebaseToken(token) {
    return apiClient.put(`user/firebase-token/me`, { token })
  },
  updateUserFirstAccess(userId) {
    return apiClient.put(`user/first-access/${userId}`)
  },
  verifyNeedsToRateCrm() {
    return apiClient.get(`rating/verify-needs-to-rate`)
  },
  verifyNeedsToRateWorkPartner() {
    return apiClient.get(`work-partner-rating`)
  },
  ratingWorkPartner(rating) {
    return apiClient.post(`work-partner-rating`, rating)
  },
  getRatings() {
    return apiClient.get(`rating`)
  },
  createRating(rating) {
    return apiClient.post(`rating`, rating)
  },
  updateProposalInfoField(realEstateAgencyId, field, value) {
    return apiClient.patch(`real-estate-agency/proposal/${realEstateAgencyId}?field=${field}&value=${value}`)
  },
  updateRealEstateAgencySocialMedias(realEstateAgency) {
    return apiClient.put(`real-estate-agency/social-medias`, realEstateAgency)
  },
  getRealEstateAgency(realEstateAgencyId) {
    return apiClient.get(`real-estate-agency/${realEstateAgencyId}`)
  },
  getRealEstateAgencies() {
    return apiClient.get(`real-estate-agency`)
  },
  addRealEstateAgencyProposalInfoLogo(realEstateAgencyId, file) {
    const formData = new FormData()
    formData.append('file', file)

    return apiClient.put(`real-estate-agency/proposal-info/add-logo/${realEstateAgencyId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  getBase64Image(imageUrl) {
    const encodedImageUrl = encodeURIComponent(imageUrl)
    return apiClient.get(`public/get-base64?imageUrl=${encodedImageUrl}`)
  },
  getAssociates(realEstateAgencyId) {
    return apiClient.get(`associate/real-estate-agency/${realEstateAgencyId}`)
  },
  getAvailableAssociates(realEstateAgencyId) {
    return apiClient.get(`associate/available/real-estate-agency/${realEstateAgencyId}`)
  },
  createAssociate(realEstateAgencyId, workPartnerIds) {
    return apiClient.post(`associate/real-estate-agency/${realEstateAgencyId}`, workPartnerIds)
  },
  updateAssociate(associate) {
    return apiClient.put(`associate`, associate)
  },
  deleteAssociate(associateId) {
    return apiClient.delete(`associate/${associateId}`)
  },
  getStates() {
    return apiClient.get('location/state')
  },
  getCities(stateId) {
    return apiClient.get(`location/city/${stateId}`)
  },
  getMyChatUser() {
    return apiClient.get('chat/me')
  },
  getAvailableChats() {
    return apiClient.get('chat/available-chats')
  },
  getChatContacts() {
    return apiClient.get('chat/contacts')
  },
  getChatByOtherId(otherId, page) {
    return apiClient.get(`chat/${otherId}?page=${page}`)
  },
  sendChatMessage(otherId, content) {
    return apiClient.post(`chat/message/text`, {
      otherId,
      content,
    })
  },
  sendChatFile(otherId, file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('otherId', otherId)

    return apiClient.post(`chat/message/file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  getNewChatMessages(otherId) {
    return apiClient.get(`chat/unread/${otherId}`)
  },
  uploadChatProfilePhoto(file) {
    const formData = new FormData()
    formData.append('file', file)

    return apiClient.put(`chat/me/photo`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  deleteChatProfilePhoto() {
    return apiClient.delete(`chat/me/photo`)
  },
}
