<template>
  <v-sheet class="transparent" style="width: 100%">
    <w-demand-files
      ref="imagesDialog"
      title="Imagens da solicitação"
      :max-file-size="$consts.MAX_FILE_SIZE.DEMAND"
      :document-type="$consts.DOCUMENT_TYPE.IMAGE"
      @files-count-changed="$refs.crud.refreshList()"
    />
    <w-demand-files
      ref="filesDialog"
      title="Arquivos da solicitação"
      :max-file-size="$consts.MAX_FILE_SIZE.DEMAND"
      @files-count-changed="$refs.crud.refreshList()"
    />

    <w-table
      ref="crud"
      title="Solicitações"
      :load-action="loadAction"
      :edit-action="editAction"
      :custom-refresh="customRefresh"
      sort-by="createdAt"
      :sort-desc="true"
      :headers="headers"
      :fill-update-form="onEdit"
      :before-send="(item) => beforeSend(item)"
      :can-edit="(item) => canEditItem(item)"
      :modelGenerator="() => ({ client: {}, realEstateId: null, city: {}, formattedEstimatedStart: null })"
      :customActions="[
        {
          action: showDemandDetails,
          text: 'Abrir solicitação',
          icon: 'mdi-format-list-bulleted',
          key: 'solicitacao',
        },
        {
          action: showDemandImages,
          text: 'Imagens da solicitação',
          icon: 'mdi-image-multiple',
          key: 'demand-images',
        },
        {
          action: showDemandDocuments,
          text: 'Demais arquivos da solicitação',
          icon: 'mdi-paperclip',
          key: 'demand-documents',
        },
      ]"
      @create="onCreateDemand"
    >
      <template #status="{ item }">
        <v-chip
          :dark="[$consts.STATUS.DONE, $consts.STATUS.CANCELLED].includes(item.currentStatus.id)"
          :color="item.vchip.color"
          >{{ item.vchip.name }}</v-chip
        >
      </template>
      <template #form="{ item }">
        <v-text-field
          label="Título"
          v-model="item.title"
          class="required"
          :rules="[$validationRules.required.rule]"
          counter="100"
        />
        <v-textarea
          outlined
          label="Descrição"
          v-model="item.description"
          class="required"
          :rules="[$validationRules.required.rule]"
        />

        <v-select
          v-if="isAdmin"
          label="Imobiliária"
          v-model="item.realEstateAgency"
          :items="realEstateAgencies"
          item-text="name"
          item-value="id"
          class="required"
          :rules="[$validationRules.required.rule]"
          return-object
        />

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFormatted"
              label="Início do serviço"
              persistent-hint
              prepend-icon="mdi-calendar"
              readonly
              :rules="[$validationRules.required.rule]"
              class="required"
              v-on="on"
              @blur="date = parseDate(dateFormatted)"
            ></v-text-field>
          </template>
          <v-date-picker locale="pt-br" v-model="date" no-title @input="menu = false"></v-date-picker>
        </v-menu>
      </template>
    </w-table>
  </v-sheet>
</template>

<script>
import services from '@/api/services'
import WDemandFiles from '@/components/WDemandFiles.vue'
import WTable from '@/components/WTable.vue'
import { mask } from 'vue-the-mask'
import { createNamespacedHelpers, mapGetters } from 'vuex'

createNamespacedHelpers('user/realEstateAgency')

export default {
  components: { WTable, WDemandFiles },
  directives: { mask },
  methods: {
    customRefresh() {
      this.$emit('updateDemands')
    },
    canEditItem(item) {
      return this.isAdmin || item.realEstateAgency?.id == this.myRealEstateAgency?.id
    },
    beforeSend(item) {
      item.estimatedStart = this.date

      item.realEstateAgencyId = item.realEstateAgency.id
    },
    onEdit(model, selectedItem) {
      if (selectedItem.estimatedStart) {
        this.date = this.$moment(selectedItem.estimatedStart).toISOString().substr(0, 10)
        this.dateFormatted = this.formatDate(this.date)
      }
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    clearCpfOrCnpj(item) {
      this.$nextTick(() => {
        item.client.cpfCnpj = ''
      })
    },
    showDemandImages(demand) {
      this.$refs.imagesDialog.open(
        demand.id,
        demand.documents?.filter((d) => d.type === this.$consts.DOCUMENT_TYPE.IMAGE),
        true,
        true,
      )
      return Promise.resolve()
    },
    showDemandDocuments(demand) {
      this.$refs.filesDialog.open(
        demand.id,
        demand.documents?.filter((d) => d.type !== this.$consts.DOCUMENT_TYPE.IMAGE),
        true,
        true,
      )
      return Promise.resolve()
    },
    async loadAction() {
      this.$emit('updateDemands')

      return Promise.resolve({
        data: this.demands,
      })
    },
    createAction(item) {
      return services.createDemand(item)
    },
    editAction(item) {
      return services.updateCompactDemand(item)
    },
    async showDemandDetails(demand) {
      const demandDetails = (await services.getDemand(demand.id)).data
      this.$router.push({
        name: 'Details',
        params: { demandId: demandDetails.id },
      })
      return Promise.resolve({})
    },
    async onCreateDemand(newDemand) {
      this.showDemandDetails(newDemand)
      this.clients = (await services.listClients()).data
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    ...mapGetters({
      myRealEstateAgency: 'myRealEstateAgency',
      isAdmin: 'isAdmin',
    }),
  },
  props: {
    demands: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      realEstateAgencies: [],
      date: null,
      dateFormatted: null,
      menu: false,
      clients: [],
      searchClient: false,
      searchAddress: true,
      showDemand: false,
      realEstateCategories: [{ id: 1, name: 'Residencial' }],
      realEstateTypes: [{ id: 2, name: 'Apartamento' }],
      cities: [],
      states: [],
      headers: [
        { text: 'Título', value: 'title' },
        {
          text: 'Início',
          value: 'estimatedStart',
          converter: (item) => this.formatDate(item.estimatedStart?.split('T')[0]),
        },
        { text: 'Nome do cliente', value: 'client.name' },
        { text: 'Email do cliente', value: 'client.email' },
        { text: 'Telefone do cliente', value: 'client.phone' },
        { text: 'Bairro', value: 'district' },
        { text: 'Cidade', value: 'city.name' },
        { text: 'Estado', value: 'city.state.shortName' },

        { text: 'Status', value: 'status', align: 'end' },
        {
          text: 'Imobiliária',
          value: 'realEstateAgency.name',
          converter: (item) => item.realEstateAgency?.name || '-',
        },
        {
          text: 'Criada em',
          value: 'createdAt',
          converter: (item) => this.$moment(item.createdAt).format('DD/MM/YYYY HH:mm'),
        },
      ],
      demand: { services: [] },
    }
  },
  async created() {
    this.realEstateAgencies = (await services.getCrud('real-estate-agency')).data
    this.realEstateTypes = (await services.listRealEstateTypes()).data
    this.realEstateCategories = (await services.listRealEstateCategories()).data
    this.cities = (await services.listCities()).data
    this.states = (await services.listStates()).data
    this.clients = (await services.listClients()).data
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
}
</script>
