<template>
  <div>
    <div class="d-flex flex-row">
      <v-autocomplete
        :loading="loading"
        dense
        label="Material"
        :items="allMaterialsToShow || []"
        return-object
        :item-text="(data) => data.name + (data.description === 'Não informado' ? '' : ' ' + data.description)"
        item-value="id"
        v-model="materialToAdd"
      />

      <v-btn
        color="primary"
        small
        @click="() => addServiceMaterial()"
        class="ml-4"
        style="font-size: 16px; font-weight: bold"
        :disabled="!materialToAdd"
        >+</v-btn
      >
    </div>

    <div v-if="serviceMaterials.length > 0" style="width: 100%" class="d-flex flex-column">
      <div
        v-for="material in serviceMaterials"
        :key="material.id"
        class="d-flex flex-row justify-space-between pa-2"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
      >
        <p style="color: rgba(0, 0, 0, 0.87)" class="ma-0">
          {{ material.name + (material.description === 'Não informado' ? '' : ' ' + material.description) }}
        </p>
        <v-btn small icon @click="deleteServiceMaterial(material)" title="Desvincular">
          <v-icon color="red">mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else>
      <p>Nenhum material adicionado</p>
    </div>
  </div>
</template>

<script>
import { default as api } from '../api/services'

export default {
  data() {
    return {
      show: false,
      materialToAdd: null,
      allMaterialsToShow: [],
      loading: false,
    }
  },

  props: {
    serviceMaterials: {
      type: Array,
      default: () => [],
    },
    currentService: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async fillMaterialList() {
      this.loading = true
      this.materialToAdd = null
      this.allMaterialList = (await api.listMaterials()).data

      this.updateMaterialList(this.serviceMaterials)
      this.loading = false
    },
    async updateMaterialList(serviceMaterials) {
      this.allMaterialsToShow = this.allMaterialList.filter((m) => {
        return !serviceMaterials.find((sm) => sm.id === m.id)
      })
    },
    async deleteServiceMaterial(material) {
      try {
        const serviceMaterials = (await api.deleteServiceMaterialsByServiceId(material.id, this.currentService.id)).data
        this.$emit('update:serviceMaterials', serviceMaterials)
        this.updateMaterialList(serviceMaterials)
        this.materialToAdd = null
      } catch (err) {
        console.error(err)
      }
    },
    async addServiceMaterial() {
      if (!this.materialToAdd) return

      try {
        const serviceMaterials = (
          await api.addServiceMaterialsByServiceId(this.materialToAdd.id, this.currentService.id)
        ).data
        this.$emit('update:serviceMaterials', serviceMaterials)
        this.updateMaterialList(serviceMaterials)
        this.materialToAdd = null
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>
